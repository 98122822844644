import axios from 'axios';
import { logger } from '../../utils/logger';
axios.defaults.withCredentials = true;

const baseURL = '/api/auth/logout';
const tokenURL = '/api/auth/token';

/**
 * Service function responsible for logging out the user. The service_structure_comment.md file contains a detailed description
 * of the structure this service function is based on (structure 2), but it differs in the last catch block. Instead of returning
 * [false, error], it simply logs the error. This is due to the fact that we still want the user to be logged out even if some
 * error would occur.
 *
 * Logging out wipes the authentication cookies and redirects to the login page.
 * @returns response from the backend
 */
export const logout = async () => {
  try {
    const response = await axios.post(baseURL, { withCredentials: true });
    return response.data;
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.post(baseURL, { withCredentials: true });
      return response.data;
    } catch (e) {
      //Some error occured, just simply log it
      logger(e);
    }
  }
};
