import axios from 'axios';
axios.defaults.withCredentials = true;

const baseURL = '/api/area';
const tokenURL = '/api/auth/token';

/**
 * Service function responsible for moving a chargepoint.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @param {*} cpId if of the chargepoint
 * @param {*} data data object that contains the old and new area ids as well as the chargepoint id
 * @returns response from backend
 */
export const sendChargepointMove = async (cpId, data) => {
  try {
    const response = await axios.post(
      `${baseURL}/${data.old_area_id}/chargepoint/${cpId}/move`,
      data,
      { withCredentials: true }
    );
    return response.data;
  } catch (e) {
    //authenticate and try again
    await axios.post(tokenURL, { withCredentials: true });
    const response = await axios.post(
      `${baseURL}/${data.old_area_id}/chargepoint/${cpId}/move`,
      data,
      { withCredentials: true }
    );
    return response.data;
  }
};
