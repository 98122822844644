import axios from 'axios';
axios.defaults.withCredentials = true;

const tokenURL = '/api/auth/token';

/**
 * Service function responsible authorizing a user to an area.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @param {*} areaId id of the area where the user will be authorized to charge at
 * @param {*} data data containing the email of the user to be authorized
 * @returns if successful: [true, response.data]. Otherwise: [false, error]
 */

export const authorizeUser = async (areaId: number, data: any) => {
  try {
    const response = await axios.post(`/api/area/${areaId}/authorize`, data, {
      withCredentials: true,
    });
    return [true, response.data];
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.post(`/api/area/${areaId}/authorize`, data, {
        withCredentials: true,
      });
      return [true, response.data];
    } catch (e) {
      return [false, e];
    }
  }
};
