import styled from 'styled-components';

//Styled-component for the contract component
export const StyledContract = styled.div`
  .date-time-picker {
    width: 30%;
    @media (min-width: ${({ theme }) => theme.laptop}) {
      height: 50%;
    }
  }
  .date-time-picker > * {
    flex: 1 1 150px;
  }

  h1,
  h2,
  h3 {
    text-align: center;
    flex-basis: 100%;
    margin-bottom: 1rem;
  }

  background: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  font-size: 1rem;

  .loading::before {
    content: 'Loading...';
    display: block;
    opacity: 1;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);

    font-size: 5em;

    @media (max-width: ${({ theme }) => theme.mobileL}) {
      font-size: 1.5rem;
    }

    @media (max-width: ${({ theme }) => theme.tablet}) {
      font-size: 2rem;
    }
    position: absolute;
  }
`;
