import styled from 'styled-components';

/**
 * Styled component for the registration form
 */
export const StyledHelp = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  background: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  font-size: 1rem;
  overflow-x: auto;

  .row {
    justify-content: center;
  }
`;
