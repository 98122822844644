import styled from 'styled-components';

/** Styled component for the reporting component */
export const StyledReporting = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-flow: row wrap;
  overflow-x: auto;
  background: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  font-size: 1rem;

  h2,
  h3 {
    text-align: center;
    flex-basis: 100%;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 0;
  }

  .csv-link,
  .create-invoices {
    max-width: max-content;
  }

  .create-invoices,
  .create-invoices:active {
    background-color: ${({ theme }) => theme.background};
    border: none;
    color: ${({ theme }) => theme.lightGreen};
    padding-top: 0;
    padding-bottom: 0;
    cursor: pointer;
    transition: none;

    &:focus {
      box-shadow: none !important;
    }

    &:hover {
      color: ${({ theme }) => theme.lightGreenHover};
      text-decoration: underline;
    }
  }

  .invoice-alert-col {
    > * {
      height: 100%;
      margin: 0;
      padding: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }

  .send-to-paytrail {
    width: 9rem;
    text-align: left;
    margin: 1rem;
  }

  .infoIcon {
    height: 1rem;
    width: 1.5rem;
  }
`;
