import axios from 'axios';
axios.defaults.withCredentials = true;

const tokenURL = '/api/auth/token';

export const sendInvoicesToPaytrail = async (data) => {
  try {
    const response = await axios.post('/api/admin/invoicing/chargeTokens', data);
    return response.data;
  } catch (e) {
    await axios.post(tokenURL, { withCredentials: true });
    const response = await axios.post('/api/admin/invoicing/chargeTokens', data);
    return response.data;
  }
};
