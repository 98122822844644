import axios from 'axios';
axios.defaults.withCredentials = true;
const tokenURL = '/api/auth/token';

/**
 * Service function responsible for adding a new card through PayTrail
 *
 * @returns response from the backend
 */
export const sendTokenizationId = async (body) => {
  await axios.post(tokenURL, { withCredentials: true });
  await axios.post('/api/user/cards/addTokenizationId', body);
};
