import {
  InvoiceChannel,
  ContractStatus,
  InvoicingMethod,
  Key,
  Nullable,
} from '../Utilities/Types';

export interface Contract {
  id?: number;
  contract_number?: number;
  name?: string;
  business_id?: string;
  address?: string;
  street_address: string;
  zip?: string;
  city?: string;
  country?: string;
  address_specifier?: Nullable<string>;
  contract_status?: Key<typeof ContractStatus>;
  bank_account: Nullable<string>;
  report_receivers: string;
  vat?: number;
  invoice_channel?: Key<typeof InvoiceChannel>;
  invoicing_method?: Key<typeof InvoicingMethod>;
  billing_address?: string;
  invoice_reference: Nullable<string>;
  payment_reference: Nullable<string>;
  einvoice_address?: Nullable<string>;
  einvoice_edi?: Nullable<string>;
  einvoice_operator?: Nullable<string>;
  invoice_email?: string;
  primary_manager?: Nullable<string>;
  notes: string;
  time_created?: string;
  time_updated?: string;
  terms_accepted: Nullable<string>;
  default_chargepoint_price: number;
}

export const DefaultContract: Contract = {
  id: -1,
  contract_number: -1,
  name: '',
  business_id: '',
  address: '',
  bank_account: '',
  street_address: '',
  zip: '',
  city: '',
  country: '',
  billing_address: '',
  invoice_reference: '',
  payment_reference: '',
  report_receivers: '',
  notes: '',
  time_created: '',
  time_updated: '',
  terms_accepted: '',
  primary_manager: '',
  default_chargepoint_price: 0.15,
};
