import axios from 'axios';
import StructuredResponse from '../../../model/Classes/StructuredResponse';
import { CompletedTransaction } from '../../../model/Classes/Transaction';
axios.defaults.withCredentials = true;

const tokenURL = '/api/auth/token';

/**
 * Service function responsible for fetching all completed transactions for a specific month and year for a user.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @param {*} year the specified year
 * @returns if successful: [true, response.data]. Otherwise: [false, error]
 */
export const getMyYearlyTransactions = async (year) => {
  try {
    const response = await axios.get(`/api/myTransactions/${year}`);
    return new StructuredResponse<CompletedTransaction[]>(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(`/api/myTransactions/${year}`);
      return new StructuredResponse<CompletedTransaction[]>(
        true,
        response.data
      );
    } catch (e: any) {
      return new StructuredResponse<CompletedTransaction[]>(false, e);
    }
  }
};
