import { Nullable } from '../Utilities/Types';

export default interface AreaMeter {
  id?: number;
  public_id: number;
  slave_id: number;
  status: AreaMeterStatus;
  area_id: Nullable<number>;
}

export const EmptyMeter: AreaMeter = {
  id: 0,
  public_id: 0,
  slave_id: 0,
  status: 2,
  area_id: null,
};

export const MeterStatus = {
  isNew: 0,
  isConfigured: 1,
  isReserved: 2,
  isInstalled: 3,
  outOfUse: 4,
  onOtherServer: 5,
} as const;

export type AreaMeterStatus = typeof MeterStatus[keyof typeof MeterStatus];
