import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en, sv, fi } from './resources/langs/locales';
import LanguageDetector from 'i18next-browser-languagedetector';

const options = {
  //should theoretically detect browser language based on these options
  order: [
    'querystring',
    'cookie',
    'sessionStorage',
    'localStorage',
    'navigator',
    'htmlTag',
  ],
  lookupQuerystring: 'lng',
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: ['fi', 'en', 'sv'],
    detection: options,
    debug: false,
    ns: 'common',
    defaultNS: 'common',
    resources: {
      en,
      fi,
      sv,
    },
    keySeparator: '.',
    supportedLngs: ['fi', 'en', 'sv'],
    interpolation: {
      prefix: '{{',
      suffix: '}}',
      formatSeparator: ',',
    },
    lng: localStorage.getItem('currLang') || 'fi',
  });

export default i18n;
