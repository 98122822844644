import axios from 'axios';
import StructuredResponse from '../../model/Classes/StructuredResponse';

const tokenURL = '/api/auth/token';

declare type SimpleResponse = {
  status: number;
  message: string;
};

export const updatePrimaryManager = async (
  contract_number: number,
  email: string
) => {
  try {
    const response = await axios.post(
      `/api/admin/contract/primary/${contract_number}`,
      { email: email },
      {
        withCredentials: true,
      }
    );
    return new StructuredResponse<SimpleResponse>(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.post(
        `/api/admin/contract/primary/${contract_number}`,
        { email: email },
        {
          withCredentials: true,
        }
      );
      return new StructuredResponse<SimpleResponse>(true, response.data);
    } catch (e: any) {
      return new StructuredResponse<SimpleResponse>(false, e);
    }
  }
};
