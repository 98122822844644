import { Row, Col, Button } from 'react-bootstrap';
import Tooltip from './Tooltip';

declare interface NotificationButtonProps {
  noTooltip?: boolean;
  tooltipText?: string;
  buttonText: string;
  clickFunc: () => void;
  variant?: string;
  tooltipPlacement?: any;
}

const NotificationButton = ({
  noTooltip,
  tooltipText,
  buttonText,
  clickFunc,
  variant,
  tooltipPlacement,
}: NotificationButtonProps) => (
  <Row className="mb-3">
    <Col style={{ textAlign: 'center' }}>
      {noTooltip ? (
        <Button variant={variant || 'primary'} onClick={clickFunc}>
          {buttonText}
        </Button>
      ) : (
        <Tooltip
          text={tooltipText}
          placement={tooltipPlacement}
          variant={variant}
        >
          <Button variant={variant || 'primary'} onClick={clickFunc}>
            {buttonText}
          </Button>
        </Tooltip>
      )}
    </Col>
  </Row>
);

export default NotificationButton;
