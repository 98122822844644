import axios from "axios";
axios.defaults.withCredentials = true;

const baseURL = "/api/area";
const tokenURL = "/api/auth/token";

/**
 * Service function responsible for adding a new chargepoint.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @param {*} parentId the id of the area the chargepoint will be added to
 * @param {*} data chargepoint data
 * @returns response from the backend
 */
export const addChargepoint = async (parentId, data) => {
  try {
    const response = await axios.post(`${baseURL}/${parentId}/chargepoint/add`, data, { withCredentials: true });
    return response.data;
  } catch (e) {
    await axios.post(tokenURL, { withCredentials: true });
    const response = await axios.post(`${baseURL}/${parentId}/chargepoint/add`, data, { withCredentials: true });
    return response.data;
  }
};
