import { useState } from 'react';
import {
  Alert,
  Button,
  Modal,
  InputGroup,
  FormControl,
  Col,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';
import { CpBase } from '../../../../model/Classes/Chargepoint';
import { timer } from '../../../../utils/timer';

interface EditDeleteCpProps {
  chargepoint: CpBase;
  handleSubmitDeleteCp: () => Promise<boolean>;
  history: any;
  areaId: number;
}

/**
 * Component for deleting a chargepoint. When the user wants to delete a chargepoint, they need to confirm
 * the deleting process by typing in the name of the chargepoint in a field to activate the second delete
 * button. A user cannot delete a chargepoint that has active transactions (TBI)
 * @param {*} chargepoint the chargepoint to be deleted
 * @param {*} handleSubmitDeleteCp helper function passed from EditChargepoint.js that handles the delete submission
 * @param {*} history history object
 * @param {*} areaId id of the area this chargepoint belongs to
 * @returns delete view for chargepoint
 */
const EditDeleteCp = ({
  chargepoint,
  handleSubmitDeleteCp,
  history,
  areaId,
}: EditDeleteCpProps) => {
  const [showModal, setShowModal] = useState(false); //state for showing the confirmation popup
  const [activeButton, setActiveButton] = useState(false); //state for the "confirm delete" button in the popup
  const [showError, setShowError] = useState(false); //state for error alert
  const { t } = useTranslation('common', { i18n: i18n });
  /**
   * Helper funtion to check if the name inserted to the popup field is the same as
   * the name of the chargepoint. Only make the "delete chargepoint" button active when
   * the input is the same as the area name.
   * @param {*} event event target (the input)
   */
  const handleDeleteInput = (event) => {
    event.target.value === chargepoint.charge_point_id
      ? setActiveButton(true)
      : setActiveButton(false);
  };

  /**
   * Helper function for opening the delete popup
   */
  const openDeleteModal = () => {
    setShowModal(true);
    setShowError(false);
  };

  /**
   * Helper function for closing the delete popup
   */
  const closeDeleteModal = () => {
    setShowModal(false);
    setActiveButton(false);
  };

  /**
   * Helper function for deleting the chargepoint. It calls the function handleSubmitDeleteCp() which is passed
   * to this component from EditChargepoint.js. If the function returns true, push the user to the path
   * /area/areaId with a state informing the SingleArea.js component that a chargepoint was just deleted
   * (needed to display a success notification to the user). If the function returns false, display an
   * error message to the user.
   */
  const deleteCp = async () => {
    setShowModal(false);
    (await handleSubmitDeleteCp())
      ? history.push({
          pathname: `/area/${areaId}`,
          state: { cpDeleted: true },
        })
      : timer(setShowError);
  };

  /**
   * The component initially return
   * 1) an alert warning the user of deleting the chargepoint + the criteria needed to delete the
   *    chargepoint (no past or ongoing transactions)
   * 2) the delete button (active or inactive)
   * 3) possibly another alert notifying the user that a criteria was not satisfied
   *
   * If the user clicks the "delete chargepoint" button, a popup (modal) appears. The popup prompts the user
   * to insert the name of the chargepoint in the field. This is done to add another layer to deleting an area
   * (less error prone). When the user confirms the action, function deleteCp is called (description found in the
   * comments).
   */
  return (
    <>
      <Col>
        <Alert key="delete-warning" variant="danger">
          <div //icky wicky
            dangerouslySetInnerHTML={{
              __html: t('components.chargepoint.edit.tabs.delete.warning', {
                interpolation: { escapeValue: false },
              }),
            }}
          />
        </Alert>
      </Col>
      <Button className="mb-3" variant="danger" onClick={openDeleteModal}>
        {t('components.chargepoint.edit.tabs.delete.buttons.delete')}
      </Button>
      {/*The Modal component is used to display the popup*/}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static" //doesn't allow the user to close the modal by clicking outside of it
        keyboard={false} //doesn't allow the user to close the modal by using the keyboard
      >
        <Modal.Header>
          <Modal.Title>
            {t('components.chargepoint.edit.tabs.delete.modal.sure', {
              chargepoint_id: chargepoint.charge_point_id,
            })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div //icky wicky
            dangerouslySetInnerHTML={{
              __html: t(
                'components.chargepoint.edit.tabs.delete.modal.sureAgain',
                {
                  interpolation: { escapeValue: false },
                  chargepoint_id: chargepoint.charge_point_id,
                }
              ),
            }}
          />
          <InputGroup className="mt-3">
            <InputGroup.Text id={`${chargepoint.charge_point_id}-remove-input`}>
              {t('components.chargepoint.edit.tabs.delete.modal.type')}:
            </InputGroup.Text>
            <FormControl
              placeholder={chargepoint.charge_point_id}
              aria-label={chargepoint.charge_point_id}
              aria-describedby={chargepoint.charge_point_id}
              onChange={handleDeleteInput}
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          {activeButton ? (
            <Button variant="danger" onClick={deleteCp} active>
              {t('components.chargepoint.edit.tabs.delete.buttons.delete')}
            </Button>
          ) : (
            <Button variant="danger" disabled>
              {t('components.chargepoint.edit.tabs.delete.buttons.delete')}
            </Button>
          )}

          <Button variant="secondary" onClick={closeDeleteModal}>
            {t('global.buttons.cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
      {showError ? (
        <Col>
          <Alert key="delete-error" variant="danger">
            <div //icky wicky
              dangerouslySetInnerHTML={{
                __html: t(
                  'components.chargepoint.edit.tabs.delete.modal.specificError',
                  {
                    interpolation: { escapeValue: false },
                  }
                ),
              }}
            />
          </Alert>
        </Col>
      ) : null}
    </>
  );
};

export default EditDeleteCp;
