import { useEffect, useState } from 'react';
import { FormControl, Row, Col, InputGroup, Button, Alert } from 'react-bootstrap';
import { authorizeUser } from '../../../../services/area/authorizeUser';
import { revokeAuthorization } from '../../../../services/area/revokeAuthorization';
import { logger } from '../../../../utils/logger';
import { timer } from '../../../../utils/timer';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import User from '../../../../model/Classes/User';
import AreaSingle from '../../../../model/Classes/Area';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';
import Toggle from 'react-toggle';
import { toggleFreeCharging } from '../../../../services/area/toggleFreeCharging';
import 'react-toggle/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

/**
 * Component responsible for the functionality for authorizing and deauhtorizing users, as well as
 * render the "Authorization" tab in the single area page. The return value can be divided into
 * three categories:
 * 1) Authorize a new user to this area (header + input field + submit button)
 * 2) Table with all the rooted authorized users
 * 3) Table with all the inherited authorized users
 *
 * The components useEffect splits the parameter authorizedUsers into 2 arrays: users that have
 * rooted authorization to this area (i.e. received charging access directly to this area), and
 * users that have inherited authorization to this area (i.e. users that have been authorized to
 * this area from higher above, e.g. from the parent of this area)
 * @param {*} area the area we handle authorization for
 * @param {*} authorizedUsers all (directly and indirectly) authorized users to this area
 * @param {*} refreshAuthorizedUsers helper function for refreshing the authorized users
 * @returns authorized users tab in single area page
 */

declare interface AuthorizeUserProps {
  area: AreaSingle;
  authorizedUsers: User[];
  refreshAuthorizedUsers: () => Promise<void>;
}
const AuthorizeUser = ({ area, authorizedUsers, refreshAuthorizedUsers }: AuthorizeUserProps) => {
  const [authorizeEmail, setAuthorizeEmail] = useState(''); //state for the email input for authorizing new users
  const [rootedAuthorized, setRootedAuthorized] = useState<User[]>([]); //state for all the ROOTED authorized users
  const [inheritedAuthorized, setInheritedAuthorized] = useState<User[]>([]); //state for all the INHERITED authorized users

  const [emptyEmailAlert, setEmptyEmailAlert] = useState(false); //state for showing an alert notifying the user to insert an email to the field if empty
  const [invalidEmailAlert, setInvalidEmailAlert] = useState(false); //state for showing an alert notifying the user to insert a valid email to the field
  const [successAlert, setSuccessAlert] = useState(false); //state for showing an alert notifying the user if authorizing a new user was successful
  const [emailAlreadyAuthorized, setEmailAlreadyAuthorized] = useState(false); //state for showing an alert notifying the user that the email is already authorized to this area
  const [emailDoesNotExistAlert, setEmailDoesNotExistAlert] = useState(false); //state for showing an alert notifying the user that the inserted email does not exist
  const [otherErrorAlert, setOtherErrorAlert] = useState(false); //state for showing an alert notifying the user of any other errors

  const [successDeauthorize, setSuccessDeauthorize] = useState(false); //state for showing an alert notifying the user that deauthorizing a user was successful
  const [errorDeauthorize, setErrorDeauthorize] = useState(false); //state for showing an alert notifying the user that deauthorizing a user failed
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(false); //state for disabling the input fields and buttons
  const { t } = useTranslation('common', { i18n: i18n });
  /**
   * On each rerender, separate the authorized users to two arrays: rooted users (users that have been authorized
   * directly to this area), and inherited users (users that have been authorized to this area from higher above, e.g.
   * from the parent of this area).
   */
  useEffect(() => {
    //Initialize empty arrays for the rooted and inherited users
    let rooted: User[] = [];
    let inherited: User[] = [];

    //Loop through all the authorized users and push them to the corresponding array
    authorizedUsers.forEach((user: User) => {
      user.user_charging_authorization === area.id ? rooted.push(user) : inherited.push(user);
    });

    //Update the states to contain the correct users
    setRootedAuthorized(rooted);
    setInheritedAuthorized(inherited);
  }, [area.id, authorizedUsers]);

  /**
   * Helper function for validating the email input field when authorizing a new user to the area.
   * The function checks the following criteria:
   * 1) The input field cannot be empty.
   * 2) The input field must contains a valid email.
   * 3) The input field can't contain an already authorized email.
   *
   * Whenever a criteria is violated, a corresponding alert is shown.
   * @returns true if successful, false otherwise
   */
  const validate = () => {
    //Check if the input field is empty
    if (authorizeEmail === '') {
      setEmptyEmailAlert(true);
      return false;
    }

    //Variable re is a regular expresion for a valid email. NOTE! This was straight up copied from a
    //stack overflow question, so don't ask me for an explanation of it :D
    let re =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //Check if the input field contains an invalid email
    if (!re.test(authorizeEmail)) {
      setInvalidEmailAlert(true);
      return false;
    }

    let success = true;
    //For each auhtorized user, check if the input field matches one of the emails already authorized
    authorizedUsers.forEach((au: User) => {
      //If an already email hasn't been found yet, continue
      if (success) {
        //Check if the input field matches the email we're checking now
        if (au.email === authorizeEmail) {
          //A match was found, toggle the correct alert and set the success variable to false
          setEmailAlreadyAuthorized(true);
          success = false;
        }
      }
    });

    //Return either true of false
    return success;
  };

  /**
   * Helper function for calling the validate() function to validate the email input and calling
   * the service function authorizeUser() to send the data to the backend. If the response is
   * successful, refresh the authorized users (gets the new data) and display a success alert.
   * Otherwise, display an correct error alert.
   */
  const handleAuthorize = async () => {
    //Toggle the alerts off
    setEmptyEmailAlert(false);
    setInvalidEmailAlert(false);
    setEmailAlreadyAuthorized(false);

    //If the validation didn't pass, exit the function
    if (!validate()) return;

    //Data to be sent to the backend
    const data = {
      email: authorizeEmail,
    };

    setDisabledSubmitButton(true);

    const res = await authorizeUser(area.id!, data);
    //Check if the request was successful
    if (res[0]) {
      //The request was successful -> refresh the authorized users and display a
      //success alert
      refreshAuthorizedUsers();
      timer(setSuccessAlert);
    } else {
      if (res[1].response) {
        //If the email does not exist in the database, notify the user
        if (res[1].response.data === 'Unknown email') {
          timer(setEmailDoesNotExistAlert);
        } else {
          timer(setOtherErrorAlert);
        }
      }

      logger(res[1]);
    }

    setDisabledSubmitButton(false);
  };

  /**
   * Helper function for deauthorizing a user. The function calls the service function
   * revokeAuthorization that revokes the charging access for the user to this area.
   * If it's successful, refresh the authorized users and display a success alert.
   * Otherwise, display an error alert.
   *
   * NOTE! You can only deauthorize a user who has ROOTED authorization to this area.
   * @param {*} user the user to deathorize
   */
  const deauthorize = async (user: User) => {
    //Data with the users email
    const data = {
      email: user.email,
    };

    const res = await revokeAuthorization(area.id!, data);
    //Check if the request was successful
    if (res[0]) {
      //The request was successful -> refresh the authorized users and display a
      //success alert
      refreshAuthorizedUsers();
      timer(setSuccessDeauthorize);
    } else {
      //Some error occured
      logger(res[1]);
      //Set an error alert
      timer(setErrorDeauthorize);
    }
  };

  // Helper function to toggle free_charging for a user

  const toggleFreeChargingHandler = async (userId: number, email: string) => {
    const data = {
      user_id: userId,
    };
    const res = await toggleFreeCharging(area.id!, data);
    if (res.success) {
      toast.success(t('global.alert.success.toggleFreeCharging', { email }));
    } else {
      toast.error(t('global.alert.failure.toggleFreeCharging', { email }));
      refreshAuthorizedUsers();
    }
  };

  const authorizedColumns = [
    {
      dataField: 'id',
      hidden: true,
      text: '',
    },
    {
      dataField: 'first_name',
      text: t('components.userSettings.tabs.settings.first'),
      sort: true,
      sortFunc: ({ a, b, order, dataField, rowA, rowB }: any) => {
        if (a.localeCompare(b))
          if (order === 'asc') return 1;
          else return -1;
        else if (b.localeCompare(a))
          if (order === 'asc') return -1;
          else return 1;
        else {
          if (rowA.last_name.localeCompare(rowB.last_name)) return 1;
          else if (rowB.last_name.localeCompare(rowA.last_name)) return -1;
          else return 1;
        }
      },
    },
    {
      dataField: 'last_name',
      text: t('components.userSettings.tabs.settings.last'),
      sort: true,
      sortFunc: ({ a, b, order, dataField, rowA, rowB }: any) => {
        if (a.localeCompare(b))
          if (order === 'asc') return 1;
          else return -1;
        else if (b.localeCompare(a))
          if (order === 'asc') return -1;
          else return 1;
        else {
          if (rowA.first_name.localeCompare(rowB.first_name)) return 1;
          else if (rowB.first_name.localeCompare(rowA.first_name)) return -1;
          else return 1;
        }
      },
    },
    {
      dataField: 'email',
      text: t('components.loginPage.static.email'),
      sort: true,
    },
  ];

  /**
   * The return value for this component can be split into three categories:
   * 1) Authorize a new user to this area (header + input field + submit button)
   * 2) Table with all the rooted authorized users
   * 3) Table with all the inherited authorized users
   *
   * Additionally, alerts are shown whenever needed.
   */
  return (
    <>
      {/*CATEGORY 1: Authorize new users*/}
      <Row className="mb-3">
        <h5>{t('components.authorize.emailInsert')}</h5>
        <p>{t('components.authorize.userCharging')}</p>
        {/*Simple text input fiel for the users email*/}
        <InputGroup>
          <InputGroup.Text id="emailAuthorize">@</InputGroup.Text>
          <FormControl
            type="text"
            value={authorizeEmail}
            aria-label="Authorize email"
            aria-describedby="authorize email"
            disabled={disabledSubmitButton}
            onChange={(event) => setAuthorizeEmail(event.target.value)}
            data-cy="authorization-input"
          />
        </InputGroup>
        {/*Alert for either an empty input field, or an invalid email*/}
        {emptyEmailAlert && (
          <Alert key="emptyEmail" variant="danger" style={{ marginBottom: 0, marginTop: 10 }}>
            {t('global.alert.failure.emailEmpty')}
          </Alert>
        )}
        {invalidEmailAlert && (
          <Alert key="invalidEmail" variant="danger" style={{ marginBottom: 0, marginTop: 10 }}>
            {t('global.alert.failure.invalidEmail')}
          </Alert>
        )}
      </Row>
      {/*Row for the submit button*/}
      <Row className="mb-3">
        <Col>
          <Button
            variant="primary"
            onClick={handleAuthorize}
            data-cy="authorize-button"
            disabled={disabledSubmitButton}
          >
            {t('global.buttons.add.default')}
          </Button>
        </Col>
      </Row>
      {/*Alerts for successfully authorizing a user*/}
      {successAlert && (
        <Row>
          <Alert key="success" variant="success" data-cy="authorize-success">
            {t('global.alert.success.authorize')}
          </Alert>
        </Row>
      )}
      {/*Alerts for notifying the user that the email is already authorized to this area*/}
      {emailAlreadyAuthorized && (
        <Row>
          <Alert key="alreadyAuthorized" variant="warning" data-cy="already-authorized">
            {t('global.alert.failure.authorizeAlready')}
          </Alert>
        </Row>
      )}
      {/*Alerts for notifying the user that the email doesn't exist*/}
      {emailDoesNotExistAlert && (
        <Row>
          <Alert key="emailNotExisting" variant="warning" data-cy="email-does-not-exist">
            {t('global.alert.failure.emailNotFound')}
          </Alert>
        </Row>
      )}
      {/*Alert for any other error*/}
      {otherErrorAlert && (
        <Row>
          <Alert key="error" variant="success">
            {t('global.alert.failure.internalError')}
          </Alert>
        </Row>
      )}
      {/* Row for all the rooted authorized users to this area. If such users exist, display
          a table with those users. Otherwise, display a table notifying the user that no users
          are directly authorized to this area nor its subarea*/}
      <Row className="mb-3" data-cy="directly-table">
        <h5>{t('components.authorize.static.authorized')}</h5>
        {/*Alerts for successfully or failing deauthorization*/}
        {successDeauthorize && (
          <Alert key="successDeauthorize" variant="success" data-cy="deauthorization-success">
            {t('global.alert.success.deauthorize')}
          </Alert>
        )}
        {errorDeauthorize && (
          <Alert key="errorDeauthorize" variant="danger">
            {t('global.alert.failure.deauthorize')}
          </Alert>
        )}
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={rootedAuthorized}
          columns={[
            ...authorizedColumns,
            {
              dataField: 'freeChargingToggle',
              isDummyField: true,
              text: t('components.authorize.toggles.freeCharging'),
              headerClasses: 'text-center',
              classes: 'text-center',
              formatter: (cell, row) => (
                <Toggle
                  defaultChecked={row.free_charging}
                  onChange={() => toggleFreeChargingHandler(row.id, row.email)}
                />
              ),
            },
            {
              dataField: 'deauthorizeButton',
              isDummyField: true,
              text: t('components.authorize.buttons.deauthorize'),
              headerClasses: 'text-center',
              classes: 'text-center',
              formatter: (cell, row) => (
                <Button data-cy="deauthorize-button" onClick={() => deauthorize(row)}>
                  {t('components.authorize.buttons.deauthorize')}
                </Button>
              ),
            },
          ]}
        >
          {(props) => (
            <BootstrapTable
              striped
              bordered
              hover
              noDataIndication={t('components.authorize.static.noUsers')}
              {...props.baseProps}
            />
          )}
        </ToolkitProvider>
      </Row>
      {/* Row for all the inherited authorized users to this area. If such users exist, display
          a table with those users. Otherwise, display a table notifying the user that no users
          are inherited authorized to this area nor its subarea*/}
      <Row data-cy="inherited-table">
        <h5>{t('components.authorize.static.inherited')}</h5>
        <ToolkitProvider bootstrap4 keyField="id" data={inheritedAuthorized} columns={authorizedColumns}>
          {(props) => (
            <BootstrapTable
              striped
              bordered
              hover
              noDataIndication={t('components.authorize.static.noInherited')}
              {...props.baseProps}
            />
          )}
        </ToolkitProvider>
      </Row>
    </>
  );
};

export default AuthorizeUser;
