import styled from 'styled-components';

/**
 * Styled component for the single area component.
 * color: ${({ theme }) => theme.text};
 */
export const StyledSingleArea = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-flow: row wrap;
  overflow-x: auto;
  background: ${({ theme }) => theme.background};

  font-size: 1rem;

  h1,
  h2,
  h3 {
    text-align: center;
    flex-basis: 100%;
    margin-bottom: 1rem;
  }

  .Yes {
    color: ${({ theme }) => theme.lightGreen};
  }

  .No {
    color: ${({ theme }) => theme.red};
  }

  .chargepoint-list,
  .subareas-list {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-bottom: 0.5rem;
  }

  .col-sm-3 {
    display: flex;
    height: 100vh;
    align-items: center;
  }

  .back-button {
    text-align: left;
  }

  .col-sm-9 {
    display: flex;
  }

  .tab-content {
    align-self: center;
  }

  .chartsContainer {
  }

  .opacity-low-gray-bg {
    opacity: 0.5;
    background-color: lightgrey;
  }

  .no-area-meter,
  .loading,
  .no-data,
  .error-graph {
    position: relative;
    display: inline-block;
  }

  .error-graph::before {
    content: 'Errors occured when fetching data';
    display: block;
    opacity: 1;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);

    font-size: 5em;

    @media (max-width: ${({ theme }) => theme.mobileL}) {
      font-size: 1.5rem;
    }

    @media (max-width: ${({ theme }) => theme.tablet}) {
      font-size: 2rem;
    }
    position: absolute;
  }

  .no-area-meter::before {
    content: 'No area meter exists for this area';
    display: block;
    opacity: 1;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);

    font-size: 5em;

    @media (max-width: ${({ theme }) => theme.mobileL}) {
      font-size: 1.5rem;
    }

    @media (max-width: ${({ theme }) => theme.tablet}) {
      font-size: 2rem;
    }
    position: absolute;
  }

  .loading::before {
    content: 'Loading...';
    display: block;
    opacity: 1;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);

    font-size: 5em;

    @media (max-width: ${({ theme }) => theme.mobileL}) {
      font-size: 1.5rem;
    }

    @media (max-width: ${({ theme }) => theme.tablet}) {
      font-size: 2rem;
    }
    position: absolute;
  }

  .no-data::before {
    content: 'No data exists for the given period';
    display: block;
    opacity: 1;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);

    font-size: 5em;

    @media (max-width: ${({ theme }) => theme.mobileL}) {
      font-size: 1.5rem;
    }

    @media (max-width: ${({ theme }) => theme.tablet}) {
      font-size: 2rem;
    }
    position: absolute;
  }

  .react-bootstrap-table table {
    table-layout: auto !important;
  }

  .chart-title {
    width: 50vw;
  }

  .chart-title tspan {
    min-width: 100%;
  }

  .power-or-current-button-group {
    width: 100%;
    @media (min-width: ${({ theme }) => theme.laptop}) {
      height: 100%;
    }
  }

  .date-time-picker {
    width: 100%;
    @media (min-width: ${({ theme }) => theme.laptop}) {
      height: 50%;
    }
  }
  .date-time-picker > * {
    flex: 1 1 150px;
  }

  .submit-button-col {
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    width: 100%;

    @media (min-width: 992px) {
      margin-top: 0;
      max-width: max-content;
    }
  }

  .submit-button-row {
    justify-content: center;

    @media (min-width: 992px) {
      justify-content: right;
    }
  }

  .submit-button {
    width: 100%;
  }

  .infoIcon {
    height: 1rem;
    width: 1.5rem;
  }
`;
