import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { StyledMyTransactions } from './MyTransactions.styled';
import { getMyYearlyTransactions } from '../../../services/transactions/MyYearlyTransactions/MyYearlyTransactions_service';
import { logger } from '../../../utils/logger';
import generateUniqueId from '../../../utils/generateUniqueId';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import { CompletedTransaction } from '../../../model/Classes/Transaction';
import User from '../../../model/Classes/User';
import i18N from '../../../i18n';
import { useTranslation } from 'react-i18next';
import { ChargepointClass } from '../../../model/Classes/Chargepoint';

/**
 * Component responsible for displaying the MyMonthlyTransactions page. The page renders:
 * 1) Page header
 * 2) Table with all the transactions for the chosen month
 * 3) Back button (redirects to the MyTransactions page)
 * @param {*} transactions state for the chosen month's transactions for the user
 * @param {*} year the chosen year
 * @param {*} user the user of the application
 * @param {*} history history object
 * @returns the MyMonthlyTransactions page
 */
declare interface MyYearlyTransactionsProps {
  user: User;
  history: any;
}
declare interface MyTransactionsThisYearProps
  extends MyYearlyTransactionsProps {
  transactions: any;
  year: string;
}
const MyTransactionsThisYear = ({
  transactions,
  year,
  user,
  history,
}: MyTransactionsThisYearProps) => {
  const { t } = useTranslation('common', {
    i18n: i18N,
  });
  const columns: ColumnDescription<CompletedTransaction>[] = [
    {
      dataField: 'unique_id',
      text: '',
      hidden: true,
    },
    {
      dataField: 'charge_point_id',
      text: `${t('components.myTransactions.table.specific.cp')}`,
      formatter: (cell, row, rowIndex, extraData) => (
        <>
          {user.user_level === 0 ? (
            ChargepointClass.toString(row)
          ) : (
            <Link
              to={`/area/${row.area_id}/chargepoint/${row.charge_point_id}`}
            >
              {ChargepointClass.toString(row)}
            </Link>
          )}
        </>
      ),
      sort: true,
    },
    {
      dataField: 'address',
      text: `${t('components.myTransactions.table.specific.address')}`,
      sort: true,
      hidden: true,
    },
    {
      dataField: 'start_time',
      text: `${t('components.myTransactions.table.specific.start')}`,
      formatter: (cell, row, rowIndex, extraData) => <>{row.start_time}</>,
      sort: true,
    },
    {
      dataField: 'stop_time',
      text: `${t('components.myTransactions.table.specific.stop')}`,
      formatter: (cell, row, rowIndex, extraData) => <>{row.stop_time}</>,
      sort: true,
    },
    {
      dataField: 'duration',
      text: `${t('components.myTransactions.table.specific.dur')} (hh:mm:ss)`,
      sort: true,
    },
    {
      dataField: 'connector_id',
      text: `${t('components.myTransactions.table.specific.connId')}`,
      sort: true,
    },
    {
      dataField: 'tag_nickname',
      text: `${t('components.myTransactions.table.specific.tag')}`,
      sort: true,
    },
    {
      dataField: 'kWh',
      text: `${t('components.myTransactions.table.specific.kWh')}`,
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === 'asc') return Number(a) - Number(b);
        else return Number(b) - Number(a);
      },
    },
    /** {
      dataField: 'start_price',
      text: `${t('components.myTransactions.table.specific.startPrice')} `,
      formatter: (cell, row, rowIndex, extraData) => (
        <>
          {row.start_price.toFixed(2)} {row.currency}
        </>
      ),
    },
    {
      dataField: 'price',
      text: `${t('components.myTransactions.table.specific.price')} /kWh`,
      formatter: (cell, row, rowIndex, extraData) => (
        <>
          {row.price.toFixed(2)} {row.currency}
        </>
      ),
    },
     */
    {
      dataField: 'pricing',
      text: `${t('components.myTransactions.table.specific.pricing')}`,
      formatter: (cell, row, rowIndex, extraData) => {
        if (row.pricing_type === 0 && Number(row.transaction_type) === 0)
          return `0 ${row.currency}/kWh`;
        else if (row.pricing_type === 0 && Number(row.transaction_type) === 1)
          return `${row.price} ${row.currency}/kWh`;
        else if (row.pricing_type === 0 && Number(row.transaction_type) === 2) {
          if (row.start_price > 0) {
            return `${row.start_price} + ${row.price} ${row.currency}/kWh`;
          } else if (row.start_price < 0) {
            return `${row.price} ${row.currency}/kWh`;
          }
        } else if (row.pricing_type === 0 && Number(row.transaction_type) === 3)
          return ` ${row.currency}/kWh`;
        else if (
          row.pricing_type === 1 &&
          Number(row.spotprice_calculated) === 1
        )
          return `spot price + ${row.margin} ${row.currency}/kWh`;
        else if (
          row.pricing_type === 1 &&
          Number(row.spotprice_calculated) === 0
        )
          return `${t(
            'components.myTransactions.table.specific.pricePending'
          )}`;
      },
    },
    {
      dataField: 'total_cost',
      text: `${t('components.myTransactions.table.specific.total')}  `,
      formatter: (cell, row, rowIndex, extraData) => (
        <>
          {row.total_cost.toFixed(2)} {row.currency}
        </>
      ),
      sort: true,
    },
  ];

  return (
    <Container id="component-margin">
      <Row>
        {/*Row for the transactions header and table*/}
        <h2>
          {t('components.myTransactions.static.header.specific.has', {
            month: '',
            year: year,
          })}
        </h2>
        <ToolkitProvider
          bootstrap4
          keyField="unique_id"
          data={transactions}
          columns={columns}
        >
          {(props) => (
            <BootstrapTable
              striped
              bordered
              hover
              noDataIndication={t(
                'components.myTransactions.static.header.specific.hasNot',
                { month: '', year: year }
              )}
              defaultSorted={[
                {
                  dataField: 'start_time',
                  order: 'desc',
                },
              ]}
              {...props.baseProps}
            />
          )}
        </ToolkitProvider>
      </Row>
      {/*Row for the back button*/}
      <Row>
        <Col xs="12" sm="1">
          <Button
            variant="secondary"
            onClick={() => history.push('/myTransactions')}
          >
            {t('global.buttons.back')}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

/**
 * Component responsible for handling the functionality related to the MyYearlyTransactions page.
 *
 * When the component is initialized, the useEffect hook is run. The hook fetches all the completed transactions for the
 * chosen year's for the user (the user is checked at the backend using the tokens). If successful, it updates the correct
 * states and sets the showView state to 1 to show the MyYearlyTransactions page. Otherwise, it sets the showView state to 0 to
 * show an error page to the user.
 * @param {*} user user of the application
 * @param {*} history history object
 * @returns loading screen, error screen, or MyyearlyTransactions screen
 */
const MyYearlyTransactions = ({ user, history }: MyYearlyTransactionsProps) => {
  const [transactionsThisYear, setTransactionsThisYear] = useState<
    CompletedTransaction[]
  >([]); //state for the chosen year's transactions for the user
  const [showView, setShowView] = useState(-1); //state for showing the loading screen, error screen, or MyYearlyTransaction screen
  const { t } = useTranslation('common', {
    i18n: i18N,
  });

  //Get the year from the URL
  const year = useParams<{ year: string }>().year;

  /**
   * Upon rerender, the useEffect hook fetches all the completed transactions for the chosen month's for the user
   * (the user is checked at the backend using the tokens). If it's successful, update the corresponding states with
   * the data and set the showView state to 1 (shows the MyYearlyTransactions page). Otherwise, log the error and set the
   * showView state to 0 (shows an error message to the user).
   */
  useEffect(() => {
    /**
     * Asynchronous helper function for calling the service function that fetch the needed data from the backend.
     * If both succeed, update the correct states with all the data and set the showView state to 1. Otherwise,
     * set the showView state to 0.
     */
    const getData = async () => {
      //Call the correct service function to get the data needed
      const res = await getMyYearlyTransactions(year);

      //Check if the request came back as successful
      if (res.data) {
        //Success, update the transactionsThisYear state and set the showView state to 1 to show the MyMonthlyTransactions page

        //We need to add a unique id field to each data row, since the component responsible
        //for rendering the table needs a unique id field
        const finalTransactions = res.data.map((tran) => {
          return { ...tran, unique_id: generateUniqueId() };
        });
        setTransactionsThisYear(finalTransactions);
        setShowView(1);
      } else {
        logger(res.data);

        setShowView(0); //Set the showView state to 0 notifying the user that an error occured
      }
    };

    getData(); //Call the asynchronous helper function
  }, [year]);

  /**
   * Initially, the component shows a loading screen. If an error occured when fetching data, an appropriate
   * error message is displayed. Otherwise, show the MyTransactionsThisMonth component
   */
  return (
    <StyledMyTransactions className="top-level-component">
      {showView === -1 ? (
        <>
          <h2 className="align-self-center">{t('global.view.loading')}</h2>
        </>
      ) : showView === 0 ? (
        <h2 className="align-self-center">{t('global.view.error')}</h2>
      ) : (
        <MyTransactionsThisYear
          transactions={transactionsThisYear}
          year={year}
          user={user}
          history={history}
        />
      )}
    </StyledMyTransactions>
  );
};

export default MyYearlyTransactions;
