import React from "react";
import { Image } from "react-bootstrap";
import styled from "styled-components";

import { QRCode } from "react-qrcode-logo";

import ChargePoint, { ChargepointClass } from "../../../model/Classes/Chargepoint";
import { AccessType } from "../../../model/Classes/Area";
import noLoginSticker from "../../../resources/WatteryNoLoginSticker.svg";

declare interface PrintableChargepointNoLoginStickerProps {
  chargepoint: ChargePoint;
  width: number;
}

declare interface ChargepointNoLoginStickerProps extends PrintableChargepointNoLoginStickerProps {
  connector: number;
}

const createNologinUrl = (cp: ChargePoint, connector: number): string => {
  const baseUrl = "https://start.wattery.io/num/"; // To conf?
  return baseUrl + [cp.cp_prefix, cp.cp_number, connector].join("-");
};

export const ChargepointNoLoginSticker = ({ chargepoint, width, connector }: ChargepointNoLoginStickerProps) => {
  if (chargepoint?.access_type && chargepoint.access_type >= AccessType.privateAndPublic) {
    return (
      <StyledChargepointNoLoginSticker $width={width}>
        <TextOverlayContainer>
          <TextContainer>
            <TextPrefix>{chargepoint.cp_prefix}</TextPrefix>
            <TextContent>
              {[...String(chargepoint.cp_number)].map((char, i) => (
                <div key={i}>{char}</div>
              ))}
            </TextContent>
            <TextSuffix>{connector}</TextSuffix>
          </TextContainer>
          <QRCodeContainer>
            <QRCode
              qrStyle="dots"
              eyeRadius={2}
              value={createNologinUrl(chargepoint, connector)}
              size={width / 7}
              ecLevel="M"
              quietZone={0}
            />
          </QRCodeContainer>
          <Image src={noLoginSticker} width={width} />
        </TextOverlayContainer>
        <StickerLegend>{ChargepointClass.formatNickname(chargepoint)}</StickerLegend>
      </StyledChargepointNoLoginSticker>
    );
  } else {
    return null;
  }
};

export const PrintableChargepointNoLoginSticker = React.forwardRef<
  HTMLDivElement,
  PrintableChargepointNoLoginStickerProps
>(({ chargepoint, width }: PrintableChargepointNoLoginStickerProps, ref) => {
  return (
    <PrintableContainer ref={ref}>
      {[...Array(chargepoint.number_of_connectors)].map((x, i) => (
        <ChargepointNoLoginSticker chargepoint={chargepoint} width={width} connector={i + 1} key={i} />
      ))}
    </PrintableContainer>
  );
});

// Margins for react-to-print
const PrintableContainer = styled.div`
  @media print {
    margin-left: 2cm;
    margin-top: 1cm;
  }
`;

const StyledChargepointNoLoginSticker = styled.div<{ $width: number }>`
  margin-left: -12px;
  margin-bottom: 30px;

  --scaling: ${(props) => props.$width / 1000};

  @media print {
    width: 100vw; /* Fixes print layout on Safari */
    page-break-after: always;
  }
`;

const QRCodeContainer = styled.div`
  position: absolute;
  bottom: calc(var(--scaling) * 52px);
  left: calc(var(--scaling) * 735px);
  background: #fff;
  padding: 12px;
  border-radius: calc(var(--scaling) * 44px);

  canvas {
    display: block;
    width: calc(var(--scaling) * 150px) !important;
    height: calc(var(--scaling) * 150px) !important;
  }
`;

const TextOverlayContainer = styled.div`
  position: relative;
`;

const TextContainer = styled.div`
  position: absolute;

  top: calc(560px * var(--scaling));
  left: calc(185px * var(--scaling));
  font-size: calc(76px * var(--scaling));

  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
`;

const TextPrefix = styled.div`
  position: absolute;
  left: 0px;
  color: #fff;
`;

const TextContent = styled.div`
  position: absolute;
  left: calc(145px * var(--scaling));

  div {
    position: absolute;
    --spacing: 101.5px;
  }

  div:nth-child(2) {
    left: calc(1 * var(--spacing) * var(--scaling));
  }

  div:nth-child(3) {
    left: calc(2 * var(--spacing) * var(--scaling));
  }

  div:nth-child(4) {
    left: calc(3 * var(--spacing) * var(--scaling));
  }

  div:nth-child(5) {
    left: calc(4 * var(--spacing) * var(--scaling));
  }
`;

const TextSuffix = styled.div`
  position: absolute;
  left: calc(675px * var(--scaling));
`;

const StickerLegend = styled.div`
  margin-left: 24px;
`;
