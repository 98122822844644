import styled from "styled-components";

/**
 * Styled component for the area component.
 */
export const StyledMaintenanceNotification = styled.div`
  font-size: 1rem;
  display: flex;
  overflow-x: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
