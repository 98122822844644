import { useState, useEffect } from 'react';
import { Row, Col, Alert } from 'react-bootstrap';
import { emailRegex } from '../../../../utils/emailRegex';
import { toggleAlertsOff } from '../../../../utils/toggleAlertsOff';
import {
  InvoiceCard,
  InvoiceEmailForm,
  //PaymentMethodSelection,
  SubmitButton,
} from './UserSettingsPaymentMethodComponents';
import { getEmailInvoiceInfo } from '../../../../services/userSettings/getEmailInvoiceInfo';
import { logger } from '../../../../utils/logger';
import { timer } from '../../../../utils/timer';
import { sendEmailInvoiceInfo } from '../../../../services/userSettings/sendEmailInvoiceInfo';
import { addCard } from '../../../../services/paytrail/addCard';
import { deleteCard } from '../../../../services/paytrail/deleteCard';
import { getCards } from '../../../../services/paytrail/getCards';
import { setDefaultCard } from '../../../../services/paytrail/setDefaultCard';
import User from '../../../../model/Classes/User';
import Card from '../../../../model/Classes/Card';
import { Nullable, StateHandler } from '../../../../model/Utilities/Types';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';
import {
  getInvoicesOutstanding,
  getPaymentsOutstanding,
} from '../../../../services/userSettings/getPaymentsOutstanding';
import { chargeUserInvoices } from '../../../../services/paytrail/chargeUserInvoices';

declare interface UserSettingsPaymentMethodProps {
  user: User;
  successfullAddCard: boolean;
  failedAddCard: boolean;
  canceledAddCard: boolean;
  existsAddCard: boolean;
}

const UserSettingsPaymentMethod = ({
  user,
  successfullAddCard,
  failedAddCard,
  canceledAddCard,
  existsAddCard,
}: UserSettingsPaymentMethodProps) => {
  // eslint-disable-next-line
  const [paymentMethod, setPaymentMethod] = useState(0);
  const [firstName, setFirstName] = useState(user.first_name ?? '');
  const [lastName, setLastName] = useState(user.last_name ?? '');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [email, setEmail] = useState(user.email ?? '');
  const [cards, setCards] = useState<Nullable<Card[]>>([]);

  const [agreeButtonClicked, setAgreeButtonClicked] = useState(false);
  const [agreeButton2Clicked, setAgreeButton2Clicked] = useState(false);
  const [disableFields, setDisableFields] = useState(false);
  const [infoAlreadyExists, setInfoAlreadyExists] = useState(false);
  const [showView, setShowView] = useState(-1);

  const [invalidNameAlert, setInvalidNameAlert] = useState(false);
  const [invalidEmailAlert, setInvalidEmailAlert] = useState(false);
  const [invalidAddressAlert, setInvalidAddressAlert] = useState(false);
  const [mustAgreeAlert, setMustAgreeAlert] = useState(false);
  const [mustAgree2Alert, setMustAgree2Alert] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitFailed, setSubmitFailed] = useState(false);
  const [handleAddCardFail, setHandleAddCardFail] = useState(false);
  const [handleChangeDefaultCardFail, setHandleChangeDefaultFail] =
    useState(false);
  const [handleChangeDefaultCardSuccess, setHandleChangeDefaultSuccess] =
    useState(false);
  const [cardDeletedSuccess, setCardDeletedSuccess] = useState(false);
  const [cardDeletedFail, setCardDeletedFail] = useState(false);
  const [cantRemoveCard, setCantRemoveCard] = useState(false);

  const [runUseEffect, setRunUseEffect] = useState(false);

  const { t } = useTranslation('common', { i18n: i18n });

  const firstOrLastNameInvalid =
    firstName.length < 1 ||
    firstName.length > 256 ||
    lastName.length < 1 ||
    lastName.length > 256;

  const addressInvalid =
    address.length < 1 ||
    address.length > 128 ||
    postalCode.length < 1 ||
    postalCode.length > 20 ||
    city.length < 1 ||
    city.length > 50;

  const disableSubmitButton =
    firstOrLastNameInvalid ||
    addressInvalid ||
    !emailRegex.test(email) ||
    !agreeButtonClicked ||
    !agreeButton2Clicked;

  useEffect(() => {
    const getData = async () => {
      const fetchRest = async (resData: any) => {
        try {
          const cards = await getCards();
          setCards(cards.data);

          if (resData) {
            const res = resData[0];

            setFirstName(res.first_name);
            setLastName(res.last_name);
            setEmail(res.email);
            setAddress(res.street_address);
            setCity(res.city);
            setPostalCode(res.postal_code);
            setDisableFields(true);
            setAgreeButtonClicked(true);
            setAgreeButton2Clicked(true);
            setInfoAlreadyExists(true);
          }

          setShowView(1);
        } catch (e) {
          logger(e);
          setShowView(0);
        }
      };

      try {
        const fetched = await getEmailInvoiceInfo();
        await fetchRest(fetched);
      } catch (e: any) {
        if (e.response) {
          if (e.response.data) {
            if (e.response.data.error) {
              if (e.response.data.error.message === 'no information available')
                await fetchRest(null);
            }
          }
        } else {
          logger(e);
          setShowView(0);
        }
      }
    };

    getData();
  }, [submitSuccess, runUseEffect]);

  useEffect(() => {
    const getData = async () => {
      await chargeUserInvoices();
    };

    if (successfullAddCard || handleChangeDefaultCardSuccess) getData();
  }, [successfullAddCard, handleChangeDefaultCardSuccess]);

  const validate = () => {
    let success = true;

    if (firstOrLastNameInvalid) {
      success = false;
      setInvalidNameAlert(true);
    }
    if (addressInvalid) {
      success = false;
      setInvalidAddressAlert(true);
    }
    if (!emailRegex.test(email)) {
      success = false;
      setInvalidEmailAlert(true);
    }

    if (!agreeButtonClicked) {
      success = false;
      setMustAgreeAlert(true);
    }

    if (!agreeButton2Clicked) {
      success = false;
      setMustAgree2Alert(true);
    }

    return success;
  };

  const handleSubmit = async () => {
    toggleAlertsOff([
      setMustAgreeAlert,
      setMustAgree2Alert,
      setInvalidNameAlert,
      setInvalidAddressAlert,
      setInvalidEmailAlert,
    ]);

    if (!validate()) return;

    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      street_address: address,
      postal_code: postalCode,
      city,
    };

    try {
      await sendEmailInvoiceInfo(data);
      timer(setSubmitSuccess);
      setDisableFields(true);
    } catch (e) {
      logger(e);
      timer(setSubmitFailed);
    }
  };

  const handleAddCard = async () => {
    toggleAlertsOff([setHandleAddCardFail]);
    try {
      const data = await addCard();
      window.location.href = data.data;
    } catch (e) {
      logger(e);
      timer(setHandleAddCardFail);
    }
  };

  const changeDefaultCard = async (card: Card) => {
    if (!card.is_default) {
      const data = {
        paytrail_card_id: card.id,
      };
      toggleAlertsOff([
        setHandleChangeDefaultFail,
        setHandleChangeDefaultSuccess,
      ]);

      try {
        await setDefaultCard(data);
        timer(setHandleChangeDefaultSuccess);
        setRunUseEffect((r) => !r);
      } catch (e) {
        logger(e);
        timer(setHandleChangeDefaultFail);
      }
    }
  };

  const handleDeleteCard = async (
    card: Card,
    setShowModal: StateHandler<boolean>
  ) => {
    setShowModal(false);
    toggleAlertsOff([setCardDeletedSuccess, setCardDeletedFail]);

    if ((cards && cards.length === 1) || card.is_default) {
      const has_payments_outstanding = await getPaymentsOutstanding();
      const has_invoices_outstanding = await getInvoicesOutstanding();
      if (
        Number(has_payments_outstanding.data.total_price) > 0 ||
        has_invoices_outstanding.data.length > 0
      ) {
        return timer(setCantRemoveCard);
      }
    }
    const data = {
      card_id: card.id,
    };

    try {
      await deleteCard(data);
      timer(setCardDeletedSuccess);
      setRunUseEffect((r) => !r);
    } catch (e) {
      timer(setCardDeletedFail);
      logger(e);
    }
  };

  if (showView === -1)
    return <h2 className="align-self-center">{t('global.view.loading')}</h2>;
  if (showView === 0)
    return <h2 className="align-self-center">{t('global.view.error')}</h2>;

  // Disabled PaymentMethodSelection since only credit cards are used, but keeping it here for a while in case it is needed later (7.6.2022)
  return (
    <>
      {/* <Row>
        <PaymentMethodSelection
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          infoAlreadyExists={infoAlreadyExists}
        />
      </Row> */}
      <Row className="mb-3" style={{ justifyContent: 'center' }}>
        {paymentMethod === -1 ? null : paymentMethod === 0 ? (
          <>
            <InvoiceCard
              cards={cards}
              handleAddCard={handleAddCard}
              handleAddCardFail={handleAddCardFail}
              successfullAddCard={successfullAddCard}
              failedAddCard={failedAddCard}
              canceledAddCard={canceledAddCard}
              existsAddCard={existsAddCard}
              changeDefaultCard={changeDefaultCard}
              disableChangeDefault={false}
              handleChangeDefaultCardFail={handleChangeDefaultCardFail}
              handleChangeDefaultCardSuccess={handleChangeDefaultCardSuccess}
              deleteCard={handleDeleteCard}
              cardDeletedSuccess={cardDeletedSuccess}
              cardDeletedFail={cardDeletedFail}
              cantRemoveCard={cantRemoveCard}
            />
          </>
        ) : (
          <div className="mt-3">
            <InvoiceEmailForm
              firstName={firstName}
              lastName={lastName}
              email={email}
              address={address}
              city={city}
              postalCode={postalCode}
              agreeButtonClicked={agreeButtonClicked}
              disableFields={disableFields}
              setFirstName={setFirstName}
              setLastName={setLastName}
              setEmail={setEmail}
              setAddress={setAddress}
              setCity={setCity}
              setPostalCode={setPostalCode}
              setAgreeButtonClicked={setAgreeButtonClicked}
              setAgreeButton2Clicked={setAgreeButton2Clicked}
              invalidNameAlert={invalidNameAlert}
              invalidAddressAlert={invalidAddressAlert}
              invalidEmailAlert={invalidEmailAlert}
              mustAgreeAlert={mustAgreeAlert}
              mustAgree2Alert={mustAgree2Alert}
              infoAlreadyExists={infoAlreadyExists}
            />
            <Row>
              <SubmitButton
                disableSubmitButton={disableSubmitButton}
                handleSubmit={handleSubmit}
                disableFields={disableFields}
                infoAlreadyExists={infoAlreadyExists}
              />
            </Row>
          </div>
        )}
      </Row>
      <Row className="flex-and-center mt-2">
        <Col xs="auto" sm="auto">
          <p>{t('components.userSettings.tabs.payment.charge')}</p>
        </Col>
      </Row>
      <Row className="flex-and-center mt-2">
        <Col xs="auto" sm="auto">
          <a href="/payment-terms-of-service" target="_blank" rel="noreferrer">
            {t('components.userSettings.tabs.payment.buttons.paymentTos.check')}
          </a>
        </Col>
      </Row>
      <Row className="flex-and-center mt-2">
        <Col xs="auto" sm="auto">
          <div //icky wicky
            dangerouslySetInnerHTML={{
              __html: t('components.userSettings.tabs.payment.service', {
                interpolation: { escapeValue: false },
              }),
            }}
          />
        </Col>
      </Row>
      {submitSuccess && (
        <Alert variant="success">{t('global.alert.success.submission')}</Alert>
      )}
      {submitFailed && (
        <Alert variant="danger">{t('global.alert.failure.submission')}</Alert>
      )}
    </>
  );
};

export default UserSettingsPaymentMethod;
