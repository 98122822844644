import { Dispatch, SetStateAction } from 'react';

/**
 * Utility function for toggling alerts off. The parameter contains an array of state handler, and sets each
 * state handlers respective state to false. Each state handler in the array MUST be a state handler for a
 * state that holds a boolean value.
 * @param {*} stateHandlersArray array containing the state handlers
 */
export const toggleAlertsOff = (
  stateHandlersArray: Dispatch<SetStateAction<boolean>>[]
): void => {
  //For each state handler in the parameter array, pass false to the state handler to update the state
  stateHandlersArray.forEach((sh) => {
    sh(false); //Set the state to false
  });
};
