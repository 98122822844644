import { useEffect, useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";

import i18N from "../../../../i18n";
import { useTranslation } from "react-i18next";

import { Nullable } from "../../../../model/Utilities/Types";

import { logger } from "../../../../utils/logger";

import User from "../../../../model/Classes/User";
import Tag from "../../../../model/Classes/Tag";
import { TransactionCp, ChargepointMap } from "../../../../model/Classes/Chargepoint";

import { getRecentChargepointIds, startNewTransaction } from "../../../../services/transactions/startNewTransaction";
import { getOwnTags } from "../../../../services/userSettings/getOwnTags";
import { getPaymentMethodStatus } from "../../../../services/authentication/getPaymentMethodStatus";
import { cardsExpiring, defaultCardExpired } from "../../../../services/paytrail/cardsExpiring";
import { getPublicChargepoints } from "../../../../services/chargepoint/getPublicChargepoints";
import { getChargingAccess } from "../../../../services/chargepoint/getChargingAccess";
import {
  checkChargepointAvailability,
  checkIfCardRequired as checkIfCardRequiredRequest,
} from "../../../../services/transactions/checkChargepointAvailability";
import { getChargepointIsConnected } from "../../../../services/chargepoint/getChargepointIsConnected";

import { StartNewTransactionView } from "./StartNewTransactionView";

import { toast, Id } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

/**
 * Component responsible for handling the functionality related to the start new transactions page.
 *
 * Checks if the user accessed the page via the url /startNewTransactions/:charge_point_id. If they did,
 * a variable called chargepointId will contain the chargepoint id and the hook will automatically fill the chargepoint id field
 * with the chargepoint id. This is done since if we later on add QR-codes to the chargepoints, they can access this page
 * via the QR-code and have the specific chargepoint id already filled in!
 */
declare interface StartNewTransactionProps {
  user: Nullable<User>;
  history: any;
}

enum CardStatus {
  NoCardAdded = 0,
  InGracePeriod = 2,
  IsBlocked = 3,
}

const StartNewTransaction = ({ user, history }: StartNewTransactionProps) => {
  const [connectorId, setConnectorId] = useState<number | null>(null); //Explicitly null for it to be controlled (cf. undefined)
  const [defaultTag, setDefaultTag] = useState<Tag>();

  const [recentChargepoints, setRecentChargepoints] = useState<ChargepointMap>(new Map());
  const [myChargepoints, setMyChargepoints] = useState<ChargepointMap>(new Map());
  const [publicChargepoints, setPublicChargepoints] = useState<ChargepointMap>(new Map());

  const [selectedChargepoint, setSelectedChargepoint] = useState<TransactionCp | undefined>(); //state for the selected chargepoint
  const [chargepointIdFromUrl, setChargepointIdFromUrl] = useState<string | undefined>(
    useParams<{ charge_point_id?: string }>().charge_point_id?.toUpperCase()
  );
  const [cardStatus, setCardStatus] = useState<CardStatus>();
  const [errors, setErrors] = useState<Id[]>([]); // Toastify ID:s
  const [showView, setShowView] = useState(-1); //state for showing the loading screen, error screen, or start new transaction screen

  const { t } = useTranslation("common", {
    i18n: i18N,
  });

  const addError = (message: string): void => {
    let toastifyId = toast.error(message, { autoClose: false });
    setErrors((errors) => [...errors, toastifyId]);
  };

  const clearErrors = (): void => {
    errors.forEach((Id) => {
      toast.dismiss(Id);
    });
    setErrors([]);
  };

  const hasErrors = (): boolean => errors.length > 0;

  const mustSetConnectorId = (cp?: TransactionCp): boolean => {
    // This construct is a bit verbose mostly for clarity
    if (!cp || !cp.number_of_connectors) {
      return false;
    } else if (Number(cp.number_of_connectors) < 2) {
      return false;
    } else {
      return true;
    }
  };

  const checkAvailability = async (cp: TransactionCp, connId?: number): Promise<boolean> => {
    const res = await checkChargepointAvailability(cp.charge_point_id);

    const checkWithConnectorId = connId || connectorId; // Unless in argument, use state

    if (!res.success) {
      logger(res.data);
      addError(t("global.view.errorTry"));
      return false;
    }

    if (res.data.length > 0) {
      if (!mustSetConnectorId(cp)) {
        // Only one connector, and not available
        return false;
      } else {
        for (const at of res.data) {
          if (at.connector_id === checkWithConnectorId) {
            return false;
          }
        }
      }
    }

    return true;
  };

  const checkIfCardRequired = async (cp: TransactionCp): Promise<boolean> => {
    const res = await checkIfCardRequiredRequest(cp.charge_point_id);

    if (!res.success) {
      addError(t("global.alert.failure.cpNoInfo"));
    }

    return res.data.length > 0;
  };

  // We can't set chargepointId with useEffect bound to selectedChargepoint since that would create an infinite loop
  const handleChargepointSelect = async (cp?: TransactionCp, connId: number | null = null) => {
    setSelectedChargepoint(cp);
    setConnectorId(connId);

    let newErrors = [];

    if (cp) {
      const available = await checkAvailability(cp, connId || undefined);
      if (!available) {
        newErrors.push(t("components.startTransaction.notAvailable"));
      }

      if (!cp.free_charging) {
        const cardRequired = await checkIfCardRequired(cp);
        if (cardRequired) {
          switch (cardStatus) {
            case CardStatus.NoCardAdded:
              newErrors.push(t("global.alert.failure.cardRequired"));
              break;
            case CardStatus.InGracePeriod:
              // Allowed, but show notification => toast directly.
              toast.warning(t("components.landingPage.buttons.invoicing.tooltip"), { autoClose: false });
              break;
            case CardStatus.IsBlocked:
              newErrors.push(t("components.landingPage.buttons.blocked.tooltip"));
          }
        }
      }
    }

    // Clear old errors
    clearErrors();
    newErrors.forEach((err) => {
      addError(err);
    });
  };

  const handleConnectorSelect = (connectorId: number) => {
    handleChargepointSelect(selectedChargepoint, connectorId);
  };

  /**
   * Asynchronous helper function for handling the functionality when the user send the start transaction form.
   * The function initially toggles off all the alerts, and checks if the fields are empty. If they are, set the
   * noPass state to true and exit the function. Otherwise, create a data object with the tag id and send it to the
   * backend (chargepoint id is part of the requests url). If it succeeds, set the success state to true notifying the
   * user that the transaction was successfully started. Otherwise, log the error and set the error state to true
   * notifying the user that some errors occured.
   */
  const handleSubmit = async () => {
    if (!selectedChargepoint || !selectedChargepoint.charge_point_id) {
      return;
    }

    clearErrors();

    if (
      mustSetConnectorId(selectedChargepoint) &&
      (!connectorId || connectorId > Number(selectedChargepoint.number_of_connectors))
    ) {
      toast.error(t("global.alert.failure.invalidConn"));
      return;
    }

    const connectedChargepoints = await getChargepointIsConnected(selectedChargepoint.charge_point_id);

    if (connectedChargepoints.success) {
      if (connectedChargepoints.data.connected !== 1) {
        toast.error(t("global.alert.failure.cpNotConnected"));
        return;
      }
    } else {
      //Log error in dev, display different message
      logger(connectedChargepoints.data);
      toast.error(t("global.alert.failure.cpNoInfo"));
      return;
    }

    //Create a data object with the default tag
    const data = {
      id_tag: defaultTag!.id_tag,
      connector_id: mustSetConnectorId(selectedChargepoint) ? connectorId : 1,
      charge_point_id: selectedChargepoint.charge_point_id,
      waitforstart: true,
    };

    let waitForStartToast = toast.info(t("components.startTransaction.waitForStart"), { autoClose: false });

    //Call the startNewTransaction service function to start the transaction with the given data
    const res = await startNewTransaction(data);

    // After await returns
    toast.dismiss(waitForStartToast);

    if (res.success) {
      if (res.result === "Accepted") {
        toast.success(t("global.alert.other.chargingStarted"));
        setTimeout(() => {
          history.push("/");
        }, 2000);
      } else if (res.result === "Rejected") {
        toast.error(t("components.startTransaction.rejected"));
      } else if (res.result === "Failed") {
        toast.error(t("components.startTransaction.failed"));
      }
    } else {
      logger(res.error);
      toast.error(t("global.view.errorTry"));
    }
  };

  /**
   * On each rerender, fetch the chargepoints the user has access to and the tag ids the user owns from the backend.
   * Additionally, if chargepointId is defined (i.e. the user accessed this page through an url looking like
   * /startNewTransaction/:chargepointId), update the selectedChargepoint state to contain the chargepoint id
   * (if the user has access to the provided chargepoint, otherwise show an error alert).
   */
  useEffect(() => {
    const getData = async () => {
      //Fetch the users tags and chargepoints where the user has charging access from the backend
      const tagsRes = await getOwnTags();
      const chargingAccessRes = await getChargingAccess();
      const publicChargepointsRes = await getPublicChargepoints();
      const recentChargepointIdsRes = await getRecentChargepointIds();

      //Check if fetching the chargepoints and the tags data were successfully fetched
      if (
        !tagsRes.success ||
        !chargingAccessRes.success ||
        !publicChargepointsRes.success ||
        !recentChargepointIdsRes.success
      ) {
        setShowView(0);
      } else {
        // Set 'App' tag as default
        setDefaultTag(tagsRes.data.filter((tag: Tag) => tag.nickname === "App")[0]);

        let tmpMyChargepoints: ChargepointMap = new Map([...myChargepoints]);
        chargingAccessRes.data.forEach((cp) => {
          if (cp.charge_point_id && !cp.disabled && cp.number_of_connectors) {
            tmpMyChargepoints.set(cp.charge_point_id!, cp);
          }
        });

        let tmpPublicChargepoints: ChargepointMap = new Map([...publicChargepoints]);
        publicChargepointsRes.data.forEach((cp) => {
          if (
            cp.charge_point_id &&
            !cp.disabled &&
            cp.number_of_connectors &&
            !tmpMyChargepoints.has(cp.charge_point_id)
          ) {
            tmpPublicChargepoints.set(cp.charge_point_id!, cp);
          }
        });

        let tmpRecentChargepoints: ChargepointMap = new Map([...recentChargepoints]);
        recentChargepointIdsRes.data.forEach((cp: { charge_point_id: string }) => {
          let id = cp.charge_point_id;
          if (tmpMyChargepoints.has(id)) {
            tmpRecentChargepoints.set(id, tmpMyChargepoints.get(id)!); // Assert !, since has() from previous line
            //tmpMyChargepoints.delete(id);
          }
          if (tmpPublicChargepoints.has(id)) {
            tmpRecentChargepoints.set(id, tmpPublicChargepoints.get(id)!);
            //tmpPublicChargepoints.delete(id);
          }
        });

        setRecentChargepoints(tmpRecentChargepoints);
        setMyChargepoints(tmpMyChargepoints);
        setPublicChargepoints(tmpPublicChargepoints);

        setShowView(1);
      }
    };

    getData();

    return () => {
      window.history.replaceState({}, document.title);
    };
  }, []); // On initial render only

  useEffect(() => {
    //Can't start a transaction via the UI unless default tag is enabled
    if (defaultTag?.disabled === 1) {
      addError(t("components.startTransaction.static.body.activate"));
    }
  }, [defaultTag]);

  // If the chargepointId is set via URL, set the selected chargepoint to load the rest of the UI
  useEffect(() => {
    if (chargepointIdFromUrl) {
      // From URL at this stage
      // Here, we don't have a Map of all CP:s in state, that's introduced in the dropdown
      let all: ChargepointMap = new Map([...recentChargepoints, ...myChargepoints, ...publicChargepoints]);
      if (all.has(chargepointIdFromUrl)) {
        setSelectedChargepoint(all.get(chargepointIdFromUrl));
      }
    } else if (recentChargepoints && recentChargepoints.size === 1) {
      let mostRecentChargepoint = recentChargepoints.values().next().value;
      setSelectedChargepoint(mostRecentChargepoint);
    }
  }, [recentChargepoints, myChargepoints, publicChargepoints]);

  useEffect(() => {
    const getData = async () => {
      try {
        const statusRes = await getPaymentMethodStatus();
        setCardStatus(statusRes[0].payment_method_status);
      } catch (e) {
        logger(e);
      }
    };

    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const expired_res = await defaultCardExpired();
        if (expired_res.data.length > 0) {
          toast.warning(t("components.landingPage.buttons.expired.tooltip"), {
            autoClose: false,
          });
        }
        const expiring_res = await cardsExpiring();
        if (expiring_res.data.length > 0) {
          toast.warning(t("components.landingPage.buttons.expiring.tooltip"), {
            autoClose: false,
          });
        }
      } catch (e) {
        logger(e);
      }
    };

    getData();
  }, []);

  /**
   * Return either a loading screen, error screen, or StartNewTransactionView component depending on the showView state
   */
  return (
    <StyledStartNewTransaction className="top-level-component">
      {showView === -1 ? (
        <>
          <h2 className="align-self-center">{t("global.view.loading")}</h2>
        </>
      ) : showView === 0 ? (
        <h2 className="align-self-center">{t("global.view.error")}</h2>
      ) : (
        <StartNewTransactionView
          selectedChargepoint={selectedChargepoint}
          setSelectedChargepoint={handleChargepointSelect}
          setSelectedConnector={handleConnectorSelect}
          publicChargepoints={publicChargepoints}
          myChargepoints={myChargepoints}
          recentChargepoints={recentChargepoints}
          handleSubmit={handleSubmit}
          connectorId={connectorId}
          setConnectorId={setConnectorId}
          mustSetConnectorId={mustSetConnectorId(selectedChargepoint)}
          hasInterrupts={hasErrors()}
        />
      )}
    </StyledStartNewTransaction>
  );
};

const StyledStartNewTransaction = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-flow: row wrap;
  overflow-x: auto;
  background: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  font-size: 1rem;

  .logo {
    width: 50vw;
    @media only screen and (max-width: 700px) {
      width: 80vw;
    }
    @media only screen and (min-width: 700px, max-width: 1000px) {
      width: 50vw;
    }
    @media only screen and (min-width: 1000px) {
      width: 500px;
    }
  }

  .price-col {
    display: flex;
    align-items: center;

    > p {
      height: min-content;
    }
  }
  .spotPrice{
    margin:15px 0 10px 0;
    text-Align: left;
  }

  td[colspan]:not([colspan='1']) {
    text-align: center;
  }

  Accordion{
    max-height:500px
    overflow-y: auto;
    margin-bottom: 10px;
  }
  .custom-thead{
    border-bottom:grey;
  }
  .table > :not(:first-child){
    border-top:0;
  }
`;

export default StartNewTransaction;
