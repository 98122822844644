import axios from 'axios';
import StructuredResponse from '../../model/Classes/StructuredResponse';
axios.defaults.withCredentials = true;

const baseURL = '/api/reporting/forceStop';
const tokenURL = '/api/auth/token';

/**
 * Service function responsible for sending a force stop request for a transaction.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @param {*} data data object containing transaction id and area id
 * @returns If successful: [true, response.data]. Otherwise: [false, error]
 */
export const sendForceStop = async (data: any) => {
  try {
    const response = await axios.post(baseURL, data, { withCredentials: true });
    return new StructuredResponse(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.post(baseURL, data, {
        withCredentials: true,
      });
      return new StructuredResponse(true, response.data);
    } catch (e) {
      return new StructuredResponse(false, e);
    }
  }
};
