import axios from 'axios';
import StructuredResponse from '../../model/Classes/StructuredResponse';
axios.defaults.withCredentials = true;

const tokenURL = '/api/auth/token';

/**
 * Service function responsible for making a user an area manager.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @param {*} data data object containing the user email and area id
 * @returns if successful: [true, response.data]. Otherwise: [false, error]
 */
export const setAreaManager = async (
  data /* : {email: string, area_id: number} */
) => {
  try {
    const response = await axios.post('/api/admin/makeAreaManager', data, {
      withCredentials: true,
    });
    return new StructuredResponse<{ status: number; message: string }>(
      true,
      response.data
    );
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.post('/api/admin/makeAreaManager', data, {
        withCredentials: true,
      });
      return new StructuredResponse<{ status: number; message: string }>(
        true,
        response.data
      );
    } catch (e: any) {
      return new StructuredResponse<{ status: number; message: string }>(
        false,
        e
      );
    }
  }
};
