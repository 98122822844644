import axios from "axios";
import StructuredOcppResponse from "../../model/Classes/StructuredOcppResponse";
import StructuredResponse from "../../model/Classes/StructuredResponse";
axios.defaults.withCredentials = true;

const baseURL = "/api/transactions/remoteStart";
const tokenURL = "/api/auth/token";
const recentCpIdURL = "/api/user/chargepoints/recent/id";
const recentCpURL = "/api/user/chargepoints/recent";

/**
 * Service function responsible for starting a new transaction.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @param {*} data data object containing the tag id, chargepoint id, and connector id
 * @returns If successful: [true, response.data]. Otherwise, [false, error]
 */
export const startNewTransaction = async (data) => {
  try {
    const response = await axios.post(`${baseURL}`, data, {
      withCredentials: true,
    });
    return new StructuredOcppResponse(true, response.data.result, response.data.message, null);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.post(`${baseURL}`, data, {
        withCredentials: true,
      });
      return new StructuredOcppResponse(true, response.data.result, response.data.message, null);
    } catch (e: any) {
      return new StructuredOcppResponse(false, e, null, e.message);
    }
  }
};

//service to get recent chargepoints from backend
export const getRecentChargepointIds = async () => {
  try {
    const response = await axios.get(`${recentCpIdURL}`, {
      withCredentials: true,
    });
    return new StructuredResponse(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(`${recentCpIdURL}`, {
        withCredentials: true,
      });
      return new StructuredResponse(true, response.data);
    } catch (e: any) {
      return new StructuredResponse(false, e);
    }
  }
};

//service to get recent chargepoints from backend
export const getRecentChargepoints = async () => {
  try {
    const response = await axios.get(`${recentCpURL}`, {
      withCredentials: true,
    });
    return new StructuredResponse(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(`${recentCpURL}`, {
        withCredentials: true,
      });
      return new StructuredResponse(true, response.data);
    } catch (e: any) {
      return new StructuredResponse(false, e);
    }
  }
};
