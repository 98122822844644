import axios from 'axios';
import AreaSingle from '../../model/Classes/Area';
import StructuredResponse from '../../model/Classes/StructuredResponse';
axios.defaults.withCredentials = true;

const baseURL = '/api/area';
const tokenURL = '/api/auth/token';

/**
 * Service function responsible for sending data containing changes made to an area to the backend.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @param {*} id id of the area to be changed
 * @param {*} data the data containing the changes
 * @returns response from the backend
 */
export const sendAreaChanges = async (id: number, data: any) => {
  try {
    const response = await axios.post(`${baseURL}/${id}/edit`, data, {
      withCredentials: true,
    });
    return new StructuredResponse<AreaSingle>(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.post(`${baseURL}/${id}/edit`, data, {
        withCredentials: true,
      });
      return new StructuredResponse<AreaSingle>(true, response.data);
    } catch (e: any) {
      return new StructuredResponse<AreaSingle>(false, e);
    }
  }
};
