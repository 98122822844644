import axios from 'axios';
import StructuredResponse from '../../model/Classes/StructuredResponse';
import User from '../../model/Classes/User';
axios.defaults.withCredentials = true;

const tokenURL = '/api/auth/token';

/**
 * Service function responsible for fetching all authorized users for an area.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @param {*} areaId id of the area to fetch authorized users for
 * @returns if successful: [true, response.data]. Otherwise: [false, error]
 */
export const getAuthorizedUsers = async (areaId: number) => {
  try {
    const response = await axios.get(`/api/area/${areaId}/chargingAccess`);
    return new StructuredResponse<User[]>(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(`/api/area/${areaId}/chargingAccess`);
      return new StructuredResponse<User[]>(true, response.data);
    } catch (e: any) {
      return new StructuredResponse<User[]>(false, e);
    }
  }
};
