import { Nullable } from '../../../model/Utilities/Types';

export const checkFOFormat = (business_id: string) => {
  const pattern2 = /^\d{7}-\d{1}$/; //FO-nummer
  return pattern2.test(business_id) || business_id.length !== 0;
};

export const checkIBANFormat = (bank_account: string) => {
  const pattern =
    /^([A-Z]{2}[ -]?[0-9]{2})(?=(?:[ -]?[A-Z0-9]){9,30}$)((?:[ -]?[A-Z0-9]{3,5}){2,7})([ -]?[A-Z0-9]{1,3})?$/;
  return pattern.test(bank_account);
};

export const checkValidPaymentReference = (payment_reference: string) => {
  const pattern = /\d{4,21}/;
  return pattern.test(payment_reference);
};

export const checkValidEmail = (email: string) => {
  const pattern =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(email);
};

export const formatDisplayIBAN = (iban: string) => {
  if (iban.length === 0) return iban;
  const formatted = iban
    .replace(/[^\dA-Z]/g, '')
    .replace(/(.{4})/g, '$1 ')
    .trim();

  if (formatted.length === 0) return iban;
  return formatted;
};

export const toModalString = (
  fieldName: string,
  array: string[],
  newVal: Nullable<string>,
  oldVal?: Nullable<string>
) => {
  if (newVal === undefined || newVal === null) {
    return oldVal
      ? array.push(`${fieldName}: ${oldVal}`)
      : array.push(`${fieldName}: `);
  }
  if (
    (oldVal === undefined || oldVal === null || oldVal!.length === 0) &&
    newVal.length > 0
  )
    return array.push(`${fieldName}: >>> ${newVal}`);
  if (newVal === oldVal) return array.push(`${fieldName}: ${oldVal}`);
  return array.push(`${fieldName}: ${oldVal} >>> ${newVal}`);
};

export const toModalStringArray = (
  fieldName: string,
  array: string[],
  newVal: Array<string>,
  oldVal?: Array<string>
) => {
  let formattedOld = '';
  oldVal?.forEach((a, i) => {
    if (i === oldVal.length - 1) return (formattedOld += a);
    return (formattedOld += a.concat(',\n'));
  });

  if (!newVal) {
    return oldVal
      ? array.push(`${fieldName}: ${oldVal}`)
      : array.push(`${fieldName}: `);
  }

  let formattedNew = '';
  newVal.forEach((a, i) => {
    if (i === newVal.length - 1) return (formattedNew += a);
    return (formattedNew += a.concat(',\n'));
  });

  return array.push(`${fieldName}: ${formattedNew}`);
};
