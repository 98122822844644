import axios from 'axios';
import StructuredResponse from '../../model/Classes/StructuredResponse';
import BiddingAreaValues from '../../model/Classes/BiddingAreaValues';
axios.defaults.withCredentials = true;

const tokenURL = '/api/auth/token';

export const getBiddingAreaValue = async () => {
  let success = true;
  try {
    const response = await axios.get('/api/area/getBiddingAreaValue', {
      withCredentials: true,
    });
    return new StructuredResponse<BiddingAreaValues[]>(success, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get('/api/user/settings/chargepoints', {
        withCredentials: true,
      });
      return new StructuredResponse<BiddingAreaValues[]>(
        success,
        response.data
      );
    } catch (e: any) {
      success = false;
      return new StructuredResponse<BiddingAreaValues[]>(success, e);
    }
  }
};
