import axios from 'axios';
import StructuredResponse from '../../model/Classes/StructuredResponse';
import DiagnosticsMessage from '../../model/Classes/DiagnosticsMessage';
axios.defaults.withCredentials = true;

const tokenURL = '/api/auth/token';
const baseUrl = '/api/diagnostics';

/**
 * Service function responsible for fetching all diagnostic messages the user has access to.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @returns if successful: [true, response.data]. Otherwise: [false, error]
 */
export const getCpDiagnosticMessages = async (charge_point_id) => {
  try {
    const response = await axios.get(`${baseUrl}/${charge_point_id}`);
    return new StructuredResponse<DiagnosticsMessage[]>(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(`${baseUrl}/${charge_point_id}`);
      return new StructuredResponse<DiagnosticsMessage[]>(true, response.data);
    } catch (e: any) {
      return new StructuredResponse<DiagnosticsMessage[]>(false, e);
    }
  }
};
