import axios from 'axios';
axios.defaults.withCredentials = true;

const baseURL = '/api/area';
const tokenURL = '/api/auth/token';

/**
 * Service function responsible for deleting a chargepoint.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @param {*} areaId the area the chargepoint belongs to
 * @param {*} cpId the id of the chargepoint
 * @returns response from the backend
 */
export const sendChargepointDelete = async (areaId, cpId) => {
  try {
    const response = await axios.post(
      `${baseURL}/${areaId}/chargepoint/${cpId}/del`
    );
    return response.data;
  } catch (e) {
    await axios.post(tokenURL);
    const response = await axios.post(
      `${baseURL}/${areaId}/chargepoint/${cpId}/del`
    );
    return response.data;
  }
};
