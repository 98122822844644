import styled from 'styled-components';

/**
 * Styled component for the UserSettings component
 */
export const StyledUserSettings = styled.div`
  display: flex;
  width: 100vw;
  height: 80vh;
  justify-content: space-evenly;
  flex-flow: row wrap;
  overflow-x: auto;
  background: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  font-size: 1rem;

  .back-button {
    text-align: left;
  }

  .outdated {
    color: ${({ theme }) => theme.red};
  }

  .infoIcon {
    height: 1rem;
    width: 1.5rem;
  }

  .payment-method-text-col {
    display: flex;
    align-items: center;

    > p {
      height: min-content;
    }
  }

  .tags-divider {
    border-top: 2px solid ${({ theme }) => theme.menuHover};
  }

  .flex-and-center {
    display: flex;
    justify-content: center;
  }

  .credit-card-wrapper {
    display: flex;
    flex-flow: column wrap;
  }

  .credit-card-button-row {
    display: block;
  }

  .credit-card-button {
    width: 100%;
  }

  .default-card-button {
    border-top-left-radius: 14.5px;
    border-bottom-left-radius: 14.5px; //this is to match the card's border-radius
  }

  .delete-card-button {
    border-top-right-radius: 14.5px;
    border-bottom-right-radius: 14.5px; //this is to match the card's border-radius
  }
`;
