import styled from 'styled-components';

/**
 * Styled component for the menu
 */
export const StyledMenu = styled.nav<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.lightGreen};
  height: 100vh;
  align-content: center;
  text-align: left;
  overflow-y: auto;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  z-index: 9;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }

  @media (max-height: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.laptop}) {
    justify-content: center;
  }

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 0.5rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: ${({ theme }) => theme.menuText};
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: 1.5rem;
      padding: 0.75rem 0;
      text-align: center;
    }
    @media (min-width: ${({ theme }) => theme.mobileL}) {
      margin-left: 2.5rem;
      padding-right: 2.5rem;
    }

    &:hover {
      color: ${({ theme }) => theme.menuHover};
      text-decoration: none;
    }
  }
`;
