import axios from 'axios';
import StructuredResponse from '../../model/Classes/StructuredResponse';
import Tag from '../../model/Classes/Tag';
axios.defaults.withCredentials = true;

const tokenURL = '/api/auth/token';

/**
 * Service function responsible for fetching a user's tags.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @returns if successful: [true, response.data]. Otherwise: [false, error]
 */
export const getOwnTags = async () => {
  let success = true;
  try {
    const response = await axios.get<Tag[]>('/api/user/tags', {
      withCredentials: true,
    });
    return new StructuredResponse<Tag[]>(success, response.data);
  } catch (e: any) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get<Tag[]>('/api/user/tags', {
        withCredentials: true,
      });
      return new StructuredResponse<Tag[]>(success, response.data);
    } catch (e: any) {
      success = false;
      return new StructuredResponse<Tag[]>(success, e);
    }
  }
};
