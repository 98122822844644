import axios from 'axios';
axios.defaults.withCredentials = true;

const tokenURL = '/api/auth/token';

/**
 * Service function responsible for changing your default card
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @returns response from the backend
 */
export const setDefaultCard = async (data) => {
  try {
    return await axios.post('/api/user/cards/setDefault', data);
  } catch (e) {
    await axios.post(tokenURL, { withCredentials: true });
    return await axios.post('/api/user/cards/setDefault', data);
  }
};
