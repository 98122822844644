import {
  Col,
  Row,
  Button,
  Alert,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable, {
  ColumnDescription,
  SelectRowProps,
} from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { parseISOString } from '../../../utils/dates';
import infoIcon from '../../../resources/infoIcon.svg';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import { ReportingInvoice } from '../../../model/Classes/Invoice';
import User from '../../../model/Classes/User';
import { StateHandler } from '../../../model/Utilities/Types';

declare interface ReportingInvoicesProps {
  invoices: ReportingInvoice[];
  setSelectedInvoices: StateHandler<ReportingInvoice[]>;
  user: User;
  sendToPayTrail: () => Promise<void>;
  noInvoiceRowChosen: boolean;
  sendToPaytrailFail: boolean;
  sendToPaytrailSuccess: boolean;
  sendToPaytrailWarning: boolean;
  sendToPaytrailMessage: string;
  sendToPaytrailHistory: any[];
  companyInvoices: number[];
  hasCompanyInvoices: boolean;
}

export const ReportingInvoices = ({
  invoices,
  setSelectedInvoices,
  user,
  sendToPayTrail,
  noInvoiceRowChosen,
  sendToPaytrailFail,
  sendToPaytrailSuccess,
  sendToPaytrailWarning,
  sendToPaytrailMessage,
  sendToPaytrailHistory,
  companyInvoices,
  hasCompanyInvoices,
}: ReportingInvoicesProps) => {
  const { t } = useTranslation('common', { i18n: i18n });

  const columnsForTable: ColumnDescription<ReportingInvoice>[] = [
    {
      dataField: 'user_invoice_id',
      text: t('components.reporting.tabs.invoices.table.id'),
    },
    {
      dataField: 'reference_number',
      text: t('components.reporting.tabs.invoices.table.ref'),
      formatter: (cell, row, rowIndex, extraData) =>
        `${row.reference_number ? row.reference_number : ''}`,
    },
    {
      dataField: 'last_name',
      text: t('components.reporting.tabs.invoices.table.user'),
      formatter: (cell, row, rowIndex, extraData) =>
        `${row.first_name} ${row.last_name}`,
      sort: true,
    },
    {
      dataField: 'period_start',
      text: t('components.reporting.tabs.invoices.table.start'),
      formatter: (cell, row, rowIndex, extraData) =>
        `${parseISOString(row.period_start).toLocaleString('en-FI')}`,
      sort: true,
    },
    {
      dataField: 'period_end',
      text: t('components.reporting.tabs.invoices.table.stop'),
      formatter: (cell, row, rowIndex, extraData) =>
        `${parseISOString(row.period_end).toLocaleString('en-FI')}`,
      sort: true,
    },
    {
      dataField: 'kWh',
      text: t('components.reporting.tabs.invoices.table.energy'),
    },
    {
      dataField: 'amount',
      text: t('components.reporting.tabs.invoices.table.total'),
      formatter: (cell, row, rowIndex, extraData) =>
        `${row.amount.toFixed(2)} ${row.currency}`,
    },
    {
      dataField: 'invoice_status',
      text: t('components.reporting.tabs.invoices.table.status'),
      formatter: (cell, row, rowIndex, extraData) => (
        <>
          {row.invoice_status === 0
            ? t('components.reporting.tabs.invoices.table.invoiceStatus.new')
            : row.invoice_status === 1
            ? t('components.reporting.tabs.invoices.table.invoiceStatus.sent')
            : row.invoice_status === 2
            ? t('components.reporting.tabs.invoices.table.invoiceStatus.paid')
            : row.invoice_status === 3
            ? t(
                'components.reporting.tabs.invoices.table.invoiceStatus.notPaid'
              )
            : row.invoice_status === 4
            ? t(
                'components.reporting.tabs.invoices.table.invoiceStatus.canceled'
              )
            : t('components.reporting.tabs.invoices.table.invoiceStatus.error')}
        </>
      ),
      sort: true,
    },
  ];

  const selectRowProps: SelectRowProps<ReportingInvoice> = {
    mode: 'checkbox',
    clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedInvoices((old: ReportingInvoice[]) => [...old, row]);
      } else {
        setSelectedInvoices((old: ReportingInvoice[]) =>
          old.filter(
            (tran: ReportingInvoice) =>
              tran.reference_number !== row.reference_number
          )
        );
      }
      isSelect = !isSelect;
    },
    onSelectAll: (isSelect, rows, e) => {
      isSelect ? setSelectedInvoices(rows) : setSelectedInvoices([]);
    },
  };

  function createCompanyInvoicingMessage(companyInvoices: number[]) {
    if (companyInvoices.length === 0) return;
    const start = companyInvoices.length === 1 ? 'Invoice' : 'Invoices';
    const end =
      companyInvoices.length === 1
        ? 'is a company invoice.'
        : 'are company invoices.';
    let invoices = '';
    for (let i = 0; i < companyInvoices.length; i++) {
      let conc = companyInvoices[i].toString();
      if (i !== companyInvoices.length - 1) conc = conc.concat(', ');
      invoices = invoices.concat(conc);
    }
    return `${start} ${invoices} ${end}`;
  }

  return (
    <>
      {user.user_level === 2 ? (
        <>
          <Row>
            <Col>
              <Button
                variant="primary"
                className="send-to-paytrail"
                onClick={() => sendToPayTrail()}
              >
                {t('components.reporting.tabs.invoices.buttons.toPaytrail')}
              </Button>
              {/*Create an info icon next to the Send to Paytrail button which shows the
              current session's result history.*/}
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="payment-history-tooltip" data-html={true}>
                    {/*Using dangerouslySetInnerHTML in order to get line breaks to work*/}
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          sendToPaytrailHistory.length > 0
                            ? sendToPaytrailHistory.join('<br>')
                            : t('components.reporting.tabs.invoices.tooltip'),
                      }}
                    />
                  </Tooltip>
                }
              >
                <img className="infoIcon" src={infoIcon} alt="info" />
              </OverlayTrigger>
            </Col>
            <Col className="pb-3 invoice-alert-col">
              {noInvoiceRowChosen && (
                <Alert variant="warning">{sendToPaytrailMessage}</Alert>
              )}
              {sendToPaytrailSuccess && (
                <Alert variant="success">{sendToPaytrailMessage}</Alert>
              )}
              {sendToPaytrailWarning && (
                <Alert variant="warning">{sendToPaytrailMessage}</Alert>
              )}
              {sendToPaytrailFail && (
                <Alert variant="danger">{sendToPaytrailMessage}</Alert>
              )}
              {hasCompanyInvoices ? (
                <Alert variant="warning">
                  {createCompanyInvoicingMessage(companyInvoices)}
                </Alert>
              ) : null}
            </Col>
          </Row>
        </>
      ) : null}
      <Row>
        <ToolkitProvider
          bootstrap4
          keyField="user_invoice_id"
          data={invoices}
          columns={columnsForTable}
        >
          {(props) => (
            <BootstrapTable
              striped
              bordered
              hover
              noDataIndication={t(
                'components.reporting.tabs.completed.table.noData'
              )}
              pagination={paginationFactory({
                hidePageListOnlyOnePage: true,
              })}
              selectRow={selectRowProps}
              {...props.baseProps}
            />
          )}
        </ToolkitProvider>
      </Row>
    </>
  );
};
