import { useState, useEffect } from 'react';
import {
  InputGroup,
  Dropdown,
  DropdownButton,
  FormControl,
  Alert,
  Button,
  ButtonGroup,
  Modal,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';
import AreaSingle from '../../../../model/Classes/Area';
import { Contract } from '../../../../model/Classes/Contract';
import User from '../../../../model/Classes/User';
import { timer } from '../../../../utils/timer';

/**
 * Component responsible for displaying the move view. The component uses two useEffects where:
 * 1) gets all the possible parents the area can be moved under and gets the original parent
 * 2) checks if the move is legal or not (description for the legality of the move is found in the useEffect's comments)
 * The component renders the move functionality as long as the area is not the user's root area.
 * @param {*} area area to be moved
 * @param {*} allAreas all the areas the user has access to
 * @param {*} handleSubmitMove function for submitting the change
 * @param {*} subAreas all the subareas for this area
 * @returns the move view
 */

declare interface MasterLoadBalanceProps {
  canBe: boolean;
  directParents: AreaSingle[];
  childrenWithMLB: AreaSingle[];
}

declare interface EditMoveProps {
  area: AreaSingle;
  allAreas: AreaSingle[];
  toggleEdit: () => void;
  userRoot: number[];
  handleSubmitMove: (data: any) => Promise<boolean>;
  subAreas: AreaSingle[];
  childrenWithMasterLoadBalance: AreaSingle[];
  contract?: Contract;
  user: User;
}
const EditMove = ({
  area,
  allAreas,
  toggleEdit,
  userRoot,
  handleSubmitMove,
  subAreas,
  childrenWithMasterLoadBalance,
  contract,
  user,
}: EditMoveProps) => {
  const [possibleParents, setPossibleParents] = useState<AreaSingle[]>([]); //state for all the possible parents
  //state containing the flag knowing if the area can be moved at all, as well as all the direct parents for the area
  const [canMoveWhenMasterLoadBalance, setCanMoveWhenMasterLoadBalance] =
    useState<MasterLoadBalanceProps>({
      canBe: true,
      directParents: [],
      childrenWithMLB: [],
    });
  const [originalParent, setOriginalParent] = useState<AreaSingle>(
    allAreas.filter((a) => a.id === area.parent)[0]
  ); //state for the original parent of this area
  const [fieldValue, setFieldValue] = useState<AreaSingle>(
    allAreas.filter((a) => a.id === area.parent)[0]
  ); //state for the input field value -> initialised to original parent
  const [showChange, setShowChange] = useState(false); //state for showing the popup
  //state for showing an alert notifying the user that there is no point moving the area under its original parent
  const [showAlert, setShowAlert] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false); //state for showing the success alert
  const [showError, setShowError] = useState(false); //state for showing the failed alert
  const { t } = useTranslation('common', { i18n: i18n });

  /**
   * Helper function for showing the move popup. If the input field is the original parent,
   * don't show the modal, just show an alert notifying the user that the move has no effect
   */
  const showMoveModal = () => {
    //get the field value from allAreas
    const newParent = allAreas.filter((a) => a.id === fieldValue.id)[0];

    //if the field value is the same as the original parent,
    //simply show an alert and do not allow the user to change it this way
    if (newParent.id === originalParent.id) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
      setShowChange(true);
    }
  };

  /**
   * Helper function for moving the area. It calls the function handleSubmitMove() which is passed to this component
   * from EditArea.js. If the function return true, show a success alert. Otherwise, show a failed alert.
   */
  const makeMove = async () => {
    const data = {
      new_parent_id: fieldValue.id,
    };
    setShowChange(false);
    (await handleSubmitMove(data))
      ? timer(setShowSuccess)
      : timer(setShowError);
  };

  /**
   * Function that is called on each rerender. The function is responsible for
   * setting the possibleParents, originalParent, and fieldValue states correctly by
   * looping through all the areas and filtering away the areas that the
   * specific area cannot be moved under
   */
  useEffect(() => {
    //It needs to be checked if the area still exists.
    //This needs to be checked due to the fact that if the area is deleted by the user, the code still
    //runs this function (since area is part of the dependency array for the function),
    //and would thus produce errors since it cannot find the area when trying to access its properties.
    if (userRoot.includes(area.id!)) {
      return;
    }
    if (allAreas.find((a) => a.id === area.id)) {
      const possibleParentsEffect: AreaSingle[] = [];

      //For all areas, check if the area is a subarea of this area. If the area is not a subarea,
      //push it to the possibleParentsEffect array.
      allAreas.forEach((a) => {
        let foundChild = false;
        subAreas.forEach((child) => {
          if (child.id === a.id)
            //the area checked is a subarea for this area, set the foundChild boolean to true
            foundChild = true;
        });
        if (!foundChild) possibleParentsEffect.push(a);
      });

      //get the original parent for this area
      const origParent = possibleParentsEffect.find(
        (p) => p.id === area.parent
      );

      //this needs to be checkd since the user root area's parent will not exist in the array
      if (origParent) {
        setOriginalParent(origParent);
      }

      let parentsWithSameContract: AreaSingle[] = [];
      if (user.user_level === 1)
        parentsWithSameContract = possibleParentsEffect.filter((a) => {
          return a.contract_id === area.contract_id;
        });
      else parentsWithSameContract = possibleParentsEffect;

      //filter is needed so that the area itself is not included in the list of possible parents
      setPossibleParents(
        parentsWithSameContract.filter((a) => a.id !== area.id)
      );
    }
  }, [allAreas, area.id, area.parent, subAreas, area, userRoot, user]);

  /**
   * Whenever the user changes the parent field, check if the move is legal or not.
   * It's legal if either 1) the area that is moved is not a master load balance area nor has any children
   * which are master load balance areas or 2) the area that is moved is a master load balancing area AND
   * the area it is moved under is NOT a master load balancing area or nor has a direct parent (parent,
   * grandparent etc.) who is a master load balancing area.
   */
  useEffect(() => {
    //Only check if the move is legal if the area is a master load balancing area or
    //if the component has just initialized (i.e. the user hasn't changed the field yet)
    if (originalParent && fieldValue.id !== originalParent.id) {
      if (
        (area.master_load_balancing ||
          childrenWithMasterLoadBalance.length > 0) &&
        //This should never be empty but cba so
        fieldValue !== undefined
      ) {
        const directParentsArray: AreaSingle[] = [];
        let noChildrenWithMLB = childrenWithMasterLoadBalance.length === 0;

        /**
         * Recursively get all the direct parents to this area and check if any of those are
         * a master load balancing area or not. Stop at the user root area.
         * @param {*} areaToCheck the area to be checked
         */
        const getDirectParents = (areaToCheck: AreaSingle) => {
          directParentsArray.push(areaToCheck); //push the direct parent to the directParentsArray

          if (areaToCheck.master_load_balancing) noChildrenWithMLB = false; //is the area to be checked a master load balancing area?

          if (userRoot.includes(areaToCheck.id!)) return; //if the area is the user root area, stop here
          //get the next area and call the function again
          const nextArea = allAreas.filter(
            (a) => a.id === areaToCheck.parent
          )[0];
          getDirectParents(nextArea);
        };
        //call the function starting from the area the user inputted
        getDirectParents(fieldValue);

        const MLBThing = {
          canBe: noChildrenWithMLB,
          directParents: directParentsArray,
          childrenWithMLB: childrenWithMasterLoadBalance,
        };

        setCanMoveWhenMasterLoadBalance(MLBThing);
      }
    }
  }, [
    allAreas,
    fieldValue,
    area.master_load_balancing,
    childrenWithMasterLoadBalance,
    userRoot,
    originalParent,
  ]);

  //do not allow the user to move their own user root area
  if (userRoot.includes(area.id!))
    return <div>{t('components.area.move.root')}</div>;
  //Sort areas according to their ID's
  //possibleParents.sort((a, b) => a.id! - b.id!);

  /**
   * The component intially returns
   * 1) text informing the user about moving the area + which area is the original parent
   * 2) input field with a dropdown for choosing the area to move this area under
   * 3) the move button
   * 4) an alert notifying the user if the move is illegal or not (description for a legal move is found
   *    in the comments for the second useEffect above)
   *
   * If the user clicks the "Move" button, a popup (modal) appears. The popup informs the user about the move and
   * asks the user to confirm the move. This is done to add another layer to moving the area (less
   * error prone). When the user confirms the action, function makeMove is called (description found in the
   * comments).
   */

  return (
    <>
      {showSuccess ? (
        <Alert key="success-move" variant="success">
          {t('global.alert.success.moveArea')}
        </Alert>
      ) : null}
      {showError ? (
        <Alert key="error" variant="danger">
          {t('global.alert.failure.moveArea')}
        </Alert>
      ) : null}
      <p>
        {t('components.area.move.description.moveWithin')}
        <br />
        {t('components.area.move.description.note')}
        <br />
        {t('components.area.move.description.original', {
          parent: originalParent
            ? originalParent.name
            : t('components.area.move.description.noParent'),
        })}{' '}
      </p>
      {/*Dropdown and input field for the move*/}
      <InputGroup className="mb-3">
        <DropdownButton
          id="dropdown-item-button"
          title={t('components.area.addArea.static.form.parentId')}
          drop="down"
          data-cy="parent-dropdown-button"
        >
          {possibleParents.map((parent, idx) => {
            return (
              <Dropdown.Item
                key={idx}
                as="button"
                onClick={() => setFieldValue(parent)}
              >
                {`${parent.name} (${parent.id})`}
              </Dropdown.Item>
            );
          })}
        </DropdownButton>
        <FormControl
          aria-label="Text input"
          value={fieldValue!.name}
          readOnly
        />
      </InputGroup>
      <>
        <ButtonGroup className="mb-3">
          <>
            <Button
              variant="primary"
              onClick={showMoveModal}
              disabled={!canMoveWhenMasterLoadBalance.canBe}
              data-cy="move-area-button"
            >
              {t('components.area.move.buttons.move')}
            </Button>
            {/*The Modal component is used to display the popup*/}
            <Modal
              show={showChange}
              onHide={() => setShowChange(false)}
              backdrop="static" //doesn't allow the user to close the modal by clicking outside of it
              keyboard={false} //doesn't allow the user to close the modal by using the keyboard
            >
              <Modal.Header>
                <Modal.Title>
                  {t('components.area.move.modal.header')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div //icky wicky
                  dangerouslySetInnerHTML={{
                    __html: t('components.area.move.modal.body', {
                      areaName: area.name,
                      newParent: fieldValue.name,
                      oldParent: originalParent.name,
                    }),
                  }}
                />
                {/* Are you sure you want to do the following move: <br />
                <br />
                <strong>
                  {area.name} and its subareas to be moved under{' '}
                  {fieldValue!.name}
                </strong>
                <br />
                <br />
                {area.name} used to be under {originalParent!.name} */}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="success"
                  onClick={makeMove}
                  data-cy="submit-move"
                >
                  {t('global.buttons.submit')}
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => setShowChange(false)}
                >
                  {t('global.buttons.cancel')}
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        </ButtonGroup>
      </>
      {showAlert ? (
        <Alert key="noChangeMove" variant="warning">
          {t('global.alert.failure.moveSameParent')}
        </Alert>
      ) : null}
      {!canMoveWhenMasterLoadBalance.canBe ? (
        <Alert
          key="cannotMoveMasterLoadBalance"
          variant="danger"
          data-cy="cannotMoveMasterLoadBalanceAlert"
        >
          <div //icky wicky
            dangerouslySetInnerHTML={{
              __html: t('components.area.move.specificAlert', {
                areaName: area.name,
                newParent: fieldValue.name,
                masterLoadBalance: area.master_load_balancing
                  ? ` '${area.name}' ${t('components.area.move.mlb')} `
                  : ' ',
              }),
            }}
          />
          <ul>
            {canMoveWhenMasterLoadBalance.directParents.map(
              (a: AreaSingle, idx: number) => {
                if (a.master_load_balancing) {
                  return (
                    <li key={`${a.id}${idx}`} data-cy={a.id}>
                      {a.name}
                    </li>
                  );
                } else return null;
              }
            )}
            {childrenWithMasterLoadBalance.length > 0 &&
              childrenWithMasterLoadBalance.map((a, idx) => (
                <li key={`${a.id}${idx}`} data-cy={a.id}>
                  {a.name} ({t('components.area.move.child')})
                </li>
              ))}
          </ul>
        </Alert>
      ) : null}
    </>
  );
};

export default EditMove;
