import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Button, Container, Row, Col } from "react-bootstrap";
import { StyledMyTransactions } from "./MyTransactions.styled";
import {
  getMyMonthlySpotPriceTransactions,
  getMyMonthlyTransactions,
} from "../../../services/transactions/MyMonthlyTransactions/MyMonthlyTransactions_service";
import { logger } from "../../../utils/logger";
import generateUniqueId from "../../../utils/generateUniqueId";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import User from "../../../model/Classes/User";
import i18N from "../../../i18n";
import { useTranslation } from "react-i18next";
import { CompletedTransaction, SpotTransactionHistory } from "../../../model/Classes/Transaction";
import { CSVLink } from "react-csv";
import { format } from "date-fns";
import { ChargepointClass } from "../../../model/Classes/Chargepoint";
import getCurrencySymbol from "../../../utils/getCurrencySymbol";

/**
 * Component responsible for displaying the MyMonthlyTransactions page. The page renders:
 * 1) Page header
 * 2) Table with all the transactions for the chosen month
 * 3) Back button (redirects to the MyTransactions page)
 * @param {*} transactions state for the chosen month's transactions for the user
 * @param {*} month the chosen month
 * @param {*} year the chosen year
 * @param {*} user the user of the application
 * @param {*} history history object
 * @returns the MyMonthlyTransactions page
 */
declare interface MyMonthlyTransactionsProps {
  user: User;
  history: any;
}
declare interface MyTransactionsThisMonthProps extends MyMonthlyTransactionsProps {
  transactions: any;
  month: string;
  year: string;
  monthlySpotTransactionHistory: any;
}
const MyTransactionsThisMonth = ({
  transactions,
  month,
  year,
  user,
  history,
  monthlySpotTransactionHistory,
}: MyTransactionsThisMonthProps) => {
  const { t } = useTranslation("common", {
    i18n: i18N,
  });

  const columns: ColumnDescription<CompletedTransaction>[] = [
    {
      dataField: "unique_id",
      text: "",
      hidden: true,
    },
    {
      dataField: "Reporting area name",
      text: `${t("components.myTransactions.table.specific.reportingArea")}`,
      formatter: (cell, row, rowIndex, extraData) => <>{row.reportingArea}</>,
    },
    {
      dataField: "charge_point_id",
      text: `${t("components.myTransactions.table.specific.cp")}`,
      formatter: (cell, row, rowIndex, extraData) => <>{`${ChargepointClass.toString(row)}-${row.connector_id}`}</>,
      sort: true,
    },
    {
      dataField: "address",
      text: `${t("components.myTransactions.table.specific.address")}`,
      sort: true,
      hidden: true,
    },
    {
      dataField: "start_time",
      text: `${t("components.myTransactions.table.specific.start")}`,
      formatter: (cell, row, rowIndex, extraData) => <>{row.start_time}</>,
      sort: true,
    },
    {
      dataField: "stop_time",
      text: `${t("components.myTransactions.table.specific.stop")}`,
      formatter: (cell, row, rowIndex, extraData) => <>{row.stop_time}</>,
      sort: true,
    },
    {
      dataField: "duration",
      text: `${t("components.myTransactions.table.specific.dur")}`,
      sort: true,
    },

    {
      dataField: "tag_nickname",
      text: `${t("components.myTransactions.table.specific.tag")}`,
      sort: true,
    },
    {
      dataField: "kWh",
      text: `${t("components.myTransactions.table.specific.kWh")}`,
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === "asc") return Number(a) - Number(b);
        else return Number(b) - Number(a);
      },
    },
    /** 
    {
      dataField: 'start_price',
      text: `${t('components.myTransactions.table.specific.startPrice')}`,
      formatter: (cell, row, rowIndex, extraData) => (
        <>
          {row.start_price.toFixed(2)} {row.currency}
        </>
      ),
    },
    {
      dataField: 'price',
      text: `${t('components.myTransactions.table.specific.price')} /kWh`,
      formatter: (cell, row, rowIndex, extraData) => (
        <>
          {row.price.toFixed(2)} {row.currency}
        </>
      ),
    },
    */
    {
      dataField: "pricing",
      text: `${t("components.myTransactions.table.specific.pricing")}`,
      formatter: (cell, row, rowIndex, extraData) => {
        const transaction_type = Number(row.transaction_type);
        const spotprice_calculated = Number(row.spotprice_calculated);
        const currencySymbol = String(getCurrencySymbol(row.currency || ""));

        switch (row.pricing_type) {
          case 0: // fixed pricing
            switch (transaction_type) {
              case 1: // private
                return `${row.price} ${currencySymbol}/kWh`;
              case 2: // public
              case 3: // blocked
                return row.start_price > 0
                  ? `${row.start_price} ${currencySymbol} + ${row.price} ${currencySymbol}/kWh`
                  : `${row.price} ${currencySymbol}/kWh`;
            }
            break;
          case 1: // spot pricing
            return spotprice_calculated === 1 // has the spot price been calculated and in the database?
              ? `${row.margin} + ${t("components.myTransactions.table.specific.spotPrice")}${currencySymbol}/kWh`
              : `${t("components.myTransactions.table.specific.pricePending")}`;
          case 2: // free charging
            return `0 ${currencySymbol}/kWh`;
        }
      },
    },
    {
      dataField: "total_cost",
      text: `${t("components.myTransactions.table.specific.total")} `,
      formatter: (cell, row, rowIndex, extraData) => (
        <>
          {row.total_cost} {getCurrencySymbol(row.currency || "")}
        </>
      ),
      sort: true,
    },
  ];

  //header field for the cvs export conditioning label and key
  const spotHeaders = [
    {
      label: "",
      key: "info_head",
    },
    {
      label: `${t("components.myTransactions.table.csv.startTime")}`,
      key: "period_start",
    },
    {
      label: `${t("components.myTransactions.table.csv.endTime")}`,
      key: "period_end",
    },
    {
      label: `${t("components.myTransactions.table.csv.kWh")}`,
      key: "kWh",
    },
    {
      label: `${t("components.myTransactions.table.csv.spotPrice")}`,
      key: "hour_spot_price",
    },
    {
      label: `${t("components.myTransactions.table.csv.margin")}`,
      key: "spot_margin",
    },
    {
      label: `${t("components.myTransactions.table.csv.total")}`,
      key: "total",
    },
  ];

  // header for all table field for my monthly transaction export
  const exportTransHeader = [
    {
      label: "Area",
      key: "reportingArea",
    },
    {
      label: "Chargepoint",
      key: "nickname",
    },
    {
      label: "Connector",
      key: "connector_id",
    },
    {
      label: "Start time",
      key: "start_time",
      formatter: (cell, row, rowIndex, extraData) => row.start_time.toString(),
    },
    {
      label: "Stop time",
      key: "stop_time",
      formatter: (cell, row, rowIndex, extraData) => row.stop_time.toString(),
    },

    {
      label: "Tag",
      key: "tag_nickname",
    },
    {
      label: "kWh",
      key: "kWh",
    },
    {
      label: "Total cost",
      key: "total_cost",
    },
    {
      label: "Currency",
      key: "currency",
    },
  ];

  //interface for empty row insertion in the csv export. created this since the headers were not usable in the context
  interface CSVRow {
    info_head?: string;
    period_start?: string;
    period_end?: string;
    kWh?: number;
    price_novat?: string;
    spot_margin?: number;
    total?: string;
  }
  // Function to format the timestamp using date-fns
  const formatTimestamp = (timestamp) => {
    return format(new Date(timestamp), "yyyy-MM-dd HH:mm:ss");
  };
  //changes date from tz to date and time strings
  const transformData = (data) => {
    return data.map((row) => ({
      ...row,
      period_start: formatTimestamp(row.period_start),
      period_end: formatTimestamp(row.period_end),
      start_time: formatTimestamp(row.start_time),
      end_time: formatTimestamp(row.end_time),
    }));
  };

  const transformedData: any[] = transformData(monthlySpotTransactionHistory);

  const csvDataWithEmptyRows: CSVRow[] = [];

  let preTransactionId: number | null = null;
  let startTime: string | null = null;
  let endTime: string | null = null;

  transformedData.forEach((row, index) => {
    if (index === 0) {
      // Initialize endTime with the end_time of the first row if available
      endTime = row.end_time || null;
    }

    if (index === 0 || row.transaction_id !== preTransactionId) {
      // Set the period start of the first transaction
      startTime = row.start_time;

      // Update endTime if it's not the first row and transaction_id changes
      if (index !== 0 && row.transaction_id !== preTransactionId) {
        const nextRow = transformedData[index];
        endTime = nextRow.end_time;
        const emptyRow = {};
        spotHeaders.forEach((header) => {
          emptyRow[header.key as keyof CSVRow] = undefined;
        });
        csvDataWithEmptyRows.push(emptyRow);
      }

      // Concatenate values to form the string
      const infoRow = {
        info_head: `${row.reporting_area} / ${ChargepointClass.toString(row)} / ${
          row.connector_id
        } / ${startTime} - ${endTime} / ${row.tag_nickname}`,
      };
      csvDataWithEmptyRows.push(infoRow);
    }

    csvDataWithEmptyRows.push(row); // Add the current row
    preTransactionId = row.transaction_id;
  });

  // Add empty row after the header
  const emptyRowAfterHeader = {};
  spotHeaders.forEach((header) => {
    emptyRowAfterHeader[header.key as keyof CSVRow] = undefined;
  });
  csvDataWithEmptyRows.unshift(emptyRowAfterHeader);
  //END CSV export formatting -----------

  // Function to check if a row contains any data, used this because undefined was adding semicolon separator on the blank row
  function isRowEmpty(row) {
    for (const key in row) {
      if (row[key] !== null && row[key] !== "") {
        return false; // Row is not empty
      }
    }
    return true; // Row is empty
  }

  //separator conditional for empty row
  const separator = csvDataWithEmptyRows.some((row) => isRowEmpty(row)) ? "" : ";";

  return (
    <Container id="component-margin">
      {/*Row for the transactions header and table*/}
      <Row>
        <h2>
          {t("components.myTransactions.static.header.specific.has", {
            month: t(`components.myTransactions.months.${month}`),
            year: year,
          })}
        </h2>

        <ToolkitProvider bootstrap4 keyField="unique_id" data={transactions} columns={columns}>
          {(props) => {
            const modifiedDatawithConditional = transactions.map((row) => ({
              ...row,
              nickname: ChargepointClass.toString(row),
              kWh: typeof row.kWh === "string" ? row.kWh.toString().replace(".", ",") : row.kWh,
              total_cost:
                typeof row.total_cost === "number" ? row.total_cost.toString().replace(".", ",") : row.total_cost,
            }));
            return (
              <>
                {/* CSV export button/link */}

                <Row>
                  <CSVLink
                    headers={exportTransHeader}
                    data={modifiedDatawithConditional}
                    filename={`export.csv`}
                    className="csv-link mb-1"
                    separator=";"
                    enclosingCharacter=""
                  >
                    {t("components.myTransactions.table.csv.exportAll")}
                  </CSVLink>
                  <CSVLink
                    data={csvDataWithEmptyRows}
                    headers={spotHeaders}
                    filename={`export.csv`}
                    className="csv-link mb-2"
                    separator={separator}
                    enclosingCharacter=""
                  >
                    {t("components.myTransactions.table.csv.export")}
                  </CSVLink>
                </Row>
                <BootstrapTable
                  classes="table-responsive-xl table-mytransactions text-center"
                  striped
                  bordered
                  hover
                  noDataIndication={`${t("components.myTransactions.static.header.specific.hasNot", {
                    month: t(`components.myTransactions.months.${month}`),
                    year: year,
                  })}`}
                  defaultSorted={[
                    {
                      dataField: "start_time",
                      order: "desc",
                    },
                  ]}
                  {...props.baseProps}
                />
              </>
            );
          }}
        </ToolkitProvider>
      </Row>
      {/*Row for the back button*/}
      <Row>
        <Col xs="12" sm="1">
          <Button variant="secondary" onClick={() => history.push("/myTransactions")}>
            {t("global.buttons.back")}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

/**
 * Component responsible for handling the functionality related to the MyMonthyTransactions page.
 *
 * When the component is initialized, the useEffect hook is run. The hook fetches all the completed transactions for the
 * chosen month's for the user (the user is checked at the backend using the tokens). If successful, it updates the correct
 * states and sets the showView state to 1 to show the MyMonthlyTransactions page. Otherwise, it sets the showView state to 0 to
 * show an error page to the user.
 * @param {*} user user of the application
 * @param {*} history history object
 * @returns loading screen, error screen, or MyMonthlyTransactions screen
 */

const MyMonthlyTransactions = ({ user, history }: MyMonthlyTransactionsProps) => {
  const [transactionsThisMonth, setTransactionsThisMonth] = useState<CompletedTransaction[]>([]); //state for the chosen month's transactions for the user
  const [spotPriceTransactionData, setSpotPriceTransactionData] = useState<SpotTransactionHistory[]>([]);
  const [showView, setShowView] = useState(-1); //state for showing the loading screen, error screen, or MyMonthlyTransaction screen

  const { t } = useTranslation("common", {
    i18n: i18N,
  });

  //Get the month and year from the URL
  const month = useParams<{ month: string }>().month;
  const year = useParams<{ year: string }>().year;

  /**
   * Upon rerender, the useEffect hook fetches all the completed transactions for the chosen month's for the user
   * (the user is checked at the backend using the tokens). If it's successful, update the corresponding states with
   * the data and set the showView state to 1 (shows the MyMonthlyTransactions page). Otherwise, log the error and set the
   * showView state to 0 (shows an error message to the user).
   */
  useEffect(() => {
    /**
     * Asynchronous helper function for calling the service function that fetch the needed data from the backend.
     * If both succeed, update the correct states with all the data and set the showView state to 1. Otherwise,
     * set the showView state to 0.
     */
    const getData = async () => {
      //Call the correct service function to get the data needed
      const res = await getMyMonthlyTransactions(year, month);

      //Check if the request came back as successful
      if (res.success) {
        //Success, update the transactionsThisMonth state and set the showView state to 1 to show the MyMonthlyTransactions page

        //We need to add a unique id field to each data row, since the component responsible
        //for rendering the table needs a unique id field
        const finalTransactions = res.data.map((tran) => {
          return { ...tran, unique_id: generateUniqueId() };
        });
        setTransactionsThisMonth(finalTransactions);
        setShowView(1);
      } else {
        logger(res.data);

        setShowView(0); //Set the showView state to 0 notifying the user that an error occured
      }
    };

    getData(); //Call the asynchronous helper function
  }, [month, year]);

  //useeffect to get data for teh spot price transactions when user clicks any month
  useEffect(() => {
    // Fetch data from your API
    const getSpotData = async () => {
      const res = await getMyMonthlySpotPriceTransactions(year, month);
      if (res.success) {
        const finalSpotData = res.data.map((spotData) => {
          return { ...spotData, unique_id: generateUniqueId() };
        });
        setSpotPriceTransactionData(finalSpotData);
      } else {
        logger(res.data);
      }
    };
    getSpotData();
  }, [month, year]);

  /**
   * Initially, the component shows a loading screen. If an error occured when fetching data, an appropriate
   * error message is displayed. Otherwise, show the MyTransactionsThisMonth component
   */
  return (
    <StyledMyTransactions className="top-level-component">
      {showView === -1 ? (
        <>
          <h2 className="align-self-center">{t("global.view.loading")}</h2>
        </>
      ) : showView === 0 ? (
        <h2 className="align-self-center">{t("global.view.error")}</h2>
      ) : (
        <MyTransactionsThisMonth
          transactions={transactionsThisMonth}
          month={month}
          year={year}
          user={user}
          history={history}
          monthlySpotTransactionHistory={spotPriceTransactionData}
        />
      )}
    </StyledMyTransactions>
  );
};

export default MyMonthlyTransactions;
