import axios from "axios";
import { TransactionCp } from "../../model/Classes/Chargepoint";
import StructuredResponse from "../../model/Classes/StructuredResponse";
axios.defaults.withCredentials = true;

const tokenURL = "/api/auth/token";
const baseUrl = "/api/user/chargingAccess/public";

/**
 * Service function responsible for fetching all chargepoints the user has access to.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @returns if successful: [true, response.data]. Otherwise: [false, error]
 */
export const getPublicChargepoints = async () => {
  try {
    const response = await axios.get(baseUrl);
    return new StructuredResponse<TransactionCp[]>(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(baseUrl);
      return new StructuredResponse<TransactionCp[]>(true, response.data);
    } catch (e: any) {
      return new StructuredResponse<TransactionCp[]>(false, e);
    }
  }
};
