import { useState, useEffect } from "react";
import { Button, ButtonGroup, Modal, Alert, Container, Col } from "react-bootstrap";
import AreaSingle, { AreaFull } from "../../../../model/Classes/Area";
import { LogLevel, StateHandler } from "../../../../model/Utilities/Types";
import { addArea } from "../../../../services/area/addArea";
import { logger } from "../../../../utils/logger";
import { timer } from "../../../../utils/timer";
import { toggleAlertsOff } from "../../../../utils/toggleAlertsOff";
import { getParentsMasterLoadBalance } from "./SettingsUtils";
import i18N from "../../../../i18n";
import { useTranslation } from "react-i18next";
import { AreaMLBSettings } from "./AreaMLBSettings";
import { AreaSettingsGeneral } from "./AreaSettingsGeneral";
import { Contract } from "../../../../model/Classes/Contract";
import { getAvailableContracts } from "../../../../services/admin/contracts";
import User from "../../../../model/Classes/User";

/**
 * Form component responsible for displaying the form for adding an area. Also
 * responsible for validating the fields that the user inputs and showing a popup with
 * the given inputs. If the user puts in valid data and clicks the Submit changes button
 * in the popup, the component will call the function commitAdd(). The function
 * further calls the service function handleSubmitArea(). If successful, redirect
 * to the area tree page or the single area page and display a success alert.
 * Otherwise, display an error alert
 * @param {*} name name state of the area to be added
 * @param {*} limit limit state for the area to be added
 * @param {*} meter meter state of the area to be added
 * @param {*} masterLoadBalance master load balance state for the area to be added
 * @param {*} setMeter state handler for meter
 * @param {*} setMasterLoadBalance state handler for master load balance
 * @param {*} handleNameChange function for using the state handler to set the name state
 * @param {*} handleLimitChange function for using the state handler to set the limit state
 * @param {*} handleSubmitArea function responsible for sending the data to the backend and updating the session storage
 * @param {*} toggleAdd toggler for switching between the area tree view/single area view and add area view
 * @param {*} areas all the areas the user has access to
 * @param {*} parent parent state for the area to be added
 * @param {*} setParent state handler for parent
 * @returns add area form
 */
declare interface AddAreaFormProps {
  name: string;
  limit: string;
  handleLimit: (event: any) => void;
  masterLoadBalance: string;
  setMasterLoadBalance: StateHandler<string>;
  handleNameChange: (event: any) => void;
  handleLimitChange: (event: any) => void;
  handleSubmitArea: (parentId: number, data: AreaFull) => Promise<boolean>;
  toggleAdd: (toggle: boolean) => void;
  areas: AreaFull[];
  parent: AreaFull;
  setParent: StateHandler<AreaFull>;
  userRoot: number[];
  contract?: Contract;
  isReporting: number;
  setIsReporting: StateHandler<number>;
  user: User;
  pricingType: number;
  setPricingType: StateHandler<number>;
}
const AddAreaForm = ({
  name,
  limit,
  handleLimit,
  masterLoadBalance,
  setMasterLoadBalance,
  handleNameChange,
  handleLimitChange,
  handleSubmitArea,
  toggleAdd,
  areas,
  parent,
  setParent,
  userRoot,
  contract,
  user,
}: AddAreaFormProps) => {
  const [showAdd, setShowAdd] = useState(false); //state for showing the add popup (modal)
  const [showCancel, setShowCancel] = useState(false); //state for showing the cancel popup (modal)
  const [nameAlert, setNameAlert] = useState(false); //state for showing an alert notifying the user to specify a name
  const [meterAlert, setMeterAlert] = useState(false); //state for showing an alert notifying the user to specify if the area has a meter or not
  const [loadAlert, setLoadAlert] = useState(false); //state for showing an alert notifying the user to specify if the area is a master load balancing area or not
  const [parentAlert, setParentAlert] = useState(false); //state for showing an alert notifying the user to specify a parent
  const [loadAndLimitAlert, setLoadAndLimitAlert] = useState(false); //state for showing an alert notifying the user to set a limit if master load balancing is toggled on
  const [canBeMasterLoadBalance, setCanBeMasterLoadBalance] = useState(true); //state for disabling or activating the master load balancing buttons
  const [details, setDetails] = useState<string[]>([]); //state containing the details of the area to be added (details area shown in the add popup)
  const [parentsWithMasterLoadBalance, setParentsWithMasterLoadBalance] = useState<AreaSingle[]>([]); //state for the parents that are master load balancing areas
  const [showError, setShowError] = useState(false); //state for showing an error alert
  const [disabledFields, setDisabledFields] = useState(false); //state for disabling the input fields and buttons
  const [isReporting, setIsReporting] = useState(0);
  const [accessType, setAccessType] = useState(parent.access_type ? parent.access_type : 1);
  const [areaChargingType, setAreaChargingType] = useState<{ name: string; value: number }[]>([]);
  const [publicPrice, setPublicPrice] = useState(
    `${parent.default_public_price ? parent.default_public_price.toFixed(2) : "0.00"}`
  );
  const [publicStartPrice, setPublicStartPrice] = useState(
    `${parent.default_public_start_price ? parent.default_public_start_price.toFixed(2) : "0.00"}`
  );

  const [privatePrice, setPrivatePrice] = useState(
    `${parent.default_price ? parent.default_price.toFixed(2) : "0.00"}`
  );
  const [publicPriceAlert, setPublicPriceAlert] = useState(false);

  const [currentContract, setCurrentContract] = useState(contract);

  const [availableContracts, setAvailableContracts] = useState<Contract[]>([]);

  const { t } = useTranslation("common", {
    i18n: i18N,
  });

  const [invoicing, setInvoicing] = useState(() =>
    contract?.invoicing_method !== undefined ? contract?.invoicing_method : 1
  );
  const [pricingType, setPricingType] = useState(() => (parent.pricing_type ? parent.pricing_type : 0));

  const [spotPriceMargin, setSpotPriceMargin] = useState(() => (parent.margin ? parent.margin : "0"));

  const [userBiddingValue, setUserBiddingValue] = useState(() => (parent.bidding_area ? parent.bidding_area : ""));

  const areaAccessType = [
    {
      name: t("components.area.static.singleArea.header.areaType.free"),
      value: 0,
    },
    {
      name: t("components.area.static.singleArea.header.areaType.private"),
      value: 1,
    },
    {
      name: t("components.area.static.singleArea.header.areaType.privPub"),
      value: 2,
    },
    {
      name: t("components.area.static.singleArea.header.areaType.public"),
      value: 3,
    },
  ];

  const areaCurr = parent.currency; //taking parent currency value for a new area

  //const [timezone, setTimezone] = useState('')
  /**
   * useEffect hook that checks if the area to be added can be a master load balancing area or not.
   * This is checked whenever the parent field is updated (the user chooses a parent from the dropdown).
   * The area to be added can be toggled as a master load balancing area IFF the chosen parent or any of
   * the chosen parents direct parents (parent, grandparent etc.) are NOT master load balancing areas.
   */
  useEffect(() => {
    //Whenever the hook is run, set the canBeMasterLoadBalance state to true (will be set to false
    //if the area cannot be a master load balance area). Additionally, reset the
    //parentsWithMasterLoadBalance state
    setCanBeMasterLoadBalance(true);
    setParentsWithMasterLoadBalance([]);

    //First the code checks if the parent is the user's root. If it is, the area to be added can always
    //be a master load balancing area (user root can never be a master load balancing area).
    //The second check is there so that the program doesn't break. As long as a parent is specified,
    //we run the code. The case when the parent is not specified, is when the user hasn't chosen a parent
    //yet, i.e. the parent state is an object with a name property eqaul to an empty string.
    if (parent)
      if (!parent.user_root && parent.name !== "") {
        //Call the util function getParentsMasterLoadBalance to get all the parents that are master load balancing areas.
        const parentsMasterLoadBalance = getParentsMasterLoadBalance(areas, parent, setCanBeMasterLoadBalance);
        //If the function getParentsMasterLoadBalance returned an array, set that array to the parentsWithMasterLoadBalance state
        //and update the state masterLoadBalance to 0.
        //If there were no such parents, the function returns undefined. The reason for this is that I tried to return an empty array,
        //but for some reason the return value was undefined, so I opted for this solution.
        if (parentsMasterLoadBalance) {
          setParentsWithMasterLoadBalance(parentsMasterLoadBalance);
          setMasterLoadBalance("0");
        }
      }
  }, [parent, areas, setMasterLoadBalance]);

  useEffect(() => {
    const getData = async () => {
      const data = await getAvailableContracts();
      if (data.success) {
        setAvailableContracts(data.data);
        if (parent) {
          setCurrentContract(data.data.find((c) => c.id === parent.contract_id));
        }
      } else {
        logger(data.data, LogLevel.ERROR);
      }
    };
    getData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (availableContracts.length > 0) setCurrentContract(availableContracts.find((c) => c.id === parent.contract_id));
    //eslint-disable-next-line
  }, [parent]);

  /**
   * Helper function to validate the input fields. The function checks for multiple criteria, and if a criteria fails
   * it sets the pass variable to false and toggles the respective alert on.
   * This is done instead of instantly returning false, because then all the criteria are checked instead of the
   * function stopping once the first violation occured.
   * @returns true if the validation passed, false otherwise
   */
  const validate = () => {
    //return value
    let success = true;
    if (name === "") {
      success = false;
      setNameAlert(true);
    } //no name was specified
    if (parent.name === "") {
      success = false;
      setParentAlert(true);
    } //no parent was specified
    if (masterLoadBalance === null) {
      success = false;
      setLoadAlert(true);
    } //didn't toggle "is master load balancing" or "is not master load balancing"
    if (Number(limit) === 0 && masterLoadBalance === "1") {
      success = false;
      setLoadAndLimitAlert(true);
    } //toggled on master load balancing but didn't set a limit

    if (!accessType) success = false;
    else if (![0, 3].includes(accessType)) {
      if (pricingType === 0) {
        const prpNumber = Number(privatePrice);
        if (prpNumber < 0 || prpNumber > 10) {
          setPublicPriceAlert(true);
          success = false;
        }
      }
    } else if (accessType >= 2) {
      if (pricingType === 0) {
        const ppNumber = Number(publicPrice);
        const pspNumber = Number(publicStartPrice);
        if (ppNumber < 0 || ppNumber > 10 || pspNumber < 0 || pspNumber > 10) {
          setPublicPriceAlert(true);
          success = false;
        }
      }
    }
    return success;
  };

  /**
   * Helper function for showing the add popup (modal). The function first toggles all alerts off, and then
   * validates the input fields by calling the validate() function. If the validation didn't pass, exit the
   * function. If the validation passed, update the details state with the correct information and set the
   * showAdd state to true (triggers the popup to appear).
   */
  const showAddModal = () => {
    //Call the utility function toggleAlertsOff to toggle all the alerts off
    toggleAlertsOff([setNameAlert, setParentAlert, setMeterAlert, setLoadAlert, setLoadAndLimitAlert]);

    if (!validate()) return; //if the validation failed, exit the function

    const deets: string[] = [];

    //Update the details state with the correct information
    deets.push(`${t("components.area.addArea.details.name")}: ${name}`);
    deets.push(`${t("components.area.addArea.details.limit")}: ${limit ? limit + " A" : "None"}`); //If the limit is undefined (the user spammed backspace on the field), set it to 0   this is old condition[limit ? limit + ' A' : 'No limit']
    deets.push(`${t("components.area.addArea.details.parent")}: ${parent.name}`);
    deets.push(
      `${
        Number(masterLoadBalance)
          ? t("components.area.addArea.details.mlb.has")
          : t("components.area.addArea.details.mlb.notHas")
      }`
    );
    deets.push(`${t("components.area.static.singleArea.header.areaType.type")}: ${areaAccessType[accessType!].name}`);

    if (currentContract) {
      deets.push(
        `${t("components.area.static.singleArea.header.spotPrice.pricingType")}: ${
          pricingType === 0
            ? `${t("components.area.static.singleArea.header.spotPrice.normal")}`
            : `${t("components.area.static.singleArea.header.spotPrice.spotPriceVal")}`
        } `
      );
      if (pricingType === 0) {
        deets.push(`${t("components.chargepoint.static.tabs.general.info.table.price")}: ${privatePrice} ${areaCurr}`);
        if ([0, 2].includes(currentContract.invoicing_method!)) {
          deets.push(`${t("components.area.static.singleArea.header.publicPrice")}: ${publicPrice} ${areaCurr}`);
          deets.push(`${t("components.area.static.singleArea.header.publicStart")}: ${publicStartPrice} ${areaCurr}`);
        }
      } else {
        deets.push(`${t("components.area.static.singleArea.header.spotPrice.margin")}: ${spotPriceMargin} ${areaCurr}`);
        deets.push(`${t("components.area.static.singleArea.header.spotPrice.biddingValue")}: ${userBiddingValue} `);
      }
    }
    deets.push(`Reporting: ${isReporting}`);
    setDetails(deets);
    //Trigger the popup to appear
    setShowAdd(true);
  };

  //function to format the margin value when ',' is used in the input
  const formatMarginValue = (margin: number | string): number => {
    return typeof margin === "string" ? parseFloat(margin.replace(",", ".")) : margin;
  };
  /**
   * Helper function that is called when the user presses the submit button in the popup.
   * The function closes the popup, and submits the data using the service function
   * handleSubmitArea(). If the submission was successful, go back to the area tree
   * page or single area page and display an alert telling the user that the submission
   * was successful. Otherwise, display an alert telling the user that the submission
   * failed.
   */
  const commitAdd = async () => {
    setDisabledFields(true);
    setShowAdd(false);
    const fromattedSpotPriceMargin = formatMarginValue(spotPriceMargin); //formats the spot price margin into  decimal when ',' is used
    //Data to be sent to the backend
    const data: AreaFull = {
      name: name,
      a_limit: limit === "" ? null : Number(limit), //If the limit is zero, send it as 0 ,
      has_meter: 0,
      master_load_balancing: Number(masterLoadBalance),
      default_price: [0, 3].includes(accessType) ? Number(parent.default_price) : Number(privatePrice),
      default_public_price:
        currentContract?.invoicing_method !== 1 ? Number(publicPrice) : Number(parent.default_public_price),
      default_public_start_price:
        currentContract?.invoicing_method !== 1 ? Number(publicStartPrice) : Number(parent.default_public_start_price),
      contract_id: parent.contract_id,
      access_type: accessType,
      reporting_site: isReporting,

      timezone: parent.timezone,
      currency: parent.currency,
      pricing_type: pricingType,
      margin: fromattedSpotPriceMargin,
      bidding_area: userBiddingValue,
      //Invoicing method is always taken from parent, but has to be defined here for simplicity
    };

    //Invoicing method is always taken from parent, but has to be defined here for simplicity

    //Submit the data using the service function handleSubmitArea()
    if (await handleSubmitArea(parent.id!, data)) {
      toggleAdd(true); //Submission was successful, go back to the area tree page or single area page and display a success alert
    } else {
      timer(setShowError);
      setDisabledFields(false);
    } //Submission failed, show an error alert
  };

  /**
   * Return a form containing fields, a dropdown, and buttons for the various inputs. Additionally,
   * render the popup whenever the save/cancel button is pressed, and alerts whenever they need to be shown.
   */
  return (
    <>
      <AreaSettingsGeneral
        newName={name}
        disabledFields={disabledFields}
        handleNameChange={handleNameChange}
        //limit={limit}
        handleLimit={handleLimitChange}
        showLimitAlert={loadAndLimitAlert}
        parent={parent}
        setAreaChargingType={setAreaChargingType}
        areaChargingType={areaChargingType}
        accessType={accessType}
        setAccessType={setAccessType}
        areas={areas}
        userRoot={userRoot}
        setParent={setParent}
        privatePrice={privatePrice}
        setPrivatePrice={setPrivatePrice}
        publicPrice={publicPrice}
        setPublicPrice={setPublicPrice}
        publicStartPrice={publicStartPrice}
        setPublicStartPrice={setPublicStartPrice}
        publicPriceAlert={publicPriceAlert}
        contract={currentContract}
        invoicing={invoicing}
        setInvoicing={setInvoicing}
        isReporting={isReporting}
        setIsReporting={setIsReporting}
        user={user}
        pricingType={pricingType}
        setPricingType={setPricingType}
        spotPriceMargin={spotPriceMargin}
        setSpotPriceMargin={setSpotPriceMargin}
        userBiddingValue={userBiddingValue}
        setUserBiddingValue={setUserBiddingValue}
        addArea={true}

        // masterLoadBalance={masterLoadBalance}
      />
      <AreaMLBSettings
        canBeMasterLoadBalance={canBeMasterLoadBalance}
        disabledFields={disabledFields}
        masterLoadBalance={masterLoadBalance}
        setMasterLoadBalance={setMasterLoadBalance}
        showMasterLoadBalancingAlert={false}
        childrenWithMasterLoadBalance={[]}
        parentsWithMasterLoadBalance={parentsWithMasterLoadBalance}
        editArea={true}
        limit={limit}
        handleLimit={handleLimitChange}
        addArea={true}
        parent={parent}
        allAreas={areas}
      />
      <Col className="mt-3">
        {nameAlert ? (
          <Alert key="invalidName" variant="danger">
            {t("global.alert.failure.invalidName")}
          </Alert>
        ) : null}
        {parentAlert ? (
          <Alert key="parentAlert" variant="danger">
            {t("components.area.addArea.alert.parent")}
          </Alert>
        ) : null}
        {meterAlert ? (
          <Alert key="meterAlert" variant="danger">
            {t("components.area.addArea.alert.meter")}
          </Alert>
        ) : null}
        {loadAlert ? (
          <Alert key="loadAlert" variant="danger">
            {t("components.area.addArea.alert.mlb")}
          </Alert>
        ) : null}
        {loadAndLimitAlert ? (
          <Alert key="loadAndLimitAlert" variant="danger">
            {t("components.area.addArea.alert.mlbLimit")}
          </Alert>
        ) : null}
        {showError ? (
          <Alert key="showError" variant="danger">
            {t("global.view.errorTry")}
          </Alert>
        ) : null}
      </Col>
      {/*ButtonGroup containing the save and cancel button with their corresponding popups*/}
      <ButtonGroup className="mb-3">
        <>
          {/*Save button*/}
          <Button variant="primary" onClick={showAddModal} id="save" disabled={disabledFields}>
            {t("components.area.addArea.static.form.save")}
          </Button>
          {/*Popup that is shown when the save button is pressed and the input fields are successfully validated*/}
          <Modal
            show={showAdd}
            onHide={() => setShowAdd(false)}
            backdrop="static" //doesn't allow the user to close the modal by clicking outside of it
            keyboard={false} //doesn't allow the user to close the modal by using the keyboard
          >
            <Modal.Header>
              <Modal.Title>{t("components.area.addArea.static.modal.save")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/*Show a list with all the details*/}
              {t("components.area.addArea.static.modal.sure")}
              <ul>
                {details.map((detail, idx) => {
                  return (
                    <li key={idx} id={`${idx}`}>
                      {detail}
                    </li>
                  );
                })}
              </ul>
            </Modal.Body>
            {/*Submit and cancel buttons*/}
            <Modal.Footer>
              <Button variant="success" onClick={commitAdd} id="add-area-final">
                {t("components.area.addArea.static.modal.add")}
              </Button>
              <Button variant="secondary" onClick={() => setShowAdd(false)}>
                {t("components.area.addArea.static.modal.cancel")}
              </Button>
            </Modal.Footer>
          </Modal>
        </>
        <>
          {/*Cancel button*/}
          <Button variant="secondary" onClick={() => setShowCancel(true)}>
            {t("components.area.addArea.static.form.cancel")}
          </Button>
          {/*Popup that is shown when the cancel button is pressed*/}
          <Modal
            show={showCancel}
            onHide={() => setShowCancel(false)}
            backdrop="static" //doesn't allow the user to close the modal by clicking outside of it
            keyboard={false} //doesn't allow the user to close the modal by using the keyboard
          >
            <Modal.Header>
              <Modal.Title>{t("components.area.addArea.static.modal.cancel")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{t("components.area.addArea.static.modal.confirmCancel")}</Modal.Body>
            <Modal.Footer>
              {/*Cancel and go back buttons*/}
              <Button variant="danger" onClick={() => toggleAdd(false)}>
                {t("components.area.addArea.static.modal.cancel")}
              </Button>
              <Button variant="secondary" onClick={() => setShowCancel(false)}>
                {t("components.area.addArea.static.form.back")}
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      </ButtonGroup>
      {/*Render the validation alerts as well as the error alert here*/}
    </>
  );
};

/**
 * Component for adding an area. This component keeps the states for the data that will be sent
 * to the backend with the function handleSubmitArea(parentId, data)
 * @param {*} toggleAdd state handler for switching between the area tree view/single area view and add area view
 * @param {*} areas all the areas that the user has access to
 * @param {*} startingParent if this is given, set parent to this
 * @param {*} runUseEffect helper function for running the useEffect in Area.js again
 * @returns add area page
 */
declare interface AddAreaProps {
  toggleAdd: (added: boolean) => void;
  areas: AreaSingle[];
  startingParent?: AreaSingle;
  runUseEffect: (s: number) => void;
  userRoot: number[];
  history: any;
  contract?: Contract;
  isReporting: number;
  setIsReporting: StateHandler<number>;
  user: User;
  handleLimit: (event: any) => void;
  pricingType: number;
  setPricingType: StateHandler<number>;
}
const AddArea = ({
  toggleAdd,
  areas,
  startingParent,
  runUseEffect,
  userRoot,
  history,
  contract,
  isReporting,
  setIsReporting,
  user,
  handleLimit,
  pricingType,
  setPricingType,
}: AddAreaProps) => {
  const [name, setName] = useState(""); //state for the name field
  const [limit, setLimit] = useState(""); //state for the limit field (initially empty/null)
  const [masterLoadBalance, setMasterLoadBalance] = useState("0"); //state for the master load balancing
  const [parent, setParent] = useState(
    startingParent ? startingParent : areas.filter((a) => userRoot.includes(a.id!))[0]
  ); //state for the parent
  const { t } = useTranslation("common", {
    i18n: i18N,
  });
  /**
   * Helper function for submitting the new area data to the backend. The function calls the
   * service function addArea, and if it's successful it will trigger the useEffect in Area.js to run
   * (fetches all the area data again) and return true. Otherwise, return false
   * @param {*} parentId id of the parent the new area will be placed under
   * @param {*} data data for the new area
   * @returns true if successful, false otherwise
   */
  const handleSubmitArea = async (parentId: number, data: AreaFull) => {
    const addRes = await addArea(parentId, data); //Call a service function for sending the new area data to the backend
    if (addRes.success) {
      if (!startingParent) history.push("/area", { parent_id: parentId });
      runUseEffect(1);
    } else {
      logger(addRes.data);
    }
    return addRes.success;
  };

  /**
   * Helper function for changing the name state
   * @param {*} event event
   */
  const handleNameChange = (event: any) => {
    setName(event.target.value);
  };

  /**
   * Helper function for changing the limit state
   * @param {*} event event
   */

  const handleLimitChange = (event: any) => {
    setLimit(event.target.value !== "" ? event.target.value : null);
  };

  //Simply returns a header and the AddAreaForm
  return (
    <Container className="anchor-areas-add" id="component-margin">
      <h2>{t("components.area.addArea.static.header")}</h2>
      <AddAreaForm
        name={name}
        limit={limit}
        handleLimit={handleLimit}
        handleSubmitArea={handleSubmitArea}
        masterLoadBalance={masterLoadBalance}
        setMasterLoadBalance={setMasterLoadBalance}
        handleNameChange={handleNameChange}
        handleLimitChange={handleLimitChange}
        toggleAdd={toggleAdd}
        areas={areas}
        parent={parent}
        setParent={setParent}
        userRoot={userRoot}
        contract={contract}
        isReporting={isReporting}
        setIsReporting={setIsReporting}
        user={user}
        pricingType={pricingType}
        setPricingType={setPricingType}
      />
    </Container>
  );
};

export default AddArea;
