import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Alert,
  Button,
  Modal,
  InputGroup,
  FormControl,
  Col,
} from 'react-bootstrap';
import { timer } from '../../../../utils/timer';
import AreaSingle from '../../../../model/Classes/Area';
import User from '../../../../model/Classes/User';
import CpFull, {
  ChargepointClass,
} from '../../../../model/Classes/Chargepoint';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';

/**
 * Component for deleting an area. When the user wants to delete an area, they need to confirm
 * the deleting process by typing in the name of the area in a field to activate the second delete
 * button
 * @param {*} area area that will be deleted
 * @param {*} handleDelete helper function passed from EditArea.js that handles the delete request
 * @param {*} history history object
 * @param {*} directlyAuthorizedUsers users that are directly authorized to this area
 * @param {*} chargepoints all the chargepoints for this area
 * @param {*} handleDeauthorizeAllUsers helper function for deauthorizing all directly authorized users in this area
 * @returns delete view
 */

declare interface EditDeleteProps {
  area: AreaSingle;
  handleDelete: () => Promise<boolean>;
  history: any;
  directlyAuthorizedUsers: User[];
  chargepoints: CpFull[];
  subAreas: AreaSingle[];
  handleDeauthorizeAllUsers: () => Promise<boolean>;
}
const EditDelete = ({
  area,
  handleDelete,
  history,
  directlyAuthorizedUsers,
  chargepoints,
  subAreas,
  handleDeauthorizeAllUsers,
}: EditDeleteProps) => {
  const [showModal, setShowModal] = useState(false); //state for showing the confirmation popup
  const [activeButton, setActiveButton] = useState(false); //state for the "confirm delete" button in the popup
  const [showError, setShowError] = useState(false); //state for error alert
  const [successfullDeauthorization, setSuccessfullDeauhorization] =
    useState(false); //state for successfully deauthorizing all the users alert
  const [failedDeauthorization, setFailedDeauhorization] = useState(false); //state for failed deauthorization of all the users alert
  const { t } = useTranslation('common', { i18n: i18n });

  /**
   * Helper funtion to check if the name inserted to the popup field is the same as
   * the name of the area. Only make the "confirm delete" button active when the input is
   * the same as the area name
   * @param {*} event event the function is called for
   */
  const handleDeleteInput = (event: string | undefined) => {
    event === area.name ? setActiveButton(true) : setActiveButton(false);
  };

  /**
   * Helper function for deauthorizing all the directly autorized users to this area.
   * If successful, show the success alert. Otherwise, show the failed alert.
   * @param {*} event event the function is called for
   */
  const handleDeauthorization = async (event: {
    preventDefault: () => void;
  }) => {
    event.preventDefault();
    (await handleDeauthorizeAllUsers())
      ? timer(setSuccessfullDeauhorization)
      : timer(setFailedDeauhorization);
  };

  /**
   * Helper function for opening the delete popup
   */
  const openDeleteModal = () => {
    setShowModal(true);
    setShowError(false);
  };

  /**
   * Helper function for closing the delete popup
   */
  const closeDeleteModal = () => {
    setShowModal(false);
    setActiveButton(false);
  };

  /**
   * Helper function for deleting the area. It calls the function handleDelete() which is passed to this component
   * from EditArea.js. If the function returns true, push the user to the path /area with a state informing the
   * Area.js component that an area was just deleted (needed to display a success notification to the user).
   * If the function returns false, display an error message to the user.
   */
  const deleteArea = async () => {
    setShowModal(false);
    (await handleDelete())
      ? history.push({
          pathname: '/area',
          state: { areaDeleted: true, area_id: area.parent },
        })
      : setShowError(true);
  };

  const findSubAreaContainingChargepoint = (area_id: number) => {
    return subAreas.find((area) => {
      return area.id === area_id;
    })?.name;
  };

  //Do not allow the user todelete their own user root area
  if (area.user_root) return <div>{t('components.area.delete.root')}</div>;

  /**
   * The component initially returns
   * 1) an alert warning the user of deleting the area + the criteria needed to delete the area
   *    (the criteria can be seen in html list below)
   * 2) the delete button (active or inactive)
   * 3) possibly another alert notifying the user that a criteria has not been satisfied
   *
   * If the user clicks the "delete area" button, a popup (modal) appears. The popup prompts the user
   * to insert the name of the area in the field. This is done to add another layer to deleting an area (less
   * error prone). When the user confirms the action, function deleteArea is called (description found in the
   * comments).
   */
  return (
    <>
      <Col>
        <Alert key="delete-warning" variant="danger">
          <div //icky wicky
            dangerouslySetInnerHTML={{
              __html: t('components.area.delete.static.warning', {
                interpolation: { escapeValue: false },
              }),
            }}
          />
        </Alert>
      </Col>
      {directlyAuthorizedUsers.length > 0 || chargepoints.length > 0 ? (
        <Alert key="cannot-delete" variant="danger" data-cy="cannot-delete">
          {t('components.area.delete.criteria.cant')}
          <ul>
            {chargepoints.length > 0 ? (
              <>
                <li>
                  {t('components.area.delete.criteria.cps')}
                  <ul>
                    {chargepoints.map((cp, idx) => {
                      return (
                        <li key={idx}>
                          <Link
                            to={`/area/${cp.area_id}/chargepoint/${cp.charge_point_id}`}
                          >
                            {ChargepointClass.toString(cp)}
                          </Link>
                          {cp.area_id !== area.id ? (
                            <>
                              {' - '}
                              {t(
                                'components.area.delete.criteria.belongs'
                              )}{' '}
                              <a href={`/area/${cp.area_id}`}>
                                {findSubAreaContainingChargepoint(cp.area_id!)}
                              </a>
                            </>
                          ) : null}
                        </li>
                      );
                    })}
                  </ul>
                </li>
              </>
            ) : null}
            {directlyAuthorizedUsers.length > 0 ? (
              <>
                <li>
                  {t('components.area.delete.criteria.users')} <br />
                  <Button
                    variant="secondary"
                    onClick={handleDeauthorization}
                    data-cy="deauthorize-users"
                  >
                    {t('components.area.delete.buttons.deauthorize')}
                  </Button>
                </li>
              </>
            ) : null}
          </ul>
        </Alert>
      ) : null}

      {successfullDeauthorization ? (
        <Col>
          <Alert
            key="successfullDeauthorization"
            variant="success"
            data-cy="delete-deauthorization"
          >
            {t('global.alert.success.deauthorizeAll')}
          </Alert>
        </Col>
      ) : null}
      {failedDeauthorization ? (
        <Col>
          <Alert key="failedDeauthorization" variant="success">
            {t('global.alert.failure.deauthorizeAll')}
          </Alert>
        </Col>
      ) : null}
      {/*The Modal component is used to display the popup*/}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static" //doesn't allow the user to close the modal by clicking outside of it
        keyboard={false} //doesn't allow the user to close the modal by using the keyboard
      >
        <Modal.Header>
          <Modal.Title>
            <div //icky wicky
              dangerouslySetInnerHTML={{
                __html: t('components.area.delete.modal.sure', {
                  areaName: area.name,
                  interpolation: { escapeValue: false },
                }),
              }}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div //icky wicky
            dangerouslySetInnerHTML={{
              __html: t('components.area.delete.modal.sureType', {
                areaName: area.name,
                interpolation: { escapeValue: false },
              }),
            }}
          />

          <InputGroup className="mt-3">
            <InputGroup.Text id={`${area.id}-remove-input`}>
              {t('components.area.delete.modal.typeHere')}
            </InputGroup.Text>
            <FormControl
              placeholder={area.name}
              aria-label={area.id as unknown as string}
              aria-describedby={area.id as unknown as string}
              onChange={(event) => handleDeleteInput(event.target.value)}
              data-cy="delete-area-input"
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          {activeButton ? (
            <Button
              variant="danger"
              onClick={deleteArea}
              active
              data-cy="final-delete-button"
            >
              {t('components.area.delete.buttons.delete')}
            </Button>
          ) : (
            <Button variant="danger" disabled data-cy="final-delete-button">
              {t('components.area.delete.buttons.delete')}
            </Button>
          )}

          <Button variant="secondary" onClick={closeDeleteModal}>
            {t('global.buttons.cancel')}
          </Button>
        </Modal.Footer>
      </Modal>

      {showError ? (
        <Col>
          <Alert key="delete-error" variant="danger">
            <div //icky wicky
              dangerouslySetInnerHTML={{
                __html: t('components.area.delete.htmlAlert', {
                  interpolation: { escapeValue: false },
                }),
              }}
            />
          </Alert>
        </Col>
      ) : null}
      <br />
      <Button
        className="mb-3"
        variant="danger"
        onClick={openDeleteModal}
        disabled={directlyAuthorizedUsers.length > 0 || chargepoints.length > 0}
        data-cy="delete-button"
      >
        {t('components.area.delete.buttons.delete')}
      </Button>
    </>
  );
};

export default EditDelete;
