import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ActiveTransaction } from '../../../model/Classes/Transaction';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import reboot from '../../../services/transactions/reboot';
import { logger } from '../../../utils/logger';
import rebootIcon from '../../../resources/rebootIcon.svg';
import { Alert, Spinner } from 'react-bootstrap';
import { timer } from '../../../utils/timer';
import Tooltip from '../../GeneralComponents/Tooltip';

declare interface ResetChargepointModalProps {
  activeTransactions: ActiveTransaction[];
  chargePointId?: string;
  status?: number;
  variant?: string;
  executionTimeExceeded: boolean;
  refreshStatusTable: () => Promise<void>;
  refresh: () => Promise<void>;
}

const ResetChargepointModal = ({
  activeTransactions,
  chargePointId,
  status,
  variant,
  executionTimeExceeded,
  refreshStatusTable,
  refresh,
}: ResetChargepointModalProps) => {
  const [show, setShow] = useState(false);
  const [toggle, setToggle] = useState(false);

  const handleClose = () => {
    setToggle(false);
    setShow(false);
  };

  const handleShow = async () => {
    await refresh();
    await refreshStatusTable();
    setToggle(true);
  };

  useEffect(() => {
    function get() {
      if (executionTimeExceeded) {
        timer(setErrorAlert);
        return;
      }
      if (status === 1) {
        setShow(true);
      } else {
        timer(setFailAlert);
        setToggle(false);
      }
    }
    if (toggle) get();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle]);

  const activeTransaction = activeTransactions.length > 0;
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [warningAlert, setWarningAlert] = useState(false);
  const [failAlert, setFailAlert] = useState(false);
  const [rebooting, setRebooting] = useState(false);

  //new function for the reboot pop up model.
  //Uses the function to do a hard reset when data is sucessfully passed to ocpp server.

  const handleRebootSubmit = async () => {
    //building a rebootData object for the reboot modal
    // refresh the connection status and active transaction status and check them before the submit.

    //the data structure for teh reboot function(payload)
    const rebootData = {
      header: 'reset',
      charge_point_id: chargePointId,
      type: 'hard',
    };

    //Call the startNewTransaction service function to start the transaction with the given data
    setRebooting(true);
    const rebootRes = await reboot(rebootData);

    setRebooting(false);
    handleClose();
    if (!rebootRes.success) {
      logger(rebootRes.error);
      timer(setErrorAlert);
    } else {
      if (rebootRes.result === 'Accepted') {
        timer(setSuccessAlert);
      } else {
        timer(setWarningAlert);
      }
    }
  };

  const { t } = useTranslation('common', { i18n: i18n });

  return (
    <>
      <div
        style={{
          overflow: 'auto',
        }}
      >
        <Tooltip
          text={t('components.chargepoint.static.modal.rebootBtn')}
          placement="bottom"
          variant={variant}
        >
          <Button
            variant="none"
            className="button"
            onClick={handleShow}
            onMouseDown={(e) => e.preventDefault()}
            style={{
              width: '27px',
              height: '27px',
              padding: '0',
              display: 'block',
              float: 'right',
              //border: ' 1px solid blue',
            }}
          >
            <img
              src={rebootIcon}
              alt="Reboot"
              style={{
                width: '24px',
                height: '24px',
                //border: ' 1px solid black',
                margin: '0',
                padding: '0',
              }}
            />
          </Button>
        </Tooltip>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>
            {t('components.chargepoint.static.modal.title')}
          </Modal.Title>
          <Button
            type="button"
            className="close"
            aria-label="Close"
            variant="outline-ligth"
            onClick={handleClose}
            disabled={rebooting}
          >
            <span aria-hidden="true">&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          {activeTransaction
            ? t(
                'components.chargepoint.static.modal.activeTransactions.isActiveDesc',
                { cpId: chargePointId }
              )
            : t(
                'components.chargepoint.static.modal.activeTransactions.inactiveDesc'
              )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            disabled
            onClick={handleRebootSubmit}
            hidden={!rebooting}
          >
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            {t(
              'components.chargepoint.static.modal.activeTransactions.rebooting'
            )}
          </Button>
          <Button
            variant="primary"
            onClick={handleRebootSubmit}
            hidden={rebooting}
          >
            {t('global.generalWords.yes')}
          </Button>
          <Button
            variant="secondary"
            onClick={handleClose}
            disabled={rebooting}
          >
            {t('global.buttons.cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
      {successAlert ? (
        <Alert
          variant="success"
          key="success"
          style={{
            margin: '10px auto 10px auto',
            width: '80% !important',
            textAlign: 'center',
            left: '+50%',
          }}
        >
          {t(
            'components.chargepoint.static.modal.activeTransactions.chargepointAccepted'
          )}
        </Alert>
      ) : null}
      {warningAlert ? (
        <Alert
          variant="warning"
          key="warning"
          style={{
            margin: '10px auto 10px auto',
            width: '80% !important',
            textAlign: 'center',
            left: '+50%',
          }}
        >
          {t(
            'components.chargepoint.static.modal.activeTransactions.chargepointRejected'
          )}
        </Alert>
      ) : null}
      {errorAlert ? (
        <Alert
          variant="danger"
          key="error"
          style={{
            margin: '10px auto 10px auto',
            width: '80% !important',
            textAlign: 'center',
            left: '+50%',
          }}
        >
          {t(
            'components.chargepoint.static.modal.activeTransactions.rebootWarning'
          )}
        </Alert>
      ) : null}
      {failAlert ? (
        <Alert
          variant="danger"
          key="error"
          style={{
            margin: '10px auto 10px auto',
            width: '80% !important',
            textAlign: 'center',
            left: '+50%',
          }}
        >
          {t(
            'components.chargepoint.static.modal.activeTransactions.rebootFail'
          )}
        </Alert>
      ) : null}
    </>
  );
};

export default ResetChargepointModal;
