import axios from 'axios';
axios.defaults.withCredentials = true;

const tokenURL = '/api/auth/token';

/**
 * Service function responsible for deauthorizing all directly authorized users in an area.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @param {*} areaId id of the area to deauthorize all users from
 * @returns if successful: [true, response.data]. Otherwise: [false, error]
 */
export const deauthorizeUsers = async (areaId: number) => {
  try {
    const response = await axios.post(
      `/api/area/${areaId}/revokeAllChargingAccess`
    );
    return [true, response.data];
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.post(
        `/api/area/${areaId}/revokeAllChargingAccess`
      );
      return [true, response.data];
    } catch (e) {
      return [false, e];
    }
  }
};
