import styled from 'styled-components';

/**
 * Styled component for the forgot password page
 */
export const StyledForgotPassword = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  background: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  font-size: 1rem;
  overflow-x: auto;
  > * {
    max-width: 40vw;
    @media only screen and (max-width: 1024px) {
      max-width: 80vw;
    }
  }

  .row {
    justify-content: center;
  }

  .logo {
    width: 35vw;
    @media only screen and (max-width: 1024px) {
      width: 80vw;
    }
  }

  h2 {
    text-align: center;
    flex-basis: 100%;
    margin-bottom: 1rem;
  }
`;
