/**
 * Utility function for creating a Javascript Date object. The function takes as parameters the year, month
 * day, hour, minutes, and seconds, and creates a Date object with these values. The millisecond parameter
 * for the setHours() function is defaulted to 0.
 * @param {*} year year for the Date object
 * @param {*} month month for the Date object
 * @param {*} day day for the Date object
 * @param {*} hour hour for the Date object
 * @param {*} minutes minutes for the Date object
 * @param {*} seconds seconds for the Date object
 * @returns Javascript Date object
 */
export const makeDate = (
  year: number,
  month: number,
  day: number,
  hour: number,
  minutes: number,
  seconds: number
) => {
  const d = new Date(); //Initialise a Javascript Date object
  d.setFullYear(year, month, day); //Set the full year with the parameters year, month, and day
  d.setHours(hour, minutes, seconds, 0); //Set the full hours with the parameters hour, minutes, and seconds. Milliseconds is defaulted to 0
  return d; //Return the Javascript Date object
};

/**
 * Utility function for decoding html date field's data into a Javascript Date object. The function takes as
 * parameters the date (given in the form YYYY-MM-DD) and time (given in the form HH:MM) as strings.
 *
 * The function initially creates a new Date object. Then, it for both the date and time parameters decodes the
 * data by splitting the respective strings into an array and create an INTEGER of each data piece. From those
 * arrays, it sets the year, month, day, hour, and minutes into the Date object and returns the object.
 * @param {*} date the dates data (year, month, day)
 * @param {*} time the time data (hour, minutes, seconds)
 * @returns Javascript Date object with given dates and time data
 */
export const decodeAndMakeDate = (date: string, time: string) => {
  const d = new Date(); //Initialize a new Javascript Date object

  //Split the dates by the "-" delimiter (always given in this form) into an array, and create numbers from each string using the map function

  const arr1 = date.split('-').map((n) => Number(n));
  //Split the time by the ":" delimiter (always given in this form) into an array, and create numbers from each string using the map function
  const arr2 = time.split(':').map((n) => Number(n));

  //Set the year, month, day, hour, and minutes into the Date object.
  //NOTE! The month that is taken from the html date input are different from the Date object's month.
  //The html months are defined as 1-12 (January = 1) and Date object months are defined as 0-11 (January = 0)
  //-> we need to take the html month (arr[1]) minus one to make it represent the same month in the Date object
  d.setFullYear(arr1[0], arr1[1] - 1, arr1[2]);
  d.setHours(arr2[0], arr2[1], arr2[2] ? arr2[2] : 0, 0);

  //Return the date object
  return d;
};

//decode and make function for the timezone part, created a separate for this part since shanging the other function could have had unwanted ripple effect on teh app.

export const decodeAndMakeDateTimezone = (date: string, time: string) => {
  const d = new Date(); //Initialize a new Javascript Date object

  //Split the dates by the "/" delimiter (always given in this form) into an array, and create numbers from each string using the map function
  const arr1 = date.split('/').map((n) => Number(n));
  //Split the time by the ":" delimiter (always given in this form) into an array, and create numbers from each string using the map function
  const arr2 = time.split(':').map((n) => Number(n));
  //Set the year, month, day, hour, and minutes into the Date object.
  //NOTE! The month that is taken from the html date input are different from the Date object's month.
  //The html months are defined as 1-12 (January = 1) and Date object months are defined as 0-11 (January = 0)
  //-> we need to take the html month (arr[1]) minus one to make it represent the same month in the Date object
  d.setFullYear(arr1[2], arr1[0] - 1, arr1[1]);
  d.setHours(arr2[0], arr2[1], arr2[2] ? arr2[2] : 0, 0);

  //Return the date object
  return d;
};
/**
 * Utility function for padding the input with a 0 in the beginning. This is needed when creating a Javascript Date object
 * and inserting the dates (year, month, day, hour, minutes, seconds) into a html date field. Since the html date fields need
 * to be formatted as a string with TWO integers (e.g. January would be given as "01"), and Date objects are pure integers
 * (January would be 0 but one is always added before putting it to the html date field), the Date object's data need to be
 * padded with a 0 in the beginning.
 *
 *
 * Case: we create a new Javascript Date object to represent the 20th of January 2021. If we want to insert the dates into
 * a html date input field, we need the year, month, and day to be in the form YYYY, MM, DD as strings respectively. If we
 * get the data from the Date object, they will be integers (2021, 0 (one is added), 20). If we'd insert them instantly to
 * the html date field, an error would occur since the month is in the form M (Date object month) and it's needed to be in
 * the form MM. Thus, we need to pad the month with a 0 in the beginning so it becomes "01".
 * @param {*} thingToPad what needs to be padded
 * @returns the padded input
 */
export const pad = (thingToPad: number) => {
  //If the parameter thingToPad is an integer < 10, pad it with a 0 in the beginning
  if (thingToPad < 10) {
    return `0${thingToPad}`;
  }
  return thingToPad;
};

/**
 * Utility function for parsing ISO-Strings into a Javascript Date object. The code for this was copy pasted from
 * stack overflow, so don't ask me how it works :D.
 *
 * The dates are stored as ISO-Strings in the database (and returned as so from the backend). Since it's much easier
 * to work with a Javascript Date object than ISO-Strings, we need to parse them into a Date object.
 * @param {*} string ISOString to parse
 * @returns Javascript Date object created from parsing the ISO-String
 */
export const parseISOString = (string: any) => {
  const s = string.split(/\D+/);
  return new Date(Date.UTC(s[0], --s[1], s[2], s[3], s[4], s[5], s[6]));
};
