import axios from 'axios';
axios.defaults.withCredentials = true;

const baseURL = '/api/user/settings/basic';
const tokenURL = '/api/auth/token';

/**
 * Service function responsible for sending data containing changes to the user account to the backend.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @param {*} data data object containing the new first/last name and new email.
 * @returns response from backend
 */
export const sendUserSettingsBasic = async (data) => {
  try {
    const response = await axios.post(baseURL, data, { withCredentials: true });
    return response.data;
  } catch (e) {
    await axios.post(tokenURL, { withCredentials: true });
    const response = await axios.post(baseURL, data, { withCredentials: true });
    return response.data;
  }
};
