import axios from 'axios';
import { SpotPrices } from '../../model/Classes/Transaction';
import StructuredResponse from '../../model/Classes/StructuredResponse';
axios.defaults.withCredentials = true;

const tokenURL = '/api/auth/token';
const baseUrl = '/api/user/getSpotprice';

/**
 * Service function responsible for fetching all chargepoints spot pricesthe .
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @returns if successful: [true, response.data]. Otherwise: [false, error]
 */
export const getCpSpotprice = async (charge_point_id) => {
  try {
    const response = await axios.get(`${baseUrl}/${charge_point_id}`, {
      withCredentials: true,
    });

    return new StructuredResponse(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(`${baseUrl}/${charge_point_id}`, {
        withCredentials: true,
      });
      return new StructuredResponse(true, response.data);
    } catch (e: any) {
      return new StructuredResponse<SpotPrices[]>(false, e);
    }
  }
};
