import { Link } from "react-router-dom";
import { Table, Row, Col, Button } from "react-bootstrap";
import { parseISOString } from "../../../utils/dates";
import refreshIcon from "../../../resources/refreshIcon.svg";
import refreshIconAnimated from "../../../resources/refreshIconAnimated.gif";
import checkmarkIcon from "../../../resources/checkmarkIcon.svg";
import CpFull, { ChargepointClass } from "../../../model/Classes/Chargepoint";
import AreaSingle from "../../../model/Classes/Area";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { logger } from "../../../utils/logger";
import paginationFactory from "react-bootstrap-table2-paginator";
import DiagnosticsMessage from "../../../model/Classes/DiagnosticsMessage";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { acknowledgeChargepointMessages } from "../../../services/chargepoint/acknowledgeChargepointMessages";
import { format } from "date-fns";
import { useState } from "react";
import styled from "styled-components";

declare interface ChargepointStatusProps {
  chargepoint: CpFull;
  showCheckmarkStatusTable: boolean;
  showLoadingStatusTable: boolean;
  refreshStatusTable: () => Promise<void>;
}

export const ChargepointStatus = ({
  chargepoint,
  showCheckmarkStatusTable,
  showLoadingStatusTable,
  refreshStatusTable,
}: ChargepointStatusProps) => {
  const { t } = useTranslation("common", { i18n: i18n });
  return (
    <>
      <Row>
        <Col xs="auto" sm="auto">
          <h4>{t("components.chargepoint.static.tabs.general.status.header")}</h4>
        </Col>
        <Col xs="auto" sm="auto">
          {showCheckmarkStatusTable ? ( //If the checkmark state is true, show a checkmark wrapped in a button element
            <Button variant="outline-ligth" style={{ padding: 0 }}>
              <img src={checkmarkIcon} alt="Checkmark" />
            </Button>
          ) : showLoadingStatusTable ? ( //If the loading state is true, show the loading animation wrapped in a button element. Otherwise, show a refresh icon wrapped in a button element that calls the refresh() function when clicked
            <Button variant="outline-ligth" style={{ padding: 0 }}>
              <img src={refreshIconAnimated} alt="Loading" style={{ width: 30, padding: 0 }} />
            </Button>
          ) : (
            <Button variant="outline-ligth" style={{ padding: 0 }}>
              <img src={refreshIcon} alt="Refresh" onClick={refreshStatusTable} />
            </Button>
          )}
        </Col>
      </Row>
      <Table className="mb-10" responsive="sm" striped bordered hover>
        <tbody>
          <tr>
            <th>{t("components.chargepoint.static.tabs.general.status.table.connected")}</th>
            <td>
              {chargepoint.connected ? (
                <span className="Yes">{t("global.generalWords.yes")}</span>
              ) : (
                <span className="No">{t("global.generalWords.no")}</span>
              )}
            </td>
          </tr>
          <tr
            className={
              chargepoint.health === 0
                ? "cp-health-0"
                : chargepoint.health === 1
                ? "cp-health-1"
                : chargepoint.health === 2
                ? "cp-health-2"
                : "cp-health-3"
            }
          >
            <th>{t("components.chargepoint.static.tabs.general.status.table.health")}</th>
            <td>
              {chargepoint.health === 0 ? (
                <span>{t("components.chargepoint.static.tabs.general.status.table.unknown")}</span>
              ) : chargepoint.health === 1 ? (
                <span>{t("global.generalWords.ok")}</span>
              ) : chargepoint.health === 2 ? (
                <span>{t("global.generalWords.warning")}</span>
              ) : (
                <span>{t("global.generalWords.error")}</span>
              )}
            </td>
          </tr>
          <tr>
            <th>{t("components.chargepoint.static.tabs.general.status.table.heartbeat")}</th>
            <td>
              {chargepoint.last_heartbeat
                ? parseISOString(chargepoint.last_heartbeat).toLocaleString("en-FI")
                : t("global.generalWords.none")}
            </td>
          </tr>
          <tr>
            <th>{t("components.chargepoint.static.tabs.general.status.table.disabled")}</th>
            <td>{chargepoint.disabled ? t("global.generalWords.yes") : t("global.generalWords.no")}</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

declare interface ChargepointGeneralProps {
  chargepoint: CpFull;
  area: AreaSingle;
}

/**
 * Component responsible for displaying the general info table for a chargepoint. The table has the following information:
 * - Id of the chargepoint
 * - Address (if given)
 * - Coordinates (if latitude and longitude is given)
 * - Area the chargepoint belongs to
 * - Price (€/kWh)
 * - Last heartbeat (if exists)
 * - Disabled or active
 * - Vendor (if given)
 * - Model (if given)
 * - Firmware (if given)
 * - Phase
 */
export const ChargepointGeneral = ({ chargepoint, area }: ChargepointGeneralProps) => {
  const phases = [
    { name: "0° RST (A=L1, B=L2, C=L3)", value: "0" },
    { name: "120° STR (A=L2, B=L3, C=L1) ", value: "1" },
    { name: "240° TRS (A=L3, B=L1, C=L2)", value: "2" },
  ];

  const { t } = useTranslation("common", { i18n: i18n });
  return (
    <>
      <h4>{t("components.chargepoint.static.tabs.general.info.header")}</h4>
      <Table className="mb-10" responsive="sm" striped bordered hover>
        <tbody>
          <tr>
            <th>{t("components.chargepoint.static.tabs.general.info.table.cpNumber")}</th>
            <td>{ChargepointClass.formatNumber(chargepoint)}</td>
          </tr>
          <tr>
            <th>{t("components.chargepoint.static.tabs.general.info.table.id")}</th>
            <td>{chargepoint.charge_point_id}</td>
          </tr>
          <tr>
            <th>{t("components.chargepoint.static.tabs.general.info.table.cpNum")}</th>
            <td>
              {chargepoint.number_of_connectors
                ? chargepoint.number_of_connectors
                : t("components.chargepoint.static.tabs.general.info.nullConnector")}
            </td>
          </tr>
          <tr>
            <th>{t("components.chargepoint.static.tabs.general.info.table.cpConnLimit")}</th>
            <td>{chargepoint.connector_limit}</td>
          </tr>
          <tr>
            <th>{t("components.chargepoint.static.tabs.general.info.table.address")}</th>
            <td>
              {!chargepoint.address ? (
                t("components.chargepoint.static.tabs.general.info.noAddress")
              ) : (
                <a href={`https://google.com/maps/place/${chargepoint.address}`} target="_blank" rel="noreferrer">
                  {chargepoint.address}
                </a>
              )}
            </td>
          </tr>
          {/** CONDITIONAL RENDERING OF TEH COORDINATES IN GENERAL INFO TABLE */}
          {chargepoint.latitude !== null &&
            chargepoint.longitude !== null &&
            chargepoint.latitude !== 0 &&
            chargepoint.longitude !== 0 && (
              <tr>
                <th>{t("components.chargepoint.static.tabs.general.info.table.coord")}</th>
                <td>
                  {!chargepoint.latitude || !chargepoint.longitude ? (
                    t("components.chargepoint.static.tabs.general.info.invalidCoord")
                  ) : (
                    <a
                      href={`https://google.com/maps/place/${chargepoint.latitude},${chargepoint.longitude}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {chargepoint.latitude}, {chargepoint.longitude}
                    </a>
                  )}
                </td>
              </tr>
            )}
          <tr>
            <th>{t("components.chargepoint.static.tabs.general.info.table.area")}</th>
            <td>
              <Link to={`/area/${area.id}`}>{area.name}</Link>
            </td>
          </tr>
          <tr>
            <th>{t("components.chargepoint.static.tabs.general.info.table.vendor")}</th>
            <td>{chargepoint.charge_point_vendor}</td>
          </tr>
          <tr>
            <th>{t("components.chargepoint.static.tabs.general.info.table.model")}</th>
            <td>{chargepoint.charge_point_model}</td>
          </tr>
          <tr>
            <th>{t("components.chargepoint.static.tabs.general.info.table.firmware")}</th>
            <td>{chargepoint.firmware_version}</td>
          </tr>
          <tr>
            <th>{t("components.chargepoint.static.tabs.general.info.table.phase")}</th>
            <td>{phases[chargepoint.phase!].name}</td>
          </tr>
          {area.access_type && area.access_type !== 3 && area.pricing_type !== 1 ? (
            <tr>
              <th>{t("components.chargepoint.static.tabs.general.info.table.price") + ` (${area.currency}/kWh)`}</th>
              <td>{chargepoint.price?.toFixed(2)}</td>
            </tr>
          ) : null}
          {area.access_type && area.access_type >= 2 && area.pricing_type !== 1 ? (
            <>
              <tr>
                <th>{t("components.area.addCp.details.publicPrice") + ` (${area.currency}/kWh)`}</th>
                <td>{chargepoint.public_price ? chargepoint.public_price.toFixed(2) : "0.00"}</td>
              </tr>

              <tr>
                <th>{t("components.area.addCp.details.publicStart") + ` (${area.currency}/kWh)`}</th>
                <td>{chargepoint.public_start_price ? chargepoint.public_start_price.toFixed(2) : "0.00"}</td>
              </tr>
            </>
          ) : null}
          {area.access_type && area.access_type !== 3 && area.pricing_type === 1 ? (
            <tr>
              <th>{t("components.area.addCp.details.spotPrice") + ` (${area.currency}/kWh)`}</th>
              <td>
                {area.margin} + {t("components.area.addCp.details.spotPriceVal")}
              </td>
            </tr>
          ) : null}
        </tbody>
      </Table>
    </>
  );
};

//diagnostic tab for the chargepoint
export const ChargepointDiagnostics = ({
  charge_point_id,
  diagnosticMessage,
  refreshDiagnosticMessageFunc,
  showLoading,
  showCheckmark,
}) => {
  const { t } = useTranslation("common", {
    i18n: i18n,
  });
  const columns: ColumnDescription<DiagnosticsMessage>[] = [
    {
      dataField: "id",
      text: `${t("components.chargepoint.static.tabs.diagnostics.table.id")}`,
      hidden: true,
      sort: true,
      sortFunc: (a, b, order) => {
        if (order === "asc") {
          return b - a; // Sort in descending order when 'asc' is selected
        }
        return a - b; // Sort in ascending order when 'desc' is selected or by default
      },
    },
    {
      dataField: "timestamp",
      text: `${t("components.chargepoint.static.tabs.diagnostics.table.timestamp")}`,
      hidden: false,
      sort: false,
      formatter: (cell, row) => {
        const timestamp = new Date(cell); // Assuming cell contains the UTC timestamp
        const localTimestamp = format(timestamp, "yyyy-MM-dd HH:mm:ss"); // Convert to local time in 24 HR format
        // const localTimestamp = timestamp.toLocaleString('en-FI', {          timeZone: 'UTC',        });
        return localTimestamp;
      },
    },
    {
      dataField: "message",
      text: `${t("components.chargepoint.static.tabs.diagnostics.table.message")}`,
      hidden: false,
    },
    {
      dataField: "data",
      text: `${t("components.chargepoint.static.tabs.diagnostics.table.data")}`,
      hidden: false,
    },

    {
      dataField: "severity",
      text: `${t("components.chargepoint.static.tabs.diagnostics.table.severity")}`,
      hidden: true,
      sort: true,
    },
    {
      dataField: "acknowledged",
      text: `${t("components.chargepoint.static.tabs.diagnostics.table.acknowledge")}`,
      hidden: true,
      sort: false,
    },
  ];

  //state for managing the refresh animation
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  const handleAcknowledge = async () => {
    const data = { charge_point_id };
    const acknowledge = await acknowledgeChargepointMessages(charge_point_id, data);

    if (!acknowledge.success) {
      logger(acknowledge.data);
    } else {
      logger(acknowledge.data);
      refreshTrigger();
    }
  };

  //state for severity checkbox
  const [showInfo, setShowInfo] = useState(true);
  const [showWarning, setShowWarning] = useState(true);
  const [showError, setShowError] = useState(true);

  //handle change function for teh severity checkbox
  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    if (id === "info") {
      setShowInfo(checked);
    } else if (id === "warning") {
      setShowWarning(checked);
    } else if (id === "error") {
      setShowError(checked);
    }
  };

  //function to filter the messages acc to severity
  const filterMessages = (messages) => {
    return messages.filter((message) => {
      const { severity } = message;

      return (
        (showInfo && severity < 200) ||
        (showWarning && severity >= 200 && severity < 300) ||
        (showError && severity >= 300)
      );

      /* if (showInfo && severity < 200) {
        return true;
      }
      if (showWarning && severity >= 200 && severity < 300) {
        return true;
      }
      if (showError && severity >= 300) {
        return true;
      }
      return false;
      */
    });
  };
  //this is the trigger for the refresh button in the diagnostic page. it contais the refresh animation a crud work around that needs to be fixed later on again
  const refreshTrigger = async () => {
    setIsLoading(true);
    await refreshDiagnosticMessageFunc();
    setTimeout(() => {
      // Data fetching completed
      setIsCompleted(true);

      // Reset the loading state and completed state after a delay
      setTimeout(() => {
        setIsLoading(false);
        setIsCompleted(false);
      }, 1000);
    }, 1000);
  };
  // function to determine the row style based on severity and acknowledgement
  const getRowClass = (row): string => {
    const severity = row.severity;
    const acknowledged = row.acknowledged;

    if (acknowledged === 1) {
      return "";
    }

    if (severity >= 200 && severity < 300) {
      return "messageSeverity200-300";
    } else if (severity >= 300 && severity < 400) {
      return "messageSeverity300-400";
    } else {
      return "";
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <h2 style={{ margin: "0 0 0 15px" }}>{t("components.chargepoint.static.tabs.diagnostics.diagMessage")}</h2>
          <Button variant="none" onClick={refreshTrigger}>
            {isLoading && !isCompleted ? (
              <img style={{ height: "30px", width: "30px" }} src={refreshIconAnimated} alt="refreshMessages" />
            ) : isCompleted ? (
              <img src={checkmarkIcon} alt="refreshMessage" />
            ) : (
              <img src={refreshIcon} alt="refreshMessages" />
            )}
          </Button>
        </div>
        <Button variant="primary" onClick={handleAcknowledge} style={{ marginBottom: "15px" }}>
          {t("components.chargepoint.static.tabs.diagnostics.button")}
        </Button>
      </div>

      {/*CHECKBOX TO FILER MESSAGES ACC TO SEVIRITY */}

      <div id="filterContainer" className="textBox-align" style={{ textAlign: "left" }}>
        <div
          className="btn-group-toggle"
          data-toggle="buttons"

          //style={{ margin: 0, padding: 0, border: '1px dashed red' }}
        >
          <label
            className="btn "
            style={{
              margin: "5px",
              padding: "0 5px 0 5px",

              //border: '1px dashed red',
            }}
          >
            <input
              type="checkbox"
              id="info"
              value="info"
              checked={showInfo}
              onChange={handleCheckboxChange}
              style={{ marginRight: "10px" }}
            />
            Info
          </label>
          <label
            className="btn "
            style={{
              margin: "5px",
              padding: "0 5px 0 5px",
            }}
          >
            <input
              type="checkbox"
              id="warning"
              value="warning"
              checked={showWarning}
              onChange={handleCheckboxChange}
              style={{ marginRight: "10px" }}
            />
            Warning
          </label>
          <label className="btn" style={{ margin: "5px", padding: "0 5px 0 5px" }}>
            <input
              type="checkbox"
              id="error"
              value="error"
              checked={showError}
              onChange={handleCheckboxChange}
              style={{ marginRight: "10px" }}
            />
            Error
          </label>
        </div>
      </div>

      <ToolkitProvider bootstrap4 keyField="id" data={filterMessages(diagnosticMessage)} columns={columns}>
        {(props) => {
          return (
            <StyledTableContainer>
              <BootstrapTable
                bordered
                striped
                hover
                noDataIndication={t("components.chargepoint.static.tabs.diagnostics.table.noData")}
                defaultSorted={[{ dataField: "id", order: "asc" }]}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                })}
                rowClasses={getRowClass}
                {...props.baseProps}
              />
            </StyledTableContainer>
          );
        }}
      </ToolkitProvider>
    </>
  );
};

const StyledTableContainer = styled.div`
  table {
    tr.messageSeverity200-300 td {
      background-color: #ffe821;
    }

    tr.messageSeverity300-400 td {
      background-color: #f44155;
    }
  }
`;
