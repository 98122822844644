import React, { useEffect } from 'react';

const AddCardFormFail = ({ history }) => {
  useEffect(() => {
    history.push({
      pathname: '/settings',
      state: { addCardCanceled: true },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex top-level-component">
      <h2 className="align-self-center text-align-center width-100">
        Redirecting...
      </h2>
    </div>
  );
};

export default AddCardFormFail;
