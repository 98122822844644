import axios from 'axios';
import StructuredResponse from '../../model/Classes/StructuredResponse';
axios.defaults.withCredentials = true;

const tokenURL = '/api/auth/token';

/**
 * Service function responsible for enabling a tag.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @param {*} data data object containing tag id
 * @returns if successful: [true, response.data]. Otherwise: [false, error]
 */
export const enableTag = async (data: { id_tag: string }) => {
  try {
    const response = await axios.post('/api/user/tags/enable', data, {
      withCredentials: true,
    });
    return new StructuredResponse<string>(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.post('/api/user/tags/enable', data, {
        withCredentials: true,
      });
      return new StructuredResponse<string>(true, response.data);
    } catch (e: any) {
      return new StructuredResponse<string>(false, e);
    }
  }
};
