import axios from "axios";
import { TransactionCp } from "../../model/Classes/Chargepoint";
import StructuredResponse from "../../model/Classes/StructuredResponse";
axios.defaults.withCredentials = true;

const tokenURL = "/api/auth/token";

export const getAllChargepointsForTagGeneration = async () => {
  let success = true;
  try {
    const response = await axios.get("/api/user/settings/chargepoints", {
      withCredentials: true,
    });
    return new StructuredResponse<TransactionCp[]>(success, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get("/api/user/settings/chargepoints", {
        withCredentials: true,
      });
      return new StructuredResponse<TransactionCp[]>(success, response.data);
    } catch (e: any) {
      success = false;
      return new StructuredResponse<TransactionCp[]>(success, e);
    }
  }
};
