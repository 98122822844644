import { useState } from "react";
import { Alert, InputGroup, Form, Button, ButtonGroup, Modal, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import StructuredResponse from "../../../model/Classes/StructuredResponse";
import User from "../../../model/Classes/User";
import { Nullable, StateHandler } from "../../../model/Utilities/Types";
import { logout } from "../../../services/authentication/logout";
import { logger } from "../../../utils/logger";
import { timer } from "../../../utils/timer";
import { toggleAlertsOff } from "../../../utils/toggleAlertsOff";

/**
 * Special component shown when the user has successfully changed a basic setting. Shown for 3 seconds
 * before the user is logged out and redirected to the login page.
 * @returns successful changes page
 */
export const SuccessfullBasicChange = () => {
  const { t } = useTranslation("common", { i18n: i18n });
  return (
    <Row>
      <Alert key="successChange" variant="success">
        {t("global.alert.success.nameChange")}
      </Alert>
    </Row>
  );
};

type UserSettingsBasicProps = {
  user: User;
  setUser: StateHandler<Nullable<User>>;
  newFirstName: string;
  newLastName: string;
  setNewFirstName: StateHandler<string>;
  setNewLastName: StateHandler<string>;
  history: any;
  handleSubmitUserSettingsBasic: any;
  setSuccessfullChange: StateHandler<boolean>;
};

/**
 * Component for the basic settings view where the user can change their first/last name
 * @param {*} user state for the user of the application
 * @param {*} setUser state handler for the user state
 * @param {*} newFirstName state for the first name field
 * @param {*} newLastName state for the last name field
 * @param {*} setNewFirstName state handler for the firstName state
 * @param {*} setNewLastName state handler for the lastName state
 * @param {*} history history object
 * @param {*} handleSubmitUserSettingsBasic helper function for handling the submission
 * @param {*} setSuccessfullChange state for triggering the SuccessfullBasicChange to be rendered
 * @returns basic settings view
 */
const UserSettingsBasic = ({
  user,
  setUser,
  newFirstName,
  newLastName,
  setNewFirstName,
  setNewLastName,
  history,
  handleSubmitUserSettingsBasic,
  setSuccessfullChange,
}: UserSettingsBasicProps) => {
  const [showChange, setShowChange] = useState(false); //state for showing the changes that will be made
  const [showError, setShowError] = useState(false); //state for showing an error alert
  const [changes, setChanges] = useState<string[]>([]); //state containing the changes that will be made

  const [showFirstNameAlert, setShowFirstNameAlert] = useState(false); //state for showing an alert if the first name field is invalid
  const [showLastNameAlert, setShowLastNameAlert] = useState(false); //state for showing an alert if the last name field is invalid
  const [showNoChangesAlert, setShowNoChangesAlert] = useState(false); //state for showing an alert notifying the user to make a change before submitting
  const { t } = useTranslation("common", { i18n: i18n });
  /**
   * Helper function to validate the input fields. The function checks for multiple criteria, and if a criteria fails
   * it sets the success variable to false and toggles the respective alert on.
   * This is done instead of instantly returning false, because then all the criteria are checked instead of the
   * function stopping once the first violation occured.
   * @returns true if the validation passes, false otherwise
   */
  const validate = () => {
    //Return value
    let success = true;

    if (newFirstName === "" || newFirstName.length > 63) {
      success = false;
      setShowFirstNameAlert(true);
    } //Check if the first name is empty or too long
    if (newLastName === "" || newLastName.length > 63) {
      success = false;
      setShowLastNameAlert(true);
    } //Check if the last name is empty or too long

    return success;
  };

  /**
   * Helper function for checking all the changes made by the user. The changes are checked by comparing
   * the data that the user will send against the existing data for the user. If there are changes, the
   * corresponding change string will be pushed to an array that will be returned at the end of the function.
   * @param {*} user data to be checked
   * @returns an array with with the first element being true if there were changes made, and the second
   * element being the array containing the changes
   */
  const checkChanges = (userParam: User) => {
    let changesArray: string[] = [];

    //If a change has been detected, push the corresponding change string to the changesArray array
    if (user.first_name !== userParam.first_name)
      changesArray.push(
        t("components.userSettings.tabs.settings.changes.first", {
          newFirst: userParam.first_name,
          oldFirst: user.first_name,
        })
      );
    if (user.last_name !== userParam.last_name)
      changesArray.push(
        t("components.userSettings.tabs.settings.changes.last", {
          newLast: userParam.last_name,
          oldLast: user.last_name,
        })
      );

    //Return an array [true if changes were made/false otherwise, changes as an array]
    return new StructuredResponse(changesArray.length > 0, changesArray);
  };

  /**
   * Helper function for displaying the changes popup (modal). The function first toggles all alert off,
   * and then validates the input fields by calling the validate() function. If the validation didn't pass,
   * exit the function. If it passed, check for the changes using the checkChanges() function and update the
   * changes state with the correct information (if changes were made). Lastly, set the showChange state to
   * true (triggers the popup to appear) if changes were made, otherwise set the showNoChangesAlert to true.
   */
  const showChangeModal = () => {
    //Call the utility function toggleAlertsOff to toggle all the alerts off
    toggleAlertsOff([setShowFirstNameAlert, setShowLastNameAlert, setShowNoChangesAlert]);

    //If the validation fails, exit the function
    if (!validate()) return;

    //Data object with the field values
    const data = {
      user_level: user.user_level,
      first_name: newFirstName,
      last_name: newLastName,
    };

    //Check the changes
    const changesReturn = checkChanges(data);

    //If there were no changes, display an alert notifying the user about this
    if (!changesReturn.success) {
      setShowChange(false);
      setShowNoChangesAlert(true);
    } else {
      //Changes were made, update the changes state, toggle the alerts off and trigger the popup to appear.
      setChanges(changesReturn.data);
      //Call the utility function toggleAlertsOff to toggle all the alerts off
      toggleAlertsOff([setShowFirstNameAlert, setShowLastNameAlert, setShowNoChangesAlert]);
      setShowChange(true);
    }
  };

  /**
   * Asynchronous helper function that is called when the user presses the submit button in the popup.
   * The function closes the popup, and calls the handleSubmitUserSettingsBasic() function. If the
   * submission was successful, call the showSuccessAlert() function. Otherwise, display an alert
   * telling the user that the submission failed.
   */
  const makeChanges = async () => {
    setShowChange(false);

    //Call the helper function handleSubmitUserSettingsBasic()
    (await handleSubmitUserSettingsBasic())
      ? showSuccessAlert() //Submission was successful, call the showSuccessAlert() function
      : timer(setShowError, 5000); //Submission failed, show an error alert
  };

  /**
   * Helper function for handling the functionality after the user successfully changes the basic settings.
   * Initially, it sets the successfullChange state to true, which will trigger the UserSettings component
   * to display the SuccessfullBasicChange component. After 3 seconds, try to log out the user and push the
   * user to the login page. If some error was caught, log the error. Finally, set the successfullChange
   * state to false.
   */
  const showSuccessAlert = () => {
    //Trigger the UserSettings component to display the SuccessfullBasicChange component.
    setSuccessfullChange(true);

    //Set a timeout for 3 seconds, after which the program executes the callback function.
    setTimeout(async () => {
      try {
        await logout(); //Call the logout() service function

        //Update the user state
        setUser(null);

        //Push the user to the login page
        history.push("/login");
      } catch (e) {
        logger(e);
      }

      //Finally, set the successfullChange state to false
      setSuccessfullChange(false);
    }, 3000);
  };

  /**
   * Return a form containing the fields for the various inputs. Additionally, render a popup whenever the save/cancel
   * button is pressed, and alerts whenever they need to be shown
   */
  return (
    <>
      {/*Row for alerts and the form fields*/}
      <Row>
        {showError && ( //Show error alert if needed
          <Alert key="error" variant="danger">
            {t("global.view.errorTry")}
          </Alert>
        )}
        {/*Simple text input field for the first name field*/}
        <InputGroup className="mb-3">
          <InputGroup.Text id="name">{t("components.userSettings.tabs.settings.first")}</InputGroup.Text>
          <Form.Control
            type="text"
            value={newFirstName}
            aria-label={user.first_name}
            aria-describedby="first name"
            onChange={(event) => setNewFirstName(event.target.value)}
          />
        </InputGroup>
        {showFirstNameAlert && ( //Show alert notifying the user if the first name is too long or empty
          <Alert key="first-name" variant="danger">
            {t("global.alert.failure.firstName")}
          </Alert>
        )}

        {/*Simple text input field for the last name field*/}
        <InputGroup className="mb-3">
          <InputGroup.Text id="name">{t("components.userSettings.tabs.settings.last")}</InputGroup.Text>
          <Form.Control
            type="text"
            value={newLastName}
            aria-label={user.last_name}
            aria-describedby="last name"
            onChange={(event) => setNewLastName(event.target.value)}
          />
        </InputGroup>
        {showLastNameAlert && ( //Show alert notifying the user if the last name is too long or empty
          <Alert key="last-name" variant="danger">
            {t("global.alert.failure.lastName")}
          </Alert>
        )}
      </Row>
      {/*Row for some text notifying the user that they will be logged out after successfully changing the
               settings + save button*/}
      <Row>
        <div //icky wicky
          dangerouslySetInnerHTML={{
            __html: t("components.userSettings.tabs.settings.notes.logout", {
              interpolation: { escapeValue: false },
            }),
          }}
        />
        {/*ButtonGroup for the save button*/}
        <ButtonGroup className="mb-3">
          <>
            {/*The save button needs to be wrapped around a Col component, since otherwise it will
                           stretch across the whole screen*/}
            <Col xs={0} sm={1}>
              <Button variant="primary" onClick={showChangeModal}>
                {t("global.buttons.save")}
              </Button>
            </Col>
            {/*Popup that is shown when the save button is pressed and the input fields are successfully validated*/}
            <Modal
              show={showChange}
              onHide={() => setShowChange(false)}
              backdrop="static" //doesn't allow the user to close the modal by clicking outside of it
              keyboard={false} //doesn't allow the user to close the modal by using the keyboard
            >
              <Modal.Header>
                <Modal.Title>{t("global.generalWords.confirm")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/*Show a list with all the changes*/}
                {t("components.area.edit.changes.changes")}:
                <ul>
                  {changes.map((change: string, idx: number) => {
                    return <li key={idx}>{change}</li>;
                  })}
                </ul>
              </Modal.Body>
              {/*Submit and cancel buttons*/}
              <Modal.Footer>
                <Button variant="success" onClick={makeChanges}>
                  {t("global.buttons.submit")}
                </Button>
                <Button variant="secondary" onClick={() => setShowChange(false)}>
                  {t("global.buttons.cancel")}
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        </ButtonGroup>
      </Row>
      <Row>
        {showNoChangesAlert && ( //Show alert notifying the user to make changes before submitting
          <Alert key="noChangesAlert" variant="warning">
            {t("global.alert.failure.requireChange")}
          </Alert>
        )}
      </Row>
    </>
  );
};

export default UserSettingsBasic;
