import axios from 'axios';
import StructuredResponse from '../../model/Classes/StructuredResponse';

axios.defaults.withCredentials = true;

const tokenURL = '/api/auth/token';

export const toggleFreeCharging = async (areaId: number, data: { user_id: number }) => {
  try {
    const response = await axios.post(`/api/area/${areaId}/toggleFreeCharging`, data, {
      withCredentials: true,
    });
    return new StructuredResponse<boolean>(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.post(`/api/area/${areaId}/toggleFreeCharging`, data, {
        withCredentials: true,
      });
      return new StructuredResponse<boolean>(true, response.data);
    } catch (e: any) {
      return new StructuredResponse<boolean>(false, e);
    }
  }
};
