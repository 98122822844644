import { Row, Image } from "react-bootstrap";
import { StyledTermsOfService } from "./TermsOfService.styled";
import logo from "../../../resources/WatteryLogoGreen.svg";

/**
 * Component responsible for rendering the Terms of Service page
 * @returns ToS page
 */
const TermsOfService = () => {
  return (
    <StyledTermsOfService className="top-level-component">
      <>
        <Image className="logo" src={logo} alt="Wattery logo" />
        <br />
        <br />

        <Row>
          <h2>1. SOVELTAMISALA</h2>
          <p>
            Näitä käyttöehtoja sovelletaan Wattery Oy:n (jäljempänä ”Palveluntuottaja”) tuottaman ja ylläpitämän
            Wattery-verkkopalvelun (jäljempänä ”Palvelu") käyttöön, käsittäen Palveluun rekisteröitymisen, sisällön
            selaamisen ja lataamisen sekä Palvelun muun käytön.
          </p>
          <p>
            Palvelu toimii alustana, jonka avulla Palveluun liitetyn latauspisteen/latauspisteiden pääkäyttäjä
            (”Pääkäyttäjä”) voi hallinnoida latauspistettä/latauspisteitä ja sen käyttäjiä sekä seurata
            latausaktiviteetteja ja sähkönkulutuksen määrää.
          </p>
          <p>
            Palvelu toimii myös alustana, jonka avulla Palveluun rekisteröitynyt käyttäjä (”Käyttäjä”) voi
            henkilökohtaisten latausoikeuksiensa puitteissa käyttää Palveluun liitettyjä latauspisteitä sekä seurata
            omia latausaktiviteettejään ja lataukseen käytettyä sähkönkulutusta reaaliaikaisesti.
          </p>
          <p>
            Palvelun kautta Käyttäjä voi käyttää sellaisia latauspisteitä, joihin Käyttäjällä on myönnetty
            henkilökohtainen latausoikeus (ns. Private Charging), ja sellaisia latauspisteitä, jotka kyseisen
            latauspisteen Pääkäyttäjä on erikseen määritellyt julkiseksi latauspisteeksi (ns. Public Charging).
          </p>
          <p>
            Näitä käyttöehtoja sovelletaan kaikkiin näiden ehtojen mukaisesti tehtyihin latauksiin Palveluun kuuluvissa
            latauspisteissä.
          </p>
          <p>Palveluntarjoaja ei ole latauspisteiden ylläpitäjä, eikä se osallistu latauspalvelun toteuttamiseen.</p>
          <p>
            Palvelun käyttö edellyttää, että Käyttäjänä sitoudut noudattamaan näitä käyttöehtoja. Sinun tulee lukea nämä
            käyttöehdot huolellisesti ennen kuin aloitat Palvelun käyttämisen. Rekisteröitymällä Palveluun vahvistat,
            että hyväksyt nämä käyttöehdot ja että tulet noudattamaan niitä.
          </p>
        </Row>
        <Row>
          <h2>2. MUUT SOVELLETTAVAT EHDOT</h2>
          <p>Näiden käyttöehtojen lisäksi Palvelun käyttämiseen sovelletaan seuraavia ehtoja:</p>
          <div>
            <ul>
              <li>
                <a href={"https://www.wattery.io/tietosuojaseloste"}>Tietosuojakäytäntö</a>, josta ilmenee ne ehdot,
                joiden perusteella Palveluntuottaja käsittelee Käyttäjää koskevia henkilötietoja. Käyttäjää pyydetään
                hyväksymään tietosuojakäytäntö ennen kuin hän luovuttaa Palveluntuottajalle henkilötietojaan ja ennen
                kuin Palveluntuottaja käsittelee Palvelun käyttöön liittyviä henkilötietoja.
              </li>
              <li>
                <a href={"https://www.wattery.io/tietosuojaseloste"}>Evästekäytäntö</a>, josta Käyttäjä saa tietoa
                Palvelussa käytettävistä evästeistä.
              </li>
            </ul>
          </div>
        </Row>
        <Row>
          <h2>3. TIETOA PALVELUNTUOTTAJASTA</h2>
          <p>
            Wattery Oy on Suomessa rekisteröity yhtiö, jonka yhtiönumero on 3421963-3. Osoite on Tilhenkatu 23, 20610
            TURKU. Yhtiön ALV-numero on FI 34219633.
          </p>
          <p>
            Käyttäjä voi ottaa yhteyttä Palveluntuottajaan sähköpostitse lähettämällä viestin osoitteeseen
            info@wattery.io.
          </p>
        </Row>
        <Row>
          <h2>4. REKISTERÖINTI PALVELUUN</h2>
          <p>
            Rekisteröinti Palveluun tapahtuu osoitteessa wattery.io. Rekisteröinnin yhteydessä Käyttäjä luo oman
            henkilökohtaisen käyttäjätunnuksen ja salasanan. Palveluun rekisteröinti edellyttää toimivaa
            sähköpostiosoitetta.
          </p>
          <p>Palveluntuottajalla on oikeus evätä rekisteröityminen ja oikeus sulkea käyttäjätili.</p>
          <p>
            Useampi käyttäjä ei saa käyttää samaa käyttäjätiliä. Palveluntuottaja pidättää oikeuden poistaa
            moninkertaiset rekisteröitymiset.
          </p>
        </Row>
        <Row>
          <h2>5. PALVELUN KÄYTTÖ </h2>
          <p>
            Rekisteröitynyt Käyttäjä saa käyttää Palvelua näiden käyttöehtojen sekä muiden Palvelua koskevien ohjeiden
            mukaisesti. Palveluntuottajalla on oikeus estää Käyttäjää Verkkopalvelun käyttöehdot käyttämästä Palvelua
            milloin tahansa riippumatta siitä, mikäli Käyttäjä ei Palveluntuottajan näkemyksen mukaan ole noudattanut
            jotain näiden käyttöehtojen määräystä.
          </p>
          <p>
            Käyttäjä kirjautuu omalla henkilökohtaisella tunnuksellaan ja salasanallaan Palveluun ja vastaa
            käyttäjätunnuksen ja salasanan huolellisesta säilytyksestä. Käyttäjätunnus on henkilökohtainen, eikä sitä
            saa luovuttaa kenenkään muun käyttöön. Käyttäjän on välittömästi vaihdettava salasana, jos hän epäilee, että
            se on joutunut ulkopuolisten tietoon ja tarvittaessa ilmoitettava siitä Palveluntuottajalle.
          </p>
          <p>
            Käyttäjän on aloittaessa Palvelun käytön käytettävä sovellusta tai aktivoitava oma RFID- tunnisteensa
            Palveluun, jotta latausaktiviteettien ja sähkönkulutuksen seuranta, onnistuu. Käyttäjä voi halutessaan
            aktivoida useamman RFID-tunnisteen oman käyttäjätilinsä taakse.
          </p>
          <p>
            Käyttäjä on vastuussa kaikista niistä järjestelyistä, joita Palvelun käyttö edellyttää, esimerkiksi
            käyttämänsä tietoliikenneyhteyden toimivuudesta ja tietoturvasta.
          </p>
          <p>
            Palvelu tuottaminen on toistaiseksi jatkuvaa, mutta Palveluntuottaja voi keskeyttää, peruuttaa tai lopettaa
            Palvelun kokonaisuudessaan tai osittain tai tehdä niihin muutoksia ilmoittamatta siitä etukäteen.
            Riippumatta syystä, ajasta tai kestosta, Palveluntuottaja ole Käyttäjälle minkäänlaisessa vastuussa
            menetyksestä tai vahingosta, joka johtuu siitä, että Palvelu ei ole ollut käytettävissä. Palveluntuottaja ei
            siten takaa, että Palvelu on aina käytettävissä tai että Palvelun käytön aikana ei ilmene häiriöitä.
          </p>
          <p>
            Käyttäjä on tietoinen siitä ja hyväksyy, että Palveluun liitettyjen latauspisteiden tehoa voidaan
            Palveluntuottajan, latauspisteen haltijan tai kantaverkko-operaattorin toimesta tilapäisesti pienentää tai
            keskeyttää sähköjärjestelmän tasapainottamiseksi tai muusta syystä.
          </p>
        </Row>
        <Row>
          <h2>6. PÄÄKÄYTTÄJÄ</h2>
          <p>
            Jokaiselle asiakkaalle on Palvelussa asetettu yksi tai useampi Pääkäyttäjä, jonka tehtävänä on muun muassa
            hallinnoida asiakkaan latauspisteitä ja niiden käyttöoikeuksia sekä määrittää latauksesta perittävän hinnan.
          </p>
          <p>Palvelun käyttöön Pääkäyttäjänä sovelletaan muutoin, mitä Käyttäjästä on näissä ehdoissa määrätty.</p>
        </Row>
        <Row>
          <h2>7. LATAUSPISTEEN KÄYTTÖ</h2>
          <p>
            Tiedot Palveluun liitetyistä latauspisteistä ja niiden kulloinkin voimassa olevasta lataushinnasta löytyvät
            Palvelusta. Käyttäjä tiedostaa, että lataushinnat voivat vaihdella sen mukaan, tapahtuuko lataus ns. Private
            Charging-asiakkaana tai Public Charging- asiakkaana.
          </p>
          <p>
            Aktivoimalla Palvelussa latauksen latauspisteestä Käyttäjä solmii sopimuksen latauksesta kyseisen
            latauspisteen haltijan kanssa niillä ehdoilla, jotka Palvelussa ennen latausta esitetään Käyttäjälle.
          </p>
          <p>
            Mikäli kyseinen latauspiste on Palveluntarjoajan laskutuspalvelun piirissä, Palveluntarjoaja kerää
            latausmaksun Käyttäjältä latauspisteen haltijan puolesta. Palveluntuottaja toimittaa myös Käyttäjälle kuitin
            latauspisteen haltijan puolesta.
          </p>
          <p>
            Todettakoon, että Palveluntarjoaja ei ole sopijapuoli Käyttäjän ja latauspisteen haltijan välisessä
            sopimuksessa. Palveluntarjoaja ei ole siten myöskään vastuussa Käyttäjälle latauspisteen haltijan
            velvoitteiden täyttämisestä.
          </p>
          <p>
            Palveluntarjoaja ei osallistu latauspisteiden ylläpitoon tai latauksen toteuttamiseen. Latauspisteen haltija
            vastaa siten yksin latauspisteen toimivuudesta, eikä Palveluntuottajalla ole minkäänlaista vastuuta
            Verkkopalvelun käyttöehdot sellaisista Käyttäjälle aiheutuneista vahingosta, jotka johtuvat latauspisteen
            käytöstä.
          </p>
          <p>
            Mikäli Käyttäjä suorittaa latausmaksunsa Palvelussa, Palveluntarjoaja perii erillisen palvelumaksun
            jokaisesta Palvelussa suoritetusta maksutransaktiosta. Kulloinkin voimassa oleva palvelumaksu ilmoitetaan
            Käyttäjälle Palvelussa.
          </p>
        </Row>
        <Row>
          <h2>8. PALVELUN MUUTOKSET</h2>
          <p>
            Palveluntuottaja päivittää ja saattaa muuttaa Palvelun sisältöä ajoittain. Palveluntuottaja pyytää Käyttäjää
            huomioimaan, että Palvelussa olevat tiedot eivät välttämättä aina ole ajan tasalla, eikä Palveluntuottajalla
            ole erityistä velvollisuutta päivittää Palvelua. Palvelun- tuottaja ei takaa, että Palvelun tai sen sisältö
            olisi virheetöntä tai että siinä ei olisi puutteita.
          </p>
        </Row>
        <Row>
          <h2>9. PALVELUNTUOTTAJAN LUOMA SISÄLTÖ </h2>
          <p>
            Palveluntuottaja on kaiken Palvelussa löytyvän materiaalin ja immateriaalioikeuksien haltija tai
            lisenssinsaaja. Teoksia suojaa tekijänoikeuslainsäädäntö ja kansainväliset sopimukset. Kaikki oikeudet
            Palvelussa oleviin teoksiin ja immateriaalioikeuksiin pidätetään.
          </p>
          <p>
            Käyttäjä ei saa muokata Palvelun sisällöstä tekemääsi tulostetta tai digitaalista tallennetta millään
            tavalla.
          </p>
          <p>
            Palvelu on vain henkilökohtaista käyttöä varten, eikä Käyttäjälle siirry mitään muita Palveluun tai sen
            sisältöön liittyviä oikeuksia. Käyttäjä ei saa käyttää Palvelua miltään osin minkäänlaisiin kaupallisiin
            tarkoituksiin hankimatta siihen ensin Palveluntuottajan suostumus.
          </p>
          <p>
            Mikäli Käyttäjä tulostaa, kopioi tai tallentaa jonkin osan Palvelusta näiden käyttöehtojen vastaisesti,
            Käyttäjän oikeus käyttää Palvelua lakkaa heti ja Käyttäjän on Palveluntuottajan ohjeiden mukaisesti joko
            palautettava tai tuhottava tulostetut, kopioidut tai tallennetut materiaalit. Palveluntuottajalla on lisäksi
            oikeus vaatia korvausta ja/tai hyvitystä Käyttäjältä.
          </p>
        </Row>
        <Row>
          <h2>10. VASTUUNRAJOITUS </h2>
          <p>
            Palveluntuottaja ei ole vastuussa Palvelun Käyttäjiä kohtaan mistään menetyksestä tai vahingosta riippumatta
            siitä, onko vahinko sopimus- tai vahingonkorvausoikeudellinen tai onko sillä muu peruste, jos se johtuu tai
            liittyy Palvelun käyttöön tai käytön estymiseen tai Palvelun sisällön tai Palvelun olevan tiedon käyttöön
            tai siihen luottamiseen.
          </p>
        </Row>
        <Row>
          <h2>11. PALVELUN KESKEYTTÄMINEN JA LOPETTAMINEN </h2>
          <p>
            Palveluntuottaja päättää oman harkintansa mukaan siitä, onko Käyttäjä rikkonut Palvelun käyttöehtoja
            käyttäessään Palvelua. Mikäli käyttöehtoja on rikottu, Palveluntuottaja ryhtyy asianmukaisiksi katsomiinsa
            toimenpiteisiin.
          </p>
          <div>
            Toimiminen näiden käyttöehtojen vastaisesti voi johtaa seuraaviin toimenpiteisiin:
            <ul>
              <li>
                Käyttäjän käyttöoikeuden välittömään ja väliaikaiseen peruuttamiseen tai käyttötilin lopulliseen
                poistamiseen
              </li>
              <li>korvauksen hakemiseen oikeusteitse Käyttäjän aiheuttamien vahinkojen johdosta</li>
              <li>muihin oikeudellisiin toimenpiteisiin Käyttäjää vastaan</li>
              <li>
                tietojen luovuttamiseen viranomaisille siinä laajuudessa kuin Palveluntuottaja katsoo sen
                tarpeelliseksi.
              </li>
            </ul>
          </div>
          <p>
            Lisäksi Palveluntuottajalla on oikeus estää käyttöehtojen vastaisesti toiminutta Käyttäjää, jonka käyttötili
            on Palveluntuottajan toimesta poistettu, perustamasta uutta käyttötiliä Palveluun aina siihen asti, kunnes
            vuosi on kulunut käyttötilin poistamisesta.
          </p>
          <p>
            Palveluntuottaja ei ole vastuussa mahdollisten toimenpiteiden aiheuttamista menetyksistä tai vahingoista.
            Tässä luvussa mainitut toimenpiteet ovat ainoastaan esimerkkejä niistä toimenpiteistä, joihin
            Palveluntuottaja voi ryhtyä rikkomuksen takia. Palveluntuottaja pidättää Verkkopalvelun käyttöehdot oikeuden
            ryhtyä myös muihin asianmukaisiksi katsomiinsa toimenpiteisiin.
          </p>
        </Row>
        <Row>
          <h2>12. MUUTOKSET KÄYTTÖEHTOIHIN </h2>
          <p>
            Palveluntuottaja voi tehdä muutoksia näihin käyttöehtoihin milloin tahansa oman harkintansa mukaan.
            Muutoksista ilmoitetaan aina Käyttäjälle kirjallisesti.
          </p>
        </Row>
        <Row>
          <h2>13. SOVELLETTAVA LAKI JA ERIMIELISYYKSIEN RATKAISEMINEN </h2>
          <p>Palvelun käyttöön ja näihin käyttöehtoihin sovelletaan Suomen lakia.</p>
          <p>
            Palvelun käyttöön ja käyttöehtoihin liittyvät mahdolliset erimielisyydet pyritään ratkaisemaan
            ensisijaisesti keskinäisin neuvotteluin. Ellei neuvotteluissa löydetä ratkaisua, tästä sopimuksesta
            aiheutuvat riidat ratkaistaan ensiasteena Varsinais-Suomen käräjäoikeudessa. Jos olet kuluttaja, sinulla on
            myös aina oikeus viedä riita oman kotipaikkakuntasi tai vakituisen asuinpaikan käräjäoikeudelle
            ratkaistavaksi, tai saattaa erimielisyys kuluttajariitalautakunnan käsiteltäväksi.
          </p>
        </Row>
      </>
    </StyledTermsOfService>
  );
};

export default TermsOfService;
