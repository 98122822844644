import axios from 'axios';
import StructuredResponse from '../../model/Classes/StructuredResponse';

axios.defaults.withCredentials = true;

const baseURL = '/api/auth/register/invite';

export const getInviteToken = async (invite_token: string) => {
  try {
    //Send the login details to the backend
    const response = await axios.get(`${baseURL}/${invite_token}`, {
      withCredentials: false,
    });
    return new StructuredResponse<string>(true, response.data);
  } catch (e: any) {
    return new StructuredResponse<string>(false, e);
  }
};
