import React from 'react';
import { Row, Col, ButtonGroup, Button } from 'react-bootstrap';
import { StyledTree } from './Tree.styled';

declare interface TreeProps<T> {
  nodes: T[];
  userRoot: number;
  history: any;
}

declare interface TreeState<T> {
  nodes: T[];
  userRoot: number;
  root: T;
  history: any;
  expandedPath: number[];
}

export default class Tree<T extends TreeNodeState<T>> extends React.Component<
  TreeProps<T>,
  TreeState<T>
> {
  constructor(props: TreeProps<T>) {
    super(props);
    this.state = {
      nodes: this.createTree(props.nodes),
      userRoot: props.userRoot,
      root: props.nodes.filter((a) => {
        return a.id === props.userRoot;
      })[0],
      history: props.history,
      expandedPath: new Array<number>(),
    };
    const duplicateCheck: number[] = [];
    for (const node of this.state.nodes) {
      duplicateCheck.push(node.id!);
    }
    if (!this.validateTree(duplicateCheck)) {
      throw new InvalidTreeError();
    }
    this.state.root.parent = -1;
  }

  private createTree<T extends TreeNodeState<T>>(nodes: T[]) {
    for (const node of nodes) {
      const children = nodes.filter((other) => {
        //NOTE! since the users root area is the parent to itself, do not include
        //it as a child to itself (otherwise an infinite loop would occur).
        return other.parent === node.id && other.id !== 0;
      });
      node.children = children;
      for (const child of children) {
        child.parentObj = node;
      }
    }
    return nodes;
  }

  private createExpandedPathToRoot<T extends TreeNodeState<T>>(
    node: TreeNodeState<T>,
    ancestors: number[],
    added?: boolean
  ) {
    //If area was recently added, also expand its parent so the new area is visible
    if (added) {
      if (node.id === this.state.userRoot || !node.parentObj) return ancestors;
      ancestors.push(node.id!);
    } else {
      //If we've just returned from viewing an area, expand every node up until that area, so that it is visible, but not expanded
      if (node.parent === this.state.userRoot || !node.parentObj)
        return ancestors;
      ancestors.push(node.parent!);
    }
    return this.createExpandedPathToRoot(node.parentObj, ancestors, added);
  }

  private checkHistory(state?: number, added?: boolean) {
    if (state) {
      const area_id = state;
      const lowestNode = this.state.nodes.filter((n) => {
        return n.id === area_id;
      })[0];
      if (lowestNode === undefined) return;
      this.setState({
        expandedPath: this.createExpandedPathToRoot(
          lowestNode,
          new Array<number>(),
          added
        ),
      });
    }
  }

  componentWillMount() {
    //Expand tree if history has an area_id in its state
    if (this.state.history && this.state.history.location.state) {
      this.checkHistory(this.state.history.location.state.area_id);
      this.checkHistory(this.state.history.location.state.parent);
      window.history.replaceState({}, document.title);
    }
  }

  componentDidMount() {
    //set state to default area expansion, sorta shitty but doesn't work with history.replaceState()
    //this.state.history.push('/area', { area_id: this.state.userRoot });
  }

  private validateTree(nodeIds: number[]) {
    const duplicates = nodeIds.filter((value, index) => {
      //[0,1,2,3,4,2] => indexOf(2) => 2 even though index is also 5
      return nodeIds.indexOf(value) !== index;
    });
    if (duplicates.length > 0) return false;
    return true;
  }

  render(): React.ReactNode {
    return (
      <StyledTree>
        <Col>
          <TreeNode
            parent={this.state.root.parent}
            parentObj={this.state.root.parentObj}
            id={this.state.root.id}
            name={this.state.root.name}
            children={this.state.root.children}
            expanded={true}
            expandedPath={this.state.expandedPath}
            history={this.state.history}
          />
        </Col>
      </StyledTree>
    );
  }
}

declare interface TreeNodeState<T extends TreeNodeState<T>> {
  expanded?: boolean;
  children?: T[];
  parent?: number;
  parentObj?: T;
  id?: number;
  name: string;
  history?: any;
  expandedPath?: number[];
}

export class TreeNode<T extends TreeNodeState<T>> extends React.Component<
  TreeNodeState<T>,
  TreeNodeState<T>
> {
  constructor(props: TreeNodeState<T>) {
    super(props);
    this.state = {
      children: props.children,
      parent: props.parent,
      parentObj: props.parentObj,
      id: props.id,
      name: props.name,
      history: props.history,
      expanded: props.expandedPath?.includes(props.id!) || props.expanded,
      expandedPath: props.expandedPath,
    };
    this.setExpanded = this.setExpanded.bind(this);
  }

  private setExpanded(expState: boolean) {
    this.setState({
      expanded: expState,
    });
  }

  componentDidMount(): void {
    if (this.state.parent === -1) this.setExpanded(true);
  }

  /**
   * @param node arbitrary treenode in your tree
   * @returns a number indicating how many steps you have to take from the root to get to the node
   */
  public static distanceFromRoot<T extends TreeNodeState<T>>(
    node: TreeNodeState<T>
  ) {
    //Root
    if (node.id === 0 || node.parent === -1) {
      return 0;
    } else {
      return 1 + this.distanceFromRoot(node.parentObj!);
    }
  }

  render(): React.ReactNode {
    const margin = TreeNode.distanceFromRoot(this.state);
    return (
      <Row style={{ marginLeft: `${margin}%` }}>
        <Col>
          <ButtonGroup>
            <Button
              variant="primary"
              href={`/area/${this.state.id}`}
              className="mb-3"
            >
              {this.state.name}
            </Button>
            {this.state.children!.length > 0 && (
              <ExpandAreaArrow
                id={this.state.id}
                callback={this.setExpanded}
                state={this.state.expanded}
              />
            )}
          </ButtonGroup>
          {
            <div>
              {this.state.children!.map((a, idx) => {
                return (
                  this.state.expanded && (
                    <Row key={idx}>
                      <TreeNode
                        children={a.children}
                        parent={a.parent}
                        parentObj={a.parentObj}
                        id={a.id}
                        name={a.name}
                        history={this.state.history}
                        expandedPath={this.state.expandedPath}
                      />
                    </Row>
                  )
                );
              })}
            </div>
          }
        </Col>
      </Row>
    );
  }
}

const ExpandAreaArrow = ({ callback, state = false, id }: any) => {
  const decoratedOnClick = () => {
    callback(!state);
  };

  return (
    <Button
      data-cy={`${id}-arrow`}
      className="tree-button mb-3"
      onClick={decoratedOnClick}
      //Don't want the arrow button to be highlighted, looks wonky
      onMouseDown={(e) => e.preventDefault()}
    >
      {state ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          width="14"
          height="14"
        >
          <polygon points="7,14 14,0 0,0" />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          width="14"
          height="14"
        >
          <polygon points="14,7 0,14 0,0" />
        </svg>
      )}
    </Button>
  );
};

class InvalidTreeError extends Error {
  message = 'The tree you supplied is invalid. Check for duplicates.';
}
