import { Component } from "react";
import { DropdownButton } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import i18n from "../../../i18n";
import { StyledLangDropdown } from "./LangDropdown.styled";

declare interface LangDropdownProps {
  i18n: typeof i18n;
  currentLang: string;
}
declare interface LangDropdownState extends LangDropdownProps {
  displayLangs: string[];
  fullDisplayLangs: object;
}

export class LangDropdown extends Component<LangDropdownProps, LangDropdownState> {
  constructor(props: LangDropdownProps) {
    super(props);
    this.state = {
      i18n: props.i18n,
      currentLang: props.currentLang,
      displayLangs: props.i18n.languages.filter((l) => l !== props.currentLang),
      fullDisplayLangs: {
        en: "English",
        fi: "Suomi",
        sv: "Svenska",
      },
    };
    this.handleLangSwitch = this.handleLangSwitch.bind(this);
  }

  private handleLangSwitch(lang: string): void {
    //Set global language -> idk why this has to be done but if we dont then the language reset on redirect
    //Might change later to use URL params but havent figured out how to yet
    i18n.store.options.lng = lang;
    localStorage.currLang = lang;
    //Set language state to remove currentLang from dropdown
    this.setState({
      currentLang: lang,
      displayLangs: this.state.i18n.languages.filter((l) => l !== lang),
    });
    //Set local language
    i18n.changeLanguage(lang);
  }

  render() {
    return (
      <StyledLangDropdown>
        <DropdownButton
          title={this.state.fullDisplayLangs[this.state.currentLang]}
          drop="down"
          className="lang-menu"
          data-cy="lang-dropdown"
          variant="default"
        >
          {this.state.displayLangs.map((lang: string, idx: number) => {
            return (
              <DropdownItem key={idx} as="button" data-cy={lang} onClick={() => this.handleLangSwitch(lang)}>
                {this.state.fullDisplayLangs[lang]}
              </DropdownItem>
            );
          })}
        </DropdownButton>
      </StyledLangDropdown>
    );
  }
}
