import axios from 'axios';
import CpFull from '../../model/Classes/Chargepoint';
import StructuredResponse from '../../model/Classes/StructuredResponse';
axios.defaults.withCredentials = true;

const tokenURL = '/api/auth/token';
const url = '/api/area';
/**
 * Service function responsible for fetching all areas the user has access to.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @param {*} apiAddress the api address to fetch data from
 * @returns if successful: [true, response.data]. Otherwise: [false, error]
 */
export const getSingleChargepoint = async (area_id, charge_point_id) => {
  try {
    const response = await axios.get(
      `${url}/${area_id}/chargepoint/${charge_point_id}`
    );
    return new StructuredResponse<CpFull>(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(
        `${url}/${area_id}/chargepoint/${charge_point_id}`
      );
      return new StructuredResponse<CpFull>(true, response.data);
    } catch (e: any) {
      return new StructuredResponse<CpFull>(false, e);
    }
  }
};
