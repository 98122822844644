import axios from 'axios';
import StructuredResponse from '../../model/Classes/StructuredResponse';

axios.defaults.withCredentials = true;

const tokenURL = '/api/auth/token';
export const getSpotPricePendingCount = async () => {
  try {
    const response = await axios.get(
      `/api/myTransactions/pending-transaction-count/`
    );
    return new StructuredResponse(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(
        `/api/myTransactions/pending-transaction-count`
      );
      return new StructuredResponse(true, response.data);
    } catch (e: any) {
      return new StructuredResponse(false, e);
    }
  }
};
