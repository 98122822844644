import axios from 'axios';
import { AreaReporting } from '../../../model/Classes/Area';
import { ReportingInvoice } from '../../../model/Classes/Invoice';
import StructuredResponse from '../../../model/Classes/StructuredResponse';
import {
  ActiveTransaction,
  ReportingTransaction,
} from '../../../model/Classes/Transaction';
import ITuple from '../../../model/Utilities/Tuple';
axios.defaults.withCredentials = true;

const tokenURL = '/api/auth/token';

/**
 * Service function responsible for fetching all completed transactions for the default mode (used in Reporting.js's useEffect).
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @param {*} data data object containing area id, start time, stop time, invoicing_method
 * @returns if successful: [true, response.data]. Otherwise: [false, error]
 */
export const getAllTransactionsDefaultCompleted = async (data: any) => {
  try {
    const response = await axios.post('/api/reporting/default', data, {
      withCredentials: true,
    });
    return new StructuredResponse<
      ITuple<ReportingTransaction[], AreaReporting[]>
    >(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.post('/api/reporting/default', data, {
        withCredentials: true,
      });
      return new StructuredResponse<
        ITuple<ReportingTransaction[], AreaReporting[]>
      >(true, response.data);
    } catch (e: any) {
      return new StructuredResponse<
        ITuple<ReportingTransaction[], AreaReporting[]>
      >(false, e);
    }
  }
};
/**
 * Service function responsible for fetching all active transactions for the default mode (used in Reporting.js's useEffect).
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @returns if successful: [true, response.data]. Otherwise: [false, error]
 */
export const getAllTransactionsDefaultActive = async () => {
  try {
    const response = await axios.get<ActiveTransaction[]>(
      '/api/reporting/default/active'
    );
    return new StructuredResponse<ActiveTransaction[]>(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get<ActiveTransaction[]>(
        '/api/reporting/default/active'
      );
      return new StructuredResponse<ActiveTransaction[]>(true, response.data);
    } catch (e: any) {
      return new StructuredResponse<ActiveTransaction[]>(false, e);
    }
  }
};

/**
 * Service function responsible for fetching all transactions data for an area (and its subareas)
 * between two specific dates as specified in the data object in the parameters
 * @param {*} data data object containing area id, start time, and stop time
 * @returns response from backend
 */
export const searchAllTransactions = async (data) => {
  try {
    const response = await axios.post('/api/reporting/specific', data, {
      withCredentials: true,
    });
    return response.data;
  } catch (e) {
    //authenticate and try again
    await axios.post(tokenURL, { withCredentials: true });
    const response = await axios.post('/api/reporting/specific', data, {
      withCredentials: true,
    });
    return response.data;
  }
};

/**
 * Service function responsible for fetching all active transactions data for an area (and its subareas)
 * between two specific dates as specified in the data object in the parameters
 * @param {*} data data object containing area id
 * @returns response from backend
 */
export const searchAllActiveTransactions = async (data) => {
  try {
    const response = await axios.post('/api/reporting/specific/active', data, {
      withCredentials: true,
    });
    return new StructuredResponse<ActiveTransaction[]>(true, response.data);
  } catch (e) {
    //authenticate and try again
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.post(
        '/api/reporting/specific/active',
        data,
        { withCredentials: true }
      );
      return new StructuredResponse<ActiveTransaction[]>(true, response.data);
    } catch (e: any) {
      return new StructuredResponse<ActiveTransaction[]>(false, e);
    }
  }
};

/**
 * Service function responsible for creating invoices based on the selections in the Completed transactions tab.
 * @param {*} data data object containing reporting_area, period_start, period_end, user_ids
 * @param invoiceMethod invoicing_method to use
 * @returns response from backend
 */
export const sendInvoiceData = async (data) => {
  const route = '/api/invoice/generateInvoices';
  try {
    const response = await axios.post(route, data);
    return new StructuredResponse(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL);
      const response = await axios.post(route, data);
      return new StructuredResponse(true, response.data);
    } catch (e) {
      return new StructuredResponse(false, e);
    }
  }
};

export const getAllActiveTransactionsForArea = async (id) => {
  try {
    const response = await axios.get(`/api/reporting/active/${id}`);
    return new StructuredResponse<ActiveTransaction[]>(true, response.data);
  } catch (e) {
    //authenticate and try again
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(`/api/reporting/active/${id}`);
      return new StructuredResponse<ActiveTransaction[]>(true, response.data);
    } catch (e: any) {
      return new StructuredResponse<ActiveTransaction[]>(false, e);
    }
  }
};

export const getAllActiveTransactionsForChargepoint = async (data) => {
  try {
    const response = await axios.get(
      `/api/reporting/active/${data.areaId}/${data.cpId}`
    );
    return new StructuredResponse<ActiveTransaction[]>(true, response.data);
  } catch (e) {
    //authenticate and try again
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(
        `/api/reporting/active/${data.areaId}/${data.cpId}`
      );
      return new StructuredResponse<ActiveTransaction[]>(true, response.data);
    } catch (e: any) {
      return new StructuredResponse<ActiveTransaction[]>(false, e);
    }
  }
};

export const getInvoicesMadeByUser = async () => {
  try {
    const response = await axios.get(`/api/invoice/getUserInvoices`);
    return new StructuredResponse<ReportingInvoice[]>(true, response.data);
  } catch (e) {
    //authenticate and try again
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(`/api/invoice/getUserInvoices`);
      return new StructuredResponse<ReportingInvoice[]>(true, response.data);
    } catch (e: any) {
      return new StructuredResponse<ReportingInvoice[]>(false, e);
    }
  }
};

export const groupUpdatePaymentMethodStatus = async (user_emails) => {
  try {
    const response = await axios.post(
      `/api/reporting/card_expiry`,
      user_emails
    );
    return new StructuredResponse(true, response.data);
  } catch (e) {
    //authenticate and try again
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.post(
        `/api/reporting/card_expiry`,
        user_emails
      );
      return new StructuredResponse(true, response.data);
    } catch (e: any) {
      return new StructuredResponse(false, e);
    }
  }
};
