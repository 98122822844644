import { useState, useEffect, useRef, MutableRefObject } from 'react';
import { login } from '../../../services/authentication/login';
import { resetPasswordRequest } from '../../../services/authentication/resetPasswordRequest';
import logo from '../../../resources/WatteryLogoGreen.svg';
import {
  Form,
  Row,
  Col,
  Button,
  Modal,
  Alert,
  Container,
  Image,
  InputGroup,
} from 'react-bootstrap';
import { StyledLogin } from './Login.styled';
import { timer } from '../../../utils/timer';
import { toggleAlertsOff } from '../../../utils/toggleAlertsOff';
import ReCAPTCHA from 'react-google-recaptcha';
import { reCAPTCHASiteKey } from '../../..';
import { logger } from '../../../utils/logger';
import i18N from '../../../i18n';
import { useTranslation } from 'react-i18next';
import { MaintenanceNotification } from '../../GeneralComponents/MaintenanceNotification';
import { Nullable, StateHandler } from '../../../model/Utilities/Types';
import User from '../../../model/Classes/User';
import { getMyTransactionsActive } from '../../../services/transactions/MyTransactions/MyTransactions_service';

declare interface LoginFormProps {
  handleSubmit: (event: any) => Promise<void>;
  registrationSuccessAlert: boolean;
  logoutSuccessAlert: boolean;
  setEmail: StateHandler<string>;
  setPassword: StateHandler<string>;
  errorAlert: boolean;
  resetPasswordSuccessAlert: boolean;
  showForgotPassword: boolean;
  setShowForgotPassword: StateHandler<boolean>;
  forgotPasswordEmail: string;
  setForgotPasswordEmail: StateHandler<string>;
  invalidEmailAlert: boolean;
  email: string;
  passwordResetSubmitSuccess: boolean;
  setPasswordResetSubmitSuccess: StateHandler<boolean>;
  passwordResetSubmitFailed: boolean;
  handleForgotPasswordSubmit: () => Promise<void>;
  confirmEmailSuccess: boolean;
  confirmEmailFail: boolean;
  history: any;
  recaptchaRef: MutableRefObject<any>;
  recaptchaNotClicked: boolean;
  recaptchaRef2: MutableRefObject<any>;
  recaptchaNotClicked2: boolean;
  password: string;
  loginAttempts: number;
}
/**
 * Login form that contains the following:
 * - Wattery logo
 * - Possible alerts (successfully logged out, successfully created an account, successfully reset password, successfully/failed email confirmation)
 * - Email and password inputs
 * - reCAPTCHA
 * - Submit button
 * - Register button
 * - Forgot password button
 * - Possible alert (login failed)
 *
 * Whenever the "Forgot password?" button is pressed, a popup appears where the user can enter their email
 * to send a request to reset their password to said email.
 * @param {*} handleSubmit helper function for submitting the form
 * @param {*} registrationSuccessAlert state for showing successful registration alert
 * @param {*} logoutSuccessAlert state for showing
 * @param {*} setEmail state handler for setting the email state
 * @param {*} setPassword state handler for setting the password state
 * @param {*} errorAlert state for showing errors when trying to log in
 * @param {*} resetPasswordSuccessAlert state for showing an alert notifying the user that resetting their password was successful
 * @param {*} showForgotPassword state for showing the popup when the "Forgot password?" button is pressed
 * @param {*} setShowForgotPassword state handler for the showForgotPassword state
 * @param {*} forgotPasswordEmail state containing the email to send the reset password request to
 * @param {*} setForgotPasswordEmail state handler for the forgotPasswordEmail
 * @param {*} invalidEmailAlert state for showing an alert notifying the user that the inserted email is invalid
 * @param {*} emailSentTo state containing the email the reset password request was sent to
 * @param {*} passwordResetSubmitSuccess state for showing an alert notifying the user that the reset password request was successfully sent to the provided email
 * @param {*} setPasswordResetSubmitSuccess state handler for the passwordResetSubmitSuccess state
 * @param {*} passwordResetSubmitFailed state for showing an alert notifying the user that the reset password request failed
 * @param {*} handleForgotPasswordSubmit helper function for handling the submission of the forgot password form
 * @param {*} confirmEmailSuccess state for showing the success email confirmation alert
 * @param {*} confirmEmailFail state for showing the failed email confirmation alert
 * @param {*} history history object
 * @param {*} recaptchaRef ref for reCAPTCHA (for login)
 * @param {*} recaptchaRef2 ref for reCAPTCHA (for requesting password reset)
 * @param {*} recaptchaNotClicked state for showing an alert notifying the user to click the reCAPTCHA button (for login)
 * @param {*} recaptchaNotClicked2 state for showing an alert notifying the user to click the reCAPTCHA button (for requesting password reset)
 * @returns login form
 */
const LoginForm = ({
  handleSubmit,
  registrationSuccessAlert,
  logoutSuccessAlert,
  setEmail,
  setPassword,
  errorAlert,
  resetPasswordSuccessAlert,
  showForgotPassword,
  setShowForgotPassword,
  forgotPasswordEmail,
  setForgotPasswordEmail,
  invalidEmailAlert,
  email,
  passwordResetSubmitSuccess,
  setPasswordResetSubmitSuccess,
  passwordResetSubmitFailed,
  handleForgotPasswordSubmit,
  confirmEmailSuccess,
  confirmEmailFail,
  history,
  recaptchaRef,
  recaptchaNotClicked,
  recaptchaRef2,
  recaptchaNotClicked2,
  loginAttempts,
}: LoginFormProps) => {
  //Setup the translation
  //Common is the default namespace for translationmapping, which means that we can omit
  //namespace declarations for most of the translations in components.
  //useSuspense is declared but not implemented, essentially means that it displays a default value
  //if loading the translations takes a while. I think.
  const { t, i18n } = useTranslation('common', {
    i18n: i18N,
  });
  return (
    <Container id="component-margin">
      <Row>
        <Image className="logo" src={logo} alt="Wattery logo" />
      </Row>
      <MaintenanceNotification />
      <Row /* xs="auto" sm="auto" */>
        {confirmEmailSuccess && (
          <Alert key="confirmEmailSuccess" variant="success" className="mt-4">
            {t('global.alert.success.emailConf')}
          </Alert>
        )}
        {confirmEmailFail && (
          <Alert key="confirmEmailFail" variant="danger" className="mt-4">
            {t('global.alert.failure.emailConf')}
          </Alert>
        )}
        {resetPasswordSuccessAlert && (
          <Alert key="resetSuccessfull" variant="success" className="mt-4">
            {t('global.alert.success.passwordRes')}
          </Alert>
        )}
        {registrationSuccessAlert && (
          <Alert key="registrationSuccess" variant="success" className="mt-4">
            {t('global.alert.success.createAcc')}
          </Alert>
        )}
        {logoutSuccessAlert && (
          <Alert key="logoutSuccess" variant="success" className="mt-4">
            {t('global.alert.success.logout')}
          </Alert>
        )}
        {recaptchaNotClicked && (
          <Alert key="recaptchaNotClicked" variant="danger">
            {t('global.alert.failure.reCaptchaPass')}
          </Alert>
        )}
      </Row>
      <Row /* xs="auto" sm="auto" */>
        <Form className="form">
          <Form.Group
            as={Row}
            className="mb-3"
            style={{ marginTop: 20, textAlign: 'center' }}
          >
            <h2 className="align-self-center">
              {t('components.loginPage.static.header')}
            </h2>
          </Form.Group>

          <Form.Group
            as={Row}
            className="mb-3"
            onChange={(event) => setEmail(event.target.value)}
          >
            <Col sm={3}>
              <Form.Label>{t('components.loginPage.static.email')}</Form.Label>
            </Col>
            <Col>
              <Form.Control
                type="email"
                placeholder={t('components.loginPage.static.email')}
                name="email"
              />
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            className="mb-3"
            onChange={(event) => setPassword(event.target.value)}
          >
            <Col sm={3}>
              <Form.Label>
                {t('components.loginPage.static.password')}
              </Form.Label>
            </Col>
            <Col>
              <Form.Control
                type="password"
                placeholder={t('components.loginPage.static.password')}
                name="password"
              />
            </Col>
          </Form.Group>

          {loginAttempts >= 4 ? (
            <Form.Group as={Row} className="mb-3">
              <ReCAPTCHA
                hl={i18n.language}
                className="mb-3"
                ref={recaptchaRef}
                sitekey={reCAPTCHASiteKey}
              />
            </Form.Group>
          ) : null}

          <Form.Group as={Row} className="mb-3">
            <Col>
              <Button
                type="submit"
                onClick={handleSubmit}
                name="login-button"
                className="mb-5"
              >
                {t('components.loginPage.static.signin')}
              </Button>
            </Col>
            <Row>
              <Col className="secondButtonCol mt-3">
                <Button
                  className="link-button"
                  variant="secondary"
                  onClick={() => history.push('/register')}
                  name="register-button"
                >
                  {t('components.loginPage.static.register')}
                </Button>
                <Button
                  className="link-button"
                  variant="secondary"
                  onClick={() => setShowForgotPassword(true)}
                  name="forgot-password"
                >
                  {t('components.loginPage.static.forgotPW')}
                </Button>
                <Modal
                  show={showForgotPassword}
                  onHide={() => {
                    setShowForgotPassword(false);
                    setPasswordResetSubmitSuccess(false);
                    setForgotPasswordEmail('');
                  }}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header>
                    <Modal.Title>
                      {t('components.loginPage.modal.title')}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>{t('components.loginPage.modal.body')}</p>

                    {invalidEmailAlert && (
                      <Alert key="invalidEmailAlert" variant="danger">
                        {t('global.alert.other.validEmail')}
                      </Alert>
                    )}
                    {passwordResetSubmitFailed && (
                      <Alert key="passwordResetSubmitFailed" variant="danger">
                        {t('global.alert.failure.something')}
                      </Alert>
                    )}
                    {recaptchaNotClicked2 && (
                      <Alert key="recaptchaNotClicked" variant="danger">
                        {t('global.alert.failure.reCaptchaPass')}
                      </Alert>
                    )}
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="forgotPasswordEmail">
                        @
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        value={forgotPasswordEmail}
                        placeholder={t('components.loginPage.modal.email')}
                        aria-label={forgotPasswordEmail}
                        aria-describedby="forgotPasswordEmail"
                        onChange={(e) => setForgotPasswordEmail(e.target.value)}
                        id="forgotPasswordEmailField"
                      />
                    </InputGroup>
                    <ReCAPTCHA
                      hl={i18n.language}
                      id="reset-password-request-recaptcha"
                      className="mb-3"
                      ref={recaptchaRef2}
                      sitekey={reCAPTCHASiteKey}
                      data-cy="reset-password-request-recaptcha"
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setShowForgotPassword(false);
                        setForgotPasswordEmail('');
                        setPasswordResetSubmitSuccess(false);
                      }}
                    >
                      {t('global.buttons.cancel')}
                    </Button>
                    <Button
                      variant="primary"
                      onClick={handleForgotPasswordSubmit}
                      id="submitResetPassword"
                    >
                      {t('global.buttons.submit')}
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Col>
            </Row>
          </Form.Group>
        </Form>
      </Row>

      {errorAlert && (
        <Row /* xs="auto" sm="auto" */>
          <Col sm="auto" xs="auto">
            <Alert key="failedLogin" variant="danger" className="mt-4">
              {t('global.alert.failure.login')}
            </Alert>
          </Col>
        </Row>
      )}

      {/* {recaptchaNotClicked && (
        <Row>
          <Col sm="auto" xs="auto">
            <Alert key="recaptchaNotClicked" variant="danger">
              {t('global.alert.failure.reCaptchaPass')}
            </Alert>
          </Col>
        </Row>
      )} */}
      {passwordResetSubmitSuccess && (
        <Row>
          <Col sm="auto" xs="auto">
            <Alert key="passwordResetSubmitSuccess" variant="success">
              {t('global.alert.other.resetSubmit', {
                email: forgotPasswordEmail,
              })}
              .
            </Alert>
          </Col>
        </Row>
      )}
    </Container>
  );
};

declare interface LoginProps {
  setUser: StateHandler<User>;
  registrationSuccessAlert: boolean;
  logoutSuccessAlert: boolean;
  resetPasswordSuccessAlert: boolean;
  history: any;
  user: Nullable<User>;
  confirmEmailSuccess: boolean;
  confirmEmailFail: boolean;
}
/**
 * Component for handling functionality related to the login page. The component uses a useEffect hook
 * to redirect the user correctly when encountered with multiple scenarios (explanation can be found
 * in the useEffects comments). The component also contains functionality for submitting the login
 * form to the backend and submitting a request to reset the password for a user.
 * @param {*} setUser state handler for setting a user
 * @param {*} registrationSuccessAlert state for showing the successful registration alert
 * @param {*} logoutSuccessAlert state for showing the successful logout alert
 * @param {*} resetPasswordSuccessAlert state for showing the successful reset password alert
 * @param {*} history history object
 * @param {*} user user (if logged in)
 * @param {*} confirmEmailSuccess state for showing the success email confirmation alert
 * @param {*} confirmEmailFail state for showing the failed email confirmation alert
 * @returns login view
 */
const Login = ({
  setUser,
  registrationSuccessAlert,
  logoutSuccessAlert,
  resetPasswordSuccessAlert,
  history,
  user,
  confirmEmailSuccess,
  confirmEmailFail,
}: LoginProps) => {
  const [email, setEmail] = useState(''); //state containing the email from the email input field
  const [password, setPassword] = useState(''); //state containing the password from the password email input field
  const [errorAlert, setErrorAlert] = useState(false); //state for showing an error alert notifying the user that login failed
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState(''); //state containing the forgot password email
  const [showForgotPassword, setShowForgotPassword] = useState(false); //state for showing the forgot password popup
  const [invalidEmailAlert, setInvalidEmailAlert] = useState(false); //state for showing an alert notifying the user that the email is invalid (forgot password)
  const [passwordResetSubmitSuccess, setPasswordResetSubmitSuccess] =
    useState(false); //state for showing an alert notifying the user that the reset password request was successful
  const [passwordResetSubmitFailed, setPasswordResetSubmitFailed] =
    useState(false); //state for showing an alert notifying the user that the reset password request failed
  const [showView, setShowView] = useState(-1); //state for showing the loading screen or the login screen
  //const [recaptchaNotClicked, setRecaptchaNotClicked] = useState(false); //state for showing an alert notifying the user to pass the reCaptcha (for login)
  const [recaptchaNotClicked2, setRecaptchaNotClicked2] = useState(false); //state for showing an alert notifying the user to pass the reCaptcha (for requesting password reset)
  const [recaptchaNotClicked, setRecaptchaNotClicked] = useState(false);
  const [loginAttempts, setLoginAttempts] = useState(0);
  const recaptchaRef = useRef<any>();
  const recaptchaRef2 = useRef<any>(); //for requesting a password reset
  const { t } = useTranslation('common', {
    i18n: i18N,
  });

  /**
   * Helper function for submitting the login form to the backend. If it's successful,
   * it will set the user to the user state. If it fails, it will display and error.
   *
   * NOTE! The function DOES NOT redirect anywhere when successful, it only updates the
   * user state in App.js. When the user state is updated, the useEffect() below
   * handles the redirection (the reasoning can be found from the useEffect() comments)
   * @param {*} event event (default action prevented)
   */
  const handleSubmit = async (event) => {
    event.preventDefault(); //Prevent the default action of the event

    toggleAlertsOff([setRecaptchaNotClicked]);

    let reCaptchaToken: any = null;
    if (loginAttempts >= 4) {
      reCaptchaToken = await recaptchaRef.current.getValue();
      if (reCaptchaToken === '') {
        timer(setRecaptchaNotClicked);
        return;
      }
    }

    const data = { email, password, reCaptchaToken }; //Data object containing the email and password
    //Call the service function login with the data object and store the result
    const res = await login(data);

    if (res.success) {
      setUser(res.data);
    }

    //call BE for active Transactions to do a check of the routes after login.
    const activeTrans = await getMyTransactionsActive();
    if (activeTrans.success) {
      //setActiveTransaction(activeTrans.data);
      if (activeTrans.data.length === 0) {
        history.push('/startNewTransaction');
      } else {
        <></>;
      }
    }

    //The submission was successful,
    //set the user state to contain the data from the backend
    else {
      logger(res.data);
      //Display an error alert for 6 seconds
      timer(setErrorAlert);

      //Messy pseduo-fix for not having recaptcha
      const temp = loginAttempts;
      setLoginAttempts(temp + 1);
    }
  };

  const validateForgotPassword = (reCaptchaToken) => {
    let success = true;

    let re =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(forgotPasswordEmail)) {
      //If the email is not valid according to the regular expression above, show an alert notifying the user of this and stop here
      timer(setInvalidEmailAlert);
      success = false;
    }

    if (reCaptchaToken === '') {
      setRecaptchaNotClicked2(true);
      return;
    }

    return success;
  };

  /**
   * Helper function for submitting the forgot password form to the backend. Initially, the function
   * toggles alerts off and the validates the email field. If the email is invalid, show an alert
   * notifying the user of this. Otherwise, try to send the data to the backend, and show a success
   * alert if the request was successful (a fail alert otherwise)
   */
  const handleForgotPasswordSubmit = async () => {
    //Toggle all the alerts off
    toggleAlertsOff([
      setInvalidEmailAlert,
      setPasswordResetSubmitSuccess,
      setPasswordResetSubmitFailed,
      setRecaptchaNotClicked2,
    ]);

    const reCaptchaToken = await recaptchaRef2.current.getValue();

    if (!validateForgotPassword(reCaptchaToken)) {
      return;
    }

    //Data to be sent to the backend
    const data = {
      email: forgotPasswordEmail,
      reCaptchaToken: reCaptchaToken,
    };

    //Send the reset password request to the backend
    const res = await resetPasswordRequest(data);

    //The request was successful, set the emailSentTo state to contain the email the mail was sent to
    //and show an alert notiyfing the user that the request was successful. The reason we store the
    //email the mail was sent to in another state is so that the text in the alert doesn't change when
    //the user changes the email field (updating email field --> updates email state --> updates alert)
    if (res.success) {
      timer(setPasswordResetSubmitSuccess);
      setShowForgotPassword(false);
    } else {
      logger(res.data);

      //Show an alert notifying the user that the submission failed
      timer(setPasswordResetSubmitFailed, 6000);
    }
  };

  /**
   * useEffect that handles if the login page should be shown (for non-logged in users) or redirection
   * should happen (for logged in users). Two scenarios need to be checked:
   * 1) If a non-logged in user tries to access any other path than /login (e.g. /area), they will
   *    be redirected to the login page from App.js. The history object will then contain a state
   *    "from" that tells the application what path they tried accessing. Upon successful login,
   *    the useEffect() is run again still containing the history state "from". Now the user state is defined,
   *    and the user will be redirected to the path they tried accessing. NOTE! if the path the user tried accessing
   *    is /login, the user will be redirected to the root page. The scenario when the path the user tried accessing
   *    is /login is when they a) were not logged in and b) tried accessing /login instantly from the browser and
   *    c) successfully logging in.
   * 2) If a user tries to access the path /login directly from the browser, they are redirected to the home page if they
   *    are logged in. If they're not logged in, the login page will be shown.
   */
  useEffect(() => {
    //Check if history contains a state
    if (history.location.state) {
      if (history.location.state.from) {
        //Check if the user is logged in. This seems odd to check, since if they were redirected to the login page
        //they cannot be logged in right? This check is here since when the user successfully logs in, the useEffect()
        //is run again. So if the history object contained a state, and the user logged in, this check passes.
        if (user) {
          //Check the pathname from the history object's state. If the pathname is /login (which happens e.g. when the
          //user accessed /login in the browser when not logged in and then logged in), redirect to the root page.
          //Otherwise, redirect to the path they tried accessing.
          history.replace(
            history.location.state.from.pathname === '/login'
              ? '/'
              : history.location.state.from.pathname
          );
          //Remove the state from history. Window.history is used to not trigger a rerender.
          //window.history.replaceState({}, document.title);
        }
        //If history object did not contain a state "from" i.e. redirected and not logged in, show the login page.
        else setShowView(1);
      }
    }
    //History did not contain a state i.e. the user tried accessing /login directly from the browser.
    else {
      //If the user is logged in, redirect to the home page, otherwise show the login page.
      user ? history.push('/') : setShowView(1);
    }

    return () => {
      window.history.replaceState({}, document.title);
    };
  }, [user, history]);

  /**
   * Return either a loading screen (showView === -1) or the LoginForm (showView !== -1).
   */
  return (
    <StyledLogin className="top-level-component">
      {showView === -1 ? (
        <>
          <h2 className="align-self-center">{t('global.view.loading')}</h2>
        </>
      ) : (
        <LoginForm
          handleSubmit={handleSubmit}
          email={email}
          setEmail={setEmail}
          setPassword={setPassword}
          password={password}
          registrationSuccessAlert={registrationSuccessAlert}
          logoutSuccessAlert={logoutSuccessAlert}
          errorAlert={errorAlert}
          resetPasswordSuccessAlert={resetPasswordSuccessAlert}
          showForgotPassword={showForgotPassword}
          setShowForgotPassword={setShowForgotPassword}
          forgotPasswordEmail={forgotPasswordEmail}
          setForgotPasswordEmail={setForgotPasswordEmail}
          handleForgotPasswordSubmit={handleForgotPasswordSubmit}
          invalidEmailAlert={invalidEmailAlert}
          passwordResetSubmitSuccess={passwordResetSubmitSuccess}
          passwordResetSubmitFailed={passwordResetSubmitFailed}
          history={history}
          setPasswordResetSubmitSuccess={setPasswordResetSubmitSuccess}
          confirmEmailSuccess={confirmEmailSuccess}
          confirmEmailFail={confirmEmailFail}
          recaptchaRef={recaptchaRef}
          recaptchaNotClicked={recaptchaNotClicked}
          recaptchaRef2={recaptchaRef2}
          recaptchaNotClicked2={recaptchaNotClicked2}
          loginAttempts={loginAttempts}
        />
      )}
    </StyledLogin>
  );
};

export default Login;
