import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Table,
  Alert,
  OverlayTrigger,
  Tooltip,
  Button,
  Modal,
  InputGroup,
  FormControl,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import {
  checkStillValidTag,
  handleDisableOrEnable,
  handleTagDelete,
  handleTagEdit,
  toggleEdit,
  generateTagIdWithCp,
  toggleEditOverload,
} from "./UserSettingsTagsUtils";
import infoIcon from "../../../../resources/infoIcon.svg";
import editIcon from "../../../../resources/editIcon.svg";
import refreshIconAnimated from "../../../../resources/refreshIconAnimated.gif";
import checkmarkIcon from "../../../../resources/checkmarkIcon.svg";
import Tag from "../../../../model/Classes/Tag";
import { Nullable, StateHandler } from "../../../../model/Utilities/Types";
import { ChargepointClass, TransactionCp } from "../../../../model/Classes/Chargepoint";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import ChargepointDropdown from "../../../Level1/Chargepoint/LegacyChargepointDropdown";
import { logger } from "../../../../utils/logger";
import { getChargepointIsConnected } from "../../../../services/chargepoint/getChargepointIsConnected";
import { timer } from "../../../../utils/timer";
import { getRecentChargepoints as getRecentCp } from "../../../../services/transactions/startNewTransaction";

declare interface EditTagModalProps {
  allTags: Tag[];
  editMode: boolean;
  setEditMode: StateHandler<boolean>;
  tagToEdit: Nullable<Tag>;
  setTagToEdit: StateHandler<Nullable<Tag>>;
  setEditNickname: StateHandler<string>;
  setEditValidUntilDate: StateHandler<string>;
  setEditValidUntilTime: StateHandler<string>;
  editNickname: string;
  editValidUntilDate: string;
  editValidUntilTime: string;
  setSuccessfullEditAlert: StateHandler<boolean>;
  setFailedEditAlert: StateHandler<boolean>;
  setAllTags: StateHandler<Tag[]>;
  setShowView: StateHandler<number>;
  noEmptyEditNicknameAlert: boolean;
  invalidEditNicknameAlert: boolean;
  setNoEmptyEditNicknameAlert: StateHandler<boolean>;
  setInvalidEditNicknameAlert: StateHandler<boolean>;
  editNicknameAlreadyExistsAlert: boolean;
  setEditNicknameAlreadyExistsAlert: StateHandler<boolean>;
}

/**
 * Component responsible for rendering the edit popup
 * @param {*} editMode state for displaying the edit mode for a specific tag
 * @param {*} tagToEdit state for the tag to be editted
 * @param {*} editNickname state for the nickname of the tag to be editted
 * @param {*} editValidUntilDate state for the valid until date for the tag to be editted
 * @param {*} editValidUntilTime state for the valid until time for the tag to be editted
 * @param {*} setEditMode state handler for the editMode state
 * @param {*} setTagToEdit state handler for the tagToEdit state
 * @param {*} setEditNickname state handler for the editNickname state
 * @param {*} setEditValidUntilDate state handler for the editValidUntilDate state
 * @param {*} setEditValidUntilTime state handler for the editValidUntilDate state
 * @param {*} setSuccessfullEditAlert state handler for showing an alert notifying the user that editting a tag was successful
 * @param {*} setFailedEditAlert state handler for showing an alert notifying the user that editting a tag failed
 * @param {*} setAllTags state handler for all the tags the user owns
 * @param {*} setShowView state handler for showing the view
 */
/** */
const EditTagModal = ({
  allTags,
  editMode,
  setEditMode,
  tagToEdit,
  setTagToEdit,
  setEditNickname,
  setEditValidUntilDate,
  setEditValidUntilTime,
  editNickname,
  editValidUntilDate,
  editValidUntilTime,
  setSuccessfullEditAlert,
  setFailedEditAlert,
  setAllTags,
  setShowView,
  noEmptyEditNicknameAlert,
  invalidEditNicknameAlert,
  setNoEmptyEditNicknameAlert,
  setInvalidEditNicknameAlert,
  editNicknameAlreadyExistsAlert,
  setEditNicknameAlreadyExistsAlert,
}: EditTagModalProps) => {
  const { t } = useTranslation("common", { i18n: i18n });
  return (
    <Row>
      {tagToEdit !== null && (
        <Modal
          show={editMode}
          onHide={() =>
            toggleEdit(
              null,
              setTagToEdit,
              setEditNickname,
              setEditValidUntilDate,
              setEditValidUntilTime,
              setEditMode,
              editMode,
              setNoEmptyEditNicknameAlert,
              setInvalidEditNicknameAlert,
              setEditNicknameAlreadyExistsAlert
            )
          }
          keyboard={false} //doesn't allow the user to close the modal by using the keyboard
        >
          <Modal.Header>
            <Modal.Title>
              {t("components.userSettings.tabs.tags.form.edit.editTag", {
                tag: tagToEdit.nickname,
              })}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/*Render the input field for the nickname and valid until field*/}
            <InputGroup className="mb-3">
              <InputGroup.Text id="editTagNickname">{t("components.userSettings.tabs.tags.form.nick")}</InputGroup.Text>
              <FormControl
                type="text"
                value={editNickname}
                aria-label="Edit tag nickname"
                aria-describedby="Edit tag nickname"
                onChange={(event) => setEditNickname(event.target.value)}
              />
            </InputGroup>
            {noEmptyEditNicknameAlert && <Alert variant="danger">{t("global.alert.failure.tagNicknameSpec")}</Alert>}
            {invalidEditNicknameAlert && <Alert variant="danger">{t("global.alert.failure.tagNicknameInvalid")}</Alert>}
            {editNicknameAlreadyExistsAlert && (
              <Alert variant="danger">{t("global.alert.failure.tagNicknameExists")}</Alert>
            )}
            <InputGroup className="mb-3">
              <p style={{ marginBottom: 5 }}>
                <strong>{t("components.userSettings.tabs.tags.table.valid")}</strong> (
                {t("components.userSettings.tabs.tags.notes.validEmpty")})
              </p>
              <FormControl
                type="date" //Make it a html date input field
                value={editValidUntilDate}
                onChange={(event) => setEditValidUntilDate(event.target.value)}
              />
              <FormControl
                type="time" //Make it a html date input field
                value={editValidUntilTime}
                onChange={(event) => setEditValidUntilTime(event.target.value)}
              />
            </InputGroup>
          </Modal.Body>
          <Modal.Footer>
            {/*Buttons for the submit and cancel buttons*/}
            <Button
              variant="primary"
              onClick={() =>
                handleTagEdit(
                  allTags,
                  editValidUntilDate,
                  editValidUntilTime,
                  tagToEdit,
                  editNickname,
                  setSuccessfullEditAlert,
                  setFailedEditAlert,
                  setEditMode,
                  setAllTags,
                  setShowView,
                  setNoEmptyEditNicknameAlert,
                  setInvalidEditNicknameAlert,
                  setEditNicknameAlreadyExistsAlert
                )
              }
            >
              {t("global.buttons.submit")}
            </Button>
            <Button
              variant="secondary"
              onClick={() =>
                toggleEdit(
                  null,
                  setTagToEdit,
                  setEditNickname,
                  setEditValidUntilDate,
                  setEditValidUntilTime,
                  setEditMode,
                  editMode,
                  setNoEmptyEditNicknameAlert,
                  setInvalidEditNicknameAlert,
                  setEditNicknameAlreadyExistsAlert
                )
              }
            >
              {t("global.buttons.cancel")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Row>
  );
};

/**
 * Component responsible for showing the Tags header as well as some alerts
 * @param {*} successfullEditAlert state for showing an alert notifying the user that editting a tag was successful
 * @param {*} failedEditAlert state handler for showing an alert notifying the user that editting a tag failed
 * @param {*} setSuccessfullDeleteAlert state for showing an alert notifying the user that deleting a tag was successful
 * @param {*} failedDeleteAlert state for showing an alert notifying the user that deleting a tag failed
 * @returns
 */
/** */
const TagsHeaderAndAlerts = ({
  successfullEditAlert,
  failedEditAlert,
  successfullDeleteAlert,
  failedDeleteAlert,
}: any) => {
  const { t } = useTranslation("common", { i18n: i18n });
  return (
    <Row>
      <h5>{t("components.userSettings.tabs.tags.title")}</h5>
      {successfullEditAlert && ( //Show a success alert if a tag was successfully edited
        <Col sm="auto" xs="auto">
          <Alert key="successfullEdit" variant="success">
            {t("global.alert.success.tagEdit")}
          </Alert>
        </Col>
      )}
      {failedEditAlert && ( //Show an error alert if editting a tag failed
        <Col sm="auto" xs="auto">
          <Alert key="failedEdit" variant="danger">
            {t("global.alert.failure.tagEdit")}
          </Alert>
        </Col>
      )}
      {successfullDeleteAlert && ( //Show a success alert if a tag was successfully deleted
        <Col sm="auto" xs="auto">
          <Alert key="successfullDelete" variant="success">
            {t("global.alert.success.tagDelete")}
          </Alert>
        </Col>
      )}
      {failedDeleteAlert && ( //Show an error alert if deleting a tag failed
        <Col sm="auto" xs="auto">
          <Alert key="failedDelete" variant="danger">
            {t("global.alert.failure.tagDelete")}
          </Alert>
        </Col>
      )}
    </Row>
  );
};

type TableForTagsProps = {
  allTags: Tag[];
  editMode: boolean;
  setAllTags: StateHandler<Tag[]>;
  setEditMode: StateHandler<boolean>;
  setTagToEdit: StateHandler<Nullable<Tag>>;
  setEditNickname: StateHandler<string>;
  setEditValidUntilDate: StateHandler<string>;
  setEditValidUntilTime: StateHandler<string>;
  setSuccessfullEnableAlert: StateHandler<boolean>;
  setSuccessfullDisableAlert: StateHandler<boolean>;
  setErrorEnableAlert: StateHandler<boolean>;
  setErrorDisableAlert: StateHandler<boolean>;
  setSuccessfullDeleteAlert: StateHandler<boolean>;
  setFailedDeleteAlert: StateHandler<boolean>;
  setShowView: StateHandler<number>;
  userSystemTime: string;
};

/**
 * Component responsible for rendering the actual table with all the tags
 * @param {*} allTags state for all the tags the user owns
 * @param {*} editMode state for displaying the edit mode for a specific tag
 * @param {*} setAllTags state handler for all the tags the user owns
 * @param {*} setEditMode state handler for the editMode state
 * @param {*} setTagToEdit state handler for the tag to be editted
 * @param {*} setEditNickname state handler for the nickname of the tag to be editted
 * @param {*} setEditValidUntilDate state handler for the valid until date for the tag to be editted
 * @param {*} setEditValidUntilTime state handler for the valid until time for the tag to be editted
 * @param {*} setSuccessfullEnableAlert state handler for showing an alert notifying the user that enabling a tag was successful
 * @param {*} setSuccessfullDisableAlert state handler for showing an alert notifying the user that disabling a tag was successful
 * @param {*} setErrorEnableAlert state handler for showing an alert notifying the user that enabling a tag failed
 * @param {*} setErrorDisableAlert state handler for showing an alert notifying the user that disabling a tag failed
 * @param {*} setSuccessfullDeleteAlert state handler for showing an alert notifying the user that deleting a tag was successful
 * @param {*} setFailedDeleteAlert state handler for showing an alert notifying the user that deleting a tag failed
 * @param {*} setShowView state handler for showing the view
 */
const TableForTags = ({
  allTags,
  editMode,
  setAllTags,
  setEditMode,
  setTagToEdit,
  setEditNickname,
  setEditValidUntilDate,
  setEditValidUntilTime,
  setSuccessfullEnableAlert,
  setSuccessfullDisableAlert,
  setErrorEnableAlert,
  setErrorDisableAlert,
  setSuccessfullDeleteAlert,
  setFailedDeleteAlert,
  setShowView,
  userSystemTime,
}: TableForTagsProps) => {
  const { t } = useTranslation("common", { i18n: i18n });
  //timezone converter function
  const convertToTimeZone = (inputDate: Date | undefined, targetTimeZone: string): string => {
    const formattedDate = new Intl.DateTimeFormat("en-FI", {
      timeZone: targetTimeZone,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    }).format(inputDate);

    return formattedDate;
  };

  return (
    <Row>
      <Table striped bordered hover responsive="xl">
        {/*Headers for the table*/}
        <thead>
          <tr>
            <th>{t("components.userSettings.tabs.tags.table.id")}</th>
            <th>{t("components.userSettings.tabs.tags.table.valid")}</th>
            <th>{t("components.userSettings.tabs.tags.table.active")}</th>
            <th>
              {/*Create an info icon next to the Delete header containing information
                 on when a user can delete a tag. This is done by using the 
                 OverlayTrigger and Tooltip components*/}
              <OverlayTrigger
                placement="left"
                overlay={<Tooltip id="delete-tooltip">{t("components.userSettings.tabs.tags.table.tooltip")}</Tooltip>}
              >
                <img className="infoIcon" src={infoIcon} alt="info" />
              </OverlayTrigger>
              {t("components.userSettings.tabs.tags.table.deleteCan")}
            </th>
          </tr>
        </thead>
        {/*Data rows for the table*/}
        <tbody>
          {/*Map all the tags to a table row*/}
          {allTags.map((tag: Tag) => {
            let tagValue = tag.valid_until;
            let tagValue_Date: Date | null = null;
            if (tagValue !== undefined && tagValue !== null) {
              tagValue_Date = new Date(tagValue);
              let convertedDateVal = convertToTimeZone(tagValue_Date, userSystemTime);
            }

            /**   if (
              userSystemTime !== '' &&
              tag.valid_until !== null &&
              validUntil_user
            ) {
              const convertedDate = convertToTimeZone(
                tag.valid_until,
                userSystemTime
              );
              
            } else {
              <></>;
            }
 */
            return (
              //Return a new table row containing the data for this tag
              <tr key={tag.id_tag}>
                <td>
                  {/*ID column is displayed in the form "nickname (id)" if the tag has a nickname,
                     or "id" otherwise. Additionally, render an edit icon wrapped in a button*/}
                  {`${tag.nickname}`}
                  {tag.nickname !== "App" ? (
                    <Button variant="outline-ligth">
                      <img
                        src={editIcon}
                        alt="Edit icon"
                        onClick={() =>
                          toggleEditOverload(
                            tag,
                            setTagToEdit,
                            setEditNickname,
                            setEditValidUntilDate,
                            setEditValidUntilTime,
                            setEditMode,
                            editMode
                          )
                        }
                      />
                    </Button>
                  ) : (
                    ""
                  )}
                </td>
                {/*If the tag has a valid_until property, check if it's expired. Otherwise, render
                   a "Indefinitely" text*/}
                <td>
                  {tag.valid_until ? checkStillValidTag(tag.valid_until) : t("components.userSettings.tabs.tags.indef")}
                </td>
                {/*Column for activating and disabling the tag. E.g. if the tag is active, the 
                   active button will be filled in (i.e. not outlined) and disabled, and the 
                   disabled button will be outlined and active (vice versa if the tag is disabled)*/}
                <td>
                  <Button
                    variant={`${!!tag.disabled ? "outline-success" : "success"}`}
                    onClick={() =>
                      handleDisableOrEnable(
                        tag,
                        1,
                        setSuccessfullEnableAlert,
                        setSuccessfullDisableAlert,
                        setErrorEnableAlert,
                        setErrorDisableAlert,
                        setAllTags,
                        setShowView
                      )
                    }
                    disabled={!!!tag.disabled}
                  >
                    {t("components.userSettings.tabs.tags.table.buttons.active")}
                  </Button>
                  <Button
                    variant={`${!tag.disabled ? "outline-danger" : "danger"}`}
                    onClick={() =>
                      handleDisableOrEnable(
                        tag,
                        0,
                        setSuccessfullEnableAlert,
                        setSuccessfullDisableAlert,
                        setErrorEnableAlert,
                        setErrorDisableAlert,
                        setAllTags,
                        setShowView
                      )
                    }
                    disabled={!!tag.disabled}
                  >
                    {t("components.userSettings.tabs.tags.table.buttons.disabled")}
                  </Button>
                </td>
                <td>
                  {/*If the tag doesn't have any transactions linked to it, the tag can be deleted.
                     Otherwise, render a "Cannot delete" text for this column.
                     Also can't delete the default tag 'App'*/}
                  {!(tag.has_start_transaction || tag.nickname === "App") ? (
                    <Button
                      variant="danger"
                      onClick={() =>
                        handleTagDelete(tag, setSuccessfullDeleteAlert, setFailedDeleteAlert, setAllTags, setShowView)
                      }
                    >
                      {t("components.userSettings.tabs.tags.table.deleteCan")}
                    </Button>
                  ) : (
                    t("components.userSettings.tabs.tags.table.deleteCant")
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Row>
  );
};

type TagsTableProps = {
  allTags: Tag[];
  setAllTags: StateHandler<Tag[]>;
  setShowView: StateHandler<number>;
  noEmptyEditNicknameAlert: boolean;
  setNoEmptyEditNicknameAlert: StateHandler<boolean>;
  invalidEditNicknameAlert: boolean;
  setInvalidEditNicknameAlert: StateHandler<boolean>;
  editNicknameAlreadyExistsAlert: boolean;
  setEditNicknameAlreadyExistsAlert: StateHandler<boolean>;
  successfullDeleteAlert: boolean;
  setSuccessfullDeleteAlert: StateHandler<boolean>;
  failedDeleteAlert: boolean;
  setFailedDeleteAlert: StateHandler<boolean>;
};
/**
 * Component responsible for rendering everything related to the tags table (table, popup, alerts)
 *
 * @param allTags state for all the tags the user owns
 * @param setAllTags state handler for allTags
 * @param setShowView state handler for showing the view
 */
export const TagsTable = ({
  allTags,
  setAllTags,
  setShowView,
  noEmptyEditNicknameAlert,
  setNoEmptyEditNicknameAlert,
  invalidEditNicknameAlert,
  setInvalidEditNicknameAlert,
  editNicknameAlreadyExistsAlert,
  setEditNicknameAlreadyExistsAlert,
  successfullDeleteAlert,
  setSuccessfullDeleteAlert,
  failedDeleteAlert,
  setFailedDeleteAlert,
}: TagsTableProps) => {
  const [editMode, setEditMode] = useState(false); //state for displaying the edit mode for a specific tag
  const [tagToEdit, setTagToEdit] = useState<Nullable<Tag>>(null); //state for the tag to be editted
  const [editNickname, setEditNickname] = useState(""); //state for the nickname of the tag to be editted
  const [editValidUntilDate, setEditValidUntilDate] = useState(""); //state for the valid until date for the tag to be editted
  const [editValidUntilTime, setEditValidUntilTime] = useState(""); //state for the valid until time for the tag to be editted
  const [successfullEditAlert, setSuccessfullEditAlert] = useState(false); //state for showing an alert notifying the user that editting a tag was successful
  const [failedEditAlert, setFailedEditAlert] = useState(false); //state for showing an alert notifying the user that editting a tag failed
  const [successFullEnableAlert, setSuccessfullEnableAlert] = useState(false); //state for showing an alert notifying the user that enabling a tag was successful
  const [errorEnableAlert, setErrorEnableAlert] = useState(false); //state for showing an alert notifying the user that enabling a tag failed
  const [successFullDisableAlert, setSuccessfullDisableAlert] = useState(false); //state for showing an alert notifying the user that disabling a tag was successful
  const [errorDisableAlert, setErrorDisableAlert] = useState(false); //state for showing an alert notifying the user that disabling a tag failed
  const { t } = useTranslation("common", { i18n: i18n });
  //state for timezone
  const [userSystemTime, setUserSystemTime] = useState("Europe/Helsinki"); // state for the user timezone Initial value set as Europe/Helsinki
  // getting user timezone from teh system
  useEffect(() => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setUserSystemTime(userTimezone);
  }, []);
  return allTags.length > 0 ? ( //Check if the user has any tags
    //The user has tags
    <>
      {/*Row for the Tags table header and possible alerts*/}
      <TagsHeaderAndAlerts
        successfullEditAlert={successfullEditAlert}
        failedEditAlert={failedEditAlert}
        successfullDeleteAlert={successfullDeleteAlert}
        failedDeleteAlert={failedDeleteAlert}
      />
      {/*Row containing the table for the users tags*/}
      <TableForTags
        allTags={allTags}
        setTagToEdit={setTagToEdit}
        setEditNickname={setEditNickname}
        setEditValidUntilDate={setEditValidUntilDate}
        setEditValidUntilTime={setEditValidUntilTime}
        setEditMode={setEditMode}
        editMode={editMode}
        setSuccessfullEnableAlert={setSuccessfullEnableAlert}
        setSuccessfullDisableAlert={setSuccessfullDisableAlert}
        setErrorEnableAlert={setErrorEnableAlert}
        setErrorDisableAlert={setErrorDisableAlert}
        setAllTags={setAllTags}
        setSuccessfullDeleteAlert={setSuccessfullDeleteAlert}
        setFailedDeleteAlert={setFailedDeleteAlert}
        setShowView={setShowView}
        userSystemTime={userSystemTime}
      />
      {successFullEnableAlert && ( //Show a success alert if enabling a tag was successful
        <Col sm="auto" xs="auto">
          <Alert key="successfullEnable" variant="success">
            {t("global.alert.success.tagEnable")}
          </Alert>
        </Col>
      )}
      {errorEnableAlert && ( //Show an error alert if enabling a tag was failed
        <Col sm="auto" xs="auto">
          <Alert key="errorEnable" variant="danger">
            {t("global.alert.failure.tagEnable")}
          </Alert>
        </Col>
      )}
      {successFullDisableAlert && ( //Show a success alert if disabling a tag was successful
        <Col sm="auto" xs="auto">
          <Alert key="successfullDisable" variant="success">
            {t("global.alert.success.tagDisable")}
          </Alert>
        </Col>
      )}
      {errorDisableAlert && ( //Show an error alert if disabling a tag was failed
        <Col sm="auto" xs="auto">
          <Alert key="errorDisable" variant="danger">
            {t("global.alert.failure.tagDisable")}
          </Alert>
        </Col>
      )}
      {/*Row containing the popup (Modal component) for editting a tag*/}
      <EditTagModal
        allTags={allTags}
        editMode={editMode}
        setTagToEdit={setTagToEdit}
        tagToEdit={tagToEdit}
        setEditNickname={setEditNickname}
        setEditValidUntilDate={setEditValidUntilDate}
        setEditValidUntilTime={setEditValidUntilTime}
        setEditMode={setEditMode}
        editNickname={editNickname}
        editValidUntilDate={editValidUntilDate}
        editValidUntilTime={editValidUntilTime}
        setSuccessfullEditAlert={setSuccessfullEditAlert}
        setFailedEditAlert={setFailedEditAlert}
        setAllTags={setAllTags}
        setShowView={setShowView}
        noEmptyEditNicknameAlert={noEmptyEditNicknameAlert}
        setNoEmptyEditNicknameAlert={setNoEmptyEditNicknameAlert}
        invalidEditNicknameAlert={invalidEditNicknameAlert}
        setInvalidEditNicknameAlert={setInvalidEditNicknameAlert}
        editNicknameAlreadyExistsAlert={editNicknameAlreadyExistsAlert}
        setEditNicknameAlreadyExistsAlert={setEditNicknameAlreadyExistsAlert}
      />
    </>
  ) : (
    <>
      {/*The user doesn't have any tags, render a row with a header*/}
      <Row className="mb-3">
        <h4>{t("components.userSettings.tabs.tags.noTags")}</h4>
      </Row>
    </>
  );
};

/**
 * Component responsible for rendering the add tag header
 */
const AddTagHeaderAndTooltip = () => {
  const { t } = useTranslation("common", { i18n: i18n });
  return (
    <Row>
      <h5>
        {t("components.userSettings.tabs.tags.form.add.addWithCp")}
        {
          //Create an info icon next to the "Add new tag" header containing information
          //about how to create a tag
        }
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id="addTagTooltip">
              {
                //Render som information text to the user
              }
              <p style={{ marginBottom: 0 }}>
                {t("components.userSettings.tabs.tags.form.add.selectCp")}
                <br />
                {t("components.userSettings.tabs.tags.form.add.showTag")}
              </p>
            </Tooltip>
          }
        >
          <img className="infoIcon" src={infoIcon} alt="info" />
        </OverlayTrigger>
      </h5>
    </Row>
  );
};

declare interface TagIdFieldAndButtonsProps {
  cpId: string;
  allChargepoints: TransactionCp[];
  allChargepointsReal: TransactionCp[];
  backendScanning: boolean;
  backendScanningComplete: boolean;
  setBackendScanning: StateHandler<boolean>;
  setBackendScanningComplete: StateHandler<boolean>;
  disabledFields: boolean;
  setNewTagId: StateHandler<string>;
  setCpId: StateHandler<string>;
  failedAddAlert: boolean;
  invalidLengthTagIdAlert: boolean;
  successfulScan: boolean;
  setSuccessfulScan: StateHandler<boolean>;
  cpIdEmptyAlert: boolean;
  setCpIdEmptyAlert: StateHandler<boolean>;
  recentCp?: TransactionCp[];
  cpLabel: string;
}
/**
 * Component responsible for rendering the tag id field as well as the buttons
 * for the different options
 * @param {*} newTagId state for the new tag id field
 * @param {*} cpId state for the chargepoint id field (if the user wants to add a tag using a chargepoint)
 * @param {*} allChargepoints state for all the chargepoints the user owns
 * @param {*} backendScanning state for knowing if the backend is scanning for a tag using a chargepoint
 * @param {*} setNewTagId state handler for the newTagId state
 * @param {*} setCpId state handler for the cpId state
 * @param {*} cpIdEmptyAlert state for showing an alert notifying the user to specify a chargepoint id
 * @param {*} emptyTagIdAlert state for showing an alert notifying the user that the tag id field for a new tag cannot be empty
 * @param {*} notHexTagIdAlert state for showing an alert notifying the user that the tag id field must contain a hexadecimal string
 * @param {*} invalidLengthTagIdAlert state for showing an alert notifying the user that the tag id field is of invalid length
 * @param {*} noOptionCheckedAlert state for showing an alert notifying the user to specify what mode of tag id to insert
 */
/** */
const TagIdFieldAndButtons = ({
  cpId,
  allChargepoints,
  allChargepointsReal,
  backendScanning,
  backendScanningComplete,
  setBackendScanning,
  setBackendScanningComplete,
  disabledFields,
  setNewTagId,
  setCpId,
  failedAddAlert,
  invalidLengthTagIdAlert,
  successfulScan,
  setSuccessfulScan,
  cpIdEmptyAlert,
  setCpIdEmptyAlert,
  cpLabel,
}: TagIdFieldAndButtonsProps) => {
  const [cpNotConnectedAlert, setCpNotConnectedAlert] = useState(false);
  const [failedScanAlert, setFailedScanAlert] = useState(false);
  const [cpDisabled, setCpDisabled] = useState(false);
  const { t } = useTranslation("common", { i18n: i18n });
  const [cpErrorMessage, setCpErrorMessage] = useState("");
  const [errorCp, setErrorCp] = useState("");
  const [tagExistsAlert, setTagExistsAlert] = useState(false);
  const [recentCp, setRecentCp] = useState([]);
  const selectedCp = allChargepointsReal.find((cp) => {
    return cp.charge_point_id === cpId;
  });
  /**
   * Modular function that calls the specified service function with optional data and stateHandlers
   * @param {*} generateFunc the service function responsible for calling the backend
   * @param {*} data data to be sent to the backend, default is null
   * @param {*} stateHandlers stateful setters
   */
  const handleGenerateTagID = async (
    generateFunc: any,
    data: { charge_point_id: string },
    setBackendScanning: StateHandler<boolean>,
    setBackendScanningComplete: StateHandler<boolean>,
    setFailedAddAlert: StateHandler<boolean>,
    setTagExistsAlert: StateHandler<boolean>
  ) => {
    const connectedChargepoints = await getChargepointIsConnected(data.charge_point_id);

    //Successful return
    if (connectedChargepoints.success) {
      setErrorCp("");
      //If cp is connected
      if (connectedChargepoints.data.connected !== 1) {
        setCpErrorMessage(t("global.alert.failure.cpNotConnected"));
        timer(setCpNotConnectedAlert);
        return;
      } else {
        setCpNotConnectedAlert(false);
      }
    } else {
      //Log error in dev, display different message
      logger(connectedChargepoints.data);
      setErrorCp(`(${data.charge_point_id})`);
      setCpErrorMessage(t("global.alert.failure.cpNoInfo"));
      setCpId("");
      timer(setCpNotConnectedAlert);
      return;
    }

    const ret = await generateFunc(
      data,
      setBackendScanning,
      setBackendScanningComplete,
      setFailedAddAlert,
      setTagExistsAlert
    );
    if (ret.success) {
      setNewTagId(ret.data.id);
      setSuccessfulScan(true);
    }
  };

  //Get recent Cp data
  useEffect(() => {
    const getData = async () => {
      try {
        const recentCpData = await getRecentCp();

        const data = recentCpData.data;

        if (recentCpData.success) {
          setRecentCp(data);
        }
      } catch (e) {
        logger(e);
      }
    };
    getData();
  }, []);
  return (
    <>
      <Row>
        {cpDisabled && (
          <Col sm="auto" xs="auto">
            <Alert variant="danger" key="cpDisabled">
              {t("components.startTransaction.static.body.isDisabled", {
                chargepoint: cpId,
              })}
            </Alert>
          </Col>
        )}
        {cpNotConnectedAlert && (
          <Col sm="auto" xs="auto">
            <Alert variant="danger" key="cpNotConnected" style={{ marginBottom: 0, marginTop: 10 }}>
              {`${cpErrorMessage} ${errorCp}`}
            </Alert>
          </Col>
        )}
        {/* cpIdEmptyAlert &&
          !failedAddAlert &&
          !cpNotConnectedAlert && ( //Show an alert notifying the user that the chargepoint field cannot be empty
            <Col sm="auto" xs="auto">
              <Alert
                variant="warning"
                key="cpIdEmpty"
                style={{ marginBottom: 0, marginTop: 10 }}
              >
                {t('global.alert.other.specCp')}
              </Alert>
            </Col>
          ) */}

        <ChargepointDropdown
          allChargepoints={allChargepoints}
          allChargepointsReal={allChargepointsReal}
          cpId={cpId}
          setCpId={setCpId}
          setCpIdEmptyAlert={setCpIdEmptyAlert}
          cpIdEmptyAlert={cpIdEmptyAlert}
          setCpDisabled={setCpDisabled}
          recentCp={recentCp}
          formDisable={backendScanning || disabledFields || (successfulScan && !failedScanAlert)}
          cpLabel={cpLabel}
        />
        {/*Don't display both error messages at the same time*/}

        {
          <Row>
            {backendScanning && (
              <Col xs="12" sm="11">
                <Alert variant="info" className="mt-3">
                  {t("global.alert.other.showTagCp")}
                </Alert>
              </Col>
            )}
            {backendScanning ? (
              <Col sm="auto" xs="auto">
                <Button variant="outline-ligth">
                  <img src={refreshIconAnimated} alt="Loading" style={{ width: 30 }} />
                </Button>
              </Col>
            ) : backendScanningComplete ? (
              <Button variant="outline-ligth">
                <img src={checkmarkIcon} alt="Checkmark" />
              </Button>
            ) : !successfulScan && !failedAddAlert ? (
              <Button
                disabled={cpIdEmptyAlert || cpId.length === 0}
                className="mt-3 w-auto m-auto"
                onClick={() => {
                  handleGenerateTagID(
                    generateTagIdWithCp,
                    {
                      charge_point_id: cpId,
                    },
                    setBackendScanning,
                    setBackendScanningComplete,
                    setFailedScanAlert,
                    setTagExistsAlert
                  );
                }}
              >
                {t("components.userSettings.tabs.tags.form.add.scan")}
              </Button>
            ) : (
              <></>
            )}
          </Row>
        }
      </Row>
      <Row>
        {selectedCp && (
          <div style={{ textAlign: "left" }}>
            <p className="mt-3 mb-0 " style={{ fontWeight: "bold", letterSpacing: "1px" }}>
              {ChargepointClass.toString(selectedCp)}
            </p>
            {selectedCp.address ? (
              <p className="mt-0 mb-0">
                {t("components.startTransaction.view.cpAddress")}
                {`${selectedCp.address}`}
              </p>
            ) : (
              ""
            )}
            <p className="mt-0">
              {t("components.startTransaction.view.cpOwner")}
              {`${selectedCp.ownerName} (${selectedCp.business_Id})`}
            </p>
          </div>
        )}
      </Row>
      <Row>
        {failedScanAlert && (
          <Col sm="auto" xs="auto">
            <Alert variant="danger" key="cpNotConnected" style={{ marginBottom: 0, marginTop: 10 }}>
              {t("global.alert.failure.scanNoTags")}
            </Alert>
          </Col>
        )}
        <Row className="mt-3">
          {tagExistsAlert && (
            <Col sm="auto" xs="auto">
              <Alert variant="warning" key="tagExists">
                {t("global.alert.failure.tagExists")}
              </Alert>
            </Col>
          )}
        </Row>
        {failedAddAlert && ( //Show an alert notifying the user that adding a tag failed
          <Col sm="auto" xs="auto">
            <Alert variant="danger" key="failedAdd" style={{ marginBottom: 0, marginTop: 10 }}>
              {t("global.alert.failure.tagAdd")}
            </Alert>
          </Col>
        )}
        {invalidLengthTagIdAlert && ( //Show an alert notifying the user that the tag id field is of invalid length
          <Col sm="auto" xs="auto">
            <Alert variant="danger" key="shortTag" style={{ marginBottom: 0, marginTop: 10 }}>
              {t("global.alert.failure.tagIdLength")}
            </Alert>
          </Col>
        )}
      </Row>
    </>
  );
};

/**
 * Component responsible for rendering the nickname field
 * @param {*} backendScanning state for knowing if the backend is scanning for a tag using a chargepoint
 * @param {*} newTagNickname state for the new tag nickname
 * @param {*} setNewTagNickname state handler for the newTagNickname state
 */
/** */
const NicknameField = ({
  backendScanning,
  disabledFields,
  newTagNickname,
  setNewTagNickname,
  noEmptyNicknameAlert,
  invalidNicknameAlert,
  nicknameAlreadyExistsAlert,
}: any) => {
  const { t } = useTranslation("common", { i18n: i18n });
  return (
    <>
      <Row className="mb-3">
        <p style={{ marginBottom: 0 }}>{t("components.userSettings.tabs.tags.form.add.giveNick")} </p>
        {/*Simple text input for the nickname*/}
        <Col>
          <InputGroup>
            <InputGroup.Text id="tagId">{t("components.userSettings.tabs.tags.form.nick")} *</InputGroup.Text>
            <FormControl
              type="text"
              value={newTagNickname}
              disabled={backendScanning || disabledFields}
              aria-label="Tag nickname"
              aria-describedby="tag nickname"
              onChange={(event) => setNewTagNickname(event.target.value)}
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        {noEmptyNicknameAlert && (
          <Col sm="auto" xs="auto">
            <Alert variant="danger">{t("global.alert.failure.tagNicknameSpec")}</Alert>
          </Col>
        )}
        {invalidNicknameAlert && (
          <Col sm="auto" xs="auto">
            <Alert variant="danger">{t("global.alert.failure.tagNicknameInvalid")}</Alert>
          </Col>
        )}
        {nicknameAlreadyExistsAlert && (
          <Col sm="auto" xs="auto">
            <Alert variant="danger">{t("global.alert.failure.tagNicknameExists")}</Alert>
          </Col>
        )}
      </Row>
    </>
  );
};

/**
 * Component responsible for rendering the valid until field
 * @param {*} backendScanning state for knowing if the backend is scanning for a tag using a chargepoint
 * @param {*} validUntilDate state for the valid until date for the new tag
 * @param {*} validUntilTime state for the valid until time for the new tag
 * @param {*} setValidUntilDate state handler for the validUntilDate state
 * @param {*} setValidUntilTime state handler for the validUntilTime state
 */
/** */
const ValidUntilField = ({
  backendScanning,
  disabledFields,
  validUntilDate,
  validUntilTime,
  setValidUntilDate,
  setValidUntilTime,
}: any) => {
  const { t } = useTranslation("common", { i18n: i18n });
  return (
    <Row className="mb-3">
      <Col xs="10" sm="10">
        <p style={{ marginBottom: 5 }}>
          {t("components.userSettings.tabs.tags.table.valid")}{" "}
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id="addTagTooltip">
                {/*Render som information text to the user*/}
                <p style={{ marginBottom: 0 }}>{t("components.userSettings.tabs.tags.notes.validEmpty")}</p>
              </Tooltip>
            }
          >
            <img className="infoIcon" src={infoIcon} alt="info" />
          </OverlayTrigger>
        </p>
        <InputGroup>
          <FormControl
            type="date" //Make it a html date input
            disabled={backendScanning || disabledFields}
            value={validUntilDate}
            onChange={(event) => setValidUntilDate(event.target.value)}
          />
          <FormControl
            type="time" //Make it a html date input
            disabled={backendScanning || disabledFields}
            value={validUntilTime}
            onChange={(event) => setValidUntilTime(event.target.value)}
          />
        </InputGroup>
      </Col>
    </Row>
  );
};

//Array used for the active/disable radio buttons

/**
 * Component responsible for rendering the active/disable buttons
 * @param {*} backendScanning state for knowing if the backend is scanning for a tag using a chargepoint
 * @param {*} newDisabled state for knowing if the new tag will be disabled or not, initially active
 * @param {*} setNewDisabled state handler for the newDisabled state
 * @param {*} invalidActiveOrDisabledAlert state for showing an alert notifying the user to specify if the new tag will be active or disabled
 * @returns
 */
/** */
const ActiveDisableButtons = ({
  backendScanning,
  disabledFields,
  newDisabled,
  setNewDisabled,
  invalidActiveOrDisabledAlert,
}: any) => {
  const { t } = useTranslation("common", { i18n: i18n });
  const [disabledRadio] = useState([
    {
      name: t("components.userSettings.tabs.tags.table.buttons.active"),
      value: "0",
    },
    {
      name: t("components.userSettings.tabs.tags.table.buttons.disabled"),
      value: "1",
    },
  ]);

  return (
    <Row className="mb-3">
      <Col sm="auto" xs="auto">
        <ButtonGroup>
          {disabledRadio.map((radio, idx) => (
            <ToggleButton
              key={radio.name}
              id={`activeOrDisabled-radio-${idx}`}
              type="checkbox"
              variant={idx % 2 ? "outline-danger" : "outline-success"} //Active button is green, disabled is red
              name="radio"
              value={radio.value}
              disabled={backendScanning || disabledFields}
              checked={newDisabled === radio.value}
              onChange={(event) => {
                setNewDisabled(event.target.value);
              }}
            >
              {` ${radio.name}`}
            </ToggleButton>
          ))}
        </ButtonGroup>
        {invalidActiveOrDisabledAlert && ( //Show an alert notifying the user to specify if the tag is active or disabled!
          <Alert variant="danger" key="invalidDisabledOrActive" style={{ marginBottom: 0, marginTop: 10 }}>
            {t("global.alert.failure.activeDisabledSpec")}
          </Alert>
        )}
      </Col>
    </Row>
  );
};

declare interface SubmitButtonProps {
  disabledFields: boolean;
  handleTagAdd: () => Promise<boolean>;
  resetAndClose: () => void;
  setSuccessfulScan: StateHandler<boolean>;
  setCpIdEmptyAlert: StateHandler<boolean>;
}
/**
 * Component responsible for rendering the submit button
 * @param {*} backendScanning state for knowing if the backend is scanning for a tag using a chargepoint
 * @param {*} backendScanningComplete state for knowing if the backend successfully scanned for a tag using a chargepoint
 * @param {*} handleTagAdd helper function for adding a tag
 */
/** */
const SubmitButton = ({
  disabledFields,
  handleTagAdd,
  resetAndClose,
  setSuccessfulScan,
  setCpIdEmptyAlert,
}: SubmitButtonProps) => {
  const { t } = useTranslation("common", { i18n: i18n });
  const handleTurboStuff = async () => {
    const mega = await handleTagAdd();
    //If tag adding is unsuccessful, reset relevant states
    if (!mega) {
      setSuccessfulScan(mega);

      setCpIdEmptyAlert(!mega);
    }
  };

  return (
    <Row className="mb-3">
      {/*Wrap the submit button on a column since otherwise it would strech across the screen.
          Additionally, show the loading animation or checkmark icon if needed*/}
      <Col sm="auto">
        <ButtonGroup>
          <Button
            variant="primary"
            onClick={async () => {
              handleTurboStuff();
            }}
            disabled={disabledFields}
          >
            {t("global.buttons.add.default")}
          </Button>

          <Button
            onClick={() => {
              resetAndClose();
            }}
            variant="secondary"
          >
            {t("global.buttons.cancel")}
          </Button>
        </ButtonGroup>
      </Col>
    </Row>
  );
};

declare interface AddTagProps {
  newTagNickname: string;
  validUntilDate: string;
  validUntilTime: string;
  newDisabled: string;
  allChargepoints: TransactionCp[];
  allChargepointsReal: TransactionCp[];
  disabledFields: boolean;
  backendScanning: boolean;
  backendScanningComplete: boolean;
  setNewTagId: StateHandler<string>;
  setNewTagNickname: StateHandler<string>;
  setValidUntilDate: StateHandler<string>;
  setValidUntilTime: StateHandler<string>;
  setNewDisabled: StateHandler<string>;
  invalidLengthTagIdAlert: boolean;
  invalidActiveOrDisabledAlert: boolean;
  handleTagAdd: () => Promise<boolean>;
  noEmptyNicknameAlert: boolean;
  invalidNicknameAlert: boolean;
  nicknameAlreadyExistsAlert: boolean;
  cpId: string;
  setCpId: StateHandler<string>;
  setBackendScanning: StateHandler<boolean>;
  setBackendScanningComplete: StateHandler<boolean>;
  failedAddAlert: boolean;
  resetAndClose: () => void;
  cpLabel: string;
}
/**
 * Component responsible for rendering everything regarding adding a tag
 * @param {*} newTagNickname state for the new tag nickname
 * @param {*} validUntilDate state for the valid until date for the new tag
 * @param {*} validUntilTime state for the valid until time for the new tag
 * @param {*} newDisabled state for knowing if the new tag will be disabled or not, initially active
 * @param {*} cpId state for the chargepoint id field (if the user wants to add a tag using a chargepoint)
 * @param {*} allChargepoints state for all the chargepoints the user owns
 * @param {*} backendScanning state for knowing if the backend is scanning for a tag using a chargepoint
 * @param {*} backendScanningComplete state for knowing if the backend successfully scanned for a tag using a chargepoint
 * @param {*} setNewTagId state handler for the newTagId state
 * @param {*} setNewTagNickname state handler for the newTagNickname state
 * @param {*} setValidUntilDate state handler for the validUntilDate state
 * @param {*} setValidUntilTime state handler for the validUntilTime state
 * @param {*} setNewDisabled state handler for the newDisabled state
 * @param {*} setCpId state handler for the cpId state
 * @param {*} cpIdEmptyAlert state for showing an alert notifying the user to specify a chargepoint id
 * @param {*} emptyTagIdAlert state for showing an alert notifying the user that the tag id field for a new tag cannot be empty
 * @param {*} notHexTagIdAlert state for showing an alert notifying the user that the tag id field must contain a hexadecimal string
 * @param {*} invalidLengthTagIdAlert state for showing an alert notifying the user that the tag id field is of invalid length
 * @param {*} noOptionCheckedAlert state for showing an alert notifying the user to specify what mode of tag id to insert
 * @param {*} invalidActiveOrDisabledAlert state for showing an alert notifying the user to specify if the new tag will be active or disabled
 * @param {*} handleTagAdd helper function for adding a tag
 */
export const AddTag = ({
  newTagNickname,
  validUntilDate,
  validUntilTime,
  newDisabled,
  allChargepoints,
  allChargepointsReal,
  disabledFields,
  backendScanning,
  backendScanningComplete,
  setNewTagId,
  setNewTagNickname,
  setValidUntilDate,
  setValidUntilTime,
  setNewDisabled,
  invalidLengthTagIdAlert,
  invalidActiveOrDisabledAlert,
  handleTagAdd,
  noEmptyNicknameAlert,
  invalidNicknameAlert,
  nicknameAlreadyExistsAlert,
  setBackendScanning,
  setBackendScanningComplete,
  failedAddAlert,
  resetAndClose,
  cpId,
  setCpId,
  cpLabel,
}: AddTagProps) => {
  const [successfulScan, setSuccessfulScan] = useState(false);
  const [cpIdEmptyAlert, setCpIdEmptyAlert] = useState(true); //state for showing an alert notifying the user to specify a chargepoint id
  //const [cpId, setCpId] = useState(''); //state for the chargepoint id field (if the user wants to add a tag using a chargepoint)
  return (
    <>
      {/*Row for the add new tag header and its tooltip icon*/}
      {<AddTagHeaderAndTooltip />}
      {/*Row for the tag id field and the buttons to choose which method for adding a tag id*/}
      <TagIdFieldAndButtons
        setNewTagId={setNewTagId}
        backendScanning={backendScanning}
        backendScanningComplete={backendScanningComplete}
        setBackendScanning={setBackendScanning}
        setBackendScanningComplete={setBackendScanningComplete}
        disabledFields={disabledFields}
        allChargepoints={allChargepoints}
        allChargepointsReal={allChargepointsReal}
        cpId={cpId}
        setCpId={setCpId}
        failedAddAlert={failedAddAlert}
        invalidLengthTagIdAlert={invalidLengthTagIdAlert}
        successfulScan={successfulScan}
        setSuccessfulScan={setSuccessfulScan}
        cpIdEmptyAlert={cpIdEmptyAlert}
        setCpIdEmptyAlert={setCpIdEmptyAlert}
        cpLabel={cpLabel}
      />
      {/*Row for the nickname input field and its tooltip icon*/}
      {successfulScan && (
        <>
          <NicknameField
            newTagNickname={newTagNickname}
            setNewTagNickname={setNewTagNickname}
            backendScanning={backendScanning}
            disabledFields={disabledFields}
            noEmptyNicknameAlert={noEmptyNicknameAlert}
            invalidNicknameAlert={invalidNicknameAlert}
            nicknameAlreadyExistsAlert={nicknameAlreadyExistsAlert}
          />
          {/*Row for the valid until field and its tooltip icon*/}
          <ValidUntilField
            backendScanning={backendScanning}
            disabledFields={disabledFields}
            validUntilDate={validUntilDate}
            setValidUntilDate={setValidUntilDate}
            validUntilTime={validUntilTime}
            setValidUntilTime={setValidUntilTime}
          />
          {/*Row for the active/disabled buttons*/}
          <ActiveDisableButtons
            backendScanning={backendScanning}
            disabledFields={disabledFields}
            newDisabled={newDisabled}
            setNewDisabled={setNewDisabled}
            invalidActiveOrDisabledAlert={invalidActiveOrDisabledAlert}
          />
          {/*Row for the submit button*/}
          <SubmitButton
            disabledFields={disabledFields}
            handleTagAdd={handleTagAdd}
            resetAndClose={resetAndClose}
            setSuccessfulScan={setSuccessfulScan}
            setCpIdEmptyAlert={setCpIdEmptyAlert}
          />
        </>
      )}
    </>
  );
};
