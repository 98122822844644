import _ from 'lodash';
import { useState, useEffect } from 'react';
import {
  Row,
  Col,
  ButtonGroup,
  ToggleButton,
  InputGroup,
  FormControl,
  OverlayTrigger,
  Tooltip,
  Button,
  Alert,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  VictoryTheme,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryAxis,
} from 'victory';
import i18n from '../../../../i18n';
import { AreaFull } from '../../../../model/Classes/Area';
import AreaMeterValue from '../../../../model/Classes/AreaMeterValues';
import { StateHandler } from '../../../../model/Utilities/Types';
import { LightTheme } from '../../../../theme';
import { parseISOString } from '../../../../utils/dates';
import infoIcon from '../../../../resources/infoIcon.svg';

declare interface SingleAreaMeterGraphProps {
  area: AreaFull;
  data: AreaMeterValue[];
  showGraph: number;
  interval: number;
  intervalMinValue: number;
  startDate: string;
  startTime: string;
  stopDate: string;
  stopTime: string;
  setInterval: StateHandler<number>;
  setStartDate: StateHandler<string>;
  setStartTime: StateHandler<string>;
  setStopDate: StateHandler<string>;
  setStopTime: StateHandler<string>;
  searchAreaMeterValues: () => Promise<void>;
  invalidDates: boolean;
}

/**
 * Component responsible for showing the area meter value graph
 *
 * @param {*} area the area visited
 * @param {*} data state containing the area meter values
 * @param {*} showGraph state for showing the different variations of the area meter value graph
 * @param {*} interval state containing the interval for the area meter value graph
 * @param {*} intervalMinValue state containing the minimum interval for the area meter value graph
 * @param {*} startDate state containing the html-date input value for the start date for the area meter value graph
 * @param {*} startTime state containing the html-time input value for the start time for the area meter value graph
 * @param {*} stopDate state containing the html-date input value for the stop date for the area meter value graph
 * @param {*} stopTime state containing the html-time input value for the stop time for the area meter value graph
 * @param {*} setInterval state handler for the interval state
 * @param {*} setStartDate state handler for the startDate state
 * @param {*} setStartTime state handler for the startTime state
 * @param {*} setstopDate state handler for the stopDate state
 * @param {*} setStopTime state handler for the stopTime state
 * @param {*} searchAreaMeterValues helper function for fetching area meter value data
 * @param {*} invalidDates state for showing an alert when invalid dates have been set for the area meter value graph
 */
export const SingleAreaMeterGraph = ({
  area,
  data,
  showGraph,
  interval,
  intervalMinValue,
  startDate,
  startTime,
  stopDate,
  stopTime,
  setInterval,
  setStartDate,
  setStartTime,
  setStopDate,
  setStopTime,
  searchAreaMeterValues,
  invalidDates,
}: SingleAreaMeterGraphProps) => {
  const [powerOrCurrent, setPowerOrCurrent] = useState('power'); //state for knowing if the users sees the power or current version of the graph
  type DomainTuple = [number, number] | [Date, Date];
  type DomainPropObjectType =
    | { x?: DomainTuple; y: DomainTuple }
    | { x: DomainTuple; y?: DomainTuple };
  const [entireDomain, setEntireDomain] = useState<DomainPropObjectType>(); //state containing the entire domain for the graph (defaults to some predefined values)
  const dataNotEmpty = data.length > 0;
  const currentToPower = 0.231;
  const phases = 3;
  const { t } = useTranslation('common', {
    i18n: i18n,
  });

  /**
   * Helper for displaying the area meter graph; allows negative values to be displayed
   *
   * @param {*} data the data from area meters
   * @param {string} powerOrCurrent whether we're looking at the power data or the current data
   * @returns the datapoint with the lowest value (negative) or zero if there are no negative values in the data
   */
  const getLowestDataPoint = (
    data: AreaMeterValue[],
    powerOrCurrent: string
  ) => {
    var ret = 0;

    powerOrCurrent === 'current'
      ? data.forEach((element) => {
          ret = ret > element.current ? element.current : ret;
        })
      : data.forEach((element) => {
          ret = ret > element.power ? element.power : ret;
        });

    return ret;
  };

  //Whenever the data or powerOrCurrent state changes, update the entireDomain state
  useEffect(() => {
    const getEntireDomain = (): DomainPropObjectType => {
      //y-domain is [0, upper limit rounded upwards (power) OR upper limit + 1 (current)]
      if (_.last(data) !== undefined && data.length > 0) {
        return {
          y:
            powerOrCurrent === 'power'
              ? //Might finesse something to get rid of powerOrCurrent in getLowestDataPoint(...) later
                [
                  getLowestDataPoint(data, powerOrCurrent),
                  Math.ceil(area.a_limit! * currentToPower * phases),
                ]
              : [getLowestDataPoint(data, powerOrCurrent), area.a_limit! + 1],
          x: [data[0].period_start, _.last(data)!.period_start],
        };
      }
      //If the data doesnt exist
      return {
        x: [
          parseISOString('1999-02-17T00:00:00.000Z'),
          parseISOString('2022-02-11T00:00:00.000Z'),
        ],
        y: [0, 10],
      };
    };
    const domain = getEntireDomain();

    //Band-aid
    if (domain.y![1] === domain.y![0]) {
      setEntireDomain({
        x: [
          parseISOString('1999-02-17T00:00:00.000Z'),
          parseISOString('2022-02-11T00:00:00.000Z'),
        ],
        y: [0, 10],
      });
      return;
    }
    setEntireDomain(domain);
  }, [data, powerOrCurrent, area.a_limit]);

  const powerOrCurrentButtons = [
    { name: 'power', value: 0 },
    { name: 'current', value: 1 },
  ];

  //Modifications to the VictoryTheme.grayscale theme
  const lightTheme = new LightTheme();
  const chartTheme = VictoryTheme.grayscale;
  chartTheme.axis!.style!.axis!.stroke = lightTheme.text;
  chartTheme.axis!.style!.tickLabels = {
    letterSpacing: 'normal',
    padding: 15,
    fill: lightTheme.text,
    stroke: 'transparent',
  };

  return (
    <div
      className={`${showGraph === -3 ? 'error-graph' : ''} ${
        showGraph === -2 ? 'no-area-meter' : ''
      } ${showGraph === -1 ? 'loading' : ''} ${
        showGraph === 0 ? 'no-data' : ''
      } mb-3`}
    >
      <div className={`${showGraph !== 1 ? 'opacity-low-gray-bg' : ''}`}>
        <VictoryChart
          domain={entireDomain}
          theme={chartTheme}
          width={800}
          maxDomain={
            powerOrCurrent === 'power'
              ? { y: Math.ceil(area.a_limit! * currentToPower * phases) }
              : { y: area.a_limit! + 1 }
          }
          scale={{ x: 'time', y: 'linear' }}
        >
          {/* added axis formatting to get the 24 hr format in on the area meter graph */}
          <VictoryAxis
            tickFormat={(x) => {
              const date = new Date(x);
              const now = new Date();
              const day = date.getDate().toString().padStart(2, '0');
              const month = (date.getMonth() + 1).toString().padStart(2, '0');
              const hours = date.getHours().toString().padStart(2, '0');
              const minutes = date.getMinutes().toString().padStart(2, '0');
              const timeDiff = now.getTime() - date.getTime();
              if (timeDiff < 24 * 60 * 60 * 1000) {
                return `${hours}:${minutes}`;
              } else {
                return `${day}/${month}`;
              }
              //return `${day}/${month} ${hours}:${minutes}`;
            }}
          />
          <VictoryAxis
            dependentAxis
            //domain={[-10, 15]} // Specifying the desired value range for the y-axis
            //tickValues={[-10, -5, 0, 5, 10, 15, 20, 25, 30, 35, 40, 45]} // Specifying the tick values
          />
          <VictoryLine data={data} x="time" y="value" />
          <VictoryLabel
            text={
              powerOrCurrent === 'power'
                ? `${t('components.area.graph.header.y.power')} (kW)`
                : `${t('components.area.graph.header.y.current')} (A)`
            }
            angle={-90}
            x={10}
            y={190}
            style={{ fill: lightTheme.text }}
          />
          <VictoryLabel
            text={t('components.area.graph.header.x')}
            x={300}
            y={280}
            style={{ fill: lightTheme.text }}
          />
          {showGraph === 1 ? (
            <VictoryLabel
              text={t('components.area.graph.header.upper')}
              x={550}
              y={55}
              style={{
                fill: lightTheme.text,
                fontSize: 8,
                fontStyle: 'italic',
              }}
            />
          ) : null}
          <VictoryLabel
            className="chart-title"
            text={t('components.area.graph.header.title')}
            x={280}
            y={20}
            style={{ fill: lightTheme.text }}
          />
          <VictoryLine
            interpolation="linear"
            data={data}
            x="period_start"
            y={`${powerOrCurrent === 'power' ? 'power' : 'current'}`}
            style={{ data: { stroke: lightTheme.text } }}
          />
          <VictoryScatter
            size={2}
            data={data}
            x="period_start"
            y={`${powerOrCurrent === 'power' ? 'power' : 'current'}`}
            style={{ data: { fill: lightTheme.text } }}
          />
          {showGraph === 1 ? (
            <VictoryLine
              data={
                dataNotEmpty
                  ? powerOrCurrent === 'power'
                    ? [
                        {
                          x: data[0].period_start,
                          y: Number(area.a_limit! * currentToPower * phases),
                        },
                        {
                          x: _.last(data)!.period_start,
                          y: Number(area.a_limit! * currentToPower * phases),
                        },
                      ]
                    : [
                        { x: data[0].period_start, y: area.a_limit },
                        { x: _.last(data)!.period_start, y: area.a_limit },
                      ]
                  : []
              }
              style={{
                data: {
                  stroke: 'lightgrey',
                  strokeWidth: 3,
                  strokeDasharray: 10,
                },
              }}
            />
          ) : null}
        </VictoryChart>
        <Row>
          <Col lg={2}>
            <ButtonGroup className="power-or-current-button-group pb-3">
              {powerOrCurrentButtons.map((radio, idx) => (
                <ToggleButton
                  key={radio.name}
                  id={`meter-radio-${idx}`}
                  type="checkbox"
                  variant="outline-success"
                  name="radio"
                  value={radio.value}
                  checked={powerOrCurrent === radio.name}
                  onChange={() => setPowerOrCurrent(radio.name)}
                  disabled={showGraph !== 1}
                >
                  {' ' + t(`components.area.graph.header.y.${radio.name}`)}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </Col>
          <Col>
            <strong>
              {t('components.area.graph.dataFilterOptions.start')}
            </strong>
            <InputGroup className="date-time-picker mb-3">
              {/*Form field for the start date. It's an html date type*/}
              <FormControl
                disabled={showGraph !== 1}
                type="date"
                value={startDate}
                onChange={(event) => setStartDate(event.target.value)}
              />
              {/*Form field for the start time. It's an html time type*/}
              <FormControl
                disabled={showGraph !== 1}
                type="time"
                value={startTime}
                onChange={(event) => setStartTime(event.target.value)}
              />
            </InputGroup>
          </Col>
          <Col>
            <strong>{t('components.area.graph.dataFilterOptions.stop')}</strong>
            <InputGroup className="date-time-picker mb-3">
              {/*Form field for the stop date. It's an html date type*/}
              <FormControl
                disabled={showGraph !== 1}
                type="date"
                value={stopDate}
                onChange={(event) => setStopDate(event.target.value)}
              />
              {/*Form field for the stop time. It's an html time type*/}
              <FormControl
                disabled={showGraph !== 1}
                type="time"
                value={stopTime}
                onChange={(event) => setStopTime(event.target.value)}
              />
            </InputGroup>
          </Col>
          <Col lg={2}>
            <strong>
              {t('components.area.graph.dataFilterOptions.time')}
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="payment-method-id">
                    {/*Render som information text to the user*/}
                    <p className="mb-0">
                      {t('components.area.graph.dataFilterOptions.tooltip')}
                    </p>
                  </Tooltip>
                }
              >
                <img className="infoIcon" src={infoIcon} alt="info" />
              </OverlayTrigger>
            </strong>
            <FormControl
              disabled={showGraph !== 1}
              type="number"
              min={intervalMinValue}
              value={interval}
              onChange={(event) => setInterval(Number(event.target.value))}
            />
          </Col>
        </Row>
        <Row className="submit-button-row">
          <Col className="submit-button-col">
            <Button
              disabled={showGraph !== 1}
              className="submit-button"
              variant="primary"
              onClick={searchAreaMeterValues}
            >
              {t('components.area.graph.button.update')}
            </Button>
          </Col>
        </Row>
        {invalidDates && (
          <Alert variant="danger" className="mt-3">
            {t('components.area.graph.alert.invalid')}
          </Alert>
        )}
      </div>
    </div>
  );
};
