import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { StyledAdminPage } from './AdminPage.styled';
import AdminSetAreaManager from './AdminSetAreaManager';
import { useTranslation } from 'react-i18next';
import i18N from '../../i18n';
import { useState } from 'react';
import { ContractView } from '../Level1/Contract/Contract';
import { Contract } from '../../model/Classes/Contract';

/**
 * Component responsible for displaying the full admin page. The page contains the tabs for
 * the different admin functionalities, and displays the correct component for the active tab.
 * The component is wrapped around a styled component StyledAdminPage containing the specific
 * styles for this page.
 * @returns full admin page with the tab view of the different admin functionalities
 */
const AdminPage = () => {
  const { t } = useTranslation('common', { i18n: i18N });

  const [activeEventKey, setActiveEventKey] = useState('areaManager');
  const [contracts, setContracts] = useState<Contract[]>([]);

  const handleKeyChange = (eventValue: any) => {
    setActiveEventKey(eventValue);
  };

  return (
    <StyledAdminPage className="top-level-component">
      <Container id="component-margin">
        <Row className="mb-3" style={{ textAlign: 'center' }}>
          <Col>
            <h2>{t('components.admin.functionality')}</h2>
          </Col>
        </Row>
        <Row>
          <Tabs
            defaultActiveKey="areaManager"
            id=""
            className="mb-3"
            onSelect={handleKeyChange}
          >
            <Tab eventKey="areaManager" title={t('components.admin.set')}>
              <AdminSetAreaManager />
            </Tab>
            <Tab
              eventKey="contracts"
              title={t('components.admin.contracts.title')}
            >
              <ContractView
                activeEventKey={activeEventKey}
                contracts={contracts}
                setContracts={setContracts}
              />
            </Tab>
          </Tabs>
        </Row>
      </Container>
    </StyledAdminPage>
  );
};

export default AdminPage;
