import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Table } from "react-bootstrap";
import { StyledMyTransactions } from "./MyTransactions.styled";
//import ActiveTransactions from './ActiveTransactions';
import {
  getMyTransactionsCompleted,
  getMyTransactionsActive,
} from "../../../services/transactions/MyTransactions/MyTransactions_service";
import { remoteStop } from "../../../utils/remoteStop";
import { refreshActiveTransactions } from "../../../utils/refreshActiveTransactions";
import { logger } from "../../../utils/logger";
import generateUniqueId from "../../../utils/generateUniqueId";
import getCurrencySymbol from "../../../utils/getCurrencySymbol";
import { ActiveTransaction, CompletedTransaction } from "../../../model/Classes/Transaction";
import User from "../../../model/Classes/User";
import linkArrow from "../../../resources/linkArrow.svg";
import i18N from "../../../i18n";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaChevronRight } from "react-icons/fa";

/**
 * Component responsible for displaying the MyTransactions page. The page renders the following:
 * 1) Page header
 * 2) Completed transactions (header + table with columns year, month, total kWh, and total cost in euro)
 * 3) Active transactions
 * @param {*} transactions all the completed transactions for the user
 * @param {*} activeTransactions all the active transactions for the user
 * @param {*} user the user of the application
 * @param {*} refresh helper function for refreshing all the active transactions
 * @param {*} showLoading state for showing the loading animation
 * @param {*} showCheckmark state for showing the checkmark when the loading is complete
 * @param {*} callRemoteStop helper function for calling the remoteStop utility function
 * @param {*} remoteStopSuccess state for showing a success alert when remote stopping
 * @param {*} remoteStopError state for showing a failure alert when remote stopping
 * @param {*} remoteStopSuccessButRefreshActive state for showing an alert when remote stopping was successful, but refreshing failed
 * @returns
 */
declare interface MyTransactionsProps {
  user: User;
}
declare interface MyTransactionsAllMonthsProps extends MyTransactionsProps {
  transactions: CompletedTransaction[];
  activeTransactions: ActiveTransaction[];
  refresh: () => Promise<void>;
  showCheckmark: boolean;
  showLoading: boolean;
  callRemoteStop: (activeId: any, cpId: any) => Promise<void>;
  remoteStopSuccess: boolean;
  remoteStopError: boolean;
  remoteStopSuccessButRefreshActive: boolean;
}
const MyTransactionsAllMonths = ({
  transactions,
  activeTransactions,
  user,
  refresh,
  showCheckmark,
  showLoading,
  callRemoteStop,
  remoteStopSuccess,
  remoteStopError,
  remoteStopSuccessButRefreshActive,
}: MyTransactionsAllMonthsProps) => {
  const { t } = useTranslation("common", {
    i18n: i18N,
  });

  transactions.sort((a, b) => {
    const monthArray = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const aDate = new Date();
    aDate.setFullYear(a.year, monthArray.indexOf(a.month));
    const bDate = new Date();
    bDate.setFullYear(b.year, monthArray.indexOf(b.month));
    return bDate.getTime() - aDate.getTime();
  });
  return (
    <Container id="component-margin">
      {/* Row for the page header. If the user doesn't have any completed nor active transactions,
          show a header notifying the user that they do not have any transactions. Otherwise, show
          the default header*/}
      <Row>
        {
          transactions.length === 0 && activeTransactions.length === 0 ? (
            <h2>{t("components.myTransactions.static.header.all.hasNot")}</h2> //No transactions, show this header
          ) : (
            <h2>{t("components.myTransactions.static.header.all.has")}</h2>
          ) //At least one transaction, show this header
        }
      </Row>
      {/*Row for the completed transactions*/}
      <Row>
        <h4>{t("components.myTransactions.static.header.completed.has")}</h4>
        <Table className="mb-10" striped bordered hover>
          <thead>
            <tr>
              <th>{t("components.myTransactions.table.main.year")}</th>
              <th>{t("components.myTransactions.table.main.month")}</th>
              <th>{t("components.myTransactions.table.main.kWh")}</th>
              <th>{t("components.myTransactions.table.main.total")}</th>
            </tr>
          </thead>
          <tbody>
            {transactions.length > 0 ? (
              transactions.map((transaction, idx) => {
                return <DisplayTransaction key={idx} transaction={transaction} />;
              })
            ) : (
              <tr>
                <td colSpan={4}>{t("components.myTransactions.static.header.completed.hasNot")}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Row>
      {/*Row for all the active transactions
      <Row>
        <ActiveTransactions
          remoteStopSuccess={remoteStopSuccess}
          remoteStopError={remoteStopError}
          remoteStopSuccessButRefreshActive={remoteStopSuccessButRefreshActive}
          activeTransactions={activeTransactions}
          showCheckmark={showCheckmark}
          showLoading={showLoading}
          refresh={refresh}
          callRemoteStop={callRemoteStop}
          forMyTransactions={true}
          user={user}
        />
      </Row>
      */}
    </Container>
  );
};

declare interface DisplayTransactionProps {
  transaction: CompletedTransaction;
}
const DisplayTransaction = ({ transaction }: DisplayTransactionProps) => {
  const { t } = useTranslation("common", { i18n: i18N });
  return (
    <>
      <tr>
        <td>{transaction.year}</td>
        <td>
          <Link to={`/myTransactions/${transaction.year}/${transaction.month}`}>
            {t(`components.myTransactions.months.${transaction.month}`)}
            <FaChevronRight style={{ marginLeft: "0.2rem", fill: "black" }} />
          </Link>
        </td>
        <td>{transaction.kWh}</td>
        <td>
          {transaction.total_cost?.toFixed(2)} {getCurrencySymbol(transaction.currency || "")}
        </td>
      </tr>
    </>
  );
};

/**
 * Component responsible for handling the funcitonality related to the MyTransactions page.
 *
 * When the component is initialized, the useEffect hook is run. The hook fetches all the completed and active transactions
 * for the user (the user is checked at the backend using the tokens). If successful, it updates the correct states and sets
 * the showView state to 1 to show the MyTransactions page. Otherwise, it sets the showView state to 0 to show an error page
 * to the user.
 * @param {*} user user of the application
 * @returns loading screen, error screen, or MyTransactions screen
 */

const MyTransactions = ({ user }: MyTransactionsProps) => {
  const [transactions, setTransactions] = useState<CompletedTransaction[]>([]); //state for all the transactions for the user
  const [activeTransactions, setActiveTransactions] = useState<ActiveTransaction[]>([]); //state for all the active transactions for this user
  const [showView, setShowView] = useState(-1); //state for showing the loading screen, error screen, or MyTransaction screen
  const [showLoading, setShowLoading] = useState(false); //state for showing the loading animation (used when refreshing active transactions)
  const [showCheckmark, setShowCheckmark] = useState(false); //state for showing a checkmark (used when refreshing active transactions)
  const [remoteStopSuccess, setRemoteStopSuccess] = useState(false); //state for succesfully remote stopping an active transaction alert
  const [remoteStopError, setRemoteStopError] = useState(false); //state for failed remote stopping of an active transaction alert
  const [remoteStopSuccessButRefreshActive, setRemoteStopSuccessButRefreshActive] = useState(false); //state for succesfully remote stopping an active transaction, but failed refreshing alert
  const { t } = useTranslation("common", {
    i18n: i18N,
  });

  /**
   * When the component initializes, the useEffect hook fetches all the completed and active transactions for the user
   * (the user is checked at the backend using the tokens). If it's successful, update the corresponding states with
   * the data and set the showView state to 1 (shows the MyTransactions page). Otherwise, log the error and set the
   * showView state to 0 (shows an error message to the user).
   */
  useEffect(() => {
    /**
     * Asynchronous helper function for calling two service functions that fetch the needed data from the backend.
     * If both succeed, update the correct states with all the data and set the showView state to 1. Otherwise,
     * set the showView state to 0.
     */
    const getData = async () => {
      //Call the correct service functions to get the data needed. gets completed transactions and active transactions respectively
      const completed = await getMyTransactionsCompleted();
      const active = await getMyTransactionsActive();

      //Check if both requests came back as successful
      if (completed.success) {
        //Success, update the transactions and activeTransactions states with the data

        //We need to add a unique id field to each data row, since the component responsible
        //for rendering the table needs a unique id field
        const finalCompleted = completed.data.map((tran: CompletedTransaction) => {
          return { ...tran, unique_id: generateUniqueId() };
        });

        setTransactions(finalCompleted);
        setActiveTransactions(active.data);
        setShowView(1); //Set the showView state to 1 to show the MyTransactions page
      } else {
        //Errors in completed?
        if (!completed.success) {
          logger(completed.data);
        }

        //Errors in active?
        if (!active.success) {
          logger(active.data);
        }

        //Set the showView state to 0 notifying the user that an error occured
        setShowView(0);
      }
    };

    getData(); //Call the asynchronous helper function
  }, []);

  /**
   * Asynchronous helper function for refreshing all the active transactions (refresh button).
   * The function calls the utility function refreshActiveTransactions which handles the
   * functionality for refreshing active transactions.
   */
  const refresh = async () => {
    //Call the utility function refresActiveTransactions.
    //We pass the string "myTransactions" as the first parameter to indicate that we're calling this
    //function for the MyTransactions component. Additionally, we pass null as the second and third
    //parameter since we don't need to specify an area id nor a chargepoint id
    await refreshActiveTransactions(
      "myTransactions",
      null,
      null,
      setActiveTransactions,
      setShowLoading,
      setShowCheckmark
    );
  };

  /**
   * Asynchronous helper function used to call the remoteStop utility function when the user
   * wants to remote stop an active transaction.
   * @param {*} activeId the id of the active transaction
   * @param {*} cpId the id of the chargepoint the transaction belongs to
   */
  const callRemoteStop = async (activeId: number, cpId: string) => {
    //Call the remoteStop utility function
    //NOTE! We pass null as the third parameter since it's only needed when the function
    //is called from the Reporting component.
    await remoteStop(
      activeId,
      "myTransactions",
      setRemoteStopSuccess,
      setRemoteStopError,
      setRemoteStopSuccessButRefreshActive,
      setActiveTransactions,
      undefined,
      cpId
    );
  };

  /**
   * Initially, the component shows a loading screen. If an error occured when fetching data, an appropriate
   * error message is displayed. Otherwise, show the MyTransactionsAllMonth component
   */
  return (
    <StyledMyTransactions className="top-level-component">
      {showView === -1 ? (
        <>
          <h2 className="align-self-center">{t("global.view.loading")}</h2>
        </>
      ) : showView === 0 ? (
        <h2 className="align-self-center">{t("global.view.error")}</h2>
      ) : (
        <>
          <MyTransactionsAllMonths
            transactions={transactions}
            activeTransactions={activeTransactions}
            user={user}
            refresh={refresh}
            showCheckmark={showCheckmark}
            showLoading={showLoading}
            remoteStopError={remoteStopError}
            callRemoteStop={callRemoteStop}
            remoteStopSuccess={remoteStopSuccess}
            remoteStopSuccessButRefreshActive={remoteStopSuccessButRefreshActive}
          />
        </>
      )}
    </StyledMyTransactions>
  );
};

export default MyTransactions;
