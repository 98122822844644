import { useEffect, useState } from "react";
import { Col, Alert } from "react-bootstrap";
import { StyledMaintenanceNotification } from "./MaintenanceNotification.styled";
import i18n from "../../i18n";

export const MaintenanceNotification = () => {
  const [notification, setNotification] = useState(() => {
    return {
      active: false,
      fi: "",
      sv: "",
      en: "",
    };
  });

  useEffect(() => {
    fetch("maintenance_notif.json").then((res) => {
      res.json().then((langs) => {
        setNotification(langs);
      });
    });

    return () => {
      setNotification({
        active: false,
        fi: "",
        sv: "",
        en: "",
      });
    };
  }, []);

  return (
    <>
      {notification.active ? (
        <StyledMaintenanceNotification>
          <Col style={{ textAlign: "center" }}>
            <Alert variant="warning">{notification[i18n.options.lng!]} </Alert>
          </Col>
        </StyledMaintenanceNotification>
      ) : (
        <></>
      )}
    </>
  );
};
