import axios, { AxiosResponse } from 'axios';
import StructuredResponse from '../../model/Classes/StructuredResponse';
import User from '../../model/Classes/User';
axios.defaults.withCredentials = true;

const baseURL = '/api/auth/login';

/**
 * Service function responsible for login in the user. If the login fails, the error will be caught by the
 * function that called this service function.
 * @param {*} data login information
 * @returns response from the backend
 */

export const login = async (data: { email: string; password: string }) => {

  try {
    //Send the login details to the backend
    const response = await axios.post<typeof data, AxiosResponse<User>>(
      baseURL,
      data,
      {
        withCredentials: true,
      }
    );
    return new StructuredResponse(true, response.data);
  } catch (e: any) {
    return new StructuredResponse<User>(false, e);
  }
  //The login was successful! Return the response from the backend
};
