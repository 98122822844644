import styled from 'styled-components';

/**
 * Styled component for the payment terms of service page
 */
export const StyledPaymentToS = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  background: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  font-size: 1rem;

  .row {
    width: 95vw;
  }

  .logo {
    width: 50vw;
    @media only screen and (max-width: 700px) {
      width: 80vw;
    }
    @media only screen and (min-width: 700px, max-width: 1000px) {
      width: 50vw;
    }
    @media only screen and (min-width: 1000px) {
      width: 500px;
    }
  }
`;
