import { Link } from "react-router-dom";
import { Alert, Button, Row, Col, DropdownButton, Dropdown, Card, Modal } from "react-bootstrap";
import refreshIcon from "../../../resources/refreshIcon.svg";
import refreshIconAnimated from "../../../resources/refreshIconAnimated.gif";
import checkmarkIcon from "../../../resources/checkmarkIcon.svg";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import {
  ActiveTransaction,
  //SpotPrices,
  //format,
} from "../../../model/Classes/Transaction";
import User from "../../../model/Classes/User";
import { ChargepointClass, CpBase } from "../../../model/Classes/Chargepoint";
import { StateHandler } from "../../../model/Utilities/Types";
import i18N from "../../../i18n";
import { useTranslation } from "react-i18next";
import Accordion from "react-bootstrap/Accordion";
import { useState } from "react";
import { FaAngleDoubleDown } from "react-icons/fa";
import { getChargepointIsConnected } from "../../../services/chargepoint/getChargepointIsConnected";
import getCurrencySymbol from "../../../utils/getCurrencySymbol";

declare interface ActiveTransactionsProps {
  activeTransactions: ActiveTransaction[];
  user: User;
  forArea?: { filterValue: number; setFilterValue: StateHandler<number> };
  forChargepoint?: boolean;
  forLandingPage?: boolean;
  forMyTransactions?: boolean;
  callForceStop?: (id: number, area_id?: number) => Promise<void>;
  callRemoteStop: (id: number, cpId?: string) => Promise<void>;
  forceStopSuccess?: boolean;
  forceStopError?: boolean;
  forceStopSuccessButRefreshActive?: boolean;
  remoteStopSuccess: boolean;
  remoteStopError: boolean;
  remoteStopSuccessButRefreshActive: boolean;
  showLoading: boolean;
  showCheckmark: boolean;
  currentSpotPrice?: String | number;
  refresh: () => Promise<void>;
}

/**
 * Component responsible for displaying active transactions. The function renders the following:
 * 1) Possible alerts related to force stopping (depending on which component is displaying the active transactions)
 * 2) Possible alerts related to remote stopping
 * 3) A header and a refresh button (and a filter if we're rendering for the SingleArea component)
 * 4) If active transactions exist: a table containing the active transactions
 *
 * This component works for all the components that need to display active transactions: SingleArea, Chargepoint, LandingPage, and
 * MyTransactions The reason for this component is so that same code doesn't have to be repeated across multiple
 * components -> reducing DRY code and making the code overall cleaner and more readable.
 *
 * The tables will look different for all the components (except for the LandingPage and MyTransactions components, their table
 * look the same). Thus, the component has to know which component needs to display the active transactions. It does so through
 * the use of the forArea, forChargepoint, forLandingPage, and forMyTransactions, flags passed as props. Depending
 * on which prop is true, a specific active transactions table will be shown. Additionally, some text alignment and header texts
 * vary between components.
 * @param {*} activeTransactions all the active transactions
 * @param {*} user the user of the application
 * @param {*} forArea flag indicating if the component is supposed to display the active transactions for the SingleArea component (also contains some data)
 * @param {*} forChargepoint flag indicating if the component is supposed to display the active transactions for the Chargepoint component
 * @param {*} forLandingPage flag indicating if the component is supposed to display the active transactions for the LandingPage component
 * @param {*} forMyTransactions flag indicating if the component is supposed to display the active transactions for the MyTransactions component
 * @param {*} callForceStop helper function for calling the forceStop utility function
 * @param {*} callRemoteStop helper function for calling the remoteStop utility function
 * @param {*} forceStopSuccess state for showing a success alert when force stopping
 * @param {*} forceStopError state for showing a failure alert when force stopping
 * @param {*} forceStopSuccessButRefreshActive state for showing an alert when force stopping was successful, but refreshing failed
 * @param {*} remoteStopSuccess state for showing a success alert when remote stopping
 * @param {*} remoteStopError state for showing a failure alert when remote stopping
 * @param {*} remoteStopSuccessButRefreshActive state for showing an alert when remote stopping was successful, but refreshing failed
 * @param {*} showLoading state for showing the loading animation when refreshing
 * @param {*} showCheckmark state for shoiwng the checkmark when refreshing is completed
 * @param {*} refresh helper function for refreshing all the active transactions
 * @returns header + refresh button + table for active transactions (with alerts sprinkled in between when needed)
 */
const ActiveTransactions = ({
  activeTransactions,
  user,
  forArea,
  forChargepoint,
  forLandingPage,
  forMyTransactions,
  callForceStop,
  callRemoteStop,
  forceStopSuccess,
  forceStopError,
  forceStopSuccessButRefreshActive,
  remoteStopSuccess,
  remoteStopError,
  remoteStopSuccessButRefreshActive,
  showLoading,
  showCheckmark,
  currentSpotPrice,
  refresh,
}: ActiveTransactionsProps) => {
  const { t } = useTranslation("common", {
    i18n: i18N,
  });
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [selectedChargepointId, setSelectedChargepointId] = useState("");
  const [selectedAreaId, setSelectedAreaId] = useState<number | null>(null);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleConfirmModal = () => {
    setShowConfirm(false);
  };

  //start --------------------------------------------------------------
  //the folllowing function and conditionals are used to have the different requirements met in the landing accordion columns. needs to be re evaluated for better code
  function pricing(trans: ActiveTransaction) {
    const currencySymbol = String(getCurrencySymbol(trans.currency || ""));
    // updateSpotValue();
    //return updateSpotValue().then(() => {
    switch (trans.pricing_type) {
      case 0: // fixed pricing
        return trans.start_price !== null && trans.start_price > 0
          ? `${trans.start_price} ${currencySymbol} + ${trans.price} ${currencySymbol}/kWh`
          : `${trans.price} ${currencySymbol}/kWh`;
      case 1: // spot pricing
        return `${trans.margin} ${currencySymbol}/kWh + ${t("components.myTransactions.table.specific.spotPrice")}(${t(
          "components.myTransactions.table.specific.now"
        )}${trans.spotPrice} c/kWh) `;
      case 2: // free charging
        return `${t("components.myTransactions.table.specific.freeCharging")}`;
    }
  }

  function displaySeller(trans: ActiveTransaction) {
    return `${trans.seller} (${trans.business_id})`;
  }

  function displayAddress(trans: ActiveTransaction) {
    return trans.address === null || trans.address.length === 0 ? null : trans.address;
  }
  function dateFormat(trans: ActiveTransaction) {
    const startDate = new Date(trans.start_time);

    return startDate.toLocaleString("en-FI", { timeZone: "UTC" });
  }
  function format(trans: ActiveTransaction) {
    return {
      name: trans.name,
      current: trans.current,
      address: displayAddress(trans),
      charge_point_id: ChargepointClass.toString(trans),
      connector_id: trans.connector_id,
      status: trans.status,
      start_time: dateFormat(trans),
      //start_time: trans.start_time,
      tag_nickname: trans.tag_nickname,
      duration: trans.duration,
      currency: trans.currency,
      power: trans.power,
      pricing: pricing(trans),
      seller: displaySeller(trans),
      business_id: trans.business_id,
      kWh: trans.kWh,
      nickname: trans.nickname,
      timezone: trans.timezone,
      margin: trans.margin,
      prefix: trans.cp_prefix,
      cpNumber: trans.cp_number,
    };
  }

  //Initial modal pop up for remote stop transaction for area managers
  const confirmStop = () => {
    return (
      <Modal show={showConfirm}>
        <Modal.Header>
          <Modal.Title>{t("components.activeTransactions.remoteStopModal.modalHeader")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("components.activeTransactions.remoteStopModal.modalBody")}</Modal.Body>
        <Modal.Footer style={{ margin: "0 auto" }}>
          <Button
            onClick={async () => {
              //refresh();
              const isRemoteConnected = await getChargepointIsConnected(selectedChargepointId);

              if (isRemoteConnected.success && isRemoteConnected.data.connected) {
                forChargepoint ? callRemoteStop(selectedId!) : callRemoteStop(selectedId!, selectedChargepointId);
                setShowConfirm(false);
              } else {
                setShowPopup(true);
                setShowConfirm(!showConfirm);
              }
            }}
          >
            {t("components.activeTransactions.remoteStopModal.modalBtn.btnYes")}
          </Button>
          <Button onClick={handleConfirmModal} variant="secondary">
            {t("components.activeTransactions.remoteStopModal.modalBtn.btnCancel")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const columns: ColumnDescription<ActiveTransaction>[] = [
    {
      dataField: "id",
      text: `${t("components.activeTransactions.table.id")}`,
      hidden: true,
    },

    {
      dataField: "last_name",
      text: `${t("components.activeTransactions.table.user")}`,
      formatter: (cell, row: ActiveTransaction, rowIndex, extraData) => (
        <>
          {row.first_name} {row.last_name}
        </>
      ),
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (a.localeCompare(b)) {
          if (order === "asc") return 1;
          else return -1;
        } else if (b.localeCompare(a)) {
          if (order === "asc") return -1;
          else return 1;
        } else {
          if (rowA.first_name.localeCompare(rowB.first_name)) {
            return -1;
          } else if (rowB.first_name.localeCompare(rowA.first_name)) {
            return 1;
          } else {
            return 1;
          }
        }
      },
      hidden: forLandingPage || forMyTransactions,
    },

    {
      dataField: "status",
      text: `${t("components.activeTransactions.table.status")}`,
      sort: true,
    },

    {
      dataField: "area_id",
      text: `${t("components.activeTransactions.table.area")}`,
      formatter: (cell, row, rowIndex, extraData) => (
        <>{user.user_level === 0 ? row.name : <Link to={`/area/${row.area_id}`}>{row.name}</Link>}</>
      ),
    },

    {
      dataField: "charge_point_id",
      text: `${t("components.activeTransactions.table.cp")}`,
      formatter: (cell, row: ActiveTransaction, rowIndex, extraData) => (
        <>
          {user.user_level === 0 ? (
            ChargepointClass.toString(row)
          ) : (
            <Link to={`/area/${row.area_id}/chargepoint/${row.charge_point_id}`}>{ChargepointClass.toString(row)}</Link>
          )}
        </>
      ),
      sort: true,
    },

    {
      dataField: "connector_id",
      text: `${t("components.activeTransactions.table.connId")}`,
      sort: true,
    },

    {
      dataField: "start_time",
      text: `${t("components.activeTransactions.table.start")}`,
      formatter: (cell, row, rowIndex, extraData) => (
        <>
          {new Date(row.start_time).toLocaleString("en-FI", {
            timeZone: "UTC",
          })}
        </>
      ),
      sort: true,
    },

    {
      dataField: "duration",
      text: `${t("components.activeTransactions.table.dur")}`,
      sort: true,
    },

    {
      dataField: "current",
      text: `${t("components.activeTransactions.table.current")} (A)`,
      sort: true,
    },

    {
      dataField: "kWh",
      text: `${t("components.activeTransactions.table.kWh")}`,
      formatter: (cell, row: ActiveTransaction, rowIndex, extraData) => (
        /*
          <> (row.kWh !== null && typeof row.kWh === 'number' ) ?
            (Number(row.kWh) > 0 ? row.kWh :
              0) : 0 
          </>
          */

        <>{Number(row.kWh) > 0 ? row.kWh : 0}</>
      ),
      sort: true,
    },

    {
      dataField: "total_cost",
      text: `${t("components.activeTransactions.table.total")}`,
      formatter: (cell, row: ActiveTransaction, rowIndex, extraData) => (
        <>{Number(row.total_cost) > 0 ? `${row.total_cost} ${row.currency}` : `0 ${row.currency}`}</>
      ),
      sort: true,
      hidden: true,
    },

    {
      dataField: "remote_stop",
      isDummyField: true,
      text: `${t("components.activeTransactions.table.stop")}`,
      formatter: (cell, row, rowIndex, extraData) => (
        <>
          <Button
            onClick={async () => {
              setSelectedId(row.id!);
              setSelectedChargepointId(row.charge_point_id);
              setSelectedAreaId(row.area_id);
              setShowConfirm(true);
              await refresh();
            }}
            variant="secondary"
          >
            {`${t("components.activeTransactions.table.stop")}`}
          </Button>
        </>
      ),
    },

    /* {
      dataField: 'force_stop',
      isDummyField: true,
      text: `${t('components.activeTransactions.table.force')}`,
      formatter: (cell, row, rowIndex, extraData) => (
        <>
          <Button
            onClick={() => {
              forArea
                ? callForceStop!(row.id!, row.area_id)
                : callForceStop!(row.id!);
            }}
            variant="danger"
          >
            {t('components.activeTransactions.table.force')}
          </Button>
        </>
      ),
      hidden:
        forLandingPage ||
        forMyTransactions ||
        user.user_level < 2 ||
        forChargepoint,
      
    },*/
  ];

  //column definition for  accrodion function

  const accordionColumns: ColumnDescription<ActiveTransaction>[] = [
    {
      dataField: "start_time",
      text: `${t("components.landingPage.accordion.startTime")}`,
    },
    {
      dataField: "duration",
      text: `${t("components.landingPage.accordion.duration")}`,
      hidden: true,
    },
    {
      dataField: "power",
      text: `${t("components.landingPage.accordion.power")}`,
      hidden: true,
    },
    {
      dataField: "current",
      text: `${t("components.landingPage.accordion.current")}`,
    },

    {
      dataField: "seller",
      text: `${t("components.landingPage.accordion.seller")}`,
    },
    {
      dataField: "nickname",
      text: `${t("components.landingPage.accordion.chargepoint")}`,
      hidden: true,
    },
    {
      dataField: "charge_point_id",
      text: `${t("components.landingPage.accordion.chargepointId")}`,
    },
    {
      dataField: "connector_id",
      text: `${t("components.landingPage.accordion.connector")}`,
    },

    {
      dataField: "address",
      text: `${t("components.landingPage.accordion.address")}`,
      hidden: forChargepoint,
    },
    {
      dataField: "tag_nickname",
      text: `${t("components.landingPage.accordion.tag")}`,
    },
    {
      dataField: "pricing",
      text: `${t("components.landingPage.accordion.pricing")}`,
    },
    /** { {
      dataField: 'margin',
      text: `margin`,
      hidden: true,
    },
    
      dataField: 'timezone',
      text: `${t('Timezone')}`,
    },
     */
  ];

  const [showPopup, setShowPopup] = useState(false); //state for pop up modal

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleForceStop = async (id, area_id) => {
    callForceStop!(id, area_id);
    refresh();
  };

  /*---Accordion function for the active transaction revamp---*/
  // setting up a function for background color for the card depending upon the status and fault_msg value
  const changeCardBackground = (connected, fault_msg) => {
    if (connected === 0) {
      return "#e8e9e8";
    } else if (fault_msg !== "") {
      return "#FFC107";
    } else {
      return "#00b350";
    }
  };
  const connectedTextColor = (connected, fault_msg) => {
    if (connected === 1 && fault_msg === "") {
      return "#FFFFFF";
    }
  };

  const AccordionFunction = () => {
    let displayTrans: any;
    return (
      <div
        style={{
          textAlign: "center",
        }}
      >
        {activeTransactions.map((transaction, index) => {
          displayTrans = format(transaction);
          if (typeof transaction.id === "number") {
            return (
              <div
                key={transaction.id}
                style={{
                  display: "block",
                  margin: "15px",
                  textAlign: "center",
                  borderRadius: "10px",
                }}
              >
                {/**<p>{transaction.status}</p> */}

                <Accordion key={transaction.id}>
                  <Card
                    style={{
                      backgroundColor: changeCardBackground(transaction.connected, transaction.fault_msg),
                      color: connectedTextColor(transaction.connected, transaction.fault_msg),
                      maxWidth: "30rem",
                      margin: "0 auto",
                      border: "none",
                    }}
                  >
                    <Accordion.Toggle as={Card.Header} eventKey={index.toString()}>
                      <div style={{ position: "relative" }}>
                        <div
                          style={{
                            position: "absolute",
                            bottom: "0",
                            right: "0",
                          }}
                        >
                          <FaAngleDoubleDown />
                        </div>
                        <p
                          style={{
                            textAlign: "center",
                            fontSize: "0.9rem",
                            padding: "0 20px 0 20px",
                            //border: ' 1px solid black',
                            marginBottom: "7px",
                          }}
                        >
                          {transaction.name}
                        </p>
                        {/*<h5>{transaction.charge_point_id}</h5>*/}
                        <h4
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {t(`components.landingPage.accordion.status.${transaction.status}`)}
                        </h4>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "0 55px 0 30px",
                            marginTop: "0",
                            height: "25px",
                          }}
                        >
                          {/*<p>{transaction.status}</p>*/}
                          <p
                            style={{
                              fontSize: "1.3rem",
                            }}
                          >
                            {transaction.kWh} kWh
                          </p>
                          <p
                            style={{
                              fontSize: "1.3rem",
                            }}
                            hidden={transaction.pricing_type === 1}
                          >
                            {/** {`${transaction.total_cost} ${transaction.currency}`}*/}
                            {`${transaction.total_cost} ${
                              transaction.currency === "EUR" ? "€" : transaction.currency === "SEK" ? "kr" : ""
                            }`}
                          </p>
                        </div>
                        <div style={{ color: "red", fontWeight: "bold" }}>
                          {transaction.connected
                            ? transaction.fault_msg
                            : `${t("components.landingPage.accordion.status.connectionLost")}`}
                        </div>
                      </div>
                    </Accordion.Toggle>
                    {/*pop Up modal for error on remote stop*/}
                    <Modal show={showPopup || remoteStopError}>
                      <Modal.Header>
                        <Modal.Title>{t("components.landingPage.accordion.status.connectionLost")}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>{t("global.alert.failure.remoteStop")}</Modal.Body>
                      <Modal.Footer style={{ margin: "0 auto" }}>
                        <Button onClick={handleClosePopup}>OK</Button>
                      </Modal.Footer>
                    </Modal>
                    {/* END OF THE MODAL */}
                    <Accordion.Collapse eventKey={index.toString()}>
                      <Card.Body>
                        {
                          <>
                            <Button
                              onClick={async () => {
                                refresh();
                                const isConnected = await getChargepointIsConnected(transaction.charge_point_id);
                                if (isConnected.success && isConnected.data.connected) {
                                  callRemoteStop(transaction.id!, transaction.charge_point_id);
                                  refresh();
                                } else setShowPopup(true);
                              }}
                              variant="danger"
                              size="sm"
                              style={{
                                width: "100px",
                                //margin: '0 0 20px 0 auto',
                                marginBottom: "10px",
                              }}
                            >
                              {t("components.landingPage.accordion.stop")}
                            </Button>
                            <table
                              // className="table table-striped"
                              style={{
                                textAlign: "left",
                                margin: "0 auto",
                              }}
                            >
                              <tbody
                                style={{
                                  marginTop: "55px",
                                }}
                              >
                                {accordionColumns.map((column) => {
                                  //checking if address is there or not
                                  if (column.dataField === "address" && !displayTrans.address) {
                                    return null;
                                  }
                                  if (column.dataField === "nickname" && !displayTrans.nickname) {
                                    return null;
                                  }
                                  return (
                                    <tr key={column.dataField}>
                                      <td>{column.text}</td>
                                      <td
                                        style={{
                                          paddingLeft: "20px",
                                        }}
                                      >
                                        {displayTrans[column.dataField]}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </>
                        }
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            );
          }
          return null; // Skip rendering if transaction.id is undefined
        })}
      </div>
    );
  };
  return (
    <>
      {/* Since force stopping an active transaction is only allowed if viewing the transaction from the
          SingleArea, Chargepoint, or Reporting component, the alerts concerning force stopping will only
          be checked if any of the flags below are true*/}
      {(forArea || forChargepoint) && (
        <>
          {forceStopSuccess && ( //Show an alert notifying the user that the force stopping was successful
            <Alert key="successfullForceStop" variant="success" style={{ textAlign: "center" }}>
              {t("global.alert.success.forceStop")}
            </Alert>
          )}
          {forceStopError && ( //Show an alert notifying the user that the force stopping failed
            <Alert key="errorForceStop" variant="danger" style={{ textAlign: "center" }}>
              {t("global.alert.failure.forceStop")}
            </Alert>
          )}
          {forceStopSuccessButRefreshActive && ( //Show an alert notifying the user that the force stopping was successful but refreshing failed
              <Alert key="manuallyRefreshForce" variant="warning" style={{ textAlign: "center" }}>
                {t("global.alert.success.forceStopRef")}
              </Alert>
            ) &&
            refresh()}
          {
            //Show an alert notifying the user that the remote stopping failed. a bad example of coding as same modal is done prior. needs refactoring to be a componenet and take in different properties
            <Modal show={showPopup || remoteStopError}>
              <Modal.Header>
                <Modal.Title>{t("global.alert.failure.areaRemoteStopHeader")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{t("global.alert.failure.remoteStopArea&CP")}</Modal.Body>
              <Modal.Footer style={{ margin: "0 auto" }}>
                <Button
                  onClick={async () => {
                    handleForceStop(selectedId, selectedAreaId);
                    setShowPopup(!showPopup);
                  }}
                >
                  {t("global.buttons.remove")}
                </Button>
                <Button onClick={handleClosePopup} variant="secondary">
                  {t("global.buttons.cancel")}
                </Button>
              </Modal.Footer>
            </Modal>
          }
          {confirmStop()}
          {/* calling in the intermidiary model here */}
        </>
      )}
      {remoteStopSuccess && ( //Show an alert notifying the user that the remote stopping was successful
        <Alert key="successfullRemoteStop" variant="success" style={{ textAlign: "center" }}>
          {t("global.alert.success.remoteStop")}
        </Alert>
      )}

      {remoteStopSuccessButRefreshActive && ( //Show an alert notifying the user that the remote stopping was successful but refreshing failed
        <Alert key="manuallyRefreshRemote" variant="warning" style={{ textAlign: "center" }}>
          {t("global.alert.success.remoteStopRef")}
        </Alert>
      )}
      {forLandingPage && activeTransactions.length === 0 ? (
        //If the user is at the landing page and has no active transactions, don't show the table.
        //Simply show a header and the refresh button
        <h4 style={{ textAlign: "center" }}>
          {t("components.activeTransactions.static.header.notExist")}
          {showCheckmark ? ( //If the checkmark state is true, show a checkmark wrapped in a button element
            <Button variant="outline-ligth">
              <img src={checkmarkIcon} alt="Checkmark" />
            </Button>
          ) : showLoading ? ( //If the loading state is true, show the loading animation wrapped in a button element. Otherwise, show a refresh icon wrapped in a button element that calls the refresh() function when clicked
            <Button variant="outline-ligth">
              <img src={refreshIconAnimated} alt="Loading" style={{ width: 30 }} />
            </Button>
          ) : (
            <Button variant="outline-ligth">
              <img src={refreshIcon} alt="Refresh" onClick={refresh} />
            </Button>
          )}
        </h4>
      ) : (
        <>
          {/* Display a header for the active transactions table
              and provide a refresh button next to the header.
              NOTE! The header alignment is different depending on which component is displaying 
              the active transactions */}
          <Row className="mb-3">
            <Col xs="auto" sm="auto">
              <h4
                style={{
                  textAlign: forLandingPage ? "center" : "left",
                  margin: forLandingPage ? "20px 0 0 0" : "0",
                }}
              >
                {t("components.activeTransactions.static.header.exist")}
              </h4>
            </Col>
            <Col xs="auto" sm="auto" style={{ padding: 0 }}>
              {showCheckmark ? ( //If the checkmark state is true, show a checkmark wrapped in a button element
                <Button
                  variant="outline-ligth"
                  style={{
                    padding: 0,
                    margin: forLandingPage ? "20px 0 0 0" : "0",
                  }}
                >
                  <img src={checkmarkIcon} alt="Checkmark" />
                </Button>
              ) : showLoading ? ( //If the loading state is true, show the loading animation wrapped in a button element. Otherwise, show a refresh icon wrapped in a button element that calls the refresh() function when clicked
                <Button
                  variant="outline-ligth"
                  style={{
                    padding: 0,
                    margin: forLandingPage ? "20px 0 0 0" : "0",
                  }}
                >
                  <img src={refreshIconAnimated} alt="Loading" style={{ width: 30, padding: 0 }} />
                </Button>
              ) : (
                <Button
                  variant="outline-ligth"
                  style={{
                    padding: 0,
                    margin: forLandingPage ? "20px 0 0 0" : "0",
                  }}
                >
                  <img src={refreshIcon} alt="Refresh" onClick={refresh} />
                </Button>
              )}
            </Col>
            {forArea && (
              <Col xs="auto" sm="auto">
                <DropdownButton
                  id="dropdown-item-button"
                  title={t("components.activeTransactions.static.filter.button")}
                  drop="right"
                  style={{ maxWidth: "20rem", marginRight: "0 !important" }}
                >
                  {[
                    {
                      text: `${t("components.activeTransactions.static.filter.thisAndSub")}`,
                      key: 0,
                    },
                    {
                      text: `${t("components.activeTransactions.static.filter.onlyThis")}`,
                      key: 1,
                    },
                  ].map((item) => {
                    return (
                      <Dropdown.Item key={item.key} as="button" onClick={() => forArea.setFilterValue(item.key)}>
                        {item.text}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
              </Col>
            )}
          </Row>

          {/*for area page tabular stracture of data  since we are not using accordian there and displaying table with data*/}
          {(forArea || forChargepoint) && ( //Render the Table for the SingleArea and chargepoint component if the forArea  & chargepoint flag is true
            <>
              <ToolkitProvider bootstrap4 keyField="id" data={activeTransactions} columns={columns}>
                {(props) => (
                  <BootstrapTable
                    striped
                    bordered
                    hover
                    noDataIndication={`${
                      forArea?.filterValue
                        ? `${t("components.activeTransactions.static.body.area")}`
                        : `${t("components.activeTransactions.static.body.subAreas")}`
                    }`}
                    {...props.baseProps}
                  />
                )}
              </ToolkitProvider>
            </>
          )}
        </>
      )}
      {/* when forMyTransactions ans forArea conditions are met there is no display  otherwise ahow the accordian function tabular data */}

      {forMyTransactions || forArea || forChargepoint ? "" : AccordionFunction()}
    </>
  );
};

export default ActiveTransactions;
