import { StateHandler } from '../model/Utilities/Types';

/**
 * Utility function used for setting states to true for a specific (or default) amount of time. The stateHandler
 * parameter must be a handler for a state that holds a boolean value.
 *
 * The function sets the state of the stateHandler to true, and sets it to false after some amount of time. The
 * amount of time depends on the time parameter (given in milliseconds). If the parameter is given, the state
 * will be true for the specified amount of time. Otherwise, the state will be true for 6 seconds (6000ms).
 * @param {*} stateHandler handler of the state that needs to be changed
 * @param {*} time the specified amount of time the state will be true (defaults to false if not given)
 */
export const timer = (
  stateHandler: StateHandler<boolean>,
  time: number = 6000
) => {
  //Initially, set the state to true with the stateHandler
  stateHandler(true);

  //The setTimeout will execute the code in the first parameter after a set amount of time (second parameter)
  setTimeout(() => {
    //Set the state to false with the stateHandler
    stateHandler(false);
  }, time); //If the time is provided (i.e. anything but false), set the setTimeout to execute after the given time. Otherwise, default to 6 seconds
};
