import axios from 'axios';
import { AreaBase } from '../../model/Classes/Area';
import { Contract } from '../../model/Classes/Contract';
import StructuredResponse from '../../model/Classes/StructuredResponse';
axios.defaults.withCredentials = true;

const baseURL = '/api/reporting/contract';
const tokenURL = '/api/auth/token';

export const getContract = async (contract_id: number, url?: string) => {
  try {
    const response = await axios.get(
      `${url ? url : baseURL}/single/${contract_id}`,
      {
        withCredentials: true,
      }
    );
    return new StructuredResponse<[Contract, AreaBase[]]>(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(
        `${url ? url : baseURL}/single/${contract_id}`,
        {
          withCredentials: true,
        }
      );
      return new StructuredResponse<[Contract, AreaBase[]]>(
        true,
        response.data
      );
    } catch (e: any) {
      return new StructuredResponse<[Contract, AreaBase[]]>(false, e);
    }
  }
};

export const addContract = async (contract: Contract) => {
  try {
    const response = await axios.post(
      `/api/admin/contract/add`,
      { contract },
      {
        withCredentials: true,
      }
    );
    return new StructuredResponse(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.post(
        `/api/admin/contract/add`,
        { contract },
        {
          withCredentials: true,
        }
      );
      return new StructuredResponse(true, response.data);
    } catch (e: any) {
      return new StructuredResponse(false, e);
    }
  }
};

export const getAreaContract = async (area_id: number) => {
  try {
    const response = await axios.get(`${baseURL}/area/${area_id}`, {
      withCredentials: true,
    });
    return new StructuredResponse<Contract>(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(`${baseURL}/area/${area_id}`, {
        withCredentials: true,
      });
      return new StructuredResponse<Contract>(true, response.data);
    } catch (e: any) {
      return new StructuredResponse<Contract>(false, e);
    }
  }
};

export const getContracts = async () => {
  try {
    const response = await axios.get(`/api/admin/contract/all`, {
      withCredentials: true,
    });
    return new StructuredResponse<Contract[]>(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(`${baseURL}`, {
        withCredentials: true,
      });
      return new StructuredResponse<Contract[]>(true, response.data);
    } catch (e: any) {
      return new StructuredResponse<Contract[]>(false, e);
    }
  }
};

export const getAvailableContracts = async () => {
  try {
    const response = await axios.get(`${baseURL}/available`, {
      withCredentials: true,
    });
    return new StructuredResponse<Contract[]>(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(`${baseURL}/available`, {
        withCredentials: true,
      });
      return new StructuredResponse<Contract[]>(true, response.data);
    } catch (e: any) {
      return new StructuredResponse<Contract[]>(false, e);
    }
  }
};

export const updateContract = async (
  apiAddress: string,
  contract: Contract
) => {
  try {
    const response = await axios.post(
      `${apiAddress}/update/${contract.contract_number}`,
      contract,
      {
        withCredentials: true,
      }
    );
    return new StructuredResponse(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.post(
        `${apiAddress}/update/${contract.contract_number}`,
        contract,
        {
          withCredentials: true,
        }
      );
      return new StructuredResponse(true, response.data);
    } catch (e: any) {
      return new StructuredResponse(false, e);
    }
  }
};

export const getPrimaryManager = async (contract_number: number) => {
  try {
    const response = await axios.get(
      `/api/admin/contract/${contract_number}/primary`,
      {
        withCredentials: true,
      }
    );
    return new StructuredResponse<{ primary_manager: string }>(
      true,
      response.data
    );
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(
        `/api/admin/contract/${contract_number}/primary`,
        {
          withCredentials: true,
        }
      );
      return new StructuredResponse<{ primary_manager: string }>(
        true,
        response.data
      );
    } catch (e: any) {
      return new StructuredResponse<{ primary_manager: string }>(false, e);
    }
  }
};
