import styled from "styled-components";

/**
 * Styled component for the MyTransactions component
 */
export const StyledMyTransactions = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-flow: row wrap;
  overflow-x: auto;
  background: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  font-size: 1rem;

  h2 {
    text-align: center;
    flex-basis: 100%;
    margin-bottom: 1rem;
  }

  .logo {
    width: 50vw;
    @media only screen and (max-width: 700px) {
      width: 80vw;
    }
    @media only screen and (min-width: 700px, max-width: 1000px) {
      width: 50vw;
    }
    @media only screen and (min-width: 1000px) {
      width: 500px;
    }
  }

  .price-col {
    display: flex;
    align-items: center;

    > p {
      height: min-content;
    }
  }
  .spotPrice{
    margin:15px 0 10px 0;
    text-Align: left;
  }

  td[colspan]:not([colspan='1']) {
    text-align: center;
  }

  Accordion{
    max-height:500px
    overflow-y: auto;
    margin-bottom: 10px;
  }
  .custom-thead{
    border-bottom:grey;
  }
  .table > :not(:first-child){
    border-top:0;
  }
  .FaChevronRight{
    marginLeft: "0.2rem",
    fill: "black" 
  }
  .table-mytransactions{
    font-size: 0.9rem;}
`;
