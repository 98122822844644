import axios from 'axios';
import StructuredResponse from '../../model/Classes/StructuredResponse';
import Tag from '../../model/Classes/Tag';
axios.defaults.withCredentials = true;

const tokenURL = '/api/auth/token';

/**
 * Service function responsible for adding a new tag.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @param {*} data tag data
 * @returns if successful: [true, response.data]. Otherwise: [false, error]
 */
export const addTag = async (data: Tag) => {
  let success = true;
  try {
    const response = await axios.post('/api/user/tags', data, {
      withCredentials: true,
    });
    return new StructuredResponse<Tag>(success, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.post('/api/user/tags', data, {
        withCredentials: true,
      });
      return new StructuredResponse<Tag>(success, response.data);
    } catch (e: any) {
      success = false;
      return new StructuredResponse<Tag>(success, e);
    }
  }
};
