import axios from "axios";

import StructuredResponse from "../../model/Classes/StructuredResponse";
axios.defaults.withCredentials = true;

const tokenURL = "/api/auth/token";

/**
 * Service function for toggling alerts on/off for an area for the current user.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @param {*} areaId id of the area
 * @returns if successful: [true, response.data]. Otherwise: [false, error]
 */

export const toggleAlerts = async (areaId: number) => {
  try {
    const response = await axios.post(`/api/area/${areaId}/toggleAlerts`, null, {
      withCredentials: true,
    });
    return new StructuredResponse<boolean>(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.post(`/api/area/${areaId}/toggleAlerts`, null, {
        withCredentials: true,
      });
      return new StructuredResponse<boolean>(true, response.data);
    } catch (e: any) {
      return new StructuredResponse<boolean>(false, e);
    }
  }
};
