import axios from 'axios';
import StructuredResponse from '../../model/Classes/StructuredResponse';
axios.defaults.withCredentials = true;

const baseURL = '/api/area';
const tokenURL = '/api/auth/token';

/**
 * Service function responsible for deleting an area.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @param {*} id id of the area to be deleted
 * @returns response from the backend
 */
export const sendAreaDelete = async (id: number, data: string[]) => {
  try {
    const response = await axios.post(`${baseURL}/${id}/del`, {
      withCredentials: true,
      data,
    });
    return new StructuredResponse<number>(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.post(`${baseURL}/${id}/del`, {
        withCredentials: true,
        data,
      });
      return new StructuredResponse<number>(true, response.data);
    } catch (e: any) {
      return new StructuredResponse<number>(false, e);
    }
  }
};
