import React from "react";
import { Tooltip as BootstrapTooltip, OverlayTrigger } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/Overlay";
import styled from "styled-components";

const StyledTooltip = styled(BootstrapTooltip)`
  .tooltip-inner {
    background-color: ${(props) =>
      props.variant === "danger"
        ? `var(--bs-danger)`
        : props.variant === "warning"
        ? `var(--bs-warning)`
        : ""} !important;
    color: ${(props) => (props.variant === "warning" ? "black" : "white")};
  }
`;

declare interface TooltipProps {
  children: React.ReactElement;
  placement?: Placement;
  text?: string | JSX.Element;
  variant?: string;
}

const Tooltip = ({ placement, text, children, variant }: TooltipProps) => (
  <OverlayTrigger
    placement={placement || "right"}
    overlay={
      <StyledTooltip
        style={{
          paddingLeft: placement?.includes("right") || !placement ? "0.5rem" : "0",
          paddingRight: placement?.includes("left") ? "0.5rem" : "0",
        }}
        variant={variant}
      >
        {text}
      </StyledTooltip>
    }
  >
    {children}
  </OverlayTrigger>
);

export default Tooltip;
