import axios from 'axios';
import StructuredResponse from '../../model/Classes/StructuredResponse';
import User from '../../model/Classes/User';
axios.defaults.withCredentials = true;

const baseUrl = '/api/auth/token';

/**
 * Service function for checking validity of tokens. Used in App.js.
 * @returns If successful: [true, response]. Otherwise: [false, e]
 */
export const checkToken = async () => {
  try {
    const response = await axios.post(baseUrl);
    return new StructuredResponse<User>(true, response.data);
  } catch (e: any) {
    return new StructuredResponse<User>(false, e);
  }
};
