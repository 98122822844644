import { useEffect, useState } from "react";

import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from "recharts";

import i18N from "../../../../i18n";
import { useTranslation } from "react-i18next";
import { logger } from "../../../../utils/logger";

import { useWindowDimensions } from "../../../../model/Utilities/ReactHelpers";

import { getCpSpotprice } from "../../../../services/chargepoint/getCpSpotprice";

const SMALL_SCREEN_BREAKPOINT = 500;

export const SpotPriceWidget = ({ chargepointId }: { chargepointId?: string }) => {
  const { t } = useTranslation("common", {
    i18n: i18N,
  });

  const [spotPrices, setSpotPrices] = useState([]);
  //const [vatRate, setVatRate] = useState(0);

  const { windowWidth } = useWindowDimensions();

  useEffect(() => {
    const getData = async () => {
      try {
        if (!chargepointId) {
          throw Error("No chargepoint selected");
        }
        const spotPriceRes = await getCpSpotprice(chargepointId);

        if (spotPriceRes.success && spotPriceRes.data.length) {
          let formattedSpotPrices = spotPriceRes.data.map((entry) => {
            // data.spotPrice _might_ be a string, so parse it
            return { ...entry, spotPrice: parseFloat(String(entry.spotPrice)) };
          });
          setSpotPrices(formattedSpotPrices);
          //setVatRate(spotPriceRes.data[0].vat_rate);
        }
      } catch (e) {
        logger(e);
      }
    };

    getData();
  }, [chargepointId]);

  const tickFormatterY = (tickValue: any): string => {
    return Number(tickValue).toFixed(1) + "¢";
  };

  const tickFormatterX = (tickValue: any): string => {
    return String(new Date(tickValue).getHours()).padStart(2, "0");
  };

  const labelFormatter = (label: any) => {
    return windowWidth < SMALL_SCREEN_BREAKPOINT ? "" : tickFormatterY(label);
  };

  const sliceSize = windowWidth < SMALL_SCREEN_BREAKPOINT ? 9 : 12;

  return (
    <div>
      <ResponsiveContainer width="100%" height={200}>
        <BarChart data={spotPrices.slice(0, sliceSize)} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis tickFormatter={tickFormatterX} dataKey="local_time" />
          <YAxis
            tickFormatter={tickFormatterY}
            type="number"
            domain={["auto", (dataMax) => Math.max(dataMax + 1, 8)]}
          />
          <Bar dataKey="spotPrice" fill="orange" label={{ position: "top", fontSize: 11, formatter: labelFormatter }}>
            {spotPrices.map((entry, index) => (
              <Cell key={index} stroke={index === 0 ? "black" : "none"} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>

      <p className="mt-3 text-center">
        {t("components.startTransaction.nordpoolTip")}
        &nbsp;
        <a
          href="https://data.nordpoolgroup.com/auction/day-ahead/prices?deliveryAreas=FI,SE1,SE2,SE3,SE4&currency=EUR&aggregation=Hourly"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("components.startTransaction.nordpool")}
        </a>
      </p>
    </div>
  );
};
