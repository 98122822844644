import React, { SetStateAction } from 'react';

//Mostly miscellaneous types / interfaces / enums
//that dont really belong to a specific model
export type StateHandler<T> = React.Dispatch<SetStateAction<T>>;
export type Nullable<T> = T | null;
export type NumericBoolean = typeof NumBool[keyof typeof NumBool];

export const NumBool = {
  t: 1,
  f: 0,
} as const;

export type Key<T> = keyof T;

export const ContractStatus = {
  0: 'created',
  1: 'pending',
  2: 'signed',
  3: 'cancelled',
} as const;

export type TContractStatus = typeof ContractStatus[Key<typeof ContractStatus>];

export const InvoiceChannel = {
  6: 'none',
  1: 'email',
  2: 'mail',
  3: 'evoice',
} as const;

export type TInvoiceChannel = typeof InvoiceChannel[Key<typeof InvoiceChannel>];

export const InvoicingMethod = {
  0: 'test',
  1: 'reporting',
  2: 'invoice',
} as const;

export type TInvoicingMethod = typeof InvoicingMethod[Key<
  typeof InvoicingMethod
>];

//At runtime these don't differ
export type SearchPeriod = 'this' | 'previous' | 'quarter' | 'year' | ''; //if you like strings i guess
export enum Period { //if you'd rather not work with strings
  thisMonth = 'this',
  previousMonth = 'previous',
  previousQuarter = 'quarter',
  previousYear = 'year',
}

export interface SearchType {
  time: string;
  period: string;
  displayTime: string;
}

export interface ReportingData {
  area_id: number;
  start_date: string;
  display_stop_date: string;
  stop_date: string;
  start_time: string;
  display_stop_time: string;
  stop_time: string;
  display_start_date?: string;
  display_start_time?: string;
}

export enum ChargepointDropdownFlags {
  CheckAccess = 'CheckAccess',
  HandleSelection = 'HandleSelection',
}

export type ChargepointDropdownStrings = 'CheckAccess' | 'HandleSelection';

export class FlagError extends Error {
  message: string;

  constructor(flag: string, callbackName: string) {
    super();
    this.message = `A defined callback is required for '${callbackName}' if '${flag}' is set as a flag`;
  }
}

export enum LogLevel {
  INFO = 'INFO',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}
