import {
  ButtonGroup,
  ToggleButton,
  Alert,
  InputGroup,
  Form,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';
import AreaSingle, { AreaFull } from '../../../../model/Classes/Area';
import { StateHandler } from '../../../../model/Utilities/Types';
import { useEffect, useState } from 'react';

declare interface AreaMLBProps {
  canBeMasterLoadBalance: boolean;
  disabledFields: boolean;
  showMasterLoadBalancingAlert: boolean;
  masterLoadBalance: string;
  setMasterLoadBalance: StateHandler<string>;
  childrenWithMasterLoadBalance: AreaSingle[];
  parentsWithMasterLoadBalance: AreaSingle[];
  editArea: boolean;
  addArea: boolean;
  limit: string | number;
  handleLimit: (event: any) => void;
  allAreas?: AreaSingle[];
  area?: AreaFull;
  parent?: AreaFull;
}

export const AreaMLBSettings = ({
  canBeMasterLoadBalance,
  disabledFields,
  masterLoadBalance,
  setMasterLoadBalance,
  showMasterLoadBalancingAlert,
  childrenWithMasterLoadBalance,
  parentsWithMasterLoadBalance,
  editArea,
  limit,
  handleLimit,
  allAreas,
  area,
  addArea,
  parent,
}: AreaMLBProps) => {
  const { t } = useTranslation('common', { i18n: i18n });
  const masterLoadBalances = [
    { name: t('components.area.addArea.static.form.mlb.notHas'), value: '0' },
    { name: t('components.area.addArea.static.form.mlb.has'), value: '1' },
  ];

  //creating state and function to create the LB for each area
  const [masterLoadBalancer, setMasterLoadBalancer] = useState<Boolean>(false);
  const [lbArea, setLbArea] = useState<Boolean>(false);

  const findParentArea = (parentId: number | undefined) => {
    if (!parentId) parentId = 0;
    return allAreas?.find((a: AreaSingle) => a.id === parentId);
  };

  useEffect(() => {
    let parentId = parent?.id;

    let reachedRootArea = false;
    do {
      const parentArea = findParentArea(parentId);
      reachedRootArea = parentArea === undefined;

      if (parentArea?.master_load_balancing === 1) {
        setMasterLoadBalancer(true);
        setLbArea(true);
        reachedRootArea = true;
      } else {
        parentId = parentArea?.parent;
      }
    } while (!reachedRootArea);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area]);

  return (
    <div>
      <h4>{t('components.area.addArea.static.form.headers.mlb')}</h4>
      <ButtonGroup className="mb-3">
        {masterLoadBalances.map((radio, idx) => {
          let disable = !canBeMasterLoadBalance || disabledFields;
          return (
            <ToggleButton
              key={radio.name}
              id={`load-radio-${idx}`}
              type="checkbox"
              variant={idx % 2 ? 'outline-success' : 'outline-danger'}
              name="radio"
              value={radio.value}
              checked={masterLoadBalance === radio.value}
              onChange={(e) => setMasterLoadBalance(e.currentTarget.value)}
              disabled={disable}
              data-cy={`editAreaMLBButton-${idx}`}
            >
              {` ${radio.name}`}
            </ToggleButton>
          );
        })}
      </ButtonGroup>
      {/*If the area contains any subareas that are master load balancing areas, show an alert
        notifying the user that they cannot toggle master load balancing for this area (unless
        they disable master load balancing area for those children)*/}
      {editArea && childrenWithMasterLoadBalance.length > 0 ? (
        <Alert
          key="childrenMasterLoadBalance"
          variant="info"
          data-cy="childrenMasterLoadBalanceAlert"
        >
          {t('global.alert.failure.childMlb')}
          <ul>
            {childrenWithMasterLoadBalance.map((child, idx) => {
              return (
                <li
                  key={`${child.name}!${idx}`}
                  data-cy={`childrenMasterLoadBalance-${idx}`}
                >
                  {child.name}
                </li>
              );
            })}
          </ul>
        </Alert>
      ) : (
        <></>
      )}
      {/*If the area contains any direct parents that are master load balancing areas, show an alert
        notifying the user that they cannot toggle master load balancing for this area (unless
        they disable master load balancing area for that parent)*/}
      {parentsWithMasterLoadBalance.length > 0 ? (
        <Alert
          key="parentWithMasterLoadBalance"
          variant="info"
          data-cy="parentWithMasterLoadBalanceAlert"
        >
          {t('global.alert.failure.parentMlb')}
          <ul>
            {parentsWithMasterLoadBalance.map((parent, idx) => {
              return (
                <li
                  key={`${parent.name}!${idx}`}
                  data-cy="parentWithMasterLoadBalance"
                >
                  {parent.name}
                </li>
              );
            })}
          </ul>
        </Alert>
      ) : (
        <br />
      )}

      {/*adding the limit datafield*/}
      {/**
      {Number(parentsWithMasterLoadBalance) !== 0 && (*/}
      <InputGroup className="mb-3">
        <InputGroup.Text id="limit">
          {t('components.area.addArea.static.form.limit')}
        </InputGroup.Text>

        <Form.Control
          type="text"
          value={limit}
          min="0"
          aria-label={'area.a_limit'}
          aria-describedby="limit"
          disabled={
            (addArea &&
              ((lbArea || masterLoadBalancer) && masterLoadBalance === '1'
                ? true
                : false)) ||
            (editArea &&
              ((!lbArea || !masterLoadBalancer) && masterLoadBalance === '0'
                ? true
                : false))
          }
          onChange={handleLimit}
          data-cy="editAreaLimitField"
        />
      </InputGroup>
      {/**    )}*/}

      {showMasterLoadBalancingAlert && (
        <Alert key="invalidMasterLoadBalance" variant="danger">
          {t('global.alert.failure.mlb')}
        </Alert>
      )}
    </div>
  );
};
