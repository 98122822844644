import { useEffect } from 'react';
/**
 * Helper function for the hamburger menu. If the menu is open and the user clicks outside of it (or on
 * a link), it closes the menu
 * @param {*} ref the reffered component
 * @param {*} handler handler function
 */
export const useOnClickOutside = (ref: any, handler: any) => {
  useEffect(() => {
    const listener = (event: { target: any }) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
};

/**
 * Hook for calling useEffect on tab click
 *
 * Note: This hook should not depend on the eventKeys provided OR the callback function provided,
 * it simply checks if the activeEventKey is contained in eventKeys and calls the callback function if so
 * @param activeEventKey The current eventKey
 * @param eventKeys An array of tab eventKeys to compare against
 * @param callback function to call after correct eventKey is selected
 */
export const useOnTabClicked = (
  activeEventKey: string,
  eventKeys: string[],
  callback: () => void,
  deps?: unknown[]
) => {
  const additionalDeps = deps ? deps : [];
  useEffect(() => {
    if (eventKeys.includes(activeEventKey)) callback();
    //This hook is not dependant on either the eventKeys provided or the callback
    //eslint-disable-next-line
  }, [activeEventKey, ...additionalDeps]);
};
