import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { StateHandler } from '../../../../model/Utilities/Types';

declare interface PrefixFormProps {
  prefix: string;
  setBtsValue: StateHandler<number>;
  initialValue: number;
}

export const PrefixForm = ({
  prefix,
  setBtsValue,
  initialValue,
}: PrefixFormProps) => {
  const [displayValue, setDisplayValue] = useState<string>(prefix);

  const handleChange = (eventValue: string) => {
    //if user tries to empty the field
    if (eventValue.length < prefix.length) {
      setDisplayValue(prefix);
      setBtsValue(0);
    }
    if (eventValue.startsWith(prefix)) {
      //Set displayValue to "prefix + userInput"
      setDisplayValue(eventValue);
      //set btsValue to "userInput"
      setBtsValue(Number(extractSubString(eventValue, prefix)));
    }
  };
  //extracts a substring which is the displayValue minus the prefix
  const extractSubString = (value: string, prefix: string) => {
    //debating whether or not to return this as a number or just introduce several overloads
    //if this is ever needed elsewhere
    return value.substring(prefix.length, value.length);
  };

  useEffect(() => {
    const initialise = () => {
      setDisplayValue(
        initialValue !== 0 ? `${prefix}${initialValue}` : `${prefix}`
      );
    };
    initialise();
  }, [initialValue, prefix]);

  return (
    <Form.Control
      value={displayValue}
      onChange={(event) => handleChange(event.target.value)}
    ></Form.Control>
  );
};

export default PrefixForm;
