import styled from 'styled-components';

/**
 * Styled component for the login page
 */
export const StyledLogin = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  background: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  font-size: 1rem;
  overflow-x: auto;

  .row {
    justify-content: center;
  }

  .logo,
  .form {
    width: 50vw;
    @media only screen and (max-width: 1024px) {
      width: 80vw;
    }
  }

  .secondButtonCol {
    display: flex;
    justify-content: center;
    @media only screen and (min-width: 1024px) {
      justify-content: right;
    }
  }

  h2 {
    text-align: center;
    flex-basis: 100%;
    margin-bottom: 1rem;
  }

  .link-button {
    margin: 5px;
    background: none !important;
    border: none;
    padding: 0 !important;
    color: ${({ theme }) => theme.lightGreen};
    text-decoration: underline;
    cursor: pointer;
  }
`;
