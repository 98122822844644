import { LogLevel } from '../model/Utilities/Types';

/**
 * Utility function for logging error messages. If the parameter e contains a custom error message
 * from the backend, log it. Otherwise, log the standard error message.
 *
 * NOTE! Logging only happens outside production (in development and testing)
 * @param {*} e the error message/object
 * @param {*} logLevel the LogLevel for the log. Default is LogLevel.ERROR
 */
export const logger = (e: any, logLevel: LogLevel = LogLevel.INFO) => {
  if (process.env.NODE_ENV !== 'production') {
    //If the error came as a response from the backend, log the response data. Otherwise,
    //just log the response
    const msg = e.response ? e.response.data : e;

    setLoggingType(logLevel)(logLevel, msg);
  }
};

function setLoggingType(logLevel: LogLevel) {
  return logLevel === LogLevel.ERROR
    ? console.error
    : logLevel === LogLevel.WARNING
    ? console.warn
    : console.log;
}
