import axios from 'axios';
axios.defaults.withCredentials = true;

const tokenURL = '/api/auth/token';
const baseUrl = '/api/user/cards';

/**
 * Service function responsible for getting your cards
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @returns response from the backend
 */
export const getCards = async () => {
  try {
    return await axios.get(baseUrl);
  } catch (e) {
    await axios.post(tokenURL, { withCredentials: true });
    return await axios.get(baseUrl);
  }
};
