import axios from 'axios';
axios.defaults.withCredentials = true;

const tokenURL = '/api/auth/token';

/**
 * Service function responsible for fetching info regarding the users email invoicing
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @returns if successful: [true, response.data]. Otherwise: [false, error]
 */
export const getEmailInvoiceInfo = async () => {
  try {
    const response = await axios.get('/api/user/emailinvoicing');
    return response.data;
  } catch (e) {
    await axios.post(tokenURL, { withCredentials: true });
    const response = await axios.get('/api/user/emailinvoicing');
    return response.data;
  }
};
