import { isUndefined } from 'lodash';
import AreaSingle from '../../../../model/Classes/Area';
import AreaMeter from '../../../../model/Classes/AreaMeter';
import { Nullable, StateHandler } from '../../../../model/Utilities/Types';

/**
 * Helper function that calls a recursive function for getting
 * 1) all the subareas for an area
 * 2) all the subareas that are master load balancing areas
 * The recursive function adds the given area to the subArea array and checks if it has any children.
 * If it does, it checks if they're a master load balancing area (if yes, add it to an array), and call the
 * function recursively for all the children.
 * @param {*} beginningArea area to begin the recursive function from
 * @param {*} allAreas all the areas the user has access to
 * @param {*} setCanBeMasterLoadBalance state handler for setting the state knowing if the area can be toggles as a master load balancing area or not
 * @return array with two array: [[area + all the areas subareas], [all the areas children that area master load balancing areas]]
 */
export const getSubareasAndCheckMasterLoadBalance = (
  beginningArea: AreaSingle,
  allAreas: AreaSingle[],
  setCanBeMasterLoadBalance: StateHandler<boolean>
) => {
  const areaAndSubareas: AreaSingle[] = [];
  const childrenMasterLoadBalance: AreaSingle[] = [];

  /**
   * Recursive helper function for getting all the subarea (+ starting area) as well as check if any subarea is a
   * master load balancing area or not.
   * The given area is added to the subarea array areaAndSubareas, and all it's children (if it has any) are then examined.
   * The function checks if a child is a master load balancing area (if yes, add it to the array childrenMasterLoadBalance),
   * and recursively call the function again for each child. The recursive call ends when a the area examined does not have
   * any children.
   * @param {*} area area to be checked
   */
  const getRec = (area: AreaSingle) => {
    areaAndSubareas.push(area);
    if (area.children!.length > 0) {
      let children = allAreas.filter((a) => a.parent === area.id);
      children = children.filter((child) => child.id !== 0);
      children.forEach((child) => {
        if (child.master_load_balancing) {
          childrenMasterLoadBalance.push(child);
          setCanBeMasterLoadBalance(false);
        }
        getRec(child);
      });
    }
  };

  getRec(beginningArea);

  return [
    areaAndSubareas.filter((a) => a.id !== beginningArea.id),
    childrenMasterLoadBalance.filter((a) => a.id !== beginningArea.id),
  ];
};

/**
 * Recursive helper function for getting parents for a specified area that area master load balancing areas.
 * Recursively checks parents up until user_root area. If it finds an area that is a master load balancing area,
 * it returns an array with the parent. If the function reaches the user root (i.e. no parent is a master load
 * balancing area), exit the loop (return value = undefined).
 * @param {*} allAreas all the areas the user has access to
 * @param {*} areaToCheck the area that is checked if it's a master load balancing area or not
 * @param {*} setCanBeMasterLoadBalance state handler for setting the state knowing if the area can be toggles as a master load balancing area or not
 * @return if a parent that is a master load balancing area was found: return [parent]. Else: return undefined
 */
export const getParentsMasterLoadBalance = (
  allAreas: AreaSingle[],
  areaToCheck: AreaSingle,
  setCanBeMasterLoadBalance: StateHandler<boolean>
) => {
  //If the area to be checked is a master load balancing area.
  if (areaToCheck) {
    if (areaToCheck.master_load_balancing) {
      setCanBeMasterLoadBalance(false);
      return [areaToCheck];
    }

    //If we reached the end, return exit the loop.
    if (areaToCheck.user_root) return;
    //Otherwise, call the function recursively with the next parent.
    else {
      const nextArea = allAreas.filter(
        (area: AreaSingle) => area.id === areaToCheck.parent
      )[0];
      return getParentsMasterLoadBalance(
        allAreas,
        nextArea,
        setCanBeMasterLoadBalance
      );
    }
  } else return;
};

/**
 * Helper function for finding db matches,
 * either an areaId or a meterPublicId is required
 * @param {*} area_meters meters from db
 * @param {number} meterIdentifier the public_id of the meter which is being assigned or withdrawn to/from an area
 * @param {boolean} searchForArea true if searching for meter by area_id, false if searching by WDLM-ID
 * @returns `Nullable<AreaMeter>`
 */
export const tryMatchMeter = (
  area_meters: AreaMeter[],
  meterIdentifier: Nullable<number> = null,
  searchForArea: boolean
): AreaMeter[] => {
  if (meterIdentifier === null) {
    return [];
  }
  const match = area_meters.filter((meter) => {
    return searchForArea
      ? meter.area_id === Number(meterIdentifier)
      : meter.public_id === Number(meterIdentifier);
  });

  if (isUndefined(match)) {
    return [];
  }

  return match;
};
