import axios from 'axios';
import CpFull from '../../model/Classes/Chargepoint';
import StructuredResponse from '../../model/Classes/StructuredResponse';
axios.defaults.withCredentials = true;

const tokenURL = '/api/auth/token';

/**
 * Service function responsible for fetching all chargepoints for an area and its subareas
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @returns if successful: [true, response.data]. Otherwise: [false, error]
 */
export const getChargepoints = async (id: number) => {
  try {
    const response = await axios.get(`/api/area/${id}/chargepoints`);
    return new StructuredResponse<CpFull[]>(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(`/api/area/${id}/chargepoints`);
      return new StructuredResponse<CpFull[]>(true, response.data);
    } catch (e: any) {
      return new StructuredResponse<CpFull[]>(false, e);
    }
  }
};

export const getChargepointsOverload = async (id: number) => {
  try {
    const response = await axios.get(`/api/area/${id}/chargepoints/all`);
    return new StructuredResponse<CpFull[]>(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(`/api/area/${id}/chargepoints/all`);
      return new StructuredResponse<CpFull[]>(true, response.data);
    } catch (e: any) {
      return new StructuredResponse<CpFull[]>(false, e);
    }
  }
};
