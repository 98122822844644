import axios from 'axios';
axios.defaults.withCredentials = true;

const tokenURL = '/api/auth/token';

export const chargeUserInvoices = async () => {
  try {
    const response = await axios.post('/api/user/invoices/charge');
    return response.data;
  } catch (e) {
    await axios.post(tokenURL, { withCredentials: true });
    const response = await axios.post('/api/user/invoices/charge');
    return response.data;
  }
};
