import axios from "axios";
import StructuredResponse from "../../model/Classes/StructuredResponse";
axios.defaults.withCredentials = true;

const tokenURL = "/api/auth/token";
const apiAddress = "/api/area/user";

/**
 * Service function responsible for fetching all areas the user has access to.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @param {*} apiAddress the api address to fetch data from
 * @returns `StructuredResponse<[T[], ChargePoint[]]>(boolean, response.data)`.
 */
export const getUserRootArea = async () => {
  try {
    const response = await axios.get(apiAddress);
    return new StructuredResponse<number>(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(apiAddress);
      return new StructuredResponse<number>(true, response.data);
    } catch (e: any) {
      return new StructuredResponse<number>(false, e);
    }
  }
};

export const getUserRootAreas = async () => {
  try {
    const response = await axios.get(apiAddress);
    return new StructuredResponse<{ area_id: number; primary: number; alerts: number }[]>(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(apiAddress);
      return new StructuredResponse<{ area_id: number; primary: number; alerts: number }[]>(true, response.data);
    } catch (e: any) {
      return new StructuredResponse<{ area_id: number; primary: number; alerts: number }[]>(false, e);
    }
  }
};
