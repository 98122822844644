import axios from 'axios';
import StructuredResponse from '../../model/Classes/StructuredResponse';
axios.defaults.withCredentials = true;

const tokenURL = '/api/auth/token';

/**
 * Service function responsible for adding a new card through PayTrail
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @returns response from the backend
 */
export const addCard = async () => {
  try {
    const response = await axios.get('/api/user/cards/cardForm');
    return new StructuredResponse(true, response.data);
  } catch (e: any) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get('/api/user/cards/cardForm');
      return new StructuredResponse(true, response.data);
    } catch (e: any) {
      return new StructuredResponse(false, e);
    }
  }
};
