import { Row, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import { AreaBase } from '../../../model/Classes/Area';
import { Period } from '../../../model/Utilities/Types';

declare interface QuickReportsProps {
  currArea: AreaBase;
  searching: boolean;
  searchComplete: boolean;
  /*   searchThisMonth: (currArea: AreaBase) => void;
  searchPreviousMonth: (currArea: AreaBase) => void;
  searchPreviousQuarter: (currArea: AreaBase) => void;
  searchPreviousYear: (currArea: AreaBase) => void; */
  search: (area: AreaBase, period: Period) => Promise<void>;
}

export const QuickReports = ({
  currArea,
  searching,
  searchComplete,
  search,
}: QuickReportsProps) => {
  const { t } = useTranslation('common', { i18n: i18n });

  return (
    <>
      {/*Row containing the quick reports header*/}
      <Row className="mb-1">
        <h5>{t('components.reporting.tabs.completed.quick')}</h5>
      </Row>
      {/*Row containing the "This month" quick report. It's disabled if either the searching or searchComplete
                   states are true. When clicked, call the searchThisMonth() utility function*/}
      <Row>
        <Button
          variant="outline-primary"
          onClick={async () => search(currArea, Period.thisMonth)}
          disabled={searching || searchComplete}
        >
          {t('components.reporting.tabs.completed.timePeriod.this')}
        </Button>
      </Row>
      {/*Row containing the "Previous month" quick report. It's disabled if either the searching or searchComplete
                   states are true. When clicked, call the searchPreviousMonth() utility function*/}
      <Row>
        <Button
          variant="outline-primary"
          onClick={async () => search(currArea, Period.previousMonth)}
          disabled={searching || searchComplete}
        >
          {t('components.reporting.tabs.completed.timePeriod.previous')}
        </Button>
      </Row>
      {/*Row containing the "Previous quarter" quick report. It's disabled if either the searching or searchComplete
                   states are true. When clicked, call the searchPreviousQuarter() utility function*/}
      <Row>
        <Button
          variant="outline-primary"
          onClick={async () => search(currArea, Period.previousQuarter)}
          disabled={searching || searchComplete}
        >
          {t('components.reporting.tabs.completed.timePeriod.quarter')}
        </Button>
      </Row>
      {/*Row containing the "Previous year" quick report. It's disabled if either the searching or searchComplete
                   states are true. When clicked, call the searchPreviousYear() utility function*/}
      <Row className="mb-4">
        <Button
          variant="outline-primary"
          onClick={async () => search(currArea, Period.previousYear)}
          disabled={searching || searchComplete}
        >
          {t('components.reporting.tabs.completed.timePeriod.year')}
        </Button>
      </Row>
    </>
  );
};
