import React from "react";
import { Row, Image } from "react-bootstrap";
import { StyledPaymentToS } from "./PaymentToS.styled";
import logo from "../../../resources/WatteryLogoGreen.svg";

/**
 * Component responsible for rendering the Payment Terms of Service page
 * @returns Payment ToS page
 */
const PaymentToS = () => {
  return (
    <StyledPaymentToS className="top-level-component">
      <Image className="logo" src={logo} alt="Wattery logo" />
      <Row>
        <h2>PALVELUNTARJOAJA</h2>
        <p>
          Wattery Oy <br />
          3421963-3 <br />
          Tilhenkatu 23 <br />
          20610 TURKU
        </p>
      </Row>
      <Row>
        <h2>PALVELU JA SEN KÄYTTÖÖNOTTO</h2>
        <p>
          Palveluntarjoaja ylläpitää Wattery-palvelua, jonka avulla käyttäjä voi maksua vastaan käyttää
          Wattery-palveluun liitetyssä käyttöpaikassa olevia latauspisteitä sähköautojen lataamiseen. Palvelussa
          käyttäjä voi myös seurata omia latausaktiviteettejaan.
        </p>
        <p>
          Käyttääkseen Wattery-palvelua, käyttäjän on rekisteröidyttävä palveluun sekä hyväksyttävä tämä käyttösopimus
          liitteineen.
        </p>
      </Row>
      <Row>
        <h2>PALVELUMAKSU</h2>
        <p>
          Palveluntarjoaja perii käyttäjältä latausmaksun kulloinkin voimassa olevan hinnaston ja muiden maksuehtojen
          mukaisesti. Kulloinkin voimassa oleva käyttöpaikan hinta ilmoitetaan Wattery-palvelussa ja/tai
          mobiilisovelluksessa.
        </p>
        <p>Maksu peritään kuukausittain jälkikäteen Wattery-palvelussa toteutuneiden lataustapahtumien mukaisesti.</p>
      </Row>
      <Row>
        <h2>SOPIMUKSEN VOIMASSAOLO</h2>
        <p>Sopimus on voimassa toistaiseksi ja se voidaan irtisanoa päättymään yhden kuukauden irtisanomisajalla.</p>
      </Row>
    </StyledPaymentToS>
  );
};

export default PaymentToS;
