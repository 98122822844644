import axios from 'axios';
axios.defaults.withCredentials = true;

const baseURL = '/api/area';
const tokenURL = '/api/auth/token';

/**
 * Service function responsible for sending data containing changes made to a chargepoint to the backend.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @param {*} areaId the area the chargepoint belongs to
 * @param {*} cpId the id of the chargepoint
 * @param {*} data chargepoint data
 * @returns response from the backend
 */
export const sendChargepointChanges = async (areaId, cpId, data) => {
  try {
    const response = await axios.post(
      `${baseURL}/${areaId}/chargepoint/${cpId}/edit`,
      data,
      { withCredentials: true }
    );
    return response.data;
  } catch (e) {
    await axios.post(tokenURL, { withCredentials: true });
    const response = await axios.post(
      `${baseURL}/${areaId}/chargepoint/${cpId}/edit`,
      data,
      { withCredentials: true }
    );
    return response.data;
  }
};
