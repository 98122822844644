import { Link } from 'react-router-dom';
import { Table, ListGroup } from 'react-bootstrap';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import AreaSingle, { AreaFull } from '../../../../model/Classes/Area';
import i18N from '../../../../i18n';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import User from '../../../../model/Classes/User';

declare interface SingleAreaGeneralProps extends SingleAreaSubareasProps {
  parent: AreaSingle;
  isReporting: number;
  allAreas: AreaSingle[];
  area: AreaFull;
  user: User;
}
/**
 * Component responsible for displaying the general info table for a single area. The table has the following information:
 * - If the area has an area meter or not
 *      - If it has, also shows the backup area limit
 * - If the area is a master load balancing area or not
 * - The limit in ampere for this area
 * - The parent for this area
 * @param {*} area the area is that being handled
 * @param {*} parent the parent of the area being handled
 * @returns general info table
 */
export const SingleAreaGeneral = ({
  area,
  parent,
  isReporting,
  allAreas,
  user,
}: SingleAreaGeneralProps) => {
  const { t } = useTranslation('common', {
    i18n: i18N,
  });

  const [masterLoadBalancer, setMasterLoadBalancer] = useState<Boolean>(false);
  const [lbArea, setLbArea] = useState<Boolean>(false);

  const accessMap = [
    {
      name: t('components.area.static.singleArea.header.areaType.free'),
      value: 0,
    },
    {
      name: t('components.area.static.singleArea.header.areaType.private'),
      value: 1,
    },
    {
      name: t('components.area.static.singleArea.header.areaType.privPub'),
      value: 2,
    },
    {
      name: t('components.area.static.singleArea.header.areaType.public'),
      value: 3,
    },
  ];

  const findParentArea = (parentId: number | undefined) => {
    if (!parentId) return;
    return allAreas.find((a: AreaSingle) => a.id === parentId);
  };

  useEffect(() => {
    /* getAllRoot().then((data: number[]) => {
      const rootIds = data;
     
    });*/
    let parentId = area?.parent;
    let reachedRootArea = false;
    do {
      const parentArea = findParentArea(parentId);
      reachedRootArea = parentArea === undefined;
      if (parentArea?.master_load_balancing === 1) {
        setMasterLoadBalancer(true);
        setLbArea(true);
        reachedRootArea = true;
      } else {
        parentId = parentArea?.parent;
      }
    } while (!reachedRootArea);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area]);

  return (
    <>
      <h4>{t('components.area.static.singleArea.header.general')}</h4>
      <Table className="mb-10" responsive="xl" striped bordered hover>
        <tbody>
          {area.access_type !== undefined && (
            <>
              <tr>
                <th>
                  {t('components.area.static.singleArea.header.areaType.type')}
                </th>
                <td>{accessMap[area.access_type].name}</td>
              </tr>
            </>
          )}
          <tr>
            <th>{t('components.area.static.singleArea.header.isReporting')}</th>
            <td>
              {isReporting ? (
                <span className="Yes">
                  {t(
                    'components.area.static.singleArea.header.reportingTrue.yes'
                  )}
                </span>
              ) : (
                <span className="No">
                  {t(
                    'components.area.static.singleArea.header.reportingTrue.no'
                  )}
                </span>
              )}
            </td>
          </tr>
          <tr>
            <th>
              <span>
                {t('components.area.static.singleArea.header.mlb.has')}
              </span>
            </th>
            <td>
              {area.master_load_balancing === 1 ? (
                <span className="Yes">
                  {t('components.area.static.singleArea.header.mlb.yesMaster')}
                </span>
              ) : masterLoadBalancer ? (
                <span className="Yes">
                  {t('components.area.static.singleArea.header.mlb.yes')}
                </span>
              ) : (
                <span className="No">
                  {t('components.area.static.singleArea.header.mlb.no')}
                </span>
              )}
            </td>
          </tr>

          {area.master_load_balancing === 1 || lbArea ? (
            <tr>
              <th>{t('components.area.static.singleArea.header.limit')}</th>
              <td>
                {area.a_limit === null
                  ? t('components.area.static.singleArea.header.noLimit')
                  : area.a_limit === 0
                  ? 0
                  : area.a_limit}
              </td>
            </tr>
          ) : null}
          {area.master_load_balancing === 1 || lbArea ? (
            <tr>
              <th>{t('components.area.static.singleArea.header.meter.has')}</th>
              <td>
                {area.has_meter === 1 ? (
                  <span className="Yes">
                    {t('components.area.static.singleArea.header.meter.yes')}
                  </span>
                ) : (
                  <span className="No">
                    {t('components.area.static.singleArea.header.meter.no')}
                  </span>
                )}
              </td>
            </tr>
          ) : null}
          {area.has_meter === 1 && (
            <tr>
              <th>{t('components.area.static.singleArea.header.onFailure')}</th>
              <td>{area.meter_fail_limit}</td>
            </tr>
          )}
          {/*If the user has access to the parent, display it. The only case when the user does not have access to the parent is when the user is viewing their user root area.*/}
          {parent && (
            <tr>
              <th>{t('components.area.static.singleArea.header.parent')}</th>
              <td>
                <Link to={`/area/${parent.id}`}>{parent.name}</Link>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

declare interface SingleAreaSubareasProps {
  area: AreaFull;
}
/**
 * Component responsible for displaying a list with all the subareas of this area (if any).
 * @param {*} area the area is being handled
 * @returns subareas list
 */
export const SingleAreaSubareas = ({ area }: SingleAreaSubareasProps) => {
  const { t } = useTranslation('', {
    i18n: i18N,
  });
  return (
    //I do not remember why this check was needed first, but I think it was due to the fact that the children
    //array was at some point undefined when the area didn't have any children. This shouldn't be the case
    //anymore, but I'll leave this check here just in case...
    //NOTE: after refactoring to TypeScript the AreaBase object is setup so that the array of children isn't necessarily
    //supplied (it is defined as type AreaObj[] | undefined), otherwise it would have to be supplied everytime an
    //AreaObj is sent to the backend via service functions containing contracts (ie the input parameter isn't of type 'any')
    area.children ? (
      area.children.length > 0 ? ( //Check if the area has any children
        <>
          <h4>{t('components.area.static.singleArea.header.hasSubAreas')}</h4>
          <ListGroup className="subareas-list">
            {area.children.map((child, idx) => {
              //The reason for this check is that the absolute root area has an ID of 0
              //(the first element in the child array is the child id), and we don't want
              //to display absolute root area as its own subarea.
              if (child.id !== 0) {
                return (
                  //dont like this, find out why it maps the children this way
                  <ListGroup.Item key={idx} data-cy="subarea-row">
                    <Link to={`/area/${child.id}`}>{child.name}</Link>
                  </ListGroup.Item>
                );
              }
              return null;
            })}
          </ListGroup>
        </>
      ) : (
        <h5>{t('components.area.static.singleArea.header.noSubAreas')}</h5>
      )
    ) : null
  );
};

/*
  const handleZoom = (domain) => {
    setZoomedXDomain(domain.x);
    setSelectedDomainState(domain);
  };

  const handleBrush = (domain) => {
    setZoomDomainState(domain);
  };

useEffect(() => {
  const getEntireDomain = () => {
    return {
      y: [
        _.minBy(L1data, (d) => d.value).value,
        _.maxBy(L1data, (d) => d.value).value,
      ],
      x: [L1data[0].date, _.last(L1data).date],
    };
  };

  setEntireDomain(L1data ? getEntireDomain() : null);
}, [L1data]);

return L1data && entireDomain ? (
    <div className="mb-3">
      <div>
        <VictoryChart
          domain={entireDomain}
          scale={{ x: 'time' }}
          width={550}
          theme={VictoryTheme.material}
          containerComponent={
            <VictoryZoomVoronoiContainer
              zoomDomain={zoomDomainState}
              onZoomDomainChange={handleZoom.bind(this)}
              zoomDimension="x"
              voronoiDimension="x"
              mouseFollowTooltips
              labels={({ datum }) => {
                return `L: ${datum.value}`;
                `${
                  datum.style.data.fill === L1color
                    ? `Date: ${pad(d.getDate())}-${pad(
                        d.getMonth() + 1
                      )}-${d.getFullYear()} ${pad(d.getHours())}:${pad(
                        d.getMinutes()
                      )}:${pad(d.getSeconds())}\n`
                    : ''
                }
              }}
              labelComponent={
                <VictoryTooltip
                  cornerRadius={0}
                  flyoutStyle={{ fill: 'white' }}
                />
              }
            />
          }
        >
          <VictoryLabel
            text="Load history"
            textAnchor="middle"
            x={275}
            y={20}
          />
          <VictoryLegend
            orientation="horizontal"
            y={10}
            gutter={30}
            data={[
              { name: 'L1', symbol: { fill: L1color } },
              { name: 'L2', symbol: { fill: L2color } },
              { name: 'L3', symbol: { fill: L3color } },
            ]}
          />
          <VictoryAxis dependentAxis label="Ampere [A]" />
          <VictoryAxis />
          <VictoryScatter
            style={{ data: { fill: L1color } }}
            size={2}
            data={getL1Data()}
            x="date"
            y="value"
          />
          <VictoryScatter
            style={{ data: { fill: L2color } }}
            size={2}
            data={getL2Data()}
            x="date"
            y="value"
          />

          {/*<VictoryLine
            interpolation="stepAfter"
            data={getL2Data()}
            x="date"
            y="value"
            style={{ data: { stroke: L3color, strokeWidth: 1 } }}
          /> }
          <VictoryScatter
            style={{ data: { fill: L3color } }}
            size={2}
            data={getL3Data()}
            x="date"
            y="value"
          />
        </VictoryChart>
      </div>
      <div>
        <VictoryChart
          domain={entireDomain}
          width={550}
          height={90}
          theme={VictoryTheme.material}
          scale={{ x: 'time' }}
          padding={{ top: 5, left: 50, right: 50, bottom: 30 }}
          containerComponent={
            <VictoryBrushContainer
              brushDimension="x"
              brushDomain={selectedDomainState}
              onBrushDomainChange={handleBrush.bind(this)}
            />
          }
        >
          <VictoryLine
            interpolation="stepAfter"
            data={getL1Data()}
            x="date"
            y="value"
            style={{ data: { stroke: L1color, strokeWidth: 1 } }}
          />
          <VictoryLine
            interpolation="stepAfter"
            data={getL2Data()}
            x="date"
            y="value"
            style={{ data: { stroke: L2color, strokeWidth: 1 } }}
          />
          <VictoryLine
            interpolation="stepAfter"
            data={getL3Data()}
            x="date"
            y="value"
            style={{ data: { stroke: L3color, strokeWidth: 1 } }}
          />
        </VictoryChart>
      </div>
    </div>
  ) : (
    <div>No load history exists for this area</div>
  );

*/
