import styled from 'styled-components';

/**
 * Styled component for the area component.
 */
export const StyledArea = styled.div`
  background: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  font-size: 1rem;
  display: flex;
  overflow-x: auto;

  .accordion {
    overflow-x: auto;
  }

  .anchor-areas {
    overflow-x: auto;
    flex: 0 1 100%;
  }

  .anchor-areas-add {
    flex: 0 1 100%;
  }

  .AddButton {
    flex: 0 1 100%;
  }

  .button-div {
    display: flex;
    margin-bottom: 2rem;
    margin-top: 0.5rem;
  }

  h2 {
    text-align: center;
    flex: 0 1 100%;
    margin-bottom: 1rem;
  }

  .card-title-class {
    display: flex;
  }

  .tree-button:focus {
    outline: none;
  }

  .tree-link {
    order: 1;
    margin-right: 0.5rem;
  }
`;
