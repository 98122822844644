import axios from 'axios';
import StructuredResponse from '../../model/Classes/StructuredResponse';
axios.defaults.withCredentials = true;

const baseURL = '/api/auth/requestPasswordReset';
const tokenURL = '/api/auth/token';

/**
 * Service function responsible for send a request to reset a password.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @param {*} data reset password request data
 * @returns response from the database
 */
export const resetPasswordRequest = async (data) => {
  try {
    const response = await axios.post(baseURL, data, { withCredentials: true });
    return new StructuredResponse(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.post(baseURL, data, {
        withCredentials: true,
      });
      return new StructuredResponse(true, response.data);
    } catch (e: any) {
      return new StructuredResponse(false, e);
    }
  }
};
