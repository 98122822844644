import axios from 'axios';
import StructuredResponse from '../../model/Classes/StructuredResponse';
axios.defaults.withCredentials = true;

const tokenURL = '/api/auth/token';

/**
 * Service function responsible for adding a new tag using a chargepoint.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @param {*} data tag data
 * @returns if successful: [true, response.data]. Otherwise: [false, error]
 */
//Refactor
export const addTagWithCp = async (data: { charge_point_id: string }) => {
  try {
    const response = await axios.post('/api/user/tags/scann', data, {
      withCredentials: true,
    });
    return new StructuredResponse(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.post('/api/user/tags/scann', data, {
        withCredentials: true,
      });
      return new StructuredResponse(true, response.data);
    } catch (e: any) {
      return new StructuredResponse(false, e);
    }
  }
};
