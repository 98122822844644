import styled from 'styled-components';

/**accountorelisae
 * Styled component for the payment terms of service page
 */
export const StyledPaymentServiceProvider = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  background: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  font-size: 1rem;
  margin-top: 8rem;

  .row {
    width: 95vw;
  }

`;
