import axios from 'axios';
import StructuredResponse from '../../../model/Classes/StructuredResponse';
import {
  ActiveTransaction,
  CompletedTransaction,
} from '../../../model/Classes/Transaction';
axios.defaults.withCredentials = true;

const tokenURL = '/api/auth/token';

/**
 * Service function responsible for fetching all completed transactions for a user.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @param {*} data data object containing area id, start time, and stop time
 * @returns if successful: [true, response.data]. Otherwise: [false, error]
 */
export const getMyTransactionsCompleted = async () => {
  try {
    const response = await axios.get('/api/myTransactions');
    return new StructuredResponse<CompletedTransaction[]>(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get('/api/myTransactions');
      return new StructuredResponse<CompletedTransaction[]>(
        true,
        response.data
      );
    } catch (e: any) {
      return new StructuredResponse<CompletedTransaction[]>(false, e);
    }
  }
};

/**
 * Service function responsible for fetching all the active transactions for a user.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @returns if successful: [true, response.data]. Otherwise: [false, error]
 */
export const getMyTransactionsActive = async () => {
  try {
    const response = await axios.get('/api/myTransactions/active');
    return new StructuredResponse<ActiveTransaction[]>(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get('/api/myTransactions/active');
      return new StructuredResponse<ActiveTransaction[]>(true, response.data);
    } catch (e: any) {
      return new StructuredResponse<ActiveTransaction[]>(false, e);
    }
  }
};
