import axios from 'axios';
import StructuredResponse from '../../model/Classes/StructuredResponse';
axios.defaults.withCredentials = true;

const baseUrl = '/api/diagnostics/acknowledge';
const tokenURL = '/api/auth/token';

/**

 */
export const acknowledgeChargepointMessages = async (
  charge_point_id: string,
  data
) => {
  try {
    const response = await axios.put(`${baseUrl}/${charge_point_id}`, data, {
      withCredentials: true,
    });
    return new StructuredResponse(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.put(`${baseUrl}/${charge_point_id}`, {
        withCredentials: true,
      });
      return new StructuredResponse(true, response.data);
    } catch (e: any) {
      return new StructuredResponse(false, e);
    }
  }
};
