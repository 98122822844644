import { decodeAndMakeDate } from '../../../utils/dates';
import { searchAllTransactions } from '../../../services/transactions/Reporting/Reporting_service';
import { makeDate, pad } from '../../../utils/dates';
import { timer } from '../../../utils/timer';
import { logger } from '../../../utils/logger';
import StructuredResponse from '../../../model/Classes/StructuredResponse';
import {
  ReportingTransaction,
  TransactionType,
} from '../../../model/Classes/Transaction';
import {
  Nullable,
  Period,
  ReportingData,
  SearchPeriod,
  SearchType,
  StateHandler,
} from '../../../model/Utilities/Types';
import { AreaReporting } from '../../../model/Classes/Area';

/**
 * Utility function for validating the dates given in the Reporting.js component. The function first checks if the
 * given dates exist (i.e. are not an empty string). If they are, return [false] and set the invalidDates state to true.
 * If this passes, the function uses the decodeAndMakeDate() utility function for decoding the data and creating
 * Javacript date objects (one for the starting date and time, and one for the stopping date time). Lastly, it checks
 * if the start date is AFTER the stop date. If it is, set the invalidDates state to true and return [false]. If it's not,
 * everything is good and return [true, start date, stop date].
 * @param {*} data data containing the start and stop time + date
 * @param {*} setInvalidDates state handler for the invalid dates state (used to display an alert)
 * @returns if successful: [true, start date, stop date]. Otherwise [false]
 */
/** 
const validateTimezoneSearch = (data, setInvalidDates) => {
  const start = decodeAndMakeDateTimezone(
    data.display_start_date,
    data.start_time
  );
  const stop = decodeAndMakeDateTimezone(
    data.display_stop_date,
    data.stop_time
  );

  //Check if dates exists (empty string if they are not given).
  if (
    data.start_date === '' ||
    data.stop_date === '' ||
    data.start_time === '' ||
    data.stop_time === ''
  ) {
    //The user forgot to input the start and stop date and time, set the invalidDates state to true and return [false]
    setInvalidDates(true);
    //We can still return the invalid dates since the caller returns if success is false
    return new StructuredResponse(false, [start, stop]);
  }

  //Decode the data and create Javascript Date objects for the starting and stopping dates.

  //If the start date is AFTER the stop date, set the invalidDates state to true and return [false]
  if (start > stop) {
    setInvalidDates(true);
    //We can still return the invalid dates since the caller returns if success is false
    return new StructuredResponse(false, [start, stop]);
  }

  //Everything is good, return [true, start date, stop date]
  return new StructuredResponse(true, [start, stop]);
};
*/

const validate = (data, setInvalidDates) => {
  const start = decodeAndMakeDate(data.start_date, data.start_time);
  const stop = decodeAndMakeDate(data.stop_date, data.stop_time);

  //Check if dates exists (empty string if they are not given).
  if (
    data.start_date === '' ||
    data.stop_date === '' ||
    data.start_time === '' ||
    data.stop_time === ''
  ) {
    //The user forgot to input the start and stop date and time, set the invalidDates state to true and return [false]
    setInvalidDates(true);
    //We can still return the invalid dates since the caller returns if success is false
    return new StructuredResponse(false, [start, stop]);
  }

  //Decode the data and create Javascript Date objects for the starting and stopping dates.

  //If the start date is AFTER the stop date, set the invalidDates state to true and return [false]
  if (start > stop) {
    setInvalidDates(true);
    //We can still return the invalid dates since the caller returns if success is false
    return new StructuredResponse(false, [start, stop]);
  }

  //Everything is good, return [true, start date, stop date]
  return new StructuredResponse(true, [start, stop]);
};

/**
 * Utility function used for the different search modes for the Reporting page. The different search modes are:
 *
 * 1) Basic search: user inputs the start date and time as well as the stop date and time and presses the search button
 * 2) This month search: user presses the "This month" quick report button
 * 3) Previous month search: user presses the "Previous month" quick report button
 * 4) Previous quarter search: user presses the "Previous quarter" quick report button
 * 5) Previous year search: user presses the "Previous year" quick report button
 *
 * The function knows which mode is used by the parameters thisMonth, previousMonth, previousQuarter, and previousYear.
 *
 * The function first validates the dataInput parameter using the utility function validate(). If it fails, it sets the
 * invalidDates state using the setInvalidDates parameter to true to indicate an alert to the user. If it succeeds,
 * the function sets the loading animation on using the setSearching, and makes two request to the backend: one for
 * getting all the transactions for the given area from the start date and time to the stop date and time, and another
 * one for getting all the active transactions for the given area. If this fails, it stops the loading animation and logs
 * the error. If it succeeds, it updates the correct states with the data, sets the rangeAreaName state to contain the area
 * name, and sets the rangeText correct depending on the flag indicating the mode. Lastly, it stops the loading animation and
 * shows a checkmark for 2 seconds.
 * @param {*} dataInput data object containing the area id, start date, stop date, start time, and stop time
 * @param {*} searchParameter the selected period to search for. '' if conducting specific search.
 * @param {*} setInvalidDates state handler for the invalid dates state (used to display an alert)
 * @param {*} areaField object containing the area name, id, and user_root flag
 * @param {*} setSearching state handler for the state searching (used for showing the loading animation)
 * @param {*} setSearchComplete state handler for the state searchCompleted (user for showing the checkmark when loading is complete)
 * @param {*} setAllTransactions state handler for the state allTransactions
 * @param {*} setAllActive state handler for the state allActive
 * @param {*} setRangeAreaName state handler for the state rangeAreaName (used to display which area the reports are for)
 * @param {*} setRangeText state handler for the state rangeText (used to display the period for which the reports belong to)
 * @param {*} startDate state for the starting date
 * @param {*} startTime state for the starting time
 * @param {*} stopDate state for the stopping date
 * @param {*} stopTime state for the stopping time
 */
export const validateAndSearch = async (
  dataInput: ReportingData,
  searchParameter: SearchPeriod | Period,
  setInvalidDates: StateHandler<boolean>,
  areaField: AreaReporting,
  setSearching: StateHandler<boolean>,
  setSearchComplete: StateHandler<boolean>,
  setAllTransactions: (transactions: ReportingTransaction[]) => void,
  setRangeAreaName: StateHandler<string>,
  startTime: string,
  stopTime: string,
  invoicing_method: Nullable<number>,
  invoicingFilter: number,
  setSelectedTransactions: StateHandler<ReportingTransaction[]>,
  previousSearch: SearchType,
  setPreviousSearch: StateHandler<SearchType>,
  quickReport: boolean
) => {
  setInvalidDates(false); //Toggle off the invalidDates state
  const validationRes =
    // quickReport?
    validate(dataInput, setInvalidDates);
  //: validateTimezoneSearch(dataInput, setInvalidDates); //Validate the dataInput

  if (!validationRes.success) return; //If validation didn't pass, exit the function

  //Create the data object that is sent to the backend.
  //NOTE! the start and stop date are sent as ISO-Strings, since the database stores them as such.
  const data = {
    area_id: areaField!.id!,
    start_date: validationRes.data[0].toISOString(),
    //display_start_date: validationRes.data[0].toISOString(),
    stop_date: validationRes.data[1].toISOString(),
    invoicing_method: invoicing_method,
    filter: invoicingFilter,
  };

  // Reset selected users
  setSelectedTransactions([]);

  setSearching(true); //Start the loading animation

  try {
    //Use service functions to send the requests to the backend.
    const res = await searchAllTransactions(data);

    //Update the allTransactions, allActiveTransactions, and rangeAreaName states
    setAllTransactions(res);
    setRangeAreaName(areaField.name);
    //this time constant is rsponsible for the display/Header data on top of reporting table
    const time = `${dataInput.start_date} ${startTime} to ${dataInput.display_stop_date} ${stopTime}`;

    //Check previous search so as to not update UI unnecessarily
    if (previousSearch.time !== time) {
      setPreviousSearch({
        period: searchParameter,
        time: time,
        displayTime: `(${time})`,
      });
    }

    setSearching(false); //Stop the loading animation
    timer(setSearchComplete, 2000); //Show a checkmark for 2 seconds indicating that the search was successful
  } catch (e) {
    //Some error occured
    //Log the error and stop the loading animation
    logger(e);
    setSearching(false);
  }
};

/**
 * Utility function the Reporting page's useEffect hook. The function creates a Javascript Date object and a data object
 * with the start and stop dates in ISO-String format. Since it's used for the useEffect hook, it needs to create a
 * date object for the PREVIOUS month.
 *
 * The function initially creates a new Javascript Date object for getting the year and month for the previous month from
 * the Date object. Then it creates two new Date objects for the starting and stopping time, and creates a data object
 * with these Dates where the Date objects are in ISO-String format. Lastly, it increments the month by one (needed since
 * the Date object's months are given as 0-11 and the html date input's months are given as 1-12), and returns the data object,
 * year, month (in html date input format), and the last day of the previous month.
 * @returns [date object with start and stop dates in ISO-String format, year, month, lastDay]
 */
export const createDataObjectAndDate = (): any[] => {
  const date = new Date(); //Create a new Date object

  // Set default invoicingMethod
  const invoicingMethod = null;

  //Get the previous month and current year from the Date object.
  let month = date.getMonth() - 1;
  let year = date.getFullYear();

  //This is needed to be checked due to the following case: if the current month is January, date.getMonth() returns 0.
  //As we want the previous month, we decrement this value by 1. This results in the month variable being -1, thus we need
  //to set it to 11 (December) and decrement the year by 1.
  if (month === -1) {
    month = 11; //Set the month to December
    year -= 1; //Decrement the year by 1.
  }

  //Since the last days of the months vary, we need to figure out the last day of the previous month
  //This is done by getting the 0:th day of the FOLLOWING month (for some odd reason :D).
  //Thus, we create a new Date object for the next month's 0:th day (makeDate() utility function), and get
  //the day by calling the getDate() function.
  const lastDay = makeDate(year, month + 1, 0, 0, 0, 0).getDate();

  //Create the start and stop dates by calling the makeDate() utility function
  const start = makeDate(year, month, 1, 0, 0, 0);
  const stop = makeDate(year, month + 1, 0, 0, 0, 24 * 3600 - 1);
  //Create the data object containing the start and stop dates in ISO-String format, as well as invoicing method
  const data = {
    start_date: start.toISOString(),
    stop_date: stop.toISOString(),
    invoicing_method: invoicingMethod,
  };

  //Since we're returning the year, month, and last day so that we can insert them into the html date input,
  //we need to increment the month by 1 (Javascript Date object's months are given as 0-11 and the html date input's
  //months are given as 1-12). Additionally, we need to pad the month with a 0 in the beginning if the month is below
  //10, since the html date input needs the month to be given as MM (if the month would be below 10, a zero is needed
  //in the beginning to conform to the format)
  //month += 1;
  const startMonth = month + 1;
  //month = pad(month);
  //let tempMonth = pad(month);

  return [data, year, pad(startMonth), lastDay, invoicingMethod];
};

/**
 * Utility function used by the "Basic mode" for creating a data object containing the area id, start date,
 * stop date, start time, and stop time. The function doesn't need to do anything else than create the data
 * object with the values from the parameters.
 * @param {*} areaId id of the area to get the reports from
 * @param {*} startDate start date to get the reports from
 * @param {*} stopDate stop date to get the reports from
 * @param {*} startTime start time to get the reports from
 * @param {*} stopTime stop time to get the reports from
 * @returns data object containing the area id, start date, stop date, start time, stop time
 */
export const createBasicData = (
  areaId: number,
  startDate: string,
  stopDate: string,
  startTime: string
) => {
  //Create the data object
  const data: ReportingData = {
    area_id: areaId,
    start_date: startDate,
    display_stop_date: stopDate,
    stop_date: stopDate,
    start_time: startTime,
    display_stop_time: '23:59',
    stop_time: '23:59:59',
  };

  return data; //Return the data object
};

export const createPeriodData = (
  areaId: number,
  period: Period | SearchPeriod
) => {
  if (period === Period.thisMonth) {
    return createThisMonthDateAndData(areaId);
  } else if (period === Period.previousMonth) {
    return createPreviousMonthDateAndData(areaId);
  } else if (period === Period.previousQuarter) {
    return createPreviousQuarterDateAndData(areaId);
  } else {
    return createPreviousYearDateAndData(areaId);
  }
};

/**
 * Utility function used by the "This month" mode for creating a data object containing the area id, start date,
 * stop date, start time, and stop time, and returning said data object alongside the start date, stop date, and
 * stop time.
 *
 * The function intially creates a new Javascript Date object, and gets the current year, month, day, hour, and
 * minute. From these, it creates the html date input strings (date in form YYYY-MM-DD and time in HH:MM),
 * creates the data object with these and the area id, and finally returns [data object, start date, stop date,
 * stop time]
 * @param {*} areaId id of the area to get the reports from
 * @returns [data object, start date, stop date, stop time]
 */
export const createThisMonthDateAndData = (areaId: number) => {
  const date = new Date(); //Create a new Data object

  const year = date.getFullYear(); //Get the year
  const month = date.getMonth() + 1; //Get the month. NOTE! We take + 1 since the html date input's months are from 1-12 while the Date object's months are from 0-11
  const mPad = pad(month);

  const lastDay = makeDate(year, month, 0, 0, 0, 0).getDate();
  const dayPad = pad(lastDay);

  //Create the start date, stop date, and stop time as strings in the html date input format
  const start = `${year}-${mPad}-01`;
  const displayStop = `${year}-${mPad}-${dayPad}`;

  //Create the data object
  const data: ReportingData = {
    area_id: areaId,
    start_date: start,
    display_stop_date: displayStop,
    start_time: '00:00',
    display_stop_time: '23:59',
    stop_date: displayStop,
    stop_time: '23:59:59',
  };

  return data; //Return the data object alongside the start date, stop date, and stop time
};

/**
 * Utility function used by the "Previous month" mode for creating a data object containing the area id, start date,
 * stop date, start time, and stop time, and returning said data object alongside the start date and stop date.
 *
 * The function initially creates a new Javascript Date object, and gets the previous month's year, month, and last day.
 * From these, it creates the html date input strings (date in form YYYY-MM-DD). Additionally, it sets the start time to
 * 00:00 and stop time to 23:59. It then creates the data object with these and the area id, and finally returns [data object
 * start date, stop date]
 * @param {*} areaId id of the area to get the reports from
 * @returns [data object, start date, stop date]
 */
export const createPreviousMonthDateAndData = (areaId: number) => {
  const date = new Date(); //Create a new Date object

  //Get the previous month and current year from the Date object.
  let month = date.getMonth() - 1;
  let year = date.getFullYear();

  //This is needed to be checked due to the following case: if the current month is January, date.getMonth() returns 0.
  //As we want the previous month, we decrement this value by 1. This results in the month variable being -1, thus we need
  //to set it to 11 (December) and decrement the year by 1.
  if (month === -1) {
    month = 11;
    year -= 1;
  }

  //Since the last days of the months vary, we need to figure out the last day of the previous month
  //This is done by getting the 0:th day of the FOLLOWING month (for some odd reason :D).
  //Thus, we create a new Date object for the next month's 0:th day (makeDate() utility function), and get
  //the day by calling the getDate() function.
  const lastDay = makeDate(year, month + 1, 0, 0, 0, 0);
  const dayPad = pad(lastDay.getDate());
  //Create the start date and stop date as strings in the html date input format
  //NOTE! We increment the month by 1 since the html date input's months are from 1-12 while the Date object's months are from 0-11
  const start = `${year}-${pad(month + 1)}-01`;
  const displayStop = `${year}-${pad(month + 1)}-${dayPad}`;

  //Create the data object
  const data: ReportingData = {
    area_id: areaId,
    start_date: start,
    display_stop_date: displayStop,
    start_time: '00:00',
    display_stop_time: '23:59',
    stop_date: displayStop,
    stop_time: '23:59:59',
  };

  return data; //Return the data object alongside the start date and stop date
};

/**
 * Utility function used by the "Previous quarter" mode for creating a data object containing the area id, start date,
 * stop date, start time, and stop time, and returning said data object alongside the start date and stop date.
 *
 * The function initially creates a new Javascript Date object. Then, it figures out which quarter we're currently in,
 * and from that it creates two new date objects: first for the first day of the PREVIOUS quarter, and the second for the last
 * day of the PREVIOUS quarter. From these, it creates the html date input strings (date in form YYYY-MM-DD). Additionally,
 * it sets the start time to 00:00 and stop time to 23:59. It then creates the data object with these and the area id, and
 * finally returns [data object, start date, stop date]
 * @param {*} areaId id of the area to get the reports from
 * @returns [data object, start date, stop date]
 */
export const createPreviousQuarterDateAndData = (areaId) => {
  const date = new Date(); //Create a new Date object
  const quarter = Math.floor(date.getMonth() / 3); //Get the current quarter we're in

  //Create a new Date object for the first day of the PREVIOUS quarter.
  ///NOTE! The beginning month of the previous quarter is: quarter * 3 - 1
  const fullStartDate = makeDate(
    date.getFullYear(),
    quarter * 3 - 3,
    1,
    0,
    0,
    0
  );

  //Create a new Date object for the last day of the PREVIOUS quarter.
  //NOTE! Since the last days of the months vary, we need to figure out the last day for the last month of the
  //previous quarter. This is done by getting the 0:th day of the month AFTER the last month of the previous quarter.
  //E.g. if we want the want the third quarters last day. Since the first month of the third quarter is July (July = 6),
  //and the last month of the third quarter is September (September = 8), we need to take the 0:th day of month AFTER
  //September (i.e. August = 9).
  //Thus, we create a new Date object for the 0:th day of the month AFTER the last month of the previous quarter. This
  //is done by taking the 0:th day for the 3 month after the fullStartDate's month.
  const fullStopDate = makeDate(
    fullStartDate.getFullYear(),
    fullStartDate.getMonth() + 3,
    0,
    0,
    0,
    0
  );

  //Create the start date and stop date as string in the html date input format
  //NOTE! We increment the month by 1 since the html date input's months are from 1-12 while the Date object's months are from 0-11
  //Additionally, we pad the months and dates with a 0 in the beginning since they need to be in the form MM and DD,
  //and DateObject.getMonth() and DateObject.getDate() will return an integer, so all values below 10 need to be prepended with a 0.
  const start = `${fullStartDate.getFullYear()}-${pad(
    fullStartDate.getMonth() + 1
  )}-01`;
  const displayStop = `${fullStopDate.getFullYear()}-${pad(
    fullStopDate.getMonth() + 1
  )}-${pad(fullStopDate.getDate())}`;

  //Create the data object
  const data: ReportingData = {
    area_id: areaId,
    start_date: start,
    display_stop_date: displayStop,
    start_time: '00:00',
    display_stop_time: '23:59',
    stop_date: displayStop,
    stop_time: '23:59:59',
  };

  return data; //Return the data object alongside the start and stop date
};

/**
 * Utility function used by the "Previous year" mode for creating a data object containing the area id, start date,
 * stop date, start time, and stop time, and returning said data object alongside the start date and stop date.
 *
 * The function initially creates a new Javascript Date object, and gets the previous year from it. With this,
 * it creates the html date input strings (date in form YYYY-MM-DD). Additionally, it sets the start time to
 * 00:00 and stop time to 23:59. It then creates the data object with these and the area id, and finally
 * returns [data object start date, stop date]
 * @param {*} areaId id of the area to get the reports from
 * @returns [data object, start date, stop date]
 */
export const createPreviousYearDateAndData = (areaId) => {
  const date = new Date(); //Create a new Date object
  const year = date.getFullYear(); //Get the previous year from the Date object.

  //Create the start and stop date as strings in the html date input format
  const start = `${year - 1}-01-01`;
  const displayStop = `${year - 1}-12-31`;

  //Create the data object
  const data: ReportingData = {
    area_id: areaId,
    start_date: start,
    display_stop_date: displayStop,
    start_time: '00:00',
    display_stop_time: '23:59',
    stop_date: displayStop,
    stop_time: '23:59:59',
  };

  return data; //Return the data object alongside the start date and stop date
};

export const combineTransactions = (transactions: ReportingTransaction[]) => {
  let ret: ReportingTransaction[] = [];
  const blocked_transactions = transactions.filter(
    (t) => t.transaction_type === 3
  );
  const accepted_transactions = transactions.filter(
    (t) => t.transaction_type !== 3
  );
  const emails = extractEmails(accepted_transactions);

  emails.forEach((entry) => {
    ret.push(
      aggregateUserTransactions(
        transactions.filter((c) => {
          return c.email === entry;
        })
      )
    );
  });
  if (blocked_transactions.length > 0)
    ret.push(aggregateTypeTransactions(blocked_transactions, 'Blocked'));

  return ret;
};

export const extractEmails = (transactions: ReportingTransaction[]) => {
  //Ensure that only unique emails are saved -> Set

  const ret: string[] = [];
  transactions.forEach((t) => {
    if (!ret.includes(t.email)) ret.push(t.email);
  });

  return ret;
};

const aggregateTypeTransactions = (
  transactions: ReportingTransaction[],
  transactionType: TransactionType
): ReportingTransaction => {
  let tFirstName = transactionType;
  let tLastName = '';
  let tEmail = `${transactionType}_charging`;
  const tType = getTypeAndMethod(transactionType);
  const kWh = aggregate<ReportingTransaction>('kWh', transactions);
  const totalCost = aggregate<ReportingTransaction>('total_cost', transactions);

  return {
    first_name: tFirstName,
    last_name: tLastName,
    email: tEmail,
    kWh: kWh,
    total_cost: totalCost,
    invoicing_method: tType.method,
    transaction_type: tType.type,
  };
};

const aggregateUserTransactions = (
  transactions: ReportingTransaction[]
): ReportingTransaction => {
  const kWh = aggregate('kWh', transactions);
  const totalCost = aggregate('total_cost', transactions);
  return {
    ...transactions[0],
    kWh: kWh,
    total_cost: totalCost,
    invoicing_method: 0, //uhh doesnt matter here, will still be extracted when generating invoices
    transaction_type: 0,
  };
};

const getTypeAndMethod = (
  tType: TransactionType
): { method: number; type: number } => {
  switch (tType) {
    case 'Public':
      return { method: 2, type: 2 };
    case 'Free':
      return { method: 2, type: 0 };
    default:
      return { method: 0, type: 3 };
  }
};

/**
 * @TTemplate A template type
 * @param value A key which exists on template TTemplate, NOTE this needs to convertible to a number / a number
 * @param objects Array of objects from which we want to aggregate a value
 * @returns an aggregated, numeric, value
 */
const aggregate = <TTemplate extends Object>(
  value: keyof TTemplate,
  objects: TTemplate[]
) => {
  let ret: number = 0;
  for (let i = 0; i < objects.length; i++) {
    ret += Number(objects[i][value]);
  }
  return ret.toFixed(2);
};

export const extractTransactions = (
  transactions: ReportingTransaction[]
): [
  ReportingTransaction[],
  //ReportingTransaction,
  ReportingTransaction,
  ReportingTransaction
] => {
  const publicTransactions: ReportingTransaction[] = [];
  const freeTransactions: ReportingTransaction[] = [];
  const privateTransactions: ReportingTransaction[] = [];
  const blockedTransactions: ReportingTransaction[] = [];

  for (const t of transactions) {
    if (t.transaction_type === 0) {
      freeTransactions.push(t);
    } else if (t.transaction_type === 1) {
      privateTransactions.push(t);
    } else if (t.transaction_type === 2) {
      publicTransactions.push(t);
    } else {
      blockedTransactions.push(t);
    }
  }

  return [
    combineTransactions(privateTransactions),
    //aggregateTypeTransactions(freeTransactions, 'Free'),
    aggregateTypeTransactions(publicTransactions, 'Public'),
    aggregateTypeTransactions(blockedTransactions, 'Blocked'),
  ];
};

export const sortOnInvoicingMethods = (
  transactions: ReportingTransaction[]
) => {
  const zero = sortOnInvoicingMethod(transactions, 0);
  const one = sortOnInvoicingMethod(transactions, 1);
  const two = sortOnInvoicingMethod(transactions, 2);
  return [zero, one, two];
};

export const sortOnInvoicingMethod = (
  transactions: ReportingTransaction[],
  invoicingMethod: number
) => {
  return transactions.filter(
    (tran) => tran.invoicing_method === invoicingMethod
  );
};
