import axios from 'axios';
axios.defaults.withCredentials = true;

const tokenURL = '/api/auth/token';

/**
 * Service function responsible for getting your cards
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @returns response from the backend
 */
export const cardsExpiring = async () => {
  try {
    return await axios.get('/api/user/cards/expiring');
  } catch (e) {
    await axios.post(tokenURL, { withCredentials: true });
    return await axios.get('/api/user/cards/expiring');
  }
};

export const defaultCardExpired = async () => {
  try {
    return await axios.get('/api/user/cards/expired');
  } catch (e) {
    await axios.post(tokenURL, { withCredentials: true });
    return await axios.get('/api/user/cards/expired');
  }
};
