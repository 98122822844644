import axios from 'axios';
import StructuredResponse from '../../model/Classes/StructuredResponse';
axios.defaults.withCredentials = true;

const tokenURL = '/api/auth/token';
//Idk where this should be honestly
const baseUrl = '/api/user/chargingAccess/connected';

/**
 * Service function responsible for fetching all chargepoints the user has access to.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @returns if successful: [true, response.data]. Otherwise: [false, error]
 */
export const getChargepointIsConnected = async (charge_point_id: string) => {
  try {
    const response = await axios.get(`${baseUrl}/${charge_point_id}`, {
      withCredentials: true,
    });
    return new StructuredResponse(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(`${baseUrl}/${charge_point_id}`, {
        withCredentials: true,
      });
      return new StructuredResponse(true, response.data);
    } catch (e) {
      return new StructuredResponse(false, e);
    }
  }
};
