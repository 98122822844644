import styled from 'styled-components';

/**
 * Styled component for the landing page
 */
export const StyledLandingPage = styled.div`
  display: flex;
  height: 80vh;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  background: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  font-size: 1rem;
  overflow-x: auto;

  .row {
    justify-content: center;
  }

  .logo {
    width: 50vw;
    @media only screen and (max-width: 700px) {
      width: 80vw;
    }
    @media only screen and (min-width: 700px, max-width: 1000px) {
      width: 50vw;
    }
    @media only screen and (min-width: 1000px) {
      width: 500px;
    }
  }

  .registerForm {
    margin-top: 2rem;
  }

  .landingAlertCol {
    text-align: center;
    justify-content: center;
    display: flex;

    > .landingAlert {
      width: 30ch;
      min-width: min-content;
    }
  }
`;
