import styled from 'styled-components';

//Styled-component for the chargepoint component
export const StyledChargepoint = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-flow: row wrap;
  overflow-x: auto;
  background: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  font-size: 1rem;

  h1,
  h2,
  h3 {
    text-align: center;
    flex-basis: 100%;
    margin-bottom: 1rem;
  }

  .Yes {
    color: ${({ theme }) => theme.lightGreen};
  }

  .No {
    color: ${({ theme }) => theme.red};
  }

  .back-button-cp {
    text-align: left;
  }

  .col-sm-9 {
    display: flex;
  }

  .tab-content {
    align-self: center;
  }

  .cp-health-0 {
    background-color: ${({ theme }) => theme.cpHealth0Gray};
  }
  .cp-health-1 {
    background-color: ${({ theme }) => theme.body};
  }
  .cp-health-2 {
    background-color: ${({ theme }) => theme.yellow};
  }
  .cp-health-3 {
    background-color: ${({ theme }) => theme.red};
  }
`;
