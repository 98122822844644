import { Row } from 'react-bootstrap';
import { StyledPaymentServiceProvider } from './PaymentServiceProvider.styled';

/**
 * Component responsible for rendering the Payment Service Provider page
 * @returns PaymentServiceProvider page
 */
const PaymentServiceProvider = () => {
  return (
    <StyledPaymentServiceProvider className="top-level-component">
      <Row>
        <h2>Maksupalvelutarjoaja</h2>
        <p>
          Maksunvälityspalvelun toteuttajana ja maksupalveluntarjoajana toimii
          Paytrail Oyj (2122839-7) yhteistyössä suomalaisten pankkien ja
          luottolaitosten kanssa. Paytrail Oyj näkyy maksun saajana tiliotteella
          tai korttilaskulla ja välittää maksun kauppiaalle. Paytrail Oyj:llä on
          maksulaitoksen toimilupa. Reklamaatiotapauksissa pyydämme ottamaan
          ensisijaisesti yhteyttä tuotteen toimittajaan.
        </p>
        <p>
          Paytrail Oyj
          <br />
          y-tunnus: 2122839-7
          <br />
          Innova 2<br />
          Lutakonaukio 7<br />
          40100 Jyväskylä
          <br />
        </p>
        <p>
          <a href="https://www.paytrail.com/kuluttaja/maksupalveluehdot">
            https://www.paytrail.com/kuluttaja/maksupalveluehdot
          </a>
        </p>
      </Row>
      <Row>
        <h2>Payment Service Provider</h2>
        <p>
          Paytrail Plc acts as a collecting payment service provider and is an
          authorized Payment Institution. Paytrail Plc will be shown as the
          recipient on your bank or credit card statement. Paytrail Plc will
          forward the payment to the merchant. For reclamations, please contact
          the website the payment was made to.
        </p>
        <p>
          Paytrail Plc
          <br />
          Innova 2<br />
          Lutakonaukio 7<br />
          40100 Jyväskylä
          <br />
          Business ID: 2122839-7
          <br />
        </p>
      </Row>
      <Row>
        <h2>Betalningsförmedlare</h2>
        <p>
          Betalningsförmedlingstjänsten utförs och som leverantör står Paytrail
          Oyj(2122839-7) i samarbete med banker och kreditinstitut. Betalningar
          med Visa Electron eller MasterCard-kort syns Paytrail Oyj som
          mottagare i korträkningen och även förmedlar betalningen till
          köpmannen. Paytrail Oyj har verksamhetstillstånd för
          betalningsinstitut beviljat av Finansinspektionen. Vid klagomål ber vi
          er i första hand vara i kontakt med nätbutiken.
        </p>
        <p>
          Paytrail Oyj
          <br />
          FO-nummer: 2122839-7
          <br />
          Innova 2<br />
          Lutakonaukio 7<br />
          40100 Jyväskylä
          <br />
          <a href="www.paytrail.com">www.paytrail.com</a>
          <br />
        </p>
      </Row>
    </StyledPaymentServiceProvider>
  );
};

export default PaymentServiceProvider;
