import { useState, useEffect } from 'react';
import {
  Row,
  Col,
  InputGroup,
  FormControl,
  Alert,
  Button,
  DropdownButton,
  Dropdown,
} from 'react-bootstrap';
import { getAreaBranches } from '../../services/area/getAreas';
import { setAreaManager } from '../../services/admin/setAreaManager';
import { timer } from '../../utils/timer';
import { logger } from '../../utils/logger';
import { AreaBase } from '../../model/Classes/Area';
import { useTranslation } from 'react-i18next';
import i18N from '../../i18n';
import { toggleAlertsOff } from '../../utils/toggleAlertsOff';

/**
 * Component responsible for the functionality for setting a user to be an area manager for a specific area.
 * The component first gets all the in the entire application, and the shows the view.
 * @returns the view where an admin can set a user to be an area manager to a specific area
 */
const AdminSetAreaManager = () => {
  const [email, setEmail] = useState(''); //state for the email field
  const [areaToSet, setAreaToSet] = useState<AreaBase>(); //state for the area field. Initially contains an object with a name
  //that is empty
  const [allAreas, setAllAreas] = useState<AreaBase[]>([]); //state for all the areas
  const [showView, setShowView] = useState(-1); //state responsible for viewing the component or not

  //states for the alerts of this component
  const [successAlert, setSuccessAlert] = useState(false);
  const [alreadyInTreeAlert, setAlreadyInTreeAlert] = useState(false);
  const [emptyEmailAlert, setEmptyEmailAlert] = useState(false);
  const [invalidEmailAlert, setInvalidEmailAlert] = useState(false);
  const [noAreaAlert, setNoAreaAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const { t } = useTranslation('common', { i18n: i18N });

  /**
   * Function for handling submission of the form. Initially toggles the alerts off, and validates the field.
   * If a field fails the validation, an alert is shown and the function exits. If it's successful,
   * send the data to the backend and show a success/failed alert corresponding to the response from the backend.
   */
  const handleSubmit = async () => {
    toggleAlertsOff([
      setEmptyEmailAlert,
      setInvalidEmailAlert,
      setNoAreaAlert,
      setAlreadyInTreeAlert,
      setSuccessAlert,
    ]);

    //validate the fields
    if (email === '') {
      setEmptyEmailAlert(true);
      return;
    }
    let re =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(email)) {
      setInvalidEmailAlert(true);
      return;
    }
    if (areaToSet!.name === '') {
      setNoAreaAlert(true);
      return;
    }

    //validation successful, create an object and send it to the backend
    const data = {
      email: email,
      area_id: areaToSet!.id,
    };

    const res = await setAreaManager(data);

    //if the response is successful, show a success alert
    if (res.success) {
      if (res.data.status === 0) {
        timer(setSuccessAlert);
        window.location.reload();
      }
      if (res.data.status === 1) {
        timer(setAlreadyInTreeAlert);
      }
    }
    //if the response failed, console log the error and show a failed alert
    else {
      logger(res.data);

      timer(setErrorAlert);
    }
  };

  /**
   * useEffect that is responsible for retrieving all the areas in the application,
   * and sets them to the allAreas state and sets the showView state to 1. If it
   * failed, console log the errors and set the showView state to 0
   */
  useEffect(() => {
    //function called at the end, needed since it needs to be asynchronous
    const getData = async () => {
      const res = await getAreaBranches<AreaBase>('/api/admin/area');
      //check if the response came back as successful
      if (res.success) {
        setAllAreas(res.data.flatMap((a) => a));
        setShowView(1);
      } else {
        logger(res.data);

        setShowView(0);
      }
    };

    getData();
  }, []);

  return (
    //showView is -1 when the component is initialized, display "Loading...".
    showView === -1 ? (
      <>
        <h2 className="align-self-center">{t('global.view.loading')}</h2>
      </>
    ) : //if showView is 0, an error occured when retrieving the data from the backend.
    showView === 0 ? (
      <h2 className="align-self-center">{t('global.view.error')}</h2>
    ) : (
      <>
        {/*row for the alerts and the email field*/}
        <Row className="mb-3">
          <InputGroup>
            <InputGroup.Text id="email">@</InputGroup.Text>
            <FormControl
              placeholder="example@email.com"
              type="text"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              id="emailField"
            />
          </InputGroup>
          {emptyEmailAlert ? (
            <Alert
              key="emptyEmailAlert"
              variant="danger"
              style={{ marginBottom: 0, marginTop: 10 }}
            >
              {t('global.alert.failure.emailEmpty')}
            </Alert>
          ) : null}
          {invalidEmailAlert ? (
            <Alert
              key="invalidEmailAlert"
              variant="danger"
              style={{ marginBottom: 0, marginTop: 10 }}
            >
              {t('global.alert.failure.invalidEmail')}
            </Alert>
          ) : null}
        </Row>
        {/*row for the area dropdown and field*/}
        <Row className="mb-3">
          <InputGroup>
            <DropdownButton
              id="dropdown-areas"
              title={t('components.admin.areaId')}
              drop="down"
            >
              {allAreas.map((area, idx) => {
                return (
                  <Dropdown.Item
                    key={idx}
                    as="button"
                    onClick={() => setAreaToSet(area)}
                  >
                    {`${area.name} (${area.id})`}
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>
            <FormControl
              aria-label="Area input"
              value={areaToSet ? areaToSet.name : ''}
              readOnly
            />
          </InputGroup>
          {noAreaAlert ? (
            <Alert
              key="noAreaAlert"
              variant="danger"
              style={{ marginBottom: 0, marginTop: 10 }}
            >
              {t('global.alert.other.chooseArea')}
            </Alert>
          ) : null}
        </Row>
        {/*row for the submit button and success/failed alerts*/}
        <Row>
          <Col>
            <Button
              variant="primary"
              onClick={handleSubmit}
              id="submitSetAreaManager"
            >
              {t('global.buttons.submit')}
            </Button>
          </Col>
          {successAlert ? (
            <Alert
              variant="success"
              key="success"
              style={{ marginBottom: 0, marginTop: 10 }}
            >
              {t('global.alert.success.userManager')}
            </Alert>
          ) : null}
          {alreadyInTreeAlert ? (
            <Alert
              variant="success"
              key="success"
              style={{ marginBottom: 0, marginTop: 10 }}
            >
              {t('global.alert.success.isManager')}
            </Alert>
          ) : null}
          {errorAlert ? (
            <Alert
              variant="danger"
              key="error"
              style={{ marginBottom: 0, marginTop: 10 }}
            >
              Error occured, please contact the admins for help (oh wait...).
              Check the browser logs, or the server logs if the browser logs
              don't help.
            </Alert>
          ) : null}
        </Row>
      </>
    )
  );
};

export default AdminSetAreaManager;
