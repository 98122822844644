export default class StructuredOcppResponse<T> {
  private readonly _success: boolean;
  private readonly _result: T;
  private readonly _message: string | null;
  private readonly _error: string | null;

  constructor(
    success: boolean,
    result: T,
    message: string | null,
    error: string | null
  ) {
    this._success = success;
    this._result = result;
    this._message = message;
    this._error = error;
  }

  public get success(): boolean {
    return this._success;
  }

  public get result(): T {
    return this._result;
  }
  public get message(): string | null {
    return this._message;
  }
  public get error(): string | null {
    return this._error;
  }
}
