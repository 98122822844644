import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { confirmEmail } from '../../../services/authentication/confirmEmail';
import { logger } from '../../../utils/logger';
import { timer } from '../../../utils/timer';

const ConfirmEmail = ({
  history,
  setConfirmEmailSuccess,
  setConfirmEmailFail,
}) => {
  const token = useParams<{ token: string }>().token;

  useEffect(() => {
    const data = {
      email_token: token,
    };

    const asyncCheck = async () => {
      try {
        await confirmEmail(data);

        history.push('/login');
        timer(setConfirmEmailSuccess);
      } catch (e) {
        logger(e);
        history.push('/login');
        timer(setConfirmEmailFail);
      }
    };

    asyncCheck();
  });

  return <h2 className="align-self-center">Loading...</h2>;
};

export default ConfirmEmail;
