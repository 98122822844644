import axios from 'axios';
import InvoicingInfo from '../../model/Classes/InvoicingInfo';
axios.defaults.withCredentials = true;

const baseURL = '/api/user/emailInvoicing';
const tokenURL = '/api/auth/token';

/**
 * Service function responsible for sending data containing email invoice info
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @param {*} data data object containing necessary data
 * @returns response from backend
 */
export const sendEmailInvoiceInfo = async (data: InvoicingInfo) => {
  try {
    const response = await axios.post(baseURL, data, { withCredentials: true });
    return response.data;
  } catch (e) {
    await axios.post(tokenURL, { withCredentials: true });
    const response = await axios.post(baseURL, data, { withCredentials: true });
    return response.data;
  }
};
