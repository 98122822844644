import axios from 'axios';
import { AreaBase } from '../../model/Classes/Area';
import StructuredResponse from '../../model/Classes/StructuredResponse';
axios.defaults.withCredentials = true;

const tokenURL = '/api/auth/token';

/**
 * Service function responsible for fetching all areas the user has access to.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @param {*} apiAddress the api address to fetch data from
 * @type `T extends AreaBase` sometimes (areaView, setManager) we dont want to map all the information to an object and rather let most of it fall out of scope
 * @returns `StructuredResponse<[T[], ChargePoint[]]>(boolean, response.data)`.
 */
/* export const getAreas = async <T extends AreaBase>(
  apiAddress: string,
  area_id?: number
) => {
  try {
    const response = await axios.get(
      `${apiAddress}${area_id ? `/${area_id}` : ''}`
    );
    //well this doesnt look disgusting at all
    return new StructuredResponse<T[]>(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(
        `${apiAddress}${area_id ? `/${area_id}` : ''}`
      );
      return new StructuredResponse<T[]>(true, response.data);
    } catch (e: any) {
      return new StructuredResponse<T[]>(false, e);
    }
  }
}; */

export const getAreaBranches = async <T extends AreaBase>(
  apiAddress: string,
  area_id?: number
) => {
  try {
    const response = await axios.get(
      `${apiAddress}${area_id ? `/${area_id}` : ''}`
    );
    //well this doesnt look disgusting at all
    return new StructuredResponse<T[][]>(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(
        `${apiAddress}${area_id ? `/${area_id}` : ''}`
      );
      return new StructuredResponse<T[][]>(true, response.data);
    } catch (e: any) {
      return new StructuredResponse<T[][]>(false, e);
    }
  }
};
