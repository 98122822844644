import { AreaBase } from '../Classes/Area';
import StructuredResponse from '../Classes/StructuredResponse';

export function shallowCopy<T extends object>(tNew: {}, tCopy: T) {
  return { ...tNew, ...tCopy };
}

export function shallowCompare<T extends object>(tThis: T, tCompare: T) {
  const keys = Object.keys(tThis);

  for (const key of keys) {
    if (tThis[key] !== tCompare[key]) return false;
  }
  return true;
}
export function findIgnoreCaseSet(set: Set<string>, value: string) {
  //find an exact match while ignoring case
  const regex = new RegExp('^' + value + '$', 'i');
  const ret = [...set.values()].find((c) => c.match(regex));
  //The value '' should always be ignored since it should only be read
  //if the success field is true
  return new StructuredResponse(ret !== undefined, ret ? ret : '');
}

export function findIgnoreCaseArray(arr: string[], value: string) {
  //find an exact match while ignoring case
  const regex = new RegExp('^' + value + '$', 'i');
  const ret = arr.find((c) => c.match(regex));
  //The value '' should always be ignored since it should only be read
  //if the success field is true
  return new StructuredResponse(ret !== undefined, ret ? ret : '');
}

/**
 * @pure does not modify original arrays
 * @param from Array you want to remove elements from
 * @param other Array of elements that you want to remove
 * @param property The property to compare by - should be unique
 * @returns Set difference of {from} - {other}
 */
export function removeElementByProperty<T extends Object>(
  from: T[],
  other: T[],
  property: keyof T
) {
  const compare: any[] = [];
  for (const element of other) {
    compare.push(element[property]);
  }

  return from.filter((element) => {
    return !compare.includes(element[property]);
  });
}

export function containsElementWithValue<
  T extends Object,
  TArr extends Array<T>
>(array: TArr, property: keyof T, value: unknown) {
  for (const element of array) {
    if (element[property] === value) return true;
  }
  return false;
}

export function mapFromDto<TRecipient extends {}, TSource extends {}>(
  from: TSource
) {
  const to = {} as TRecipient;
  for (const key of Object.keys(from)) if (key in to) to[key] = from[key];

  return to;
}

/**
 * Shorthand for checking controlled values
 * @param val the value to check
 * @param def default value
 * @returns `val`, if it is defined or not null. `def`, otherwise
 */
export function valOrDef<T>(val: T | null | undefined, def: T) {
  if (!(val === null || val === undefined)) return val;
  return def;
}

/**
 * Shorthand for checking controlled strings
 * @param str the string to check
 * @returns `val`, if it is defined or not null, `""` otherwise
 */
export function strOrDef(str: string | null | undefined) {
  return valOrDef(str, '');
}

/**
 * Shorthand for checking controlled numbers
 * @param num the number to check
 * @returns `num`, if it is defined or not null, zero (`0`) otherwise
 */
export function numOrDef(num: number | null | undefined) {
  return valOrDef(num, 0);
}

/**
 * Shorthand for checking controlled numbers represented as strings in the UI
 * @param numStr the number to check
 * @returns `numStr.toString()`, if it is defined or not null, `"0"` otherwise
 */
export function numStrOrDef(numStr: number | null | undefined) {
  return numOrDef(numStr).toString();
}

export function configureObservableAreas<
  T extends AreaBase,
  TCollection extends T[] | T[][]
>(
  collection: TCollection,
  keys: Array<{ area_id: number; primary: number }>
): Map<number, Array<T>> {
  if (Array.isArray(collection[0]))
    return sortObservableMap(collection as T[][], keys);
  return sortArrayIntoObservableMap(collection as T[], keys);
}

function sortObservableMap<T extends AreaBase>(
  collection: Array<Array<T>>,
  keys: Array<{ area_id: number; primary: number }>
): Map<number, Array<T>> {
  //This is probably very shit
  const ret = new Map<number, Array<T>>();
  const primaryKeys = keys.filter((key) => !!key.primary);
  const secondaryKeys = keys.filter((key) => !!!key.primary);
  const all = collection.flatMap((a) => a.map((b) => b.id!));

  for (const branch of collection) {
    for (const root of primaryKeys) {
      if (containsElementWithValue<T, T[]>(branch, 'id', root.area_id)) {
        ret.set(root.area_id, branch);
        break;
      }
    }

    for (const sRoot of secondaryKeys) {
      const amount = all.filter((a) => a === sRoot.area_id);
      if (amount.length === 1) {
        if (containsElementWithValue<T, T[]>(branch, 'id', sRoot.area_id)) {
          ret.set(sRoot.area_id, branch);
          break;
        }
      }
    }
  }
  return ret;
}

function sortArrayIntoObservableMap<T extends AreaBase>(
  collection: Array<T>,
  keys: Array<{ area_id: number; primary: number }>
): Map<number, Array<T>> {
  const ret = new Map();
  keys.forEach((root) => {
    if (containsElementWithValue<T, T[]>(collection, 'id', root.area_id)) {
      ret.set(root.area_id, collection);
      return;
    }
  });

  return ret;
}
