import axios from 'axios';
import StructuredResponse from '../../model/Classes/StructuredResponse';
axios.defaults.withCredentials = true;

const tokenURL = '/api/auth/token';
const baseUrl = '/api/user/chargingAccess';

/**
 * Service function responsible for fetching all chargepoints belong to Wattery invoiced areas the user has access to.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @returns response from backend
 */
export const getChargingAccessWatteryOnly = async () => {
  try {
    const response = await axios.get(`${baseUrl}/watteryOnly`);
    return new StructuredResponse<any[]>(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(`${baseUrl}/watteryOnly`);
      return new StructuredResponse<any[]>(true, response.data);
    } catch (e: any) {
      return new StructuredResponse<any[]>(true, e);
    }
  }
};

export const getChargingAccessReporting = async () => {
  try {
    const response = await axios.get(`${baseUrl}/reporting`);
    return new StructuredResponse<any[]>(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(`${baseUrl}/reporting`);
      return new StructuredResponse<any[]>(true, response.data);
    } catch (e: any) {
      return new StructuredResponse<any[]>(true, e);
    }
  }
};
