import styled from 'styled-components';

/**
 * Styled component for the admin page
 */
export const StyledAdminPage = styled.div`
    display: flex;
    width: 100vw;
    height: 80vh;
    justify-content: space-evenly;
    flex-flow: row wrap;
    overflow-x: auto;
    background: ${({ theme }) => theme.background}
    color: ${({ theme }) => theme.text};
    font-size: 1rem;

    .back-button {
        text-align: left;
    }
`;
