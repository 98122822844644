import axios from 'axios';
import StructuredOcppResponse from '../../model/Classes/StructuredOcppResponse';
axios.defaults.withCredentials = true;

const baseURL = '/api/transactions/unlock';
const tokenURL = '/api/auth/token';

const unlock = async (connectorData) => {
  try {
    const response = await axios.post(`${baseURL}`, connectorData, {
      withCredentials: true,
    });
    return new StructuredOcppResponse(
      true,
      response.data.result,
      response.data.message,
      null
    );
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.post(`${baseURL}`, connectorData, {
        withCredentials: true,
      });
      return new StructuredOcppResponse(
        true,
        response.data.result,
        response.data.message,
        null
      );
    } catch (e: any) {
      return new StructuredOcppResponse(false, e, null, e.message);
    }
  }
};

export default unlock;
