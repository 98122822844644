import axios from 'axios';
axios.defaults.withCredentials = true;

const tokenURL = '/api/auth/token';
const baseUrl = '/api/user/paymentMethodStatus';

/**
 * Service function responsible for fetching the payment method status for a user
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @returns response from backend
 */
export const getPaymentMethodStatus = async () => {
  try {
    const response = await axios.get(baseUrl);
    return response.data;
  } catch (e) {
    await axios.post(tokenURL, { withCredentials: true });
    const response = await axios.get(baseUrl);
    return response.data;
  }
};
