import Tag from '../../../model/Classes/Tag';
import { addTag } from '../../../services/userSettings/addTag';

export const addDefaultTag = async () => {
  const appTagData: Tag = {
    id_tag: null,
    nickname: 'App',
    valid_until: null,
    disabled: 0,
  };
  const res = await addTag(appTagData);

  return res;
};
