import axios from 'axios';
import AreaMeter from '../../model/Classes/AreaMeter';
import StructuredResponse from '../../model/Classes/StructuredResponse';
axios.defaults.withCredentials = true;

const baseURL = '/api/area';
const tokenURL = '/api/auth/token';

/**
 * Service function responsible for adding a new area.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @param {*} area_id id of the parent for the area to be added
 * @param {*} data data of the area to be added
 * @returns response from the backend
 */
export const sendMeterChanges = async (
  area_id: number,
  newMeter: AreaMeter,
  oldMeter: AreaMeter,
  had: boolean, //could be static booleans
  has: boolean
) => {
  try {
    const response = await axios.post(`${baseURL}/${area_id}/area_meters`, [
      newMeter,
      oldMeter,
      had,
      has,
      {
        withCredentials: true,
      },
    ]);
    return new StructuredResponse(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.post(`${baseURL}/${area_id}/area_meters`, [
        newMeter,
        oldMeter,
        had,
        has,
        {
          withCredentials: true,
        },
      ]);
      return new StructuredResponse(true, response.data);
    } catch (e: any) {
      return new StructuredResponse(false, e);
    }
  }
};
