import { useState } from 'react';
import {
  Row,
  Col,
  ButtonGroup,
  ToggleButton,
  InputGroup,
  FormControl,
  Form,
  Button,
  Alert,
  Modal,
} from 'react-bootstrap';
import Tooltip from '../../../GeneralComponents/Tooltip';
import infoIcon from '../../../../resources/infoIcon.svg';
import ReactCreditCard from 'react-credit-cards';
import { pad } from '../../../../utils/dates';
import trash from '../../../../resources/trash.svg';
import Card from '../../../../model/Classes/Card';
import { Nullable, StateHandler } from '../../../../model/Utilities/Types';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';

declare interface PaymentMethodSelectionProps {
  paymentMethod: number;
  setPaymentMethod: StateHandler<number>;
  //infoAlreadyExists;
}

export const PaymentMethodSelection = ({
  paymentMethod,
  setPaymentMethod,
}: //infoAlreadyExists,
PaymentMethodSelectionProps) => {
  const { t } = useTranslation('common', { i18n: i18n });
  //Array used for the active/disable radio buttons
  const paymentMethodButtons = [
    { name: t('components.userSettings.tabs.payment.buttons.card'), key: 0 },
    //{ name: 'Invoice via email', key: 1 },
  ];

  return (
    <>
      <Row className="flex-and-center">
        <Col xs="auto" sm="auto" className="payment-method-text-col">
          <h5>{t('components.userSettings.tabs.payment.method')}</h5>
        </Col>
      </Row>
      <Row className="flex-and-center">
        <Col xs="auto" sm="auto">
          <ButtonGroup>
            {paymentMethodButtons.map((radio) => (
              <ToggleButton
                key={radio.key}
                id={`paymentMethodButtons-radio-${radio.key}`}
                type="checkbox"
                variant="outline-success"
                name="radio"
                value={radio.key}
                checked={paymentMethod === radio.key}
                onChange={(event) =>
                  setPaymentMethod(Number(event.target.value))
                }
              >
                {` ${radio.name}`}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </Col>
      </Row>
    </>
  );
};

declare interface InvoiceEmailFormProps {
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  city: string;
  postalCode: string;
  disableFields: boolean;
  setFirstName: StateHandler<string>;
  setLastName: StateHandler<string>;
  setEmail: StateHandler<string>;
  setAddress: StateHandler<string>;
  setCity: StateHandler<string>;
  setPostalCode: StateHandler<string>;
  setAgreeButtonClicked: StateHandler<boolean>;
  setAgreeButton2Clicked: StateHandler<boolean>;
  invalidNameAlert: boolean;
  invalidAddressAlert: boolean;
  invalidEmailAlert: boolean;
  mustAgreeAlert: boolean;
  mustAgree2Alert: boolean;
  infoAlreadyExists: boolean;
  agreeButtonClicked: boolean;
}

export const InvoiceEmailForm = ({
  firstName,
  lastName,
  email,
  address,
  city,
  postalCode,
  agreeButtonClicked,
  disableFields,
  setFirstName,
  setLastName,
  setEmail,
  setAddress,
  setCity,
  setPostalCode,
  setAgreeButtonClicked,
  setAgreeButton2Clicked,
  invalidNameAlert,
  invalidAddressAlert,
  invalidEmailAlert,
  mustAgreeAlert,
  mustAgree2Alert,
  infoAlreadyExists,
}: InvoiceEmailFormProps) => {
  const { t } = useTranslation('common', { i18n: i18n });
  return (
    <>
      <Row>
        <Col>
          <InputGroup className="mb-3">
            <InputGroup.Text id="tagId">
              {t('components.userSettings.tabs.settings.first')} *
            </InputGroup.Text>
            <FormControl
              type="text"
              value={firstName}
              aria-label="First Name"
              aria-describedby="First Name"
              disabled={disableFields}
              onChange={(event) => setFirstName(event.target.value)}
            />
          </InputGroup>
        </Col>
        <Col>
          <InputGroup className="mb-3">
            <InputGroup.Text id="tagId">
              {t('components.userSettings.tabs.settings.last')} *
            </InputGroup.Text>
            <FormControl
              type="text"
              value={lastName}
              aria-label="Last Name"
              aria-describedby="Last Name"
              disabled={disableFields}
              onChange={(event) => setLastName(event.target.value)}
            />
          </InputGroup>
        </Col>
      </Row>
      {invalidNameAlert && (
        <Row>
          <Alert variant="danger">{t('global.alert.failure.name')}</Alert>
        </Row>
      )}
      <Row>
        <InputGroup className="mb-3">
          <InputGroup.Text id="tagId">
            {t('components.loginPage.static.email')} *
          </InputGroup.Text>
          <FormControl
            type="text"
            value={email}
            aria-label="Email"
            aria-describedby="Email"
            disabled={disableFields}
            onChange={(event) => setEmail(event.target.value)}
          />
        </InputGroup>
        {invalidEmailAlert && (
          <Alert variant="danger">
            {t('global.alert.failure.invalidEmail')}
          </Alert>
        )}
      </Row>
      <Row>
        <InputGroup className="mb-3">
          <InputGroup.Text id="tagId">
            {t('components.activeTransactions.table.address')} *
          </InputGroup.Text>
          <FormControl
            type="text"
            value={address}
            aria-label="Address"
            aria-describedby="Address"
            disabled={disableFields}
            onChange={(event) => setAddress(event.target.value)}
          />
        </InputGroup>
      </Row>
      <Row>
        <Col>
          <InputGroup className="mb-3">
            <InputGroup.Text id="tagId">
              {t('components.userSettings.tabs.payment.form.city')} *
            </InputGroup.Text>
            <FormControl
              type="text"
              value={city}
              aria-label="City"
              aria-describedby="City"
              disabled={disableFields}
              onChange={(event) => setCity(event.target.value)}
            />
          </InputGroup>
        </Col>
        <Col>
          <InputGroup className="mb-3">
            <InputGroup.Text id="tagId">
              {t('components.userSettings.tabs.payment.form.zip')} *
            </InputGroup.Text>
            <FormControl
              type="text"
              value={postalCode}
              aria-label="Postal Code"
              aria-describedby="Postal Code"
              disabled={disableFields}
              onChange={(event) => setPostalCode(event.target.value)}
            />
          </InputGroup>
        </Col>
      </Row>
      {invalidAddressAlert && (
        <Row>
          <Alert variant="danger">
            {t('global.alert.failure.invalidAddress')}
          </Alert>
        </Row>
      )}
      {!infoAlreadyExists && (
        <>
          <Row>
            <Form.Group
              controlId="formGridTos"
              onChange={() => setAgreeButtonClicked((r: any) => !r)}
            >
              <Form.Check
                type="checkbox"
                disabled={disableFields}
                label={
                  <p>
                    *{' '}
                    {t(
                      'components.userSettings.tabs.payment.buttons.paymentTos.byClicking1'
                    )}{' '}
                    <a
                      href="/payment-terms-of-service"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t(
                        'components.userSettings.tabs.payment.buttons.paymentTos.check'
                      )}
                    </a>{' '}
                    (
                    {t(
                      'components.userSettings.tabs.payment.buttons.paymentTos.finnish'
                    )}
                    ).
                  </p>
                }
              />
            </Form.Group>
            {mustAgreeAlert && (
              <Alert variant="danger">
                {t(
                  'components.userSettings.tabs.payment.buttons.paymentTos.agree1'
                )}
              </Alert>
            )}
          </Row>
          <Row>
            <Form.Group
              controlId="formGridSecondAgree"
              onChange={() => setAgreeButton2Clicked((r: any) => !r)}
            >
              <Form.Check
                type="checkbox"
                disabled={disableFields}
                label={
                  <p>
                    *{' '}
                    {t(
                      'components.userSettings.tabs.payment.buttons.paymentTos.byClicking2'
                    )}
                  </p>
                }
              />
            </Form.Group>
            {mustAgree2Alert && (
              <Alert variant="danger">
                {t(
                  'components.userSettings.tabs.payment.buttons.paymentTos.agree2'
                )}
              </Alert>
            )}
          </Row>
        </>
      )}
    </>
  );
};

declare interface InvoiceCardProps {
  cards: Nullable<Card[]>;
  handleAddCard: () => Promise<void>;
  handleAddCardFail: boolean;
  successfullAddCard: boolean;
  failedAddCard: boolean;
  canceledAddCard: boolean;
  existsAddCard: boolean;
  changeDefaultCard: (card: Card) => Promise<void>;
  handleChangeDefaultCardFail: boolean;
  handleChangeDefaultCardSuccess: boolean;
  disableChangeDefault: boolean;
  deleteCard: (
    card: Card,
    setShowModal: StateHandler<boolean>
  ) => Promise<void>;
  cardDeletedSuccess: boolean;
  cardDeletedFail: boolean;
  cantRemoveCard: boolean;
}

export const InvoiceCard = ({
  cards,
  handleAddCard,
  handleAddCardFail,
  successfullAddCard,
  failedAddCard,
  canceledAddCard,
  existsAddCard,
  changeDefaultCard,
  handleChangeDefaultCardFail,
  handleChangeDefaultCardSuccess,
  disableChangeDefault,
  deleteCard,
  cardDeletedSuccess,
  cardDeletedFail,
  cantRemoveCard,
}: InvoiceCardProps) => {
  const createNumber = (lastDigits: string) => {
    // magic number ? :D
    return `${Array(16 - lastDigits.length)
      .fill('*')
      .join('')}${lastDigits}`;
  };
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [cardToBeDeleted, setCardToBeDeleted] = useState<Nullable<Card>>(null);

  const trashCanClicked = (card: Card) => {
    setCardToBeDeleted(card);
    setShowDeleteModal(true);
  };

  const closeModal = () => {
    setCardToBeDeleted(null);
    setShowDeleteModal(false);
  };
  const { t } = useTranslation('common', { i18n: i18n });
  return (
    <>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>
            {t('components.userSettings.tabs.payment.modal.header')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('components.userSettings.tabs.payment.modal.body.ifAdd')}</p>
          <Form.Group onChange={() => setAgreed((r) => !r)}>
            <Form.Check
              type="checkbox"
              label={
                <p>
                  *{' '}
                  <a
                    href="/payment-terms-of-service"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t(
                      'components.userSettings.tabs.payment.buttons.paymentTos.check'
                    )}
                  </a>
                </p>
              }
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleAddCard} disabled={!agreed}>
            {t('global.buttons.add.card')}
          </Button>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            {t('global.buttons.cancel')}
          </Button>
        </Modal.Footer>
      </Modal>

      {handleAddCardFail && (
        <Row>
          <Alert variant="danger">{t('global.view.errorAdmin')}</Alert>
        </Row>
      )}
      {successfullAddCard && (
        <Row>
          <Alert variant="success">{t('global.alert.success.card')}</Alert>
        </Row>
      )}
      {failedAddCard && (
        <Row>
          <Alert variant="danger">{t('global.alert.failure.cardError')}</Alert>
        </Row>
      )}
      {canceledAddCard && (
        <Row>
          <Alert variant="warning">
            {t('global.alert.failure.cardCancel')}
          </Alert>
        </Row>
      )}
      {existsAddCard && (
        <Row>
          <Alert variant="warning">
            {t('global.alert.failure.cardAlready')}
          </Alert>
        </Row>
      )}
      <Row className="mb-3 mt-2 flex-and-center">
        <Col xs="auto" sm="auto">
          <h5>
            {cards!.length > 0
              ? t('components.userSettings.tabs.payment.userCards.has')
              : t('components.userSettings.tabs.payment.userCards.hasNot')}
          </h5>
        </Col>
      </Row>
      {cards && (
        <Row className="flex-and-center mb-3">
          {cards!.map((card: Card) => {
            return (
              <Col xs="auto" sm="auto" key={card.id}>
                <div className="credit-card-wrapper">
                  <ReactCreditCard
                    cvc="***"
                    expiry={`${pad(card.expire_month)}/${pad(
                      card.expire_year
                    )}`}
                    number={createNumber(card.partial_pan)}
                    name={''}
                    issuer={card.type}
                    preview
                  />
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <ToggleButton
                            value={card.is_default}
                            type="checkbox"
                            variant="outline-success"
                            checked={!!card.is_default}
                            onClick={() => changeDefaultCard(card)}
                            className="default-card-button credit-card-button"
                            disabled={disableChangeDefault}
                          >
                            {t('global.generalWords.default')}
                          </ToggleButton>
                        </td>
                        <td>
                          <Button
                            variant="danger"
                            className="delete-card-button credit-card-button"
                            onClick={() => trashCanClicked(card)}
                          >
                            <img src={trash} alt="delete" />
                          </Button>
                          <Modal show={showDeleteModal} onHide={closeModal}>
                            <Modal.Header>
                              <Modal.Title>
                                {t(
                                  'components.userSettings.tabs.payment.modal.body.sure'
                                )}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              {t(
                                'components.userSettings.tabs.payment.modal.body.deleteCard'
                              )}{' '}
                              {cardToBeDeleted
                                ? cardToBeDeleted.partial_pan
                                : ''}
                              ?
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                variant="danger"
                                onClick={() =>
                                  deleteCard(
                                    cardToBeDeleted!,
                                    setShowDeleteModal
                                  )
                                }
                              >
                                {t('global.buttons.delete')}
                              </Button>
                              <Button variant="secondary" onClick={closeModal}>
                                {t('global.buttons.cancel')}
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Col>
            );
          })}
        </Row>
      )}
      {handleChangeDefaultCardFail && (
        <Alert variant="danger">
          {t('global.alert.failure.defaultCardChange')}
        </Alert>
      )}
      {handleChangeDefaultCardSuccess && (
        <Alert variant="success">
          {t('global.alert.success.defaultCardChange')}
        </Alert>
      )}
      {cardDeletedSuccess && (
        <Row>
          <Alert variant="success">
            {t('global.alert.success.cardDelete')}
          </Alert>
        </Row>
      )}
      {cardDeletedFail && (
        <Row>
          <Alert variant="danger">{t('global.alert.failure.cardDelete')}</Alert>
        </Row>
      )}
      {cantRemoveCard ? (
        <Alert variant="warning">{t('global.alert.failure.outstanding')}</Alert>
      ) : null}
      <Row className="mb-3 mt-3 flex-and-center">
        <Col xs="auto" sm="auto">
          <Button onClick={() => setShowModal(true)}>
            {t('global.buttons.add.card')}
          </Button>
        </Col>
      </Row>
    </>
  );
};

declare interface SubmitButtonProps {
  disableSubmitButton: boolean;
  handleSubmit: () => Promise<void>;
  disableFields: boolean;
  infoAlreadyExists: boolean;
}

export const SubmitButton = ({
  disableSubmitButton,
  handleSubmit,
  disableFields,
  infoAlreadyExists,
}: SubmitButtonProps) => {
  const { t } = useTranslation('common', { i18n: i18n });
  return (
    <Row>
      <Col xs="auto" sm="auto" style={{ paddingRight: 0 }}>
        <Button
          variant="primary"
          onClick={handleSubmit}
          id="submit-button"
          disabled={disableFields || disableSubmitButton}
        >
          {t('global.buttons.submit')}
        </Button>
      </Col>
      {infoAlreadyExists && (
        <Col xs="auto" sm="auto" className="payment-method-text-col">
          <Tooltip
            text={
              <div //icky wicky
                dangerouslySetInnerHTML={{
                  __html: t(
                    'components.userSettings.tabs.payment.invoiceInfoExists'
                  ),
                }}
              />
            }
          >
            <img className="infoIcon" src={infoIcon} alt="info" />
          </Tooltip>
        </Col>
      )}
    </Row>
  );
};
