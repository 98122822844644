import axios from 'axios';
import AreaMeterValue from '../../model/Classes/AreaMeterValues';
import StructuredResponse from '../../model/Classes/StructuredResponse';
axios.defaults.withCredentials = true;

const tokenURL = '/api/auth/token';

/**
 * Service function responsible for fetching all the meter values for an area
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @returns if successful: [true, response.data]. Otherwise: [false, error]
 */

export const getAreaMeterValues = async (data: any) => {
  let success = true;
  try {
    const response = await axios.post(`/api/area/meterValues`, data);
    return new StructuredResponse<AreaMeterValue[]>(success, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.post(`/api/area/meterValues`, data);
      return new StructuredResponse<AreaMeterValue[]>(success, response.data);
    } catch (e: any) {
      success = false;
      return new StructuredResponse<AreaMeterValue[]>(success, e);
    }
  }
};
