import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import { Col, Row, Alert } from 'react-bootstrap';
import BootstrapTable, {
  ColumnDescription,
  SelectRowProps,
} from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { CSVLink } from 'react-csv';
import { ReportingTransaction } from '../../../model/Classes/Transaction';

declare interface TransactionTableProps {
  rangeAreaName: string;
  rangeText: { time: string; displayTime: string; period: string };
  selectedTransactions: ReportingTransaction[];
  headers?: { label: string; key: string }[];
  noRowChosen: boolean;
  notReportingSite: boolean;
  createInvoiceSuccess: boolean;
  createInvoiceFail: boolean;
  allTransactions: ReportingTransaction[];
  displayTransactions: ReportingTransaction[];
  columnsForTable: ColumnDescription<ReportingTransaction>[];
  selectRowProps: SelectRowProps<ReportingTransaction>;
  cardsSuccess: boolean;
  cardsFail: boolean;
}

export const TransactionTable = ({
  rangeAreaName,
  rangeText,
  selectedTransactions,
  headers,
  noRowChosen,
  notReportingSite,
  createInvoiceSuccess,
  createInvoiceFail,
  allTransactions,
  displayTransactions,
  columnsForTable,
  selectRowProps,
  cardsSuccess,
  cardsFail,
}: TransactionTableProps) => {
  const { t } = useTranslation('common', { i18n: i18n });

  const calculateSelectedKWh = () => {
    let totalKWh = 0;
    for (const transaction of selectedTransactions) {
      totalKWh += Number(transaction.kWh);
    }
    return totalKWh.toFixed(2);
  };

  const calculateSelectedPrice = () => {
    let totalPrice = 0;
    for (const transaction of selectedTransactions) {
      totalPrice += Number(transaction.total_cost);
    }
    return totalPrice.toFixed(2);
  };
  const modifiedDatawithConditional = selectedTransactions.map((row) => ({
    ...row,
    kWh:
      typeof row.kWh === 'string'
        ? row.kWh.toString().replace('.', ',')
        : row.kWh,
    total_cost:
      typeof row.total_cost === 'string'
        ? row.total_cost.toString().replace('.', ',')
        : row.total_cost,
  }));
  return (
    <Col className="column">
      <Row>
        <Col>
          {/*Headers for the area and period the search was conducted for*/}
          <h5>
            {t('components.reporting.tabs.completed.area')}: {rangeAreaName}
          </h5>
          <h5>
            {t('components.reporting.tabs.completed.period')}:{' '}
            {`${
              t(
                `components.reporting.tabs.completed.timePeriod.${rangeText.period}`
              ).length > 20
                ? ''
                : t(
                    `components.reporting.tabs.completed.timePeriod.${rangeText.period}`
                  )
            } ${
              rangeText.period.length === 0
                ? rangeText.time
                : rangeText.displayTime
            }`}
          </h5>

          {/*Row containing the "Export to .csv" link. The component responsible for doing this a library
                  called react-csv, which contains a component called CSVLink*/}
          <Row className="mb-2">
            {/*Component responsible for creating a .csv file and downloading it to the users machine. 
                      The component takes all the transactions as its data, the headers array for the headers in 
                      the .csv file, and lastly the filename for the file (which is in the form 
                      "'areaThatWasSearchedFrom'_'periodThatWasSearchedFor'.csv"*/}

            <CSVLink
              data={modifiedDatawithConditional}
              headers={headers}
              filename={`${rangeAreaName}_${rangeText.time}.csv`}
              className="csv-link"
              separator=";"
              enclosingCharacter=""
            >
              {t('components.reporting.tabs.completed.export')}
            </CSVLink>
          </Row>
        </Col>
        <Col className="pb-3 invoice-alert-col">
          {noRowChosen && (
            <Alert variant="warning">
              {t('global.alert.failure.selectUser')}
            </Alert>
          )}
          {notReportingSite && (
            <Alert variant="warning">
              {t('global.alert.failure.selectReportingSite')}
            </Alert>
          )}
          {createInvoiceSuccess && (
            <Alert variant="success">
              {t('global.alert.success.invoices')}
            </Alert>
          )}
          {createInvoiceFail && (
            <Alert variant="danger">{t('global.alert.failure.invoices')}</Alert>
          )}
          {cardsSuccess && (
            <Alert variant="success">{t('global.alert.success.cards')}</Alert>
          )}
          {cardsFail && (
            <Alert variant="danger">{t('global.alert.failure.cards')}</Alert>
          )}
        </Col>
      </Row>
      <Row>
        {/*Table containing the reports*/}
        <ToolkitProvider
          bootstrap4
          keyField="email"
          data={displayTransactions}
          columns={columnsForTable}
        >
          {(props) => (
            <>
              <BootstrapTable
                striped
                bordered
                hover
                noDataIndication={t(
                  'components.reporting.tabs.completed.table.noData'
                )}
                selectRow={selectRowProps}
                {...props.baseProps}
                pagination={paginationFactory({
                  hidePageListOnlyOnePage: true,
                  sizePerPageList: [
                    { text: '10', value: 10 },
                    { text: '20', value: 20 },
                    { text: '30', value: 30 },
                    { text: '50', value: 50 },
                    { text: '100', value: 100 },
                    { text: '200', value: 200 },
                  ],
                })}
              />
              {/**{selectedTransactions.length > 0 && ( */}
              <div>
                <Row>
                  <Col>
                    <p>
                      {t('components.reporting.tabs.completed.table.totalkWh')}
                      {calculateSelectedKWh()}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>
                      {t(
                        'components.reporting.tabs.completed.table.totalPrice'
                      )}
                      {calculateSelectedPrice()}
                    </p>
                  </Col>
                </Row>
              </div>
              {/**  )}*/}
            </>
          )}
        </ToolkitProvider>
      </Row>
    </Col>
  );
};
