import React, { useEffect } from 'react';
import { bool, func } from 'prop-types';
import { StyledBurger } from './Burger.styled';
import { Nullable, StateHandler } from '../../../model/Utilities/Types';
import User from '../../../model/Classes/User';

declare interface BurgerProps {
  open: boolean;
  setOpen: StateHandler<boolean>;
  user: Nullable<User>;
}
/**
 * Component for displaying the hamburger menu toggler at the top left of the screen. This component
 * was created at the very beginning of us starting this project, and I followed a tutorial for
 * creating this, so I'm not 100% sure how it works...
 * @returns hamburger menu toggler
 */
const Burger = ({ open, setOpen, user, ...props }: BurgerProps) => {
  //Needed for the aria-expanded prop for the StyledBurger styled-component
  const isExpanded = open ? true : false;

  useEffect(() => {
    if (document.querySelector('.top-level-component')) {
      if (isExpanded) {
        //idk what to do honestly fuck around and find out
        document.querySelector('body')!.classList.add('overflow-y-hidden');
      } else {
        document.querySelector('body')!.classList.remove('overflow-y-hidden');
      }
    }
  }, [isExpanded]);

  if (!user) return null; //Do not show the hamburger menu toggler when the user is not logged in

  /**
   * Returns three spans (one for each line of the hamburger) wrapped around the StyledBurger
   * styled-component.
   */
  return (
    <StyledBurger
      aria-label="Toggle menu"
      aria-expanded={isExpanded}
      open={open}
      onClick={() => {
        window.scrollTo(0, 0);
        setOpen(!open);
      }}
      name="burger"
      {...props}
    >
      <span />
      <span />
      <span />
    </StyledBurger>
  );
};

Burger.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};

export default Burger;
