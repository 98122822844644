import axios from 'axios';
import StructuredResponse from '../../model/Classes/StructuredResponse';
axios.defaults.withCredentials = true;

const baseUrl = '/api/user/chargingAccess/specific';
const tokenURL = '/api/auth/token';

/**
    Return new StructuredResponse(true, data) only if user has access,
    otherwise return new StructuredResponse(false, data)
    or new StructuredResponse(false, error)
 */
export const getAccessToCp = async (charge_point_id: string) => {
  try {
    const response = await axios.get(`${baseUrl}/${charge_point_id}`, {
      withCredentials: true,
    });
    return new StructuredResponse(response.data.length > 0, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(`${baseUrl}/${charge_point_id}`, {
        withCredentials: true,
      });
      return new StructuredResponse(response.data.length > 0, response.data);
    } catch (e: any) {
      return new StructuredResponse(false, e);
    }
  }
};
