import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { emailRegex } from '../../utils/emailRegex';
import { useEffect, useState } from 'react';
import { StateHandler } from '../../model/Utilities/Types';

declare interface DLFProps {
  header: string;
  array: any[];
  setArray: StateHandler<any[]>;
}

export default function DynamicListForm({ header, array, setArray }: DLFProps) {
  const [focusIndex, setFocusIndex] = useState(0);

  useEffect(() => {
    if (array.length === 0) {
      setArray(['']);
    }
    //eslint-disable-next-line
  }, []);

  function addField(): void {
    setArray([...array, '']);
    setFocusIndex(array.length - 1);
  }

  useEffect(() => {
    if (
      (array.length > 1 &&
        document.activeElement &&
        document.activeElement ===
          document.getElementById(`dynamic-list-${array.length - 2}`)) ||
      focusIndex > 0
    )
      document.getElementById(`dynamic-list-${array.length - 1}`)?.focus();
  }, [array, focusIndex]);

  const handleFormChange = (index: number, event) => {
    let data = [...array];
    data[index] = event.target.value;
    setArray(data);
  };

  return (
    <>
      <InputGroup.Text className="mb-3">{header}</InputGroup.Text>
      <div className="mb-3">
        {array.map((a, i) => {
          return (
            <InputGroup
              style={{ left: '2%', maxWidth: '98%' }}
              key={i}
              className="mb-3"
            >
              {i === array.length - 1 ? (
                <Button
                  onClick={() => addField()}
                  disabled={!emailRegex.test(a)}
                >
                  +
                </Button>
              ) : (
                <Button disabled={true}>{i + 1}.</Button>
              )}
              <FormControl
                type="text"
                id={`dynamic-list-${i}`}
                value={a}
                onChange={(event) => handleFormChange(i, event)}
                onKeyDown={(event) => {
                  if (
                    event.key === 'Enter' &&
                    i === array.length - 1 &&
                    emailRegex.test(a)
                  ) {
                    addField();
                  }
                }}
              />
            </InputGroup>
          );
        })}
      </div>
    </>
  );
}
