import axios from 'axios';
import StructuredResponse from '../../model/Classes/StructuredResponse';
axios.defaults.withCredentials = true;

const tokenURL = '/api/auth/token';

export const getPaymentsOutstanding = async () => {
  let success = true;
  try {
    const response = await axios.get('/api/user/payments/outstanding', {
      withCredentials: true,
    });
    return new StructuredResponse<{ total_price: number }>(
      success,
      response.data
    );
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get('/api/user/payments/outstanding', {
        withCredentials: true,
      });
      return new StructuredResponse<{ total_price: number }>(
        success,
        response.data
      );
    } catch (e: any) {
      success = false;
      return new StructuredResponse<{ total_price: number }>(success, e);
    }
  }
};

export const getInvoicesOutstanding = async () => {
  let success = true;
  try {
    const response = await axios.get('/api/user/invoices/outstanding', {
      withCredentials: true,
    });
    return new StructuredResponse(success, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get('/api/user/invoices/outstanding', {
        withCredentials: true,
      });
      return new StructuredResponse(success, response.data);
    } catch (e: any) {
      success = false;
      return new StructuredResponse(success, e);
    }
  }
};
