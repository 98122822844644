import axios from 'axios';
import StructuredResponse from '../../model/Classes/StructuredResponse';
axios.defaults.withCredentials = true;

const baseURL = '/api/area';
const tokenURL = '/api/auth/token';

/**
 * Service function responsible for adding a new area.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @param {*} parentId id of the parent for the area to be added
 * @param {*} data data of the area to be added
 * @returns response from the backend
 */

export const addArea = async (parentId: number, data: any) => {
  try {
    const response = await axios.post(`${baseURL}/${parentId}/add`, data, {
      withCredentials: true,
    });
    return new StructuredResponse(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.post(`${baseURL}/${parentId}/add`, data, {
        withCredentials: true,
      });
      return new StructuredResponse(true, response.data);
    } catch (e: any) {
      return new StructuredResponse(false, e);
    }
  }
};

export const addDefaultArea = async (parentId: number, data: any) => {
  try {
    const response = await axios.post(
      `/api/admin/area/${parentId}/addDefault`,
      data,
      {
        withCredentials: true,
      }
    );
    return new StructuredResponse(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.post(
        `/api/admin/area/${parentId}/addDefault`,
        data,
        {
          withCredentials: true,
        }
      );
      return new StructuredResponse(true, response.data);
    } catch (e: any) {
      return new StructuredResponse(false, e);
    }
  }
};
