import { useEffect, useState, useCallback, Fragment } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { findIgnoreCaseArray } from "../../../model/Utilities/Extensions";
import { TransactionCp, ChargepointClass } from "../../../model/Classes/Chargepoint";
import {
  ChargepointDropdownFlags,
  ChargepointDropdownStrings,
  FlagError,
  StateHandler,
} from "../../../model/Utilities/Types";
import { getAccessToCp } from "../../../services/chargepoint/getAccessToCp";
import { toggleAlertsOff } from "../../../utils/toggleAlertsOff";
import AsyncSelect from "react-select/async";
import { components, GroupProps } from "react-select";

declare interface ChargepointDropdownProps {
  allChargepoints: TransactionCp[];
  publicChargepoints?: TransactionCp[];
  allChargepointsReal?: TransactionCp[];
  cpId?: string;
  formDisable?: boolean;
  setCpId: StateHandler<string>;
  cpIdEmptyAlert: boolean;
  setCpIdEmptyAlert: StateHandler<boolean>;
  setNoAccess?: StateHandler<boolean>;
  setCpDisabled: StateHandler<boolean>;
  flags?: ChargepointDropdownFlags[] | ChargepointDropdownStrings[];
  selectChargepoint?: (cpId: string, priv: TransactionCp[], pub?: TransactionCp[], recentCp?: TransactionCp[]) => void;
  recentCp?: TransactionCp[];
  cpLabel: string;
}

/**
 * Component responsible for displaying the chargepoint id row containing:
 * - A dropdown containing the chargepoints the user has access to. If the user doesn't have access to any chargepoints,
 *   the dropdown will not contain anything.
 * - An input field where the user can type in the chargepoint id. If the user selects a chargepoint id from the dropdown,
 *   the field is updated with the selected chargepoint automatically.
 * - A short text informing the user of the actions they can take.
 **/

const CustomGroup = (props: GroupProps<any>) => (
  <Fragment>
    {
      // @ts-ignore
      !!!props.data.isHidden && <components.Group {...props} />
    }
  </Fragment>
);

const ChargepointDropdown = ({
  allChargepoints,
  publicChargepoints,
  allChargepointsReal,
  cpId,
  setCpId,
  setCpIdEmptyAlert,
  setNoAccess,
  setCpDisabled,
  flags = [],
  selectChargepoint,
  recentCp,
  cpLabel,
}: ChargepointDropdownProps) => {
  const [chargepointIdSub] = useState(() => new Set<string>());
  const [allChargepointsWithPublic] = useState(() =>
    !publicChargepoints ? allChargepoints : [...allChargepoints, ...publicChargepoints]
  );
  const [isInput, setIsInput] = useState(false);

  const [selectionState, setSelectionState] = useState<{ label: string; value: string }>();

  //User-input awaiter
  useEffect(() => {
    if (cpId === undefined) return;
    const timer = setTimeout(
      () => {
        if (isInput) handleInputChargepoint(cpId!);
      },
      //Reacts faster when inputField is empty
      cpId.length === 0 ? 10 : 750
    );

    return () => clearTimeout(timer);
    //Doesn't depend on the the value of isInput changing, only whether it's true or not when run
    //Doesn't depend on handleInputChargepoint, only calls it
    //eslint-disable-next-line
  }, [cpId]);

  useEffect(() => {
    allChargepoints.sort((a: TransactionCp, b: TransactionCp) => {
      const a_nick = a.nickname ? a.nickname : a.charge_point_id;
      const b_nick = b.nickname ? b.nickname : b.charge_point_id;
      return b_nick!.toLowerCase() < a_nick!.toLowerCase() ? 1 : -1;
    });
  }, [allChargepoints]);

  const { t } = useTranslation("common", { i18n: i18n });

  const isSameChargePoint = (source: string | undefined, value: string | undefined) => {
    if (!source || !value) return false;
    return source.toLowerCase() === value.toLowerCase();
  };

  const hasAccess = async (cpId: string) => {
    const isPublic =
      publicChargepoints?.find((cp) => {
        return isSameChargePoint(cp.charge_point_id, cpId);
      }) !== undefined;

    const onPrivate = await getAccessToCp(cpId);
    //If it's public, grant access
    //Else, check if user has access
    return onPrivate.success || isPublic;
  };

  const isDisabled = (cpId: string) => {
    return (
      allChargepointsWithPublic.find((cp) => {
        return isSameChargePoint(cp.charge_point_id, cpId);
      })?.disabled === 1
    );
  };

  //Debugging
  useEffect(() => {
    if (flags.includes(ChargepointDropdownFlags.HandleSelection) && !selectChargepoint)
      throw new FlagError(ChargepointDropdownFlags.HandleSelection, "selectChargepoint");
    if (flags.includes(ChargepointDropdownFlags.CheckAccess) && !setNoAccess)
      throw new FlagError(ChargepointDropdownFlags.CheckAccess, "setNoAccess");
    //eslint-disable-next-line
  }, []);

  /**
   * Handles checking if the chargepoint is connected and displays a message if not
   * If there is an error in the database response it will display a different message and empty
   * the inputfield
   * @param {*} dropdownValue value from the dropdown or inputfield if the inputfield is included in chargePointIdSub
   */

  const handleSelectChargepoint = async (dropdownValue: string) => {
    setCpId(dropdownValue);
    if (flags.includes(ChargepointDropdownFlags.HandleSelection)) {
      selectChargepoint!(dropdownValue, allChargepoints, publicChargepoints, recentCp);
    }
    toggleAlertsOff([setCpDisabled, setCpIdEmptyAlert, setIsInput]);
    const disabled = isDisabled(dropdownValue);
    if (disabled) {
      setCpDisabled(true);
      return;
    }
    if (flags.includes(ChargepointDropdownFlags.CheckAccess)) {
      const noAccess = !(await hasAccess(dropdownValue));
      setNoAccess!(noAccess);
      if (noAccess) return;
    }
  };

  // the type for dropdown is changed to any because the compiler was complaining about type error
  const handleSelectChargepointOptions = async (dropdownValue: any) => {
    setCpId(dropdownValue.value);
    if (flags.includes(ChargepointDropdownFlags.HandleSelection)) {
      selectChargepoint!(dropdownValue.value, allChargepoints, publicChargepoints, recentCp);
    }
    toggleAlertsOff([setCpDisabled, setCpIdEmptyAlert, setIsInput]);
    const disabled = isDisabled(dropdownValue.value);
    if (disabled) {
      setCpDisabled(true);
      return;
    }
    if (flags.includes(ChargepointDropdownFlags.CheckAccess)) {
      const noAccess = !(await hasAccess(dropdownValue.value));
      setNoAccess!(noAccess);
      if (noAccess) return;
    }
  };

  /**
   * If userInput exists in our charge_point_idSet, call handleSelectChargepoint and validate.
   * Otherwise tell the user to specify a valid charge_point_id
   * @param {*} inputValue the value changed manually by the user
   */

  const handleInputChargepoint = async (inputValue: any) => {
    setCpIdEmptyAlert(true);
    const found = findIgnoreCaseArray([...chargepointIdSub.values()], inputValue);
    if (found.success) {
      await handleSelectChargepoint(found.data);
    } else {
      if (flags.includes(ChargepointDropdownFlags.CheckAccess)) setNoAccess!(false);
      if (flags.includes(ChargepointDropdownFlags.HandleSelection)) {
        selectChargepoint!(inputValue, allChargepoints, publicChargepoints);
      }
    }
  };

  //Idk dynamic programming i guess
  //Creates a set of valid charge_point_ids which user input is compared against
  const onEnterTab = useCallback(() => {
    if (chargepointIdSub.size === 0)
      if (allChargepointsReal) {
        for (const cp of allChargepointsReal) {
          if (cp.charge_point_id) chargepointIdSub.add(cp.charge_point_id);
        }
      } else {
        for (const cp of allChargepointsWithPublic) if (cp.charge_point_id) chargepointIdSub.add(cp.charge_point_id);
      }
  }, [chargepointIdSub, allChargepointsWithPublic, allChargepointsReal]);

  const onCpIdDefined = (cpId?: string) => {
    if (cpId) {
      handleInputChargepoint(cpId);
    } else {
      setCpId("");
    }
  };

  /**
   * Helper method for defining a Set of chargepoint IDs
   * And checking if a chargepoint is accessed via URL params
   */
  useEffect(() => {
    //Creates the charge_point_id Set on load
    onEnterTab();
    onCpIdDefined(cpId);
    //eslint-disable-next-line
  }, [onEnterTab]);
  //useEffect hook to initially load the recent CP value . NOTE: it also initialises the isInput to true
  useEffect(() => {
    if (recentCp && recentCp.length === 1 && cpId === undefined) {
      setCpId(recentCp?.[0].charge_point_id || "");
      setSelectionState({
        label: recentCp![0].charge_point_id!,
        value: ChargepointClass.toStringWithId(recentCp![0]),
      });
      setIsInput(true);
    }
    //eslint-disable-next-line
  }, []);
  //  filter options based on the search query
  const filterOptions = (inputValue) => {
    // Filter options based on the input value
    return options.flatMap((group) =>
      group.options.filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase()))
    );
  };

  const pvtOptions = allChargepoints.map((option) => ({
    value: option.charge_point_id,
    label: ChargepointClass.toStringWithId(option),
  }));
  const recentOptions = recentCp!.map((option) => ({
    value: option.charge_point_id,
    label: ChargepointClass.toStringWithId(option),
  }));
  const publicOptions = publicChargepoints
    ? publicChargepoints!.map((option) => ({
        value: option.charge_point_id,
        label: ChargepointClass.toStringWithId(option),
      }))
    : [];

  const options = [
    {
      label: `${t("components.startTransaction.view.recentUsed")}`,
      options: recentOptions,
    },
    {
      label: `${t("components.startTransaction.view.privateCp")}`,
      options: pvtOptions,
    },
    {
      label: "public",
      options: publicOptions,
      isHidden: true,
    },
  ];

  /** 
  const [selectionState, setSelectionState] = useState(() => {
    let selectionTemp: { label: string; value: string | undefined } | undefined;
    for (const a of options) {
      selectionTemp = a.options.find((a) => a.label === cpId);
    }

    return selectionTemp;
  });
  */

  //  loadOptions function for AsyncSelect
  const loadOptions = (inputValue, callback) => {
    // Filter options based on the input value
    const filteredOptions = filterOptions(inputValue);

    callback(filteredOptions);
  };

  return (
    <>
      <AsyncSelect
        className="mt-3 w-250 "
        defaultInputValue={cpId}
        defaultOptions={options as unknown as any}
        value={selectionState}
        loadOptions={loadOptions as unknown as any}
        isSearchable={true}
        isClearable
        placeholder={`${t("components.startTransaction.view.cpDropdown")}`}
        components={{ Group: CustomGroup }}
        styles={{
          menu: (provided) => ({
            ...provided,
            zIndex: 1000, // Adjust the z-index value as needed
          }),
        }}
        onChange={(selectedOption) => {
          if (selectedOption) {
            handleSelectChargepointOptions(selectedOption);
          } else {
            // when the selection is cleared
            setCpId("");
          }
        }}
      />
    </>
  );
};

export default ChargepointDropdown;
