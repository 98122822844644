/**
 * Global values for dark and light theme. The dark theme is not used in the application,
 * but it exists here if a light/dark mode functionality will be implemented later on
 */

import { DefaultTheme } from 'styled-components';

//Idk about this class thing, seemed cool
export class DarkTheme implements DefaultTheme {
  body: string = '#0D0C1D';
  text: string = '#FAFAFA';
  toggleBorder: string = '#6B8096';
  background: string = '#0D0C1D';
  menuBackground: string = '#383D3D';
  notification: string = '#00cc00';
  error: string = '#FF0000';
  mobile: string = '576px';
  hover: string = '#3175bd';
}

export class LightTheme implements DefaultTheme {
  body: string = '#FFF';
  text: string = '#3B3C43';
  toggleBorder: string = '#FAFAFA';
  background: string = '#FFF';
  notification: string = '#00cc00';
  error: string = '#FF0000';
  mobile: string = '576px';

  lightGray: string = '#E8E9E8';
  darkGray: string = '#3B3C43';
  cpHealth0Gray: string = '#B9BDB9';
  lightGreen: string = '#00B451';
  lightGreenHover: string = '#00813A';
  mediumGreen: string = '#007D5C';
  darkGreen: string = '#005740';
  red: string = '#DC3545';
  yellow: string = '#FFED00';
  darkYellow: string = '#c7b900';

  menuHover: string = '#CDCDCD';
  menuText: string = '#FFFFFF';

  btnPrimaryHover: string = '#00A552';
  btnPrimaryFocusBoxShadow: string =
    '0 0 0 0.25rem rgb(0 151 75 / 50%) !important';

  mobileS: string = '320px';
  mobileM: string = '375px';
  mobileL: string = '425px';
  tablet: string = '768px';
  laptop: string = '1024px';
  laptopL: string = '1440px';
  fourK: string = '2560px';
}
