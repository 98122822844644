export default function getCurrencySymbol(currency: string | undefined): string {
  switch (currency) {
    case "EUR":
      return "€";
    case "SEK":
      return "kr";
    default:
      return "";
  }
}
