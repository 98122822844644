import axios from 'axios';
import StructuredResponse from '../../../model/Classes/StructuredResponse';
import {
  CompletedTransaction,
  SpotTransactionHistory,
} from '../../../model/Classes/Transaction';
axios.defaults.withCredentials = true;

const tokenURL = '/api/auth/token';

/**
 * Service function responsible for fetching all completed transactions for a specific month for a user.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @param {*} year the specified year
 * @param {*} month the specified month
 * @returns if successful: [true, response.data]. Otherwise: [false, error]
 */
export const getMyMonthlyTransactions = async (year, month) => {
  try {
    const response = await axios.get(`/api/myTransactions/${year}/${month}`);
    return new StructuredResponse<CompletedTransaction[]>(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(`/api/myTransactions/${year}/${month}`);
      return new StructuredResponse<CompletedTransaction[]>(
        true,
        response.data
      );
    } catch (e: any) {
      return new StructuredResponse<CompletedTransaction[]>(false, e);
    }
  }
};
export const getMyMonthlySpotPriceTransactions = async (year, month) => {
  try {
    const response = await axios.get(
      `/api/myTransactions/spotPrice/${year}/${month}`
    );
    return new StructuredResponse<SpotTransactionHistory[]>(
      true,
      response.data
    );
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(
        `/api/myTransactions/spotPrice/${year}/${month}`
      );
      return new StructuredResponse<SpotTransactionHistory[]>(
        true,
        response.data
      );
    } catch (e: any) {
      return new StructuredResponse<SpotTransactionHistory[]>(false, e);
    }
  }
};
