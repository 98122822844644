import axios from 'axios';
import StructuredResponse from '../../model/Classes/StructuredResponse';
axios.defaults.withCredentials = true;

const baseURL = '/api/transactions';
const tokenURL = '/api/auth/token';

/**
 * Service function responsible for checking chargepoint availability.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @param {*} data data object containing the tag id, chargepoint id, and connector id
 * @returns If successful: [true, response.data]. Otherwise, [false, error]
 */
export const checkChargepointAvailability = async (charge_point_id) => {
  try {
    const response = await axios.get(`${baseURL}/inUse/${charge_point_id}`, {
      withCredentials: true,
    });
    return new StructuredResponse(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(`${baseURL}/inUse/${charge_point_id}`, {
        withCredentials: true,
      });
      return new StructuredResponse(true, response.data);
    } catch (e: any) {
      return new StructuredResponse(false, e);
    }
  }
};

export const checkIfCardRequired = async (charge_point_id) => {
  try {
    const response = await axios.get(
      `${baseURL}/cardRequired/${charge_point_id}`,
      {
        withCredentials: true,
      }
    );
    return new StructuredResponse(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(
        `${baseURL}/cardRequired/${charge_point_id}`,
        {
          withCredentials: true,
        }
      );
      return new StructuredResponse(true, response.data);
    } catch (e: any) {
      return new StructuredResponse(false, e);
    }
  }
};
